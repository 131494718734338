/**
 * CustomEvent polyfill for IE
 * Code based on polyfill example provided in
 * https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent
 */
(function () {

  if (typeof window.CustomEvent === 'function') return;

  function CustomEvent(event, params) {
    params = params || { bubbles: false, cancelable: false, detail: null };

    var customEvent = document.createEvent('CustomEvent');

    customEvent.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);

    return customEvent;
   }

  CustomEvent.prototype = window.Event.prototype;

  window.CustomEvent = CustomEvent;
  window.Event = CustomEvent;
})();
