define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = [["de", {
    "a11y": null,
    "active-engagement": {
      "already-escalated": "Dieses Engagement wurde bereits eskaliert",
      "app-chat": "App-{chat}",
      "apple-business-chat": "Apple-Business-{chat}",
      "background-color": "Background Color",
      "bold": "Bold",
      "channel": "Channel",
      "close": "Schließen",
      "close-chat": "Chat schließen",
      "conference": "Konferenz",
      "conversation": "Conversation",
      "copy": "Copy",
      "copy-masked-transcript": "Copy Transcript",
      "copy-transcript-notification": "Transcript Copied",
      "desktop-chat": "Desktop-{chat}",
      "device": "Device",
      "eapi": "EAPI",
      "engagement": "Engagement",
      "engagement-action-disabled-follow-up": "Deaktiviert bis zum Engagement-Beginn",
      "escalate": "Eskalieren",
      "escalate-chat": "Chat-Eskalation",
      "escalated": "Escaliert",
      "facebook-chat": "Facebook-Messenger-{chat}",
      "file-upload": "File Upload",
      "follow-up": "Verfolgen",
      "general-chat": "General {chat}",
      "googles-business-messages-chat": "Google's-Business-Messages-{chat}",
      "hide-details": "Details verbergen",
      "hide-editor": "Hide Editor",
      "hide-file-upload": "Hide File Upload Request",
      "instagram-chat": "Instagram-{chat}",
      "italic": "Italic",
      "join-conference": "Teilnehmen",
      "join-conference-ada": "join conference with customer",
      "line-chat": "Line-{chat}",
      "mask": "Mask as Personal Information",
      "mobile-chat": "Handy-{chat}",
      "more-options": "More Options",
      "pending-send-message": "Pending, message is being sent",
      "resend": "erneut senden",
      "resolve": "Lösen",
      "resolve-chat": "Chat klären",
      "restricted-by-webtool": "Restricted by webtool",
      "search": "Search...",
      "search-icon-title": "Search",
      "search-input-title": "search input",
      "send-a-message": "Send a message",
      "send-message": "Send Message",
      "show-details": "Show Details",
      "show-editor": "Show Editor",
      "show-file-upload": "Send File Upload Request",
      "sms-chat": "SMS-{chat}",
      "tablet-chat": "Tablet-{chat}",
      "telegram-chat": "Telegram-{chat}",
      "transfer": "Transferieren",
      "transfer-and-conference": "Vermittlung und Konferenz",
      "twitter": null,
      "twitter-chat": "Twitter-{chat}",
      "type-your-message": "type your message",
      "underline": "Underline",
      "viber-chat": "Viber-{chat}",
      "waiting": "Waiting",
      "web-chat": "Web-{chat}",
      "whatsapp-chat": "WhatsApp-{chat}",
      "you-are-not-the-conference-owner": "Sie sind nicht der Konferenzinhaber"
    },
    "agent-coach": {
      "context": {
        "reset": "Reset to follow live chat",
        "set": "Show recommendations"
      },
      "error-fetching": "There was an error fetching recommendations",
      "fetch-recommendation": "Customer message {customerMessage}. Press enter to fetch response recommendations.",
      "filter-as-i-type": "Filter as I type",
      "named-entities": null,
      "no-recommendations-found": "No Recommendations Found",
      "top-suggestions": "Top Suggestions"
    },
    "agent-group": "Vertretergruppe",
    "agent-groups": null,
    "agent-settings": {
      "chat-details": "Chat-details",
      "chat-tab-position": "Chat-Tab Position",
      "close": "Schließen",
      "collapse-all": "Collapse All",
      "collapse-script-headings": "Standardmäßig alle Skriptüberschriften ausblenden",
      "collapse-script-headings-disabled-description": "Script headings are all expanded by default for new engagements",
      "collapse-script-headings-enabled-description": "Script headings are all collapsed by default for new engagements",
      "create-custom-layout": "Create custom layout",
      "create-custom-layout-description": "You’ve  modified the layout of your current engagement. You can create a custom layout to always default new engagements to this state.",
      "ctrl-enter-submit": "Tastenkürzel \"Strg+Enter\" zum Senden von Nachrichten (statt \"Enter\")",
      "custom-layout-created": "Custom layout has been created",
      "custom-layout-updated": "Custom layout has been updated",
      "double-click": "Doppelklick",
      "double-click-description": "einfacher Klick fügt Skript in das Eingabefeld ein",
      "english": "English",
      "expand-all": "Expand All",
      "font-size": "Schriftgröße",
      "freeze-chat-tab-position": "Tab-Position für die Dauer des Chats einfrieren",
      "french": "Français",
      "german": "Deutsch",
      "hide-details": "Details ausblenden",
      "hide-details-disabled-description": "System messages are shown by default in the chat transcript for new engagements",
      "hide-details-enabled-description": "System messages are hidden by default in the chat transcript for new engagements",
      "italian": "Italiano",
      "japanese": "日本語",
      "keyboard": "Tastatur",
      "language": "Sprache",
      "large": "Groß",
      "layout": "Layout-Vorlage",
      "layouts": {
        "chat-center": "Chatten Sie im Zentrum",
        "chat-left": "Chat auf der linken Seite",
        "chat-left-two-columns": "Zwei Spalten (Chat auf der linken Seite)",
        "chat-right": "Chat auf der rechten Seite",
        "custom": "Benutzerdefiniert"
      },
      "login-as-available": "Als \"verfügbar\" anmelden",
      "login-as-busy": "Als \"beschäftigt\" anmelden",
      "login-state": "Login-Status",
      "medium": "Mittel",
      "my-settings": "Meine Einstellungen",
      "notifications": "Benachrichtigungen",
      "play-sounds": "Sounds abspielen",
      "portuguese": "Português",
      "preview": "Preview",
      "russian": "Русский",
      "script-headings": "Skriptüberschriften",
      "send-scripts": "Skripte senden",
      "single-click": "Einfacher Klick",
      "single-click-description": "Shift-Klick fügt Skript in das Eingabefeld ein",
      "small": "Klein",
      "spanish": "Español",
      "status-available": "Verfügbar",
      "update-custom-layout": "Update custom layout",
      "update-custom-layout-description": "You’ve modified the layout of your current engagement. You can update your custom layout to always default new engagements to this state.",
      "visual-alerts": "Visuelle Alarme"
    },
    "agent-status-toggle": {
      "available": "Verfügbar",
      "busy": "Beschäftigt",
      "busy-auto": "beschäftigt – automatisch",
      "warning-set-fail": "There was an error updating your status. Please try again. If the problem continues please contact your administrator."
    },
    "agents": "Vertreter",
    "alert": "Alert",
    "alerts": {
      "agent-has-active-engagements": "Vor einer Abmeldung müssen alle aktiven Chats beendet werden",
      "cobrowse-already-active": "Please end active cobrowse session before sending a new invitation"
    },
    "app-error-modal": {
      "error-n-of-total": "Fehler {n} von {total}:",
      "error-occurred": "{count, plural, =1 {Ein Fehler ist aufgetreten.} other {# Fehler sind aufgetreten.}}",
      "hide-details": "Details ausblenden",
      "hide-errors": "Fehler ausblenden",
      "ok": "OK",
      "show-details": "Details anzeigen",
      "title": "Fehler"
    },
    "app-error-pill-title": "Fehler",
    "async": null,
    "auto-transfer": {
      "message": "Ein neuer Chat wurde zugewiesen, auf den in der nötigen Zeit keine Erstantwort erfolgte.",
      "respond-now": "Jetzt antworten",
      "title": "Automatischer Transfer"
    },
    "business-unit": "Geschäftsbereich",
    "cancel": "Abbrechen",
    "close-chat-modal": {
      "cancel": "Cancel",
      "chat": "{engagementId}",
      "continue": "Continue",
      "conversation": "{engagementId}",
      "resolve": "{engagementId}"
    },
    "co-browse": "CoBrowsen",
    "cobrowse": null,
    "collapsable-tile": null,
    "color": {
      "blue": "Blau",
      "cyan": "Cyan",
      "gray": "grau",
      "green": "Grün",
      "light-blue": "Light Blue",
      "light-green": "Light Green",
      "magenta": "Magenta",
      "no-color": "Keine Farbe",
      "orange": "Orange",
      "peach": "Peach",
      "pink": "Rosa",
      "purple": "Purple",
      "white": "White",
      "yellow": "Gelb"
    },
    "conference": {
      "close-modal": {
        "no": "Nein",
        "title": "Konferenz schließen?",
        "warning": "Sie sind der Konferenzinhaber. Durch das Verlassen der Konferenz wird diese beendet. Möchten Sie sie verlassen?",
        "yes": "Ja"
      },
      "ownership-modal": {
        "accept": "Akzeptieren",
        "refuse": "Ablehnen",
        "title": "Akzeptieren Sie die Inhaberschaft?"
      }
    },
    "custom-scripts": {
      "add-new-header-script": "Add New Header",
      "add-new-text-script": "Neues Skript hinzufügen",
      "cancel": "Abbrechen",
      "confirm-changes": "Änderungen bestätigen",
      "delete": "Löschen",
      "delete-header-script": "Delete header",
      "delete-text-script": "Delete script",
      "do-not-save": "Nicht speichern",
      "edit-custom-scripts": "Edit custom scripts",
      "edit-header-script": "Edit header",
      "edit-text-script": "Edit script",
      "incorrect-scripts": "Scripts could not be loaded because of invalid character(s)",
      "input-header-placeholder": "Enter heading here",
      "input-text-placeholder": "Skript hier eingeben",
      "invalid-chars-in-scripts": "Invalid character(s) in script(s): {n}",
      "my-scripts": "Meine Skripte",
      "no-access": "You do not have access to custom scripts",
      "save": "Speichern",
      "save-and-close": "Speichern & Schließen",
      "saved": "Saved custom scripts",
      "title": "Benutzerdefinierte Skripte",
      "undo-delete-header-script": "Undo delete header",
      "undo-delete-text-script": "Undo delete script",
      "unsaved-changes-prompt": "Sie haben nicht gespeicherte Änderungen in Ihren Skripts. Möchten Sie sie speichern?"
    },
    "device-settings": null,
    "digital-voice-and-video": null,
    "dispositions": "Anordnungen",
    "emojis": null,
    "end-call-confirmation-modal": null,
    "engagement-tab": {
      "chat": "chat",
      "chat-duration": "chat duration",
      "chat-has-new-messages": "{deviceType} chat {chatIndex}, unresponded timer {unrespondedMessageTimer} seconds, {unrespondedMessages} new messages.",
      "completed": "ABGESCHLOSSEN",
      "countdown": "countdown",
      "current-chat-has-new-messages": "Current chat has {unrespondedMessages} new messages.",
      "no-engagements": "You have no engagements at this time",
      "unresponded-timer": "unresponded timer"
    },
    "engagements-to-follow-up": "Engagements zur Nachverfolgung",
    "escalate-modal": {
      "chat-escalation": "Chat-Eskalation"
    },
    "escalation-form": {
      "cancel": "Abbrechen",
      "description": "Description",
      "submit": "Absenden"
    },
    "filter": "Filter",
    "follow-up": {
      "conversation-id": "Gesprächskennung",
      "notes": "Notizen",
      "required-field": "* is a required field",
      "time-since-last-engagement": "Zeit seit letzter Interaktion",
      "time-to-expire": "Zeit bis zum Ablauf",
      "updated": "Follow up engagement has been updated"
    },
    "follow-up-disabled": "Es gibt keine Follow-up-Chats in Ihrer Warteschlange.",
    "follow-up-enabled": "There are follow up chats ready in your queue",
    "follow-up-modal": {
      "cancel": "Abbrechen",
      "follow-up-agent": "Einstellen in Follow-up-Warteschlange für:",
      "notes": "Notizen",
      "submit": "Senden",
      "title": "Follow-up"
    },
    "header": {
      "active-chats": "Aktive Chats",
      "agent-desktop": "Vertreter-Desktop",
      "chats-completed": "Abgeschlossene Chats",
      "chats-in-queue": "Chats in Warteschleife",
      "dismiss": "Dismiss",
      "ie-warning": "Support for Internet Explorer 11 will be retired as of August 30th, 2021. For optimal user experience, please move to a supported browser (Google Chrome, Microsoft Edge or Mozilla Firefox).",
      "logged-in-at": "Angemeldet bei",
      "menu": {
        "custom-scripts": "Benutzerdefinierte Skripte",
        "logout": "Abmelden",
        "my-settings": "Meine Einstellungen"
      },
      "navigation": "Navigation",
      "nina-coach-completed": "Nina Coach Completed",
      "public-user-id": "Öffentliche Benutzer-ID",
      "scripts": "Skripte",
      "skip-main-region": "Skip to Main Region",
      "skip-main-region-abbr": "Skip to Main",
      "summary": "Zusammenfassung",
      "username": "Benutzername"
    },
    "illegal-word": "kann/können nicht in Ihrer Nachricht an den Kunden verwendet werden. Dies muss vor dem Absenden entfernt werden.",
    "interactive-data": null,
    "internal-chat": null,
    "link": "Link",
    "load-engagement": "Engagement laden",
    "lockout": null,
    "login-form": {
      "access-denied": "User does not have access to Agent Desktop.",
      "account-maybe-locked": "You have attempted to authenticate 5 times unsuccessfully. Your account may be locked, please check with your administrator",
      "agent-id": "Agent ID",
      "agent-logged-off": "You were logged off",
      "ajax-authorization-failed": "Fehler bei Ajax-Genehmigung – Bitte kontaktieren Sie Ihren Systemadministrator.",
      "already-logged-in-modal-message": "Derzeit ist eine Sitzung für Agent \"{agentName}\" aktiviert. Bitte schließen Sie diese, um eine neue zu öffnen.",
      "already-logged-in-modal-title": "Bereits angemeldet",
      "application-error": "Beim Anmeldeversuch ist ein Anwendungsfehler aufgetreten.",
      "application-error-generic": "An application error has occurred please contact your system administrator and relogin.",
      "bad-credentials": "Benutzername oder Passwort ist ungültig",
      "changed-password": "Passwortänderung war erfolgreich",
      "default-invalidation-error": "You were logged off due to an unexpected authentication error. Please log in again. If this problem persists, contact your system administrator.",
      "details": "Details",
      "forgot-your-password": "Passwort vergessen?",
      "hide-details": "Details ausblenden",
      "internet-restored": "Internet connection has been restored, please login again",
      "log-in": "Anmelden",
      "logout-inactivity": "Your session has been logged out due to inactivity",
      "missing-password": "Passwort fehlt",
      "missing-username": "Benutzername fehlt",
      "no-internet": "No internet connection detected, please reconnect and try again",
      "password": "Passwort",
      "server-timeout-message": "A server timeout error occurred. Please refresh and try again. Thank You.",
      "server-timeout-title": "Sie wurden vom Agenten-Desktop abgemeldet.",
      "service-unavailable": "Komponente ist nicht verfügbar. Bitte wenden Sie sich an den Systemadministrator.",
      "show-details": "Details anzeigen",
      "timestamp": "Timestamp",
      "unlock": "Unlock",
      "url": "URL",
      "user-account-is-locked": "Ihr Konto wurde gesperrt, da Sie die maximale Anzahl von Anmeldeversuchen erreicht haben.",
      "username": "Benutzername"
    },
    "logout-button": {
      "logout": "Abmelden"
    },
    "metrics": {
      "agent-metrics": "Vertreter-Metrik",
      "available": "Verfügbar",
      "busy": "Beschäftigt",
      "chat-slots": "Chat-Slots",
      "chats-in-my-queue": "chats waiting in \"My Queue\"",
      "chats-in-queue": "Chats in Warteschleife",
      "free": "Frei",
      "max": "Max.",
      "metrics-title": "Zusammenfassung der Agentur Verfügbarkeit",
      "my-queue": "(Meine Warteschlange: {n})",
      "total": "Gesamt"
    },
    "misc": {
      "prevent-refresh-message": "Sind Sie sicher, dass Sie die Seite aktualisieren möchten?"
    },
    "modal": {
      "cancel": "Abbrechen",
      "close": "Close",
      "okay": "Okay",
      "warn-title": "Warning"
    },
    "my-settings": null,
    "named-entities-form": null,
    "next": "Next",
    "nina-coach": null,
    "notifications": null,
    "oh-no": "Oh no",
    "password-reset": {
      "confirm-password": "Passwort bestätigen",
      "current-password": "Aktuelles Passwort",
      "message": "Ihr Passwort ist abgelaufen. Bitte geben Sie ein neues ein.",
      "minimum-password-length": "Passwort sollte mindestens zwölf Zeichen lang sein",
      "new-password": "Neues Passwort",
      "please-confirm-your-new-password": "Bitte bestätigen Sie Ihr neues Passwort",
      "please-provide-a-new-password": "Bitte geben Sie Ihr neues Passwort ein",
      "please-provide-your-current-password": "Bitte geben Sie Ihr aktuelles Passwort ein",
      "submit": "Absenden",
      "username": "Benutzername",
      "username-is-a-required-field": "Benutzername ist ein Pflichtfeld",
      "your-passwords-must-match": "Neues Passwort und bestätigtes neues Passwort sind unterschiedlich"
    },
    "performance-metrics": {
      "agentUtilization": "Chat-Slot-Nutzung",
      "assignedCount": "Anfrage zugewiesen",
      "availableUtilization": "Chat-Slot-Verfügbarkeit",
      "avgConversionProductQuantity": "Einheiten pro Konvertierung",
      "avgDispositionTime": "Durchschnittliche Einteilungszeit",
      "avgEngagementHandleTime": "Chat-Bearbeitungszeit",
      "avgEngagementHandleTimeSLA": "Chat-Bearbeitungszeit SLA %",
      "avgInitialAgentResponseTime": "Durchschnittliche Erstantwort-Zeit",
      "avgOrderValue": "Durchschnittlicher Bestellwert ($)",
      "busyClickCount": "Beschäftigte Zeit pro Anmeldung",
      "chatsPerLaborHour": "Chats pro Arbeitsstunde",
      "conversionCount": "Konvertierungen",
      "conversionProductQuantity": "Verkaufte Einheiten",
      "cumulativeLaborUtilization": "Angesammelte Arbeitsnutzung",
      "efficiency": "Efficiency",
      "initialResponseWithinSLA": "Erstantwort-Zeit innerhalb SLA (%)",
      "logged-in-time": "Logged in Time Metrics",
      "loginDuration": "Angemeldete Zeit",
      "percentAssisted": "% geholfen",
      "percentConversion": "Konvertierung %",
      "percentEndedByCustomer": "% vom Kunden beendet",
      "salesPerLaborHour": "Verkäufe pro Arbeitsstunde",
      "totalBusyTime": "Gesamte beschäftige Zeit",
      "utilization": "Utilization",
      "volume": "Volume"
    },
    "power-select": null,
    "previous": "Previous",
    "request-additional-chat": {
      "chat-slots-enabled": "Additional chats enabled",
      "chat-slots-not-full": "Zur Anforderung zusätzlicher Chats müssen alle Ihre Chat-Plätze belegt sein.",
      "default": "Additional chat slots are available",
      "details": "To toggle the state please press space bar",
      "extra-chat-slots-full": "Sie haben die Höchstanzahl an zusätzlichen Chats erreicht.",
      "not-available": "Sie müssen im Verfügbarkeitsstatus sein.",
      "request-more-chats": "Request more chats"
    },
    "retry": "Retry",
    "rich-widget": null,
    "scripts": "Skripte",
    "sdk-dispositions": {
      "add-notes": "Notizen hinzufügen",
      "cancel": "Abbrechen",
      "choose-all": "Choose all that apply.",
      "choose-one": "Choose the one which best applies.",
      "close-engagement": "Close Engagement",
      "load-fail": "An error occurred while loading dispositions",
      "loading": "Loading",
      "loading-dispositions": "Fetching dispositions",
      "not-found": "\"{filterInput}\" not found",
      "pre-selected": {
        "title": "From previous engagement",
        "tooltip": {
          "clear-all": "Unselect all disposition reasons from previous engagement",
          "select-all": "Select all disposition reasons from previous engagement"
        }
      },
      "search-placeholder": "Search Dispositions",
      "select-disposition": "You must select a disposition first.",
      "show-pagination": "Pagination",
      "submit": "Senden",
      "window-title": "Dispositionen"
    },
    "sdk-scripts": {
      "absent": "There are no scripts configured. Please contact administrator.",
      "agent-coach": "Agent Coach",
      "commands-cannot-be-copied": "Befehle können nicht kopiert werden (die Shift-Taste war gedrückt)",
      "custom-scripts": "Meine Skripte",
      "custom-scripts-here": "Benutzerdefinierte Skripte kommen hier her",
      "empty-script-tree": "Scriptbaum ist leer.",
      "error": null,
      "error-fetching": "There was an error while fetching scripts",
      "input-script": {
        "cancel": "Abbrechen",
        "submit": "Absenden"
      },
      "limited-access": "Script Tree Access Limited.",
      "loading": "Bitte warten Sie, während die Scripts geladen werden ...",
      "script-cannot-be-modified": "Dieses Skript kann nicht geändert werden.",
      "system-scripts": "System-Skripte",
      "training-keyboard-1": "Press",
      "training-keyboard-2": "to navigate to script groups. Use the",
      "training-keyboard-3": "arrow keys to navigate to script lines.",
      "unavailable": "Script ist nicht verfügbar"
    },
    "search": null,
    "settings": "Einstellungen",
    "site": "Site",
    "slideout": {
      "expand-details": "expand details"
    },
    "sso": {
      "error-contact": "Bitte kontaktieren Sie Ihren technischen Support. Zur Umleitung auf die Anmeldeseite klicken Sie im Folgenden auf \"Okay\".",
      "error-message": "Etwas hat mit SSO nicht geklappt.",
      "error-okay": "Okay",
      "logout-message": "Sie wurden vom Agenten-Desktop abgemeldet.",
      "relogin": "ERNEUT ANMELDEN"
    },
    "static-transcript": {
      "abc-widget-message-sent": "{agentDisplayText} sent",
      "chat-history": "Chatverlauf",
      "clear-filter": "clear filter",
      "current": "Current",
      "default-abc-widget-message-sent": "Interactive Data sent",
      "default-rich-widget-message-sent": "*** Initiated Rich Widget ***",
      "initiated-widget": "*** Initiated Rich Widget: {label} ***",
      "next-result": "next result",
      "previous-result": "previous result",
      "rich-widget-message-sent": "{agentDisplayText} sent",
      "search-scripts": "Search Scripts",
      "search-transcripts": "search transcripts",
      "static-transcript": "Statisches Transkript"
    },
    "submit": "Absenden",
    "summary": {
      "updated-every-10-seconds": "alle 10 Sekunden aktualisiert"
    },
    "training": "Schulung",
    "training-tab": "Training Tab",
    "transcript-agent-input": {
      "send": "Senden"
    },
    "transcript-history": null,
    "transcript-window": {
      "abc-widget-message-sent": "{agentDisplayText} has been sent",
      "customer-closed-engagement": "Kunde hat Chat-Fenster geschlossen",
      "customer-is-typing": "Kunde tippt…",
      "customer-lost-connection": "Kunde hat die Verbindung verloren",
      "customer-minimized-chat-window": "Der Kunde hat den Chat verkleinert.",
      "customer-restored-chat-window": "Der Kunde hat den Chat wiederhergestellt.",
      "customer-stopped-typing": "Kunde hat aufgehört zu tippen…",
      "default-abc-widget-message-sent": "Interactive Data has been sent",
      "default-rich-widget-message-sent": "Rich Widget has been sent",
      "events": null,
      "interrupted": "Unterbrochen, nicht versendet",
      "moved-to-persistent": "Kunde wurde erfolgreich zu einem dauerhaften Chat verschoben",
      "new-chat-information": "There is new chat information. Press control plus shift plus A to announce content.",
      "no-new-chat-information": "There is no new chat information.",
      "not-sent": "Message failed to send",
      "rich-widget-message-sent": "{agentDisplayText} has been sent",
      "scroll-to-bottom": "Nach unten scrollen",
      "see-new-messages": "Neue Nachrichten ansehen",
      "started-move-to-persistent": "Kunde wird zu einem permanenten Chat-Fenster transferiert",
      "time-delayed": "Zeitverzögert",
      "transcript-line": {
        "auto-close-close": "(Engagement will auto-end in {seconds} seconds if customer doesn’t respond)",
        "auto-close-warn": "(Warning Message - {seconds} seconds)",
        "command": "Befehl",
        "rich-widget-response": "Rich Widget Response",
        "view-more": "View More",
        "xform": "Datapass"
      }
    },
    "transfer-engagement": {
      "agent-group-placeholder": "Please select an agent group",
      "agent-notes": "Vertreter-Notizen",
      "agent-placeholder": "No Agent Selected",
      "async-agent-group-info": "Derzeit sind keine Agenten verfügbar. Kunde kommt in die Warteschlange, bis ein Agent verfügbar ist",
      "available-slots": "Available slots",
      "business-unit-placeholder": "Please select a business unit",
      "conference": "Konferenz",
      "conference-queue-slots": "Konferenz-Warteschlangen-Plätze",
      "field-reset": "fields have been reset",
      "no-agents-with-attributes-available": "No agents with attributes available",
      "no-matching-agents-found": "Keine passenden Vertreter gefunden",
      "optionally-select": "Sie können auch direkt einen Vertreter auswählen",
      "please-select-a-bunit": "Bitte wählen Sie einen Geschäftsbereich aus der Drop-Down-Liste",
      "prioritize-request": "Anfragen prioritisieren",
      "reset": "Zurücksetzen",
      "select-a-bunit": "Geschäftsbereich wählen",
      "select-an-agent-group": "Vertretergruppe wählen",
      "select-one-or-more-agent-attributes": "Whlen Sie eine oder mehr gewünschte Vertreter-Eigenschaften",
      "transfer": "Transferieren",
      "transfer-queue-slots": "Warteschlangen-Plätze transferieren"
    },
    "transfer-window": {
      "transfer-and-conference": "Vermittlung und Konferenz"
    },
    "twitter-private-reply-confirmation": null,
    "video-chat": null,
    "visitor-info-tab": {
      "browser-type": "Browsertyp",
      "browser-version": "Browser Version",
      "business-rule": "Geschäftsregeln",
      "chat-id": "Chat-ID",
      "chat-type": "Chattyp",
      "conversation-channel": "Gesprächskanal",
      "conversation-id": "Gesprächskennung",
      "customer-id": "Kunden-ID",
      "customer-information": "Customer Information",
      "customer-name": "Kunden-Name",
      "device-type": "Gerätetyp",
      "locale": "Regionale Einstellungen",
      "operating-system-type": "Operating System Type",
      "page-marker": "Lesezeichen"
    },
    "visitor-information": "Besucherinformation",
    "voice-chat": null,
    "web-tools": "Web-Tools",
    "x-tabs": null
  }], ["en", {
    "a11y": {
      "arrow-key-navigation": "use the up and down arrow keys to navigate to each item. use left and right arrow keys to navigate to clickable items inside of an item",
      "link-navigation-instruction": "{content}. Use left, right arrow keys to navigate links",
      "resize-horizontal": "resizable handle, use up down arrow keys to resize",
      "resize-vertical": "resizable handle, use left right arrow keys to resize",
      "scroll-message": "press the up and down arrow keys to navigate",
      "transcript-history-navigation-instruction": "Transcript History. Use up, down arrow keys to navigate through items",
      "transcript-navigation-instruction": "Engagement transcript. Use up, down arrow keys to navigate through items",
      "widget-navigation-instruction": "{content}. Use left, right arrow keys to navigate"
    },
    "active-engagement": {
      "already-escalated": "This engagement has already been escalated",
      "app-chat": "App {chat}",
      "apple-business-chat": "Apple Business {chat}",
      "background-color": "Background Color",
      "bold": "Bold",
      "channel": "Channel",
      "close": "Close",
      "close-chat": "Close Chat",
      "conference": "Conference",
      "conversation": "Conversation",
      "copy": "Copy",
      "copy-masked-transcript": "Copy Transcript",
      "copy-transcript-notification": "Transcript Copied",
      "desktop-chat": "Desktop {chat}",
      "device": "Device",
      "eapi": "EAPI",
      "engagement": "Engagement",
      "engagement-action-disabled-follow-up": "Disabled until engagement has started",
      "escalate": "Escalate",
      "escalate-chat": "Escalate Chat",
      "escalated": "Escalated",
      "facebook-chat": "Facebook Messenger {chat}",
      "file-upload": "File Upload",
      "follow-up": "Follow Up",
      "general-chat": "General {chat}",
      "googles-business-messages-chat": "Google's Business Messages {chat}",
      "hide-details": "Hide Details",
      "hide-editor": "Hide Editor",
      "hide-file-upload": "Hide File Upload Request",
      "instagram-chat": "Instagram {chat}",
      "italic": "Italic",
      "join-conference": "Join",
      "join-conference-ada": "join conference with customer",
      "line-chat": "Line {chat}",
      "mask": "Mask as Personal Information",
      "mobile-chat": "Mobile {chat}",
      "more-options": "More Options",
      "pending-send-message": "Pending, message is being sent",
      "resend": "Resend",
      "resolve": "Resolve",
      "resolve-chat": "Resolve Chat",
      "restricted-by-webtool": "Restricted by webtool",
      "search": "Search...",
      "search-icon-title": "Search",
      "search-input-title": "search input",
      "send-a-message": "Send a message",
      "send-message": "Send Message",
      "show-details": "Show Details",
      "show-editor": "Show Editor",
      "show-file-upload": "Send File Upload Request",
      "sms-chat": "SMS {chat}",
      "tablet-chat": "Tablet {chat}",
      "telegram-chat": "Telegram {chat}",
      "transfer": "Transfer",
      "transfer-and-conference": "Transfer & Conference",
      "twitter": {
        "private": "Send as private message",
        "public": "Send public Tweet"
      },
      "twitter-chat": "Twitter {chat}",
      "type-your-message": "type your message",
      "underline": "Underline",
      "viber-chat": "Viber {chat}",
      "waiting": "Waiting",
      "web-chat": "Web {chat}",
      "whatsapp-chat": "WhatsApp {chat}",
      "you-are-not-the-conference-owner": "You are not the conference owner"
    },
    "agent-coach": {
      "context": {
        "reset": "Reset to follow live chat",
        "set": "Show recommendations"
      },
      "error-fetching": "There was an error fetching recommendations",
      "fetch-recommendation": "Customer message {customerMessage}. Press enter to fetch response recommendations.",
      "filter-as-i-type": "Filter as I type",
      "named-entities": {
        "address": "Address",
        "person-agent": "Agent Name",
        "person-customer": "Customer Name"
      },
      "no-recommendations-found": "No Recommendations Found",
      "top-suggestions": "Top Suggestions"
    },
    "agent-group": "Agent Group",
    "agent-groups": {
      "confirm-button": "Confirm Selection",
      "description": "Select one or more agent group(s) that you want to take chat for in this session",
      "list-header": "Agent Groups",
      "select-all": "Select All",
      "title": "Agent Group Selection"
    },
    "agent-settings": {
      "chat-details": "Chat Details",
      "chat-tab-position": "Chat Tab Position",
      "close": "Close",
      "collapse-all": "Collapse All",
      "collapse-script-headings": "Collapse all script headings by default",
      "collapse-script-headings-disabled-description": "Script headings are all expanded by default for new engagements",
      "collapse-script-headings-enabled-description": "Script headings are all collapsed by default for new engagements",
      "create-custom-layout": "Create custom layout",
      "create-custom-layout-description": "You’ve  modified the layout of your current engagement. You can create a custom layout to always default new engagements to this state.",
      "ctrl-enter-submit": "\"Ctrl+Enter\" shortcut to send messages (instead of “Enter”)",
      "custom-layout-created": "Custom layout has been created",
      "custom-layout-updated": "Custom layout has been updated",
      "double-click": "Double Click",
      "double-click-description": "single click adds script to input field",
      "english": "English",
      "expand-all": "Expand All",
      "font-size": "Font Size",
      "freeze-chat-tab-position": "Freeze tab position for duration of chat",
      "french": "Français",
      "german": "Deutsch",
      "hide-details": "Hide Details",
      "hide-details-disabled-description": "System messages are shown by default in the chat transcript for new engagements",
      "hide-details-enabled-description": "System messages are hidden by default in the chat transcript for new engagements",
      "italian": "Italiano",
      "japanese": "日本語",
      "keyboard": "Keyboard",
      "language": "Language",
      "large": "Large",
      "layout": "Layout Template",
      "layouts": {
        "chat-center": "Chat in Center",
        "chat-left": "Chat on Left",
        "chat-left-two-columns": "Two Columns (Chat on Left)",
        "chat-right": "Chat on Right",
        "custom": "Custom"
      },
      "login-as-available": "Login as Available",
      "login-as-busy": "Login as Busy",
      "login-state": "Login State",
      "medium": "Medium",
      "my-settings": "My Settings",
      "notifications": "Notifications",
      "play-sounds": "Play Sounds",
      "portuguese": "Português",
      "preview": "Preview",
      "russian": "Русский",
      "script-headings": "Script Headings",
      "send-scripts": "Send Scripts",
      "single-click": "Single Click",
      "single-click-description": "shift+click adds script to input field",
      "small": "Small",
      "spanish": "Español",
      "status-available": "Available",
      "update-custom-layout": "Update custom layout",
      "update-custom-layout-description": "You’ve modified the layout of your current engagement. You can update your custom layout to always default new engagements to this state.",
      "visual-alerts": "Visual Alerts"
    },
    "agent-status-toggle": {
      "available": "Available",
      "busy": "Busy",
      "busy-auto": "busy-auto",
      "warning-set-fail": "There was an error updating your status. Please try again. If the problem continues please contact your administrator."
    },
    "agents": "Agents",
    "alert": "Alert",
    "alerts": {
      "agent-has-active-engagements": "You must exit all active chats before logging out",
      "cobrowse-already-active": "Please end active cobrowse session before sending a new invitation"
    },
    "app-error-modal": {
      "error-n-of-total": "Error {n} of {total}:",
      "error-occurred": "{count, plural, =1 {# error has occurred.} other {# errors have occurred.}}",
      "hide-details": "Hide Details",
      "hide-errors": "Hide Errors",
      "ok": "OK",
      "show-details": "Show Details",
      "title": "Error"
    },
    "app-error-pill-title": "Error",
    "async": {
      "cannot-resolve": "Cannot resolve this conversation {conversationId}, because the customer has started a new chat.",
      "okay": "Okay",
      "title": "Warning"
    },
    "auto-transfer": {
      "message": "A new chat has been assigned that has not received an initial response in the required time.",
      "respond-now": "Respond Now",
      "title": "Auto Transfer"
    },
    "business-unit": "Business Unit",
    "cancel": "Cancel",
    "close-chat-modal": {
      "cancel": "Cancel",
      "chat": "Close Engagement {engagementId}",
      "continue": "Continue",
      "conversation": "Close Conversation {engagementId}",
      "resolve": "Resolve Conversation {engagementId}"
    },
    "co-browse": "Co-Browse",
    "cobrowse": {
      "color-picker": "Select Highlight Color",
      "end": "End Cobrowse",
      "error": "An error with cobrowse just occurred.",
      "hide-chat-window": "Hide Chat Window",
      "inactive": "Inactive",
      "reload": "Reload Page",
      "show-chat-window": "Show Chat Window",
      "unhighlight": "Remove Highlight"
    },
    "collapsable-tile": {
      "collapse": "Collapse",
      "compress": "Compress",
      "expand": "Expand",
      "header-snap-back": "Close to return to side menu",
      "reminders": "Reminders"
    },
    "color": {
      "blue": "Blue",
      "cyan": "Cyan",
      "gray": "Gray",
      "green": "Green",
      "light-blue": "Light Blue",
      "light-green": "Light Green",
      "magenta": "Magenta",
      "no-color": "No Color",
      "orange": "Orange",
      "peach": "Peach",
      "pink": "Pink",
      "purple": "Purple",
      "white": "White",
      "yellow": "Yellow"
    },
    "conference": {
      "close-modal": {
        "no": "No",
        "title": "Close Conference?",
        "warning": "You are the conference owner. Leaving the conference will terminate it. Do you want to leave it?",
        "yes": "Yes"
      },
      "ownership-modal": {
        "accept": "Accept",
        "refuse": "Refuse",
        "title": "Accept Ownership?"
      }
    },
    "custom-scripts": {
      "add-new-header-script": "Add New Header",
      "add-new-text-script": "Add New Script",
      "cancel": "Cancel",
      "confirm-changes": "Confirm Changes",
      "delete": "Delete",
      "delete-header-script": "Delete header",
      "delete-text-script": "Delete script",
      "do-not-save": "Don’t Save",
      "edit-custom-scripts": "Edit custom scripts",
      "edit-header-script": "Edit header",
      "edit-text-script": "Edit script",
      "incorrect-scripts": "Scripts could not be loaded because of invalid character(s)",
      "input-header-placeholder": "Enter heading here",
      "input-text-placeholder": "Enter script here",
      "invalid-chars-in-scripts": "Invalid character(s) in script(s): {n}",
      "my-scripts": "My Scripts",
      "no-access": "You do not have access to custom scripts",
      "save": "Save",
      "save-and-close": "Save & Close",
      "saved": "Saved custom scripts",
      "title": "Custom Scripts",
      "undo-delete-header-script": "Undo delete header",
      "undo-delete-text-script": "Undo delete script",
      "unsaved-changes-prompt": "You have unsaved changes in your scripts. Do you want to save them?"
    },
    "device-settings": {
      "audio-device": "Audio Device",
      "browser-not-supported": "This application does not support devices in your current browser",
      "close": "Close",
      "ff-warn-message": "Audio output devices are not supported by your browser",
      "header": "Device Settings",
      "mic-device": "Microphone Device",
      "no-device": "selection not available",
      "permission-not-granted": "Device permission not granted",
      "video-device": "Video Device",
      "video-error-message": "preview not available",
      "video-not-available": "Video is not available"
    },
    "digital-voice-and-video": {
      "invite-to-video-call": "Invite the customer to a video call",
      "invite-to-voice-call": "Invite the customer to a voice-only call",
      "title": "Digital Voice & Video",
      "title-video": "Digital Video",
      "title-voice": "Digital Voice",
      "video-call": "Video call",
      "voice-call": "Voice call"
    },
    "dispositions": "Dispositions",
    "emojis": {
      "clapping-hands": "Clapping Hands",
      "grinning-face": "Grinning Face",
      "happy-face": "Happy Face",
      "icon-title": "Emojis",
      "panel-title": "Emoji Panel",
      "raising-hands": "Raising Hands",
      "red-heart": "Red Heart",
      "sad-face": "Sad Face",
      "thumbs-down": "Thumbs Down",
      "thumbs-up": "Thumbs Up",
      "waving-hand": "Waving Hand"
    },
    "end-call-confirmation-modal": {
      "cancel": "Cancel",
      "content-video": "You are about to end the video call with the customer.",
      "content-voice": "You are about to end the call with the customer.",
      "end-call-button": "End call",
      "title": "End call?"
    },
    "engagement-tab": {
      "chat": "chat",
      "chat-duration": "chat duration",
      "chat-has-new-messages": "{deviceType} chat {chatIndex}, unresponded timer {unrespondedMessageTimer} seconds, {unrespondedMessages} new messages.",
      "completed": "COMPLETED",
      "countdown": "countdown",
      "current-chat-has-new-messages": "Current chat has {unrespondedMessages} new messages.",
      "no-engagements": "You have no engagements at this time",
      "unresponded-timer": "unresponded timer"
    },
    "engagements-to-follow-up": "Engagements to Follow-Up",
    "escalate-modal": {
      "chat-escalation": "Chat Escalation"
    },
    "escalation-form": {
      "cancel": "Cancel",
      "description": "Description",
      "submit": "Submit"
    },
    "filter": "Filter",
    "follow-up": {
      "conversation-id": "Conversation-ID",
      "notes": "Notes",
      "required-field": "* is a required field",
      "time-since-last-engagement": "Time since last engagement",
      "time-to-expire": "Time to expire",
      "updated": "Follow up engagement has been updated"
    },
    "follow-up-disabled": "There are no follow up chats in your queue",
    "follow-up-enabled": "There are follow up chats ready in your queue",
    "follow-up-modal": {
      "cancel": "Cancel",
      "follow-up-agent": "Put in Follow-Up Queue for:",
      "notes": "Notes",
      "submit": "Submit",
      "title": "Follow Up"
    },
    "header": {
      "active-chats": "Active chats",
      "agent-desktop": "Agent Desktop",
      "chats-completed": "Chats completed",
      "chats-in-queue": "Chats in queue",
      "dismiss": "Dismiss",
      "ie-warning": "Support for Internet Explorer 11 will be retired as of August 30th, 2021. For optimal user experience, please move to a supported browser (Google Chrome, Microsoft Edge or Mozilla Firefox).",
      "logged-in-at": "Logged in at",
      "menu": {
        "custom-scripts": "Custom Scripts",
        "logout": "Logout",
        "my-settings": "My Settings"
      },
      "navigation": "Navigation",
      "nina-coach-completed": "Nina Coach Completed",
      "public-user-id": "Public User Id",
      "scripts": "Scripts",
      "skip-main-region": "Skip to Main Region",
      "skip-main-region-abbr": "Skip to Main",
      "summary": "Summary",
      "username": "Username"
    },
    "illegal-word": "cannot be used in your message to customer. This must be removed before sending.",
    "interactive-data": {
      "default-label": "Interactive Data"
    },
    "internal-chat": {
      "close": "Close",
      "expand-internal-chat": "unread messages. Press control plus shift plus X to expand internal chat.",
      "how-to-close": "To close internal chat, other members must first leave the engagement.",
      "internal-chat": "Internal Chat",
      "minimize": "Minimize",
      "unread-messages": "unread messages"
    },
    "link": "Link",
    "load-engagement": "Load Engagement",
    "lockout": {
      "inactivity-message": "Auto-Locked due to inactivity"
    },
    "login-form": {
      "access-denied": "User does not have access to Agent Desktop.",
      "account-maybe-locked": "You have attempted to authenticate 5 times unsuccessfully. Your account may be locked, please check with your administrator",
      "agent-id": "Agent ID",
      "agent-logged-off": "You were logged off",
      "ajax-authorization-failed": "Ajax authorization error - please contact your system administrator.",
      "already-logged-in-modal-message": "There is currently an active session for agent {agentName}, please close to open a new session.",
      "already-logged-in-modal-title": "Already Logged In",
      "application-error": "An application error has occurred while attempting to log in",
      "application-error-generic": "An application error has occurred please contact your system administrator and relogin.",
      "bad-credentials": "Invalid username or password",
      "changed-password": "Password successfully changed",
      "default-invalidation-error": "You were logged off due to an unexpected authentication error. Please log in again. If this problem persists, contact your system administrator.",
      "details": "Details",
      "forgot-your-password": "Forgot your password?",
      "hide-details": "Hide Details",
      "internet-restored": "Internet connection has been restored, please login again",
      "log-in": "Log In",
      "logout-inactivity": "Your session has been logged out due to inactivity",
      "missing-password": "Missing password",
      "missing-username": "Missing username",
      "no-internet": "No internet connection detected, please reconnect and try again",
      "password": "Password",
      "server-timeout-message": "A server timeout error occurred. Please refresh and try again. Thank You.",
      "server-timeout-title": "Server Timeout",
      "service-unavailable": "component is unavailable please reach out to system administrator",
      "show-details": "Show Details",
      "timestamp": "Timestamp",
      "unlock": "Unlock",
      "url": "URL",
      "user-account-is-locked": "Your account has been locked because you have reached the maximum number of login attempts",
      "username": "Username"
    },
    "logout-button": {
      "logout": "Logout"
    },
    "metrics": {
      "agent-metrics": "Agent Metrics",
      "available": "Available",
      "busy": "Busy",
      "chat-slots": "Chat Slots",
      "chats-in-my-queue": "chats waiting in \"My Queue\"",
      "chats-in-queue": "Chats in Queue",
      "free": "Free",
      "max": "Max",
      "metrics-title": "Agent Availability Summary",
      "my-queue": "(My Queue: {n})",
      "total": "Total"
    },
    "misc": {
      "prevent-refresh-message": "Are you sure you want to refresh the page?"
    },
    "modal": {
      "cancel": "Cancel",
      "close": "Close",
      "okay": "Okay",
      "warn-title": "Warning"
    },
    "my-settings": {
      "layout-preview": "layout preview",
      "preview": "enlarge preview image"
    },
    "named-entities-form": {
      "submit-disabled-tooltip": "One or more placeholder values have not been set. Please specify a value for all placeholders to submit."
    },
    "next": "Next",
    "nina-coach": {
      "alert": "nina coach alert",
      "all": "All",
      "all-checkbox": "all checkbox checked",
      "arrow-down-icon": "arrow down icon",
      "arrow-right-icon": "arrow right icon",
      "clear-search": "clear search",
      "cleared-intents-query": "Intents search query cleared",
      "concepts": "Concepts",
      "filter-by": "Filter by",
      "filter-icon": "filter icon",
      "intents": "Intents",
      "loading-intents": "loading intents",
      "name": "Name",
      "nina": "Nina",
      "nina-suggested": "Nina Suggested",
      "no-available-concepts": "No Available Concepts",
      "no-intents": "No Intents",
      "no-opportunity": "No Opportunity",
      "press-tab-key": "press tab to navigate to filter",
      "response-text": "Possible Response Text",
      "search-icon": "search icon",
      "search-placeholder": "Search intents, concepts, response texts",
      "selected-intent": "Selected Intent",
      "send": "Send",
      "suggest-intent": "suggested intent",
      "take-over": "Take Over",
      "title": "Nina Coach",
      "transfer": "Transfer",
      "type": "Type"
    },
    "notifications": {
      "lost-connection": "Lost connection, attempting to reconnect",
      "lost-connection-no-internet": "Your internet connection has been lost. Check internet connection, refresh the page and log back in",
      "notify-new-engagement": "New chat assigned",
      "notify-new-message": "New message from customer"
    },
    "oh-no": "Oh no",
    "password-reset": {
      "confirm-password": "Confirm Password",
      "current-password": "Current Password",
      "message": "Your password has been expired, please enter new password",
      "minimum-password-length": "Minimum password length should be at least twelve characters",
      "new-password": "New Password",
      "please-confirm-your-new-password": "Please confirm your new password",
      "please-provide-a-new-password": "Please provide a new password",
      "please-provide-your-current-password": "Please provide your current password",
      "submit": "Submit",
      "username": "Username",
      "username-is-a-required-field": "Username is a required field",
      "your-passwords-must-match": "New password and confirmed new password are different"
    },
    "performance-metrics": {
      "agentUtilization": "Chat Slots Utilization",
      "assignedCount": "Request Assigned",
      "availableUtilization": "Chat Slots Availability",
      "avgConversionProductQuantity": "Units per Conversion",
      "avgDispositionTime": "Average Disposition Time",
      "avgEngagementHandleTime": "Chat handle time",
      "avgEngagementHandleTimeSLA": "Chat handle time SLA %",
      "avgInitialAgentResponseTime": "Average Initial Response Time",
      "avgOrderValue": "Average Order Value ($)",
      "busyClickCount": "Times on Busy Per Login",
      "chatsPerLaborHour": "Chats per Labor Hour",
      "conversionCount": "Conversions",
      "conversionProductQuantity": "Units Sold",
      "cumulativeLaborUtilization": "Cumulative Labor Utilization",
      "efficiency": "Efficiency",
      "initialResponseWithinSLA": "Initial Response Within SLA (%)",
      "logged-in-time": "Logged in Time Metrics",
      "loginDuration": "Logged in Time",
      "percentAssisted": "Assisted %",
      "percentConversion": "Conversion %",
      "percentEndedByCustomer": "% Ended by Customer",
      "salesPerLaborHour": "Sales per Labor Hour",
      "totalBusyTime": "Total Busy Time",
      "utilization": "Utilization",
      "volume": "Volume"
    },
    "power-select": {
      "no-results-found": "No results found",
      "type-to-filter": "type to filter"
    },
    "previous": "Previous",
    "request-additional-chat": {
      "chat-slots-enabled": "Additional chats enabled",
      "chat-slots-not-full": "Your chat slots must be full to request additional chats",
      "default": "Additional chat slots are available",
      "details": "To toggle the state please press space bar",
      "extra-chat-slots-full": "You have reached the limit for additional chats",
      "not-available": "You must be in available status",
      "request-more-chats": "Request more chats"
    },
    "retry": "Retry",
    "rich-widget": {
      "default-label": "Rich Widget",
      "error-render": "Widget preview cannot be loaded",
      "load": "Load",
      "preview-title": "Widget Preview",
      "preview-widget-navigation": "Widget: {widgetLabel}. Press \"enter\" key to open preview"
    },
    "scripts": "Scripts",
    "sdk-dispositions": {
      "add-notes": "Add notes",
      "cancel": "Cancel",
      "choose-all": "Choose all that apply.",
      "choose-one": "Choose the one which best applies.",
      "close-engagement": "Close Engagement",
      "load-fail": "An error occurred while loading dispositions",
      "loading": "Loading",
      "loading-dispositions": "Fetching dispositions",
      "not-found": "\"{filterInput}\" not found",
      "pre-selected": {
        "title": "From previous engagement",
        "tooltip": {
          "clear-all": "Unselect all disposition reasons from previous engagement",
          "select-all": "Select all disposition reasons from previous engagement"
        }
      },
      "search-placeholder": "Search Dispositions",
      "select-disposition": "You must select a disposition first.",
      "show-pagination": "Pagination",
      "submit": "Submit",
      "window-title": "Dispositions"
    },
    "sdk-scripts": {
      "absent": "There are no scripts configured. Please contact administrator.",
      "agent-coach": "Agent Coach",
      "commands-cannot-be-copied": "commands cannot be copied (you had the shift-key down)",
      "custom-scripts": "Custom",
      "custom-scripts-here": "Custom scripts will go here",
      "empty-script-tree": "Script Tree is empty.",
      "error": {
        "rich-widget-agent-input": "rich widgets cannot be sent to input field"
      },
      "error-fetching": "There was an error while fetching scripts",
      "input-script": {
        "cancel": "Cancel",
        "submit": "Submit"
      },
      "limited-access": "Script Tree Access Limited.",
      "loading": "Please wait while scripts load...",
      "script-cannot-be-modified": "This script cannot be modified",
      "system-scripts": "System",
      "training-keyboard-1": "Press",
      "training-keyboard-2": "to navigate to script groups. Use the",
      "training-keyboard-3": "arrow keys to navigate to script lines.",
      "unavailable": "Script is unavailable"
    },
    "search": {
      "result": "result {searchResultsIndex} of {searchResultsCount}"
    },
    "settings": "Settings",
    "site": "Site",
    "slideout": {
      "expand-details": "expand details"
    },
    "sso": {
      "error-contact": "Please contact your tech support. Click \"Okay\" below to redirect to the Login page",
      "error-message": "Something went wrong with SSO.",
      "error-okay": "Okay",
      "logout-message": "You’ve been logged out of Agent Desktop",
      "relogin": "RELOGIN"
    },
    "static-transcript": {
      "abc-widget-message-sent": "{agentDisplayText} sent",
      "chat-history": "Chat history",
      "clear-filter": "clear filter",
      "current": "Current",
      "default-abc-widget-message-sent": "Interactive Data sent",
      "default-rich-widget-message-sent": "*** Initiated Rich Widget ***",
      "initiated-widget": "*** Initiated Rich Widget: {label} ***",
      "next-result": "next result",
      "previous-result": "previous result",
      "rich-widget-message-sent": "{agentDisplayText} sent",
      "search-scripts": "Search Scripts",
      "search-transcripts": "search transcripts",
      "static-transcript": "Static Transcript"
    },
    "submit": "Submit",
    "summary": {
      "updated-every-10-seconds": "Updated every 10 seconds"
    },
    "training": "Training",
    "training-tab": "Training Tab",
    "transcript-agent-input": {
      "send": "Send"
    },
    "transcript-history": {
      "no-transcript-history": "No Transcript History",
      "select": "Transcript Select",
      "title": "Transcript History"
    },
    "transcript-window": {
      "abc-widget-message-sent": "{agentDisplayText} has been sent",
      "customer-closed-engagement": "Customer closed chat window",
      "customer-is-typing": "Customer is typing...",
      "customer-lost-connection": "Customer lost connection",
      "customer-minimized-chat-window": "The customer has minimized the chat",
      "customer-restored-chat-window": "The customer has restored the chat",
      "customer-stopped-typing": "Customer stopped typing",
      "default-abc-widget-message-sent": "Interactive Data has been sent",
      "default-rich-widget-message-sent": "Rich Widget has been sent",
      "events": {
        "callEnded": "Call ended",
        "callInvitation": "Call invitation has been sent",
        "callRequestDeclined": "Call request declined",
        "createRoomFailed": "Failed to create room",
        "createRoomSuccess": "Room created",
        "error": "Device not supported",
        "newCallRequest": "Incoming call request",
        "newVoiceCallRequest": "Incoming call request",
        "roomAgentJoin": "Agent has joined the room",
        "roomAgentLeave": "Agent has left the room"
      },
      "interrupted": "Interrupted, not sent",
      "moved-to-persistent": "Customer successfully moved to a persistent chat",
      "new-chat-information": "There is new chat information. Press control plus shift plus A to announce content.",
      "no-new-chat-information": "There is no new chat information.",
      "not-sent": "Message failed to send",
      "rich-widget-message-sent": "{agentDisplayText} has been sent",
      "scroll-to-bottom": "scroll to bottom",
      "see-new-messages": "see new messages",
      "started-move-to-persistent": "Customer is being transferred to a persistent chat window",
      "time-delayed": "Time-delayed",
      "transcript-line": {
        "auto-close-close": "(Engagement will auto-end in {seconds} seconds if customer doesn’t respond)",
        "auto-close-warn": "(Warning Message - {seconds} seconds)",
        "command": "Command",
        "rich-widget-response": "Rich Widget Response",
        "view-more": "View More",
        "xform": "Datapass"
      }
    },
    "transfer-engagement": {
      "agent-group-placeholder": "Please select an agent group",
      "agent-notes": "Agent Notes",
      "agent-placeholder": "No Agent Selected",
      "async-agent-group-info": "No agents currently available. Customer will be placed on queue till an agent becomes available",
      "available-slots": "Available slots",
      "business-unit-placeholder": "Please select a business unit",
      "conference": "Conference",
      "conference-queue-slots": "Conference queue slots",
      "field-reset": "fields have been reset",
      "no-agents-with-attributes-available": "No agents with attributes available",
      "no-matching-agents-found": "No matching agents found",
      "optionally-select": "Optionally, you may directly select an agent",
      "please-select-a-bunit": "Please select a BUnit from the drop down list",
      "prioritize-request": "Prioritize Request",
      "reset": "Reset",
      "select-a-bunit": "Select a BUnit",
      "select-an-agent-group": "Select an Agent Group",
      "select-one-or-more-agent-attributes": "Select one or more desired agent attributes",
      "transfer": "Transfer",
      "transfer-queue-slots": "Transfer queue slots"
    },
    "transfer-window": {
      "transfer-and-conference": "Transfer and Conference"
    },
    "twitter-private-reply-confirmation": {
      "message": "Are you sure you want to send the reply as a private message? Once this message is sent, this chat tab will be closed and a new one will be created for Twitter Direct Message once the customer replies to your private message.",
      "title": "Reply as Private - Confirmation"
    },
    "video-chat": {
      "accept-button": "Accept",
      "available": "Available for video chat",
      "close-engagement": "Close engagement",
      "connected": "Connected",
      "customer-disconnected": "The customer has left the call",
      "decline-button": "Decline",
      "device-settings": "Device Settings",
      "end-call-button": "End Call",
      "end-video-call": "Video call has ended",
      "failed-to-create-video-room": "Failed to create a video chat room.",
      "incoming-call": "Incoming video call...",
      "incoming-call-in-progress": "Incoming video call in progress",
      "mute": "Mute",
      "retry": "Retry",
      "un-mute": "Unmute"
    },
    "visitor-info-tab": {
      "browser-type": "Browser Type",
      "browser-version": "Browser Version",
      "business-rule": "Business Rule",
      "chat-id": "Chat ID",
      "chat-type": "Chat Type",
      "conversation-channel": "Conversation Channel",
      "conversation-id": "Conversation ID",
      "customer-id": "Cust. ID",
      "customer-information": "Customer Information",
      "customer-name": "Cust. Name",
      "device-type": "Device Type",
      "locale": "Locale",
      "operating-system-type": "Operating System Type",
      "page-marker": "Page Marker"
    },
    "visitor-information": "Visitor Information",
    "voice-chat": {
      "available": "Available for voice chat",
      "incoming-call": "Incoming voice call...",
      "incoming-call-in-progress": "Incoming voice call in progress"
    },
    "web-tools": "Web Tools",
    "x-tabs": {
      "access-frame": "or click to access frame.",
      "how-to-use": "use the left and right arrow keys to navigate through tabs",
      "left-arrow": "Left Arrow",
      "press": "Press",
      "right-arrow": "Right Arrow"
    }
  }], ["es", {
    "a11y": null,
    "active-engagement": {
      "already-escalated": "Ya se ha escalado la interacción",
      "app-chat": "{chat} de app",
      "apple-business-chat": "{chat} empresarial de Apple",
      "background-color": "Background Color",
      "bold": "Bold",
      "channel": "Channel",
      "close": "Cerrar",
      "close-chat": "Cerrar chat",
      "conference": "Conferencia",
      "conversation": "Conversation",
      "copy": "Copy",
      "copy-masked-transcript": "Copy Transcript",
      "copy-transcript-notification": "Transcript Copied",
      "desktop-chat": "{chat} de escritorio",
      "device": "Device",
      "eapi": "EAPI",
      "engagement": "Engagement",
      "engagement-action-disabled-follow-up": "Desactivado hasta inicio de la interacción",
      "escalate": "Escalar",
      "escalate-chat": "Escalamiento de chat",
      "escalated": "Escalado",
      "facebook-chat": "{chat} de Facebook Messenger",
      "file-upload": "File Upload",
      "follow-up": "Seguimiento",
      "general-chat": "General {chat}",
      "googles-business-messages-chat": "{chat} de Google's Business Messages",
      "hide-details": "Esconder detalles",
      "hide-editor": "Hide Editor",
      "hide-file-upload": "Hide File Upload Request",
      "instagram-chat": "{chat} de Instagram",
      "italic": "Italic",
      "join-conference": "Join",
      "join-conference-ada": "join conference with customer",
      "line-chat": "{chat} de Line",
      "mask": "Mask as Personal Information",
      "mobile-chat": "{chat} móvil",
      "more-options": "More Options",
      "pending-send-message": "Pending, message is being sent",
      "resend": "Reenviar",
      "resolve": "Resolver",
      "resolve-chat": "Resolver chat",
      "restricted-by-webtool": "Restricted by webtool",
      "search": "Search...",
      "search-icon-title": "Search",
      "search-input-title": "search input",
      "send-a-message": "Send a message",
      "send-message": "Send Message",
      "show-details": "Show Details",
      "show-editor": "Show Editor",
      "show-file-upload": "Send File Upload Request",
      "sms-chat": "{chat} de SMS",
      "tablet-chat": "{chat} de tablet",
      "telegram-chat": "{chat} de Telegram",
      "transfer": "Transferencia",
      "transfer-and-conference": "Transferencia y conferencia",
      "twitter": null,
      "twitter-chat": "{chat} de Twitter",
      "type-your-message": "type your message",
      "underline": "Underline",
      "viber-chat": "{chat} de Viber",
      "waiting": "Waiting",
      "web-chat": "{chat} web",
      "whatsapp-chat": "{chat} de WhatsApp",
      "you-are-not-the-conference-owner": "No es el titular de la conferencia"
    },
    "agent-coach": {
      "context": {
        "reset": "Reset to follow live chat",
        "set": "Show recommendations"
      },
      "error-fetching": "There was an error fetching recommendations",
      "fetch-recommendation": "Customer message {customerMessage}. Press enter to fetch response recommendations.",
      "filter-as-i-type": "Filter as I type",
      "named-entities": null,
      "no-recommendations-found": "No Recommendations Found",
      "top-suggestions": "Top Suggestions"
    },
    "agent-group": "Grupo de agentes",
    "agent-groups": null,
    "agent-settings": {
      "chat-details": "Detalles del chat",
      "chat-tab-position": "Posición de la pestaña del chat",
      "close": "Cerrar",
      "collapse-all": "Collapse All",
      "collapse-script-headings": "Contraer todos los encabezados de script de forma predeterminada",
      "collapse-script-headings-disabled-description": "Script headings are all expanded by default for new engagements",
      "collapse-script-headings-enabled-description": "Script headings are all collapsed by default for new engagements",
      "create-custom-layout": "Create custom layout",
      "create-custom-layout-description": "You’ve  modified the layout of your current engagement. You can create a custom layout to always default new engagements to this state.",
      "ctrl-enter-submit": "Presionar \"Ctrl+Enter\" para enviar mensajes (en vez de \"Enter\")",
      "custom-layout-created": "Custom layout has been created",
      "custom-layout-updated": "Custom layout has been updated",
      "double-click": "Doble clic",
      "double-click-description": "un solo clic añade secuencia de comandos al campo de entrada",
      "english": "English",
      "expand-all": "Expand All",
      "font-size": "Tamaño de letra",
      "freeze-chat-tab-position": "Fijar la posición de la pestaña durante el chat",
      "french": "Français",
      "german": "Deutsch",
      "hide-details": "Ocultar detalles",
      "hide-details-disabled-description": "System messages are shown by default in the chat transcript for new engagements",
      "hide-details-enabled-description": "System messages are hidden by default in the chat transcript for new engagements",
      "italian": "Italiano",
      "japanese": "日本語",
      "keyboard": "Teclado",
      "language": "Idioma",
      "large": "Grande",
      "layout": "Plantilla de diseño",
      "layouts": {
        "chat-center": "Chat en el centro",
        "chat-left": "Chat a la izquierda",
        "chat-left-two-columns": "Dos columnas (chat a la izquierda)",
        "chat-right": "Chat a la derecha",
        "custom": "Personaliza"
      },
      "login-as-available": "Iniciar sesión como disponible",
      "login-as-busy": "Iniciar sesión como ocupado",
      "login-state": "Estado de inicio de sesión",
      "medium": "Medio",
      "my-settings": "Mi configuración",
      "notifications": "Notificaciones",
      "play-sounds": "Reproducir sonidos",
      "portuguese": "Português",
      "preview": "Preview",
      "russian": "Русский",
      "script-headings": "Encabezados de scripts",
      "send-scripts": "Enviar secuencias de comandos",
      "single-click": "Un solo clic",
      "single-click-description": "mayús + clic añade secuencia de comandos al campo de entrada",
      "small": "Pequeño",
      "spanish": "Español",
      "status-available": "Disponible",
      "update-custom-layout": "Update custom layout",
      "update-custom-layout-description": "You’ve modified the layout of your current engagement. You can update your custom layout to always default new engagements to this state.",
      "visual-alerts": "Alertas visuales"
    },
    "agent-status-toggle": {
      "available": "Disponible1",
      "busy": "Ocupado1",
      "busy-auto": "ocupado/automático",
      "warning-set-fail": "There was an error updating your status. Please try again. If the problem continues please contact your administrator."
    },
    "agents": "Agentes",
    "alert": "Alert",
    "alerts": {
      "agent-has-active-engagements": "Debe salir de todos los chats activos antes de cerrar sesión",
      "cobrowse-already-active": "Please end active cobrowse session before sending a new invitation"
    },
    "app-error-modal": {
      "error-n-of-total": "Error {n} de {total}:",
      "error-occurred": "{count, plural, =1 {# error ha ocurrido.} other {# errores han ocurrido.}}",
      "hide-details": "Ocultar Detalles",
      "hide-errors": "Ocultar Errores",
      "ok": "OK",
      "show-details": "Mostrar Detalles",
      "title": "Error"
    },
    "app-error-pill-title": "Error",
    "async": null,
    "auto-transfer": {
      "message": "Se asignó un chat nuevo que no recibió respuesta inicial dentro del tiempo requerido.",
      "respond-now": "Responder ahora",
      "title": "Transferencia automática"
    },
    "business-unit": "Unidad de negocios",
    "cancel": "Cancelar",
    "close-chat-modal": {
      "cancel": "Cancel",
      "chat": "{engagementId}",
      "continue": "Continue",
      "conversation": "{engagementId}",
      "resolve": "{engagementId}"
    },
    "co-browse": "CoBrowse",
    "cobrowse": null,
    "collapsable-tile": null,
    "color": null,
    "conference": {
      "close-modal": {
        "no": "No",
        "title": "¿Cerrar conferencia?",
        "warning": "Usted es el titular de la conferencia. Al salir de la conferencia se terminará. ¿Desea salir?",
        "yes": "Sí"
      },
      "ownership-modal": {
        "accept": "Aceptar",
        "refuse": "Rechazar",
        "title": "¿Acepta la titularidad?"
      }
    },
    "custom-scripts": {
      "add-new-header-script": "Add New Header",
      "add-new-text-script": "Añadir nueva secuencia de comandos",
      "cancel": "Cancelar",
      "confirm-changes": "Confirmar cambios",
      "delete": "Eliminar",
      "delete-header-script": "Delete header",
      "delete-text-script": "Delete script",
      "do-not-save": "No guardar",
      "edit-custom-scripts": "Edit custom scripts",
      "edit-header-script": "Edit header",
      "edit-text-script": "Edit script",
      "incorrect-scripts": "Scripts could not be loaded because of invalid character(s)",
      "input-header-placeholder": "Enter heading here",
      "input-text-placeholder": "Escribir secuencia de comandos aquí",
      "invalid-chars-in-scripts": "Invalid character(s) in script(s): {n}",
      "my-scripts": "Mis secuencias de comandos",
      "no-access": "You do not have access to custom scripts",
      "save": "Guardar",
      "save-and-close": "Guardar y cerrar",
      "saved": "Saved custom scripts",
      "title": "Secuencias de comandos personalizados",
      "undo-delete-header-script": "Undo delete header",
      "undo-delete-text-script": "Undo delete script",
      "unsaved-changes-prompt": "Hay cambios sin guardar en sus diálogos. ¿Quiere guardarlos?"
    },
    "device-settings": null,
    "digital-voice-and-video": null,
    "dispositions": "Disposiciones",
    "emojis": null,
    "end-call-confirmation-modal": null,
    "engagement-tab": {
      "chat": "chat",
      "chat-duration": "chat duration",
      "chat-has-new-messages": "{deviceType} chat {chatIndex}, unresponded timer {unrespondedMessageTimer} seconds, {unrespondedMessages} new messages.",
      "completed": "COMPLETADO",
      "countdown": "countdown",
      "current-chat-has-new-messages": "Current chat has {unrespondedMessages} new messages.",
      "no-engagements": "You have no engagements at this time",
      "unresponded-timer": "unresponded timer"
    },
    "engagements-to-follow-up": "Interacciones para seguir",
    "escalate-modal": {
      "chat-escalation": "Escalamiento de chat"
    },
    "escalation-form": {
      "cancel": "Cancelar",
      "description": "Description",
      "submit": "Enviar"
    },
    "filter": "Filter",
    "follow-up": {
      "conversation-id": "ID de conversación",
      "notes": "Notas",
      "required-field": "* is a required field",
      "time-since-last-engagement": "Tiempo desde última interacción",
      "time-to-expire": "Tiempo para expirar",
      "updated": "Follow up engagement has been updated"
    },
    "follow-up-disabled": "No hay chats de seguimiento en cola",
    "follow-up-enabled": "There are follow up chats ready in your queue",
    "follow-up-modal": {
      "cancel": "Cancelar",
      "follow-up-agent": "Colocar en cola de seguimiento para:",
      "notes": "Notas",
      "submit": "Enviar",
      "title": "Seguimiento"
    },
    "header": {
      "active-chats": "Chats activos",
      "agent-desktop": "Escritorio de agentes",
      "chats-completed": "Chats completados",
      "chats-in-queue": "Chats en cola",
      "dismiss": "Dismiss",
      "ie-warning": "Support for Internet Explorer 11 will be retired as of August 30th, 2021. For optimal user experience, please move to a supported browser (Google Chrome, Microsoft Edge or Mozilla Firefox).",
      "logged-in-at": "Sesión iniciada a las",
      "menu": {
        "custom-scripts": "Scripts personalizados",
        "logout": "Cerrar sesión",
        "my-settings": "Mi configuración"
      },
      "navigation": "Navigation",
      "nina-coach-completed": "Nina Coach Completed",
      "public-user-id": "ID de usuario pública",
      "scripts": "Secuencias de comandos",
      "skip-main-region": "Skip to Main Region",
      "skip-main-region-abbr": "Skip to Main",
      "summary": "Resumen",
      "username": "Nombre de usuario"
    },
    "illegal-word": "No se puede usar en su mensaje al cliente Se debe eliminar antes de enviar.",
    "interactive-data": null,
    "internal-chat": {
      "close": "Cerrar",
      "expand-internal-chat": "unread messages. Press control plus shift plus X to expand internal chat.",
      "how-to-close": "To close internal chat, other members must first leave the engagement.",
      "internal-chat": "Internal Chat",
      "minimize": "Minimize",
      "unread-messages": "unread messages"
    },
    "link": "Link",
    "load-engagement": "Cargar interacción",
    "lockout": null,
    "login-form": {
      "access-denied": "User does not have access to Agent Desktop.",
      "account-maybe-locked": "You have attempted to authenticate 5 times unsuccessfully. Your account may be locked, please check with your administrator",
      "agent-id": "Agent ID",
      "agent-logged-off": "You were logged off",
      "ajax-authorization-failed": "Error de autorización de Ajax - póngase en contacto con el administrador del sistema.",
      "already-logged-in-modal-message": "Actualmente hay una sesión activa para el agente \"{agentName}\", ciérrela para abrir una nueva sesión.",
      "already-logged-in-modal-title": "Ya está conectado",
      "application-error": "Ocurrió un error de la aplicación al intentar iniciar sesión",
      "application-error-generic": "An application error has occurred please contact your system administrator and relogin.",
      "bad-credentials": "Nombre de usuario o contraseña no válidos",
      "changed-password": "La contraseña se guardó correctamente",
      "default-invalidation-error": "You were logged off due to an unexpected authentication error. Please log in again. If this problem persists, contact your system administrator.",
      "details": "Detalles",
      "forgot-your-password": "¿Olvidó su contraseña?",
      "hide-details": "Ocultar detalles",
      "internet-restored": "Internet connection has been restored, please login again",
      "log-in": "Iniciar sesión",
      "logout-inactivity": "Your session has been logged out due to inactivity",
      "missing-password": "Falta la contraseña",
      "missing-username": "Falta el nombre de usuario",
      "no-internet": "No internet connection detected, please reconnect and try again",
      "password": "Contraseña",
      "server-timeout-message": "A server timeout error occurred. Please refresh and try again. Thank You.",
      "server-timeout-title": "Server Timeout",
      "service-unavailable": "Componente no disponible, póngase en contacto con el administrador del sistema",
      "show-details": "Mostrar detalles",
      "timestamp": "Timestamp",
      "unlock": "Unlock",
      "url": "URL",
      "user-account-is-locked": "Se ha bloqueado su cuenta porque superó la cantidad máxima de intentos de inicio de sesión",
      "username": "Nombre de usuario"
    },
    "logout-button": {
      "logout": "Cerrar sesión"
    },
    "metrics": {
      "agent-metrics": "Métricas de agente",
      "available": "Disponible",
      "busy": "Ocupado",
      "chat-slots": "Puestos de chat",
      "chats-in-my-queue": "chats waiting in \"My Queue\"",
      "chats-in-queue": "Chats en cola",
      "free": "Libre",
      "max": "Máximo",
      "metrics-title": "Resumen de disponibilidad del agente",
      "my-queue": "(Mi Cola: {n})",
      "total": "Total"
    },
    "misc": {
      "prevent-refresh-message": "¿Está seguro de que desea volver a cargar la página?"
    },
    "modal": {
      "cancel": "Cancelar",
      "close": "Cerrar",
      "okay": "Okay",
      "warn-title": "Warning"
    },
    "my-settings": null,
    "named-entities-form": null,
    "next": "Next",
    "nina-coach": null,
    "notifications": null,
    "oh-no": "Oh no",
    "password-reset": {
      "confirm-password": "Confirmar contraseña",
      "current-password": "Contraseña actual",
      "message": "Ha expirado su contraseña, ingrese una nueva contraseña",
      "minimum-password-length": "La contraseña debe tener doce caracteres como mínimo",
      "new-password": "Nueva contraseña",
      "please-confirm-your-new-password": "Por favor escriba nuevamente su nueva contraseña",
      "please-provide-a-new-password": "Por favor proporcione una contraseña nueva",
      "please-provide-your-current-password": "Por favor proporcione su contraseña actual",
      "submit": "Enviar",
      "username": "Nombre de usuario",
      "username-is-a-required-field": "El nombre de usuario es un campo obligatorio",
      "your-passwords-must-match": "La nueva contraseña y la nueva contraseña confirmada son diferentes"
    },
    "performance-metrics": {
      "agentUtilization": "Utilización de puestos de chat",
      "assignedCount": "Solicitud asignado",
      "availableUtilization": "Disponibilidad de puestos de chat",
      "avgConversionProductQuantity": "Unidades por conversión",
      "avgDispositionTime": "Tiempo medio de disposición",
      "avgEngagementHandleTime": "Tiempo de manejo de chat",
      "avgEngagementHandleTimeSLA": "Tiempo de manejo de chat SLA %",
      "avgInitialAgentResponseTime": "Tiempo medio de respuesta inicial",
      "avgOrderValue": "Valor promedio de pedido ($)",
      "busyClickCount": "Veces en ocupado por cada inicio de sesión",
      "chatsPerLaborHour": "Chats por hora de trabajo",
      "conversionCount": "Conversiones",
      "conversionProductQuantity": "Unidades vendidas",
      "cumulativeLaborUtilization": "Utilización acumulativa de trabajo",
      "efficiency": "Efficiency",
      "initialResponseWithinSLA": "Respuesta inicial dentro de SLA (%)",
      "logged-in-time": "Logged in Time Metrics",
      "loginDuration": "Tiempo de sesión iniciada",
      "percentAssisted": "Asistido %",
      "percentConversion": "% de Conversión",
      "percentEndedByCustomer": "% terminado por el cliente",
      "salesPerLaborHour": "Chats por hora de trabajo",
      "totalBusyTime": "Tiempo total ocupado",
      "utilization": "Utilization",
      "volume": "Volume"
    },
    "power-select": null,
    "previous": "Previous",
    "request-additional-chat": {
      "chat-slots-enabled": "Additional chats enabled",
      "chat-slots-not-full": "Sus slots de chat deben estar llenos para solicitar chats adicionales",
      "default": "Additional chat slots are available",
      "details": "To toggle the state please press space bar",
      "extra-chat-slots-full": "Ha alcanzado el límite de chats adicionales",
      "not-available": "Debe estar en estado disponible",
      "request-more-chats": "Request more chats"
    },
    "retry": "Retry",
    "rich-widget": null,
    "scripts": "Secuencias de comandos",
    "sdk-dispositions": {
      "add-notes": "Agregar notas",
      "cancel": "Cancelar",
      "choose-all": "Choose all that apply.",
      "choose-one": "Choose the one which best applies.",
      "close-engagement": "Close Engagement",
      "load-fail": "An error occurred while loading dispositions",
      "loading": "Loading",
      "loading-dispositions": "Fetching dispositions",
      "not-found": "\"{filterInput}\" not found",
      "pre-selected": {
        "title": "From previous engagement",
        "tooltip": {
          "clear-all": "Unselect all disposition reasons from previous engagement",
          "select-all": "Select all disposition reasons from previous engagement"
        }
      },
      "search-placeholder": "Search Dispositions",
      "select-disposition": "You must select a disposition first.",
      "show-pagination": "Pagination",
      "submit": "Enviar",
      "window-title": "Disposiciones"
    },
    "sdk-scripts": {
      "absent": "There are no scripts configured. Please contact administrator.",
      "agent-coach": "Agent Coach",
      "commands-cannot-be-copied": "Los comandos no se pueden copiar (tenía la tecla de mayúsculas pulsada)",
      "custom-scripts": "Mis secuencias de comandos",
      "custom-scripts-here": "Las secuencias de comandos personalizados van aquí",
      "empty-script-tree": "Árbol de escritura vacío.",
      "error": null,
      "error-fetching": "There was an error while fetching scripts",
      "input-script": {
        "cancel": "Cancelar",
        "submit": "Enviar"
      },
      "limited-access": "Script Tree Access Limited.",
      "loading": "Espere mientras se cargan los diálogos...",
      "script-cannot-be-modified": "Este diálogo no se puede modificar",
      "system-scripts": "Secuencias de comandos de sistema",
      "training-keyboard-1": "Press",
      "training-keyboard-2": "to navigate to script groups. Use the",
      "training-keyboard-3": "arrow keys to navigate to script lines.",
      "unavailable": "Diálogo no disponible"
    },
    "search": null,
    "settings": "Configuración",
    "site": "Site",
    "slideout": {
      "expand-details": "expand details"
    },
    "sso": {
      "error-contact": "Póngase en contacto con soporte técnico. Haga clic en \"Aceptar\" abajo para ir a la página de inicio de sesión",
      "error-message": "Ocurrió un error con SSO.",
      "error-okay": "Aceptar",
      "logout-message": "Ha sido desconectado de Agent Desktop",
      "relogin": "REINICIAR SESIÓN"
    },
    "static-transcript": {
      "abc-widget-message-sent": "{agentDisplayText} sent",
      "chat-history": "Historial de chat",
      "clear-filter": "clear filter",
      "current": "Current",
      "default-abc-widget-message-sent": "Interactive Data sent",
      "default-rich-widget-message-sent": "*** Initiated Rich Widget ***",
      "initiated-widget": "*** Initiated Rich Widget: {label} ***",
      "next-result": "next result",
      "previous-result": "previous result",
      "rich-widget-message-sent": "{agentDisplayText} sent",
      "search-scripts": "Search Scripts",
      "search-transcripts": "search transcripts",
      "static-transcript": "Transcripción estática"
    },
    "submit": "Enviar",
    "summary": {
      "updated-every-10-seconds": "se actualiza cada 10 segundos"
    },
    "training": "Entrenamiento",
    "training-tab": "Training Tab",
    "transcript-agent-input": {
      "send": "Enviar"
    },
    "transcript-history": null,
    "transcript-window": {
      "abc-widget-message-sent": "{agentDisplayText} has been sent",
      "customer-closed-engagement": "El cliente cerró la ventana de chat",
      "customer-is-typing": "El cliente está escribiendo...",
      "customer-lost-connection": "El cliente perdió la conexión",
      "customer-minimized-chat-window": "El cliente ha minimizado el chat",
      "customer-restored-chat-window": "El cliente ha restaurado el chat",
      "customer-stopped-typing": "El cliente dejó de escribir...",
      "default-abc-widget-message-sent": "Interactive Data has been sent",
      "default-rich-widget-message-sent": "Rich Widget has been sent",
      "events": null,
      "interrupted": "interrumpido, no enviado",
      "moved-to-persistent": "El cliente ha sido transferido exitosamente a un chat persistente",
      "new-chat-information": "There is new chat information. Press control plus shift plus A to announce content.",
      "no-new-chat-information": "There is no new chat information.",
      "not-sent": "Message failed to send",
      "rich-widget-message-sent": "{agentDisplayText} has been sent",
      "scroll-to-bottom": "Desplazarse al final",
      "see-new-messages": "Ver mensajes nuevos",
      "started-move-to-persistent": "El cliente está siendo transferido a una ventana de chat persistente",
      "time-delayed": "retrasado en tiempo",
      "transcript-line": {
        "auto-close-close": "(Engagement will auto-end in {seconds} seconds if customer doesn’t respond)",
        "auto-close-warn": "(Warning Message - {seconds} seconds)",
        "command": "Comando",
        "rich-widget-response": "Rich Widget Response",
        "view-more": "View More",
        "xform": "Datapass"
      }
    },
    "transfer-engagement": {
      "agent-group-placeholder": "Please select an agent group",
      "agent-notes": "Notas del agente",
      "agent-placeholder": "No Agent Selected",
      "async-agent-group-info": "No hay agentes disponibles El cliente quedará en cola de espera hasta que haya un agente disponible",
      "available-slots": "Available slots",
      "business-unit-placeholder": "Please select a business unit",
      "conference": "Conferencia",
      "conference-queue-slots": "puestos en la cola de conferencia",
      "field-reset": "fields have been reset",
      "no-agents-with-attributes-available": "No agents with attributes available",
      "no-matching-agents-found": "No se encontraron agentes que coinciden",
      "optionally-select": "Opcionalmente, puede seleccionar directamente un agente",
      "please-select-a-bunit": "Por favor seleccione una unidad de negocios de la lista desplegable",
      "prioritize-request": "Priorizar la solicitud",
      "reset": "Restablecer",
      "select-a-bunit": "Seleccione una unidad de negocios",
      "select-an-agent-group": "Seleccione un grupo de agentes",
      "select-one-or-more-agent-attributes": "Seleccione uno o más atributos deseados en el agente",
      "transfer": "Transferencia",
      "transfer-queue-slots": "puestos en la cola de transferencia"
    },
    "transfer-window": {
      "transfer-and-conference": "Transferencia y conferencia"
    },
    "twitter-private-reply-confirmation": null,
    "video-chat": null,
    "visitor-info-tab": {
      "browser-type": "Tipo de navegador",
      "browser-version": "Browser Version",
      "business-rule": "Regla de negocios",
      "chat-id": "ID de chat",
      "chat-type": "Tipo de Chat",
      "conversation-channel": "Canal de conversación",
      "conversation-id": "ID de conversación",
      "customer-id": "ID del cliente",
      "customer-information": "Customer Information",
      "customer-name": "Nombre del cliente",
      "device-type": "Tipo de dispositivo",
      "locale": "Sitio",
      "operating-system-type": "Operating System Type",
      "page-marker": "Marcador de página"
    },
    "visitor-information": "Información para visitantes",
    "voice-chat": null,
    "web-tools": "Herramientas de la web",
    "x-tabs": null
  }], ["fr", {
    "a11y": {
      "arrow-key-navigation": "Utilisez les flèches haut et bas pour passer d’un élément à un autre. Utilisez les flèches gauche et droite pour passer d’un élément cliquable à un autre au sein d’un même élément.",
      "link-navigation-instruction": "{content}. Utilisez les flèches gauche et droite pour passer d’un lien à un autre.",
      "resize-horizontal": "Poignée de redimensionnement. Utilisez les flèches haut et bas pour redimensionner.",
      "resize-vertical": "Poignée de redimensionnement. Utilisez les flèches gauche et droite pour redimensionner.",
      "scroll-message": "appuyez sur les flèches haut et bas pour naviguer",
      "transcript-history-navigation-instruction": "Historique des transcriptions. Utilisez les flèches haut et bas pour passer d’un élément à un autre.",
      "transcript-navigation-instruction": "Transcription de la discussion. Utilisez les flèches haut et bas pour passer d’un élément à un autre.",
      "widget-navigation-instruction": "{content}. Utilisez les flèches gauche et droite pour naviguer."
    },
    "active-engagement": {
      "already-escalated": "Cette discussion a déjà été transmise au supérieur",
      "app-chat": "{chat} via appli",
      "apple-business-chat": "{chat} via Apple Business Chat",
      "background-color": "Couleur de fond",
      "bold": "Gras",
      "channel": "Canal",
      "close": "Fermer",
      "close-chat": "Fermer le tchat",
      "conference": "Conférence",
      "conversation": "Conversation",
      "copy": "Copier",
      "copy-masked-transcript": "Copier la transcription",
      "copy-transcript-notification": "Transcription copiée",
      "desktop-chat": "{chat} sur ordinateur de bureau",
      "device": "Appareil",
      "eapi": "API de la discussion",
      "engagement": "Discussion",
      "engagement-action-disabled-follow-up": "Désactivé jusqu’à ce que la conversation commence",
      "escalate": "Faire remonter",
      "escalate-chat": "Faire remonter le tchat",
      "escalated": "Transmis au supérieur",
      "facebook-chat": "{chat} via Facebook Messenger",
      "file-upload": "Téléchargement de fichiers",
      "follow-up": "En suivi",
      "general-chat": "{chat} général",
      "googles-business-messages-chat": "{chat} via Google's Business Messages",
      "hide-details": "Cacher les détails",
      "hide-editor": "Cacher l’éditeur",
      "hide-file-upload": "Cacher la demande de téléchargement de fichier",
      "instagram-chat": "{chat} via Instagram",
      "italic": "Italique",
      "join-conference": "Rejoindre",
      "join-conference-ada": "Rejoindre la conférence avec le client",
      "line-chat": "{chat} via Line",
      "mask": "Masquer cette information personnelle",
      "mobile-chat": "{chat} sur mobile",
      "more-options": "Plus d’options",
      "pending-send-message": "En attente, le message est en cours d’envoi",
      "resend": "Envoyer à nouveau",
      "resolve": "Résoudre",
      "resolve-chat": "Résoudre le tchat",
      "restricted-by-webtool": "Restreint par l’outil web",
      "search": "Recherche...",
      "search-icon-title": "Recherche",
      "search-input-title": "Entrée de recherche",
      "send-a-message": "Envoyer un message",
      "send-message": "Envoyer le message",
      "show-details": "Afficher les détails",
      "show-editor": "Afficher l’éditeur",
      "show-file-upload": "Envoyer une demande de téléchargement de fichier",
      "sms-chat": "{chat} par SMS",
      "tablet-chat": "{chat} sur tablette",
      "telegram-chat": "{chat} via Telegram",
      "transfer": "Transfert",
      "transfer-and-conference": "Transfert et conférence",
      "twitter": {
        "private": "Envoyer un message privé",
        "public": "Envoyer un tweet public"
      },
      "twitter-chat": "{chat} via Twitter",
      "type-your-message": "tapez votre message",
      "underline": "Souligné",
      "viber-chat": "{chat} via Viber",
      "waiting": "Mettre en attente",
      "web-chat": "{chat} internet",
      "whatsapp-chat": "{chat} via WhatsApp",
      "you-are-not-the-conference-owner": "Vous n’êtes pas le propriétaire de la conférence"
    },
    "agent-coach": {
      "context": {
        "reset": "Retour au plus récentes recommandations",
        "set": "Afficher les recommandations"
      },
      "error-fetching": "Une erreur s’est produite lors de la récupération des recommandations",
      "fetch-recommendation": "Message du client {customerMessage}. Appuyez sur ENTRÉE pour obtenir les réponses recommandées.",
      "filter-as-i-type": "Filtre dynamique",
      "named-entities": {
        "address": "Adresse",
        "person-agent": "Nom de l’agent",
        "person-customer": "Nom du client"
      },
      "no-recommendations-found": "Aucune recommandation n’a été trouvée",
      "top-suggestions": "Meilleures suggestions"
    },
    "agent-group": "Groupe d’agent",
    "agent-groups": {
      "confirm-button": "Confirmer la sélection",
      "description": "Sélectionnez un ou plusieurs groupe(s) d’agents au(x)quel(s) vous souhaitez être affecté(e) durant cette session de tchat",
      "list-header": "Groupes d’agents",
      "select-all": "Tout sélectionner",
      "title": "Sélection de groupe d’agents"
    },
    "agent-settings": {
      "chat-details": "Détails du tchat",
      "chat-tab-position": "Position de l’onglet du tchat",
      "close": "Fermer",
      "collapse-all": "Tout masquer",
      "collapse-script-headings": "Masquer tous les en-têtes de scripts par défaut",
      "collapse-script-headings-disabled-description": "Les en-têtes des scripts sont tous affichés par défaut pour les nouvelles discussions",
      "collapse-script-headings-enabled-description": "Les en-têtes des scripts sont tous masqués par défaut pour les nouvelles discussions",
      "create-custom-layout": "Créer une mise en page personnalisée",
      "create-custom-layout-description": "Vous avez modifié la mise en page de votre discussion en cours. Vous pouvez créer une mise en page personnalisée à appliquer systématiquement aux nouvelles discussions.",
      "ctrl-enter-submit": "Le raccourci « CTRL + ENTRÉE » permet d’envoyer des messages (au lieu d’appuyer seulement sur ENTRÉE)",
      "custom-layout-created": "La mise en page personnalisée a été créée",
      "custom-layout-updated": "La mise en page personnalisée a été mise à jour",
      "double-click": "Double clic",
      "double-click-description": "Un simple clic permet d’insérer le script dans le champ de saisie",
      "english": "English",
      "expand-all": "Tout afficher",
      "font-size": "Taille de la police",
      "freeze-chat-tab-position": "Verrouiller la position de l’onglet pendant toute la durée du tchat",
      "french": "Français",
      "german": "Deutsch",
      "hide-details": "Masquer les détails",
      "hide-details-disabled-description": "Les messages du système sont affichés par défaut dans la transcription du tchat pour les nouvelles discussions",
      "hide-details-enabled-description": "Les messages du système sont masqués par défaut dans la transcription du tchat pour les nouvelles discussions",
      "italian": "Italiano",
      "japanese": "日本語",
      "keyboard": "Clavier",
      "language": "Langue",
      "large": "Grand",
      "layout": "Modèle de mise en page",
      "layouts": {
        "chat-center": "Tchat au centre",
        "chat-left": "Tchat à gauche",
        "chat-left-two-columns": "Deux colonnes (tchat à gauche)",
        "chat-right": "Tchat à droite",
        "custom": "Mise en page personnalisée"
      },
      "login-as-available": "Connexion avec le statut Disponible",
      "login-as-busy": "Connexion avec le statut Occupé",
      "login-state": "Statut de connexion",
      "medium": "Moyen",
      "my-settings": "Mes paramètres",
      "notifications": "Notifications",
      "play-sounds": "Activer les sons",
      "portuguese": "Português",
      "preview": "Aperçu",
      "russian": "Русский",
      "script-headings": "En-têtes de scripts",
      "send-scripts": "Envoi de scripts",
      "single-click": "Simple clic",
      "single-click-description": "La commande « MAJ + clic » permet d’insérer le script dans le champ de saisie",
      "small": "Petit",
      "spanish": "Español",
      "status-available": "Disponible",
      "update-custom-layout": "Mettre à jour la mise en page personnalisée",
      "update-custom-layout-description": "Vous avez modifié la mise en page de votre discussion en cours. Vous pouvez mettre à jour votre mise en page personnalisée à appliquer systématiquement aux nouvelles discussions.",
      "visual-alerts": "Alertes visuelles"
    },
    "agent-status-toggle": {
      "available": "Disponible",
      "busy": "Occupé",
      "busy-auto": "Occupé (automatique)",
      "warning-set-fail": "Une erreur s’est produite lors de la mise à jour de votre statut. Veuillez réessayer. Si le problème persiste, veuillez contacter votre administrateur."
    },
    "agents": "Agents",
    "alert": "Alerte",
    "alerts": {
      "agent-has-active-engagements": "Vous devez quitter tous les tchats actifs avant de vous déconnecter",
      "cobrowse-already-active": "Veuillez fermer la session de partage de navigation en cours avant d’envoyer une nouvelle invitation"
    },
    "app-error-modal": {
      "error-n-of-total": "Erreur {n} sur {total}:",
      "error-occurred": "{count, plural, =1 {# erreur est survenue.} other {# erreurs sont survenues.}}",
      "hide-details": "Cacher les détails",
      "hide-errors": "Cacher les erreurs",
      "ok": "OK",
      "show-details": "Afficher les détails",
      "title": "Erreur"
    },
    "app-error-pill-title": "Erreur",
    "async": {
      "cannot-resolve": "Impossible de résoudre cette conversation {conversationId}, car le client a commencé un nouveau tchat.",
      "okay": "OK",
      "title": "Avertissement"
    },
    "auto-transfer": {
      "message": "Un nouveau chat qui n’a pas reçu de réponse initiale dans le temps requis a été assigné.",
      "respond-now": "Répondre maintenant",
      "title": "Transfert automatique"
    },
    "business-unit": "Unité opérationnelle",
    "cancel": "Annuler",
    "close-chat-modal": {
      "cancel": "Annuler",
      "chat": "Fermer la discussion {engagementId}",
      "continue": "Continuer",
      "conversation": "Fermer la conversation {engagementId}",
      "resolve": "Résoudre la conversation {engagementId}"
    },
    "co-browse": "Partage de navigation",
    "cobrowse": {
      "color-picker": "Sélectionner la couleur de la surbrillance",
      "end": "Terminer le partage de navigation",
      "error": "Une erreur vient d’affecter le partage de navigation",
      "hide-chat-window": "Masquer la fenêtre de tchat",
      "inactive": "Inactif",
      "reload": "Rafraîchir la page",
      "show-chat-window": "Afficher la fenêtre de tchat",
      "unhighlight": "Enlever la surbrillance"
    },
    "collapsable-tile": {
      "collapse": "Masquer",
      "compress": "Réduire",
      "expand": "Afficher",
      "header-snap-back": "Fermer pour revenir au menu latéral",
      "reminders": "Rappels"
    },
    "color": {
      "blue": "Bleu",
      "cyan": "Cyan",
      "gray": "Gris",
      "green": "Vert",
      "light-blue": "Bleu clair",
      "light-green": "Vert clair",
      "magenta": "Magenta",
      "no-color": "Pas de couleur",
      "orange": "Orange",
      "peach": "Pêche",
      "pink": "Rose",
      "purple": "Violet",
      "white": "Blanc",
      "yellow": "Jaune"
    },
    "conference": {
      "close-modal": {
        "no": "Non",
        "title": "Fermer la conférence ?",
        "warning": "Vous êtes le propriétaire de la conférence. Quitter la conférence y mettra fin. Souhaitez-vous la quitter ?",
        "yes": "Oui"
      },
      "ownership-modal": {
        "accept": "Accepter",
        "refuse": "Refuser",
        "title": "Accepter d’être le nouveau propriétaire ?"
      }
    },
    "custom-scripts": {
      "add-new-header-script": "Ajouter un nouvel en-tête",
      "add-new-text-script": "Ajouter un nouveau script",
      "cancel": "Annuler",
      "confirm-changes": "Confirmer les changements",
      "delete": "Supprimer",
      "delete-header-script": "Supprimer l’en-tête",
      "delete-text-script": "Supprimer le script",
      "do-not-save": "Ne pas sauvegarder",
      "edit-custom-scripts": "Modifier les scripts personnalisés",
      "edit-header-script": "Modifier l’en-tête",
      "edit-text-script": "Modifier le script",
      "incorrect-scripts": "Les scripts n’ont pas pu être chargés en raison d’un ou plusieurs caractère(s) invalide(s)",
      "input-header-placeholder": "Entrer l’en-tête ici",
      "input-text-placeholder": "Entrer le script ici",
      "invalid-chars-in-scripts": "Caractère(s) invalide(s) dans le(s) script(s): {n}",
      "my-scripts": "Mes scripts",
      "no-access": "Vous n’avez pas accès aux scripts personnalisés",
      "save": "Sauvegarder",
      "save-and-close": "Sauvegarder et fermer",
      "saved": "Scripts personnalisés sauvegardés",
      "title": "Scripts personnalisés",
      "undo-delete-header-script": "Annuler la suppression de l’en-tête",
      "undo-delete-text-script": "Annuler la suppression du script",
      "unsaved-changes-prompt": "Vos scripts contiennent des modifications non sauvegardées. Voulez-vous les sauvegarder ?"
    },
    "device-settings": {
      "audio-device": "Appareil audio",
      "browser-not-supported": "Cette application ne prend pas en charge les appareils de votre navigateur actuel",
      "close": "Fermer",
      "ff-warn-message": "Les appareils dotés d’une sortie audio ne sont pas pris en charge par votre navigateur",
      "header": "Paramètres de l’appareil",
      "mic-device": "Microphone",
      "no-device": "sélection indisponible",
      "permission-not-granted": "L’autorisation relative à cet appareil n’a pas été accordée",
      "video-device": "Appareil vidéo",
      "video-error-message": "aperçu indisponible",
      "video-not-available": "La vidéo n’est pas disponible"
    },
    "digital-voice-and-video": {
      "invite-to-video-call": "Proposer un appel vidéo au client",
      "invite-to-voice-call": "Proposer un appel vocal au client",
      "title": "Voix numérique & Vidéo numérique",
      "title-video": "Vidéo numérique",
      "title-voice": "Voix numérique",
      "video-call": "Appel vidéo",
      "voice-call": "Appel vocal"
    },
    "dispositions": "Résolutions apportées",
    "emojis": {
      "clapping-hands": "Applaudissement",
      "grinning-face": "Visage souriant",
      "happy-face": "Visage joyeux",
      "icon-title": "Emojis",
      "panel-title": "Panneau d’emojis",
      "raising-hands": "Mains en l’air",
      "red-heart": "Cœur rouge",
      "sad-face": "Visage triste",
      "thumbs-down": "Pouce vers le bas",
      "thumbs-up": "Pouce vers le haut",
      "waving-hand": "Salut de la main"
    },
    "end-call-confirmation-modal": {
      "cancel": "Annuler",
      "content-video": "Vous êtes sur le point de terminer l’appel vidéo avec ce client.",
      "content-voice": "Vous êtes sur le point de terminer l’appel avec ce client.",
      "end-call-button": "Terminer l’appel",
      "title": "Terminer l’appel ?"
    },
    "engagement-tab": {
      "chat": "tchat",
      "chat-duration": "durée du tchat",
      "chat-has-new-messages": "{deviceType} tchat {chatIndex}, temps écoulé depuis le dernier message du client {unrespondedMessageTimer} secondes, {unrespondedMessages} nouveaux messages.",
      "completed": "TERMINÉ",
      "countdown": "compte à rebours",
      "current-chat-has-new-messages": "Ce tchat contient {unrespondedMessages} nouveaux messages.",
      "no-engagements": "Vous n’avez pas de discussions en cours",
      "unresponded-timer": "temps écoulé depuis le dernier message du client"
    },
    "engagements-to-follow-up": "Discussions en attente d’un suivi",
    "escalate-modal": {
      "chat-escalation": "Faire remonter le tchat"
    },
    "escalation-form": {
      "cancel": "Annuler",
      "description": "Description",
      "submit": "Soumettre"
    },
    "filter": "Filtre",
    "follow-up": {
      "conversation-id": "ID de la conversation",
      "notes": "Notes",
      "required-field": "* est un champ requis",
      "time-since-last-engagement": "Temps écoulé depuis le dernier tchat",
      "time-to-expire": "Temps restant avant l’expiration",
      "updated": "La discussion en suivi a été mise à jour"
    },
    "follow-up-disabled": "Il n’y a pas de tchats en suivi dans votre file d’attente",
    "follow-up-enabled": "Il y a des tchats en suivi dans votre file d’attente",
    "follow-up-modal": {
      "cancel": "Annuler",
      "follow-up-agent": "Mettre dans la file d’attente en suivi pour:",
      "notes": "Notes",
      "submit": "Soumettre",
      "title": "Mettre en suivi"
    },
    "header": {
      "active-chats": "Tchats actifs",
      "agent-desktop": "Agent Desktop",
      "chats-completed": "Tchats terminés",
      "chats-in-queue": "Tchats en file d’attente",
      "dismiss": "Rejeter",
      "ie-warning": "La compatibilité avec Internet Explorer 11 a été supprimée. Pour bénéficier d’une expérience utilisateur optimale, veuillez basculer sur un navigateur compatible (Google Chrome, Microsoft Edge ou Mozilla Firefox).",
      "logged-in-at": "Connecté à",
      "menu": {
        "custom-scripts": "Personnaliser les scripts",
        "logout": "Se déconnecter",
        "my-settings": "Mes paramètres"
      },
      "navigation": "Navigation",
      "nina-coach-completed": "Nina Coach terminé",
      "public-user-id": "Identifiant d’utilisateur public",
      "scripts": "Scripts",
      "skip-main-region": "Passer à la région principale",
      "skip-main-region-abbr": "Passer à la principale",
      "summary": "Sommaire",
      "username": "Nom d’utilisateur"
    },
    "illegal-word": "ne peut pas être utilisé dans votre message au client. Ceci doit être retiré avant l’envoi.",
    "interactive-data": {
      "default-label": "Données interactives"
    },
    "internal-chat": {
      "close": "Fermer",
      "expand-internal-chat": "Messages non lus. Appuyez sur CTRL + MAJ + X pour afficher le tchat interne.",
      "how-to-close": "Pour fermer le tchat interne, les autres membres doivent d’abord quitter la discussion.",
      "internal-chat": "Tchat interne",
      "minimize": "Diminuer",
      "unread-messages": "messages non lus"
    },
    "link": "Lien",
    "load-engagement": "Ouvrir la discussion",
    "lockout": {
      "inactivity-message": "Blocage automatique en raison de votre inactivité"
    },
    "login-form": {
      "access-denied": "L’utilisateur n’a pas accès au Agent Desktop.",
      "account-maybe-locked": "Vous avez effectué 5 tentatives d’authentification, sans succès. Il se peut que votre compte ait été bloqué. Vérifiez si c’est le cas auprès de votre administrateur.",
      "agent-id": "ID de l’agent",
      "agent-logged-off": "Vous avez été déconnecté(e)",
      "ajax-authorization-failed": "Erreur d’autorisation Ajax: veuillez contacter votre administrateur système.",
      "already-logged-in-modal-message": "L’agent {agentName} possède actuellement une session active. Veuillez fermer cette session pour en ouvrir une nouvelle.",
      "already-logged-in-modal-title": "Déjà connecté",
      "application-error": "Une erreur d’application est survenue lors de la tentative de connexion.",
      "application-error-generic": "Une erreur d’application est survenue. Veuillez contacter votre administrateur système puis vous reconnecter.",
      "bad-credentials": "Nom d’utilisateur ou mot de passe invalide",
      "changed-password": "Mot de passe changé avec succès",
      "default-invalidation-error": "Vous avez été déconnecté(e) en raison d’une erreur d’authentification inattendue. Veuillez vous reconnecter. Si le problème persiste, contactez votre administrateur système.",
      "details": "Détails",
      "forgot-your-password": "Mot de passe oublié ?",
      "hide-details": "Masquer les détails",
      "internet-restored": "La connexion internet est rétablie. Veuillez vous reconnecter.",
      "log-in": "Se connecter",
      "logout-inactivity": "Votre session a été déconnectée en raison de votre inactivité",
      "missing-password": "Mot de passe manquant",
      "missing-username": "Nom d’utilisateur manquant",
      "no-internet": "Aucune connexion internet n’a été détectée. Veuillez vous reconnecter et réessayer.",
      "password": "Mot de passe",
      "server-timeout-message": "Le serveur s’est arrêté inopinément. Veuiller rafraîchir la page et réessayer. Merci.",
      "server-timeout-title": "Coupure du serveur",
      "service-unavailable": "Le composant est indisponible, veuillez contacter l’administrateur du système",
      "show-details": "Afficher les détails",
      "timestamp": "Horodatage",
      "unlock": "Débloquer",
      "url": "URL",
      "user-account-is-locked": "Votre compte a été bloqué car vous avez atteint le nombre maximal de tentatives de connexion",
      "username": "Nom d’utilisateur"
    },
    "logout-button": {
      "logout": "Déconnexion"
    },
    "metrics": {
      "agent-metrics": "Statistiques de l’agent",
      "available": "Disponible",
      "busy": "Occupé",
      "chat-slots": "Emplacements de tchat",
      "chats-in-my-queue": "Tchats en attente dans \"Ma file d’attente\"",
      "chats-in-queue": "Tchats dans la file d’attente",
      "free": "Libre",
      "max": "Max",
      "metrics-title": "Sommaire de la disponibilité de l’agent",
      "my-queue": "(Ma file d’attente: {n})",
      "total": "Total"
    },
    "misc": {
      "prevent-refresh-message": "Êtes-vous sûr de vouloir actualiser la page ?"
    },
    "modal": {
      "cancel": "Annuler",
      "close": "Fermer",
      "okay": "OK",
      "warn-title": "Avertissement"
    },
    "my-settings": {
      "layout-preview": "aperçu de la mise en page",
      "preview": "agrandir l’aperçu"
    },
    "named-entities-form": {
      "submit-disabled-tooltip": "Un ou plusieurs champs de saisie n’ont pas été configurées. Veuillez spécifier une valeur pour chacun des champs avant d’envoyer."
    },
    "next": "Suivant",
    "nina-coach": {
      "alert": "alerte de Nina Coach",
      "all": "Tout",
      "all-checkbox": "la case ’tout’ est cochée",
      "arrow-down-icon": "icône de flèche vers le bas",
      "arrow-right-icon": "icône de flèche vers la droite",
      "clear-search": "effacer la recherche",
      "cleared-intents-query": "Recherche effacée",
      "concepts": "Concepts",
      "filter-by": "Filtrer par",
      "filter-icon": "icône de filtre",
      "intents": "Intentions",
      "loading-intents": "chargement des intentions",
      "name": "Nom",
      "nina": "Nina",
      "nina-suggested": "Suggestion de Nina",
      "no-available-concepts": "Pas de concepts disponibles",
      "no-intents": "Pas d’intentions",
      "no-opportunity": "Aucune opportunité",
      "press-tab-key": "appuyez sur TAB pour vous rendre sur le filtre",
      "response-text": "Texte de réponse possible",
      "search-icon": "icône de recherche",
      "search-placeholder": "Recherche d’intentions, de concepts ou de textes de réponse",
      "selected-intent": "Intention sélectionnée",
      "send": "Envoyer",
      "suggest-intent": "intention suggérée",
      "take-over": "Prendre le contrôle",
      "title": "Nina Coach",
      "transfer": "Transfert",
      "type": "Type"
    },
    "notifications": {
      "lost-connection": "Connexion perdue. Tentative de reconnexion.",
      "lost-connection-no-internet": "Votre connexion internet a été perdue. Vérifiez votre connexion internet, actualisez la page et reconnectez-vous.",
      "notify-new-engagement": "Nouveau tchat assigné",
      "notify-new-message": "Nouveau message du client"
    },
    "oh-no": "Oh non",
    "password-reset": {
      "confirm-password": "Confirmer le mot de passe",
      "current-password": "Mot de passe actuel",
      "message": "Votre mot de passe a expiré, veuillez en saisir un nouveau",
      "minimum-password-length": "La longueur minimale du mot de passe doit être d’au moins douze caractères",
      "new-password": "Nouveau mot de passe",
      "please-confirm-your-new-password": "Veuillez confirmer votre nouveau mot de passe",
      "please-provide-a-new-password": "Veuillez saisir un nouveau mot de passe",
      "please-provide-your-current-password": "Veuillez saisir votre mot de passe actuel",
      "submit": "Soumettre",
      "username": "Nom d’utilisateur",
      "username-is-a-required-field": "Le nom d’utilisateur est un champ obligatoire",
      "your-passwords-must-match": "Le nouveau mot de passe et la confirmation du nouveau mot de passe ne sont pas identiques"
    },
    "performance-metrics": {
      "agentUtilization": "Utilisation des emplacements de tchat",
      "assignedCount": "Demande assignée",
      "availableUtilization": "Disponibilité des emplacements de tchat",
      "avgConversionProductQuantity": "Unités par conversion",
      "avgDispositionTime": "Temps de résolution moyen",
      "avgEngagementHandleTime": "Temps de traitement du tchat",
      "avgEngagementHandleTimeSLA": "Temps de traitement du tchat conforme au SLA (en %)",
      "avgInitialAgentResponseTime": "Temps moyen de réponse initiale",
      "avgOrderValue": "Valeur moyenne de la commande ($)",
      "busyClickCount": "Nombre de fois en statut « Occupé » par connexion",
      "chatsPerLaborHour": "Tchats par heure de travail",
      "conversionCount": "Conversions",
      "conversionProductQuantity": "Unités vendues",
      "cumulativeLaborUtilization": "Utilisation cumulée de la main-d’oeuvre",
      "efficiency": "Efficacité",
      "initialResponseWithinSLA": "Réponse initiale dans les délais du SLA (en %)",
      "logged-in-time": "Temps de connexion (stats)",
      "loginDuration": "Temps de connexion",
      "percentAssisted": "% assisté",
      "percentConversion": "% de conversion",
      "percentEndedByCustomer": "% terminé par le client",
      "salesPerLaborHour": "Ventes par heure de travail",
      "totalBusyTime": "Temps total en statut « Occupé »",
      "utilization": "Utilisation",
      "volume": "Volume"
    },
    "power-select": {
      "no-results-found": "Aucun résultat trouvé",
      "type-to-filter": "tapez pour filtrer"
    },
    "previous": "Précédent",
    "request-additional-chat": {
      "chat-slots-enabled": "Tchats supplémentaires accordés",
      "chat-slots-not-full": "Vos emplacements de tchat doivent être tous occupés pour pouvoir demander des tchats supplémentaires",
      "default": "Des emplacements de tchat supplémentaires sont disponibles",
      "details": "Pour activer/désactiver l’état, veuillez appuyer sur la touche ESPACE",
      "extra-chat-slots-full": "Vous avez atteint le nombre maximal de tchats supplémentaires",
      "not-available": "Vous devez avoir le statut « Disponible »",
      "request-more-chats": "Demander d’autres tchats"
    },
    "retry": "Réessayer",
    "rich-widget": {
      "default-label": "Widget riche",
      "error-render": "L’aperçu du widget ne peut pas être chargé",
      "load": "Charger",
      "preview-title": "Aperçu du widget",
      "preview-widget-navigation": "Widget: {widgetLabel}. Appuyez sur la touche ENTRÉE pour ouvrir l’aperçu"
    },
    "scripts": "Scripts",
    "sdk-dispositions": {
      "add-notes": "Ajouter des notes",
      "cancel": "Annuler",
      "choose-all": "Sélectionnez toutes les options qui s’appliquent.",
      "choose-one": "Sélectionnez l’option qui correspond le plus.",
      "close-engagement": "Fermer la discussion",
      "load-fail": "Une erreur est survenue pendant le chargement des résolutions",
      "loading": "Chargement en cours",
      "loading-dispositions": "Récupération des résolutions",
      "not-found": "\"{filterInput}\" non trouvé",
      "pre-selected": {
        "title": "De la discussion précédente",
        "tooltip": {
          "clear-all": "Désélectionner toutes les résolutions de la discussion précédente",
          "select-all": "Sélectionner toutes les résolutions de la discussion précédente"
        }
      },
      "search-placeholder": "Recherche de résolutions",
      "select-disposition": "Vous devez d’abord sélectionner une résolution.",
      "show-pagination": "Pagination",
      "submit": "Soumettre",
      "window-title": "Résolutions"
    },
    "sdk-scripts": {
      "absent": "Aucun script n’est configuré. Veuillez contacter l’administrateur.",
      "agent-coach": "Agent Coach",
      "commands-cannot-be-copied": "Les commandes ne peuvent pas être copiées (la touche MAJ de votre clavier était enfoncée)",
      "custom-scripts": "Scripts personnalisés",
      "custom-scripts-here": "Les scripts personnalisés se trouveront ici",
      "empty-script-tree": "L’arborescence des scripts est vide.",
      "error": {
        "rich-widget-agent-input": "Les widgets riches ne peuvent pas être envoyés dans le champ de saisie"
      },
      "error-fetching": "Une erreur est survenue lors de la récupération des scripts",
      "input-script": {
        "cancel": "Annuler",
        "submit": "Envoyer"
      },
      "limited-access": "Accès limité à l’arborescence des scripts.",
      "loading": "Veuillez patienter pendant le chargement des scripts...",
      "script-cannot-be-modified": "Ce script ne peut être modifié",
      "system-scripts": "Scripts système",
      "training-keyboard-1": "Appuyez sur",
      "training-keyboard-2": "pour naviguer au sein des groupes de scripts. Utilisez les",
      "training-keyboard-3": "flèches pour naviguer au sein des lignes de scripts.",
      "unavailable": "Le script est indisponible"
    },
    "search": {
      "result": "résultat {searchResultsIndex} de {searchResultsCount}"
    },
    "settings": "Paramètres",
    "site": "Site",
    "slideout": {
      "expand-details": "afficher les détails"
    },
    "sso": {
      "error-contact": "Veuillez contacter votre assistance technique. Cliquez sur OK ci-dessous pour être redirigé(e) vers la page de connexion.",
      "error-message": "Un problème a été rencontré avec le SSO.",
      "error-okay": "OK",
      "logout-message": "Vous avez été déconnecté(e) du Agent Desktop",
      "relogin": "SE RECONNECTER"
    },
    "static-transcript": {
      "abc-widget-message-sent": "{agentDisplayText} envoyé",
      "chat-history": "Historique de tchat",
      "clear-filter": "supprimer le filtre",
      "current": "Actuelle",
      "default-abc-widget-message-sent": "Données interactives envoyées",
      "default-rich-widget-message-sent": "*** Widget riche activé ***",
      "initiated-widget": "*** Widget riche activé: {label} ***",
      "next-result": "résultat suivant",
      "previous-result": "résultat précédent",
      "rich-widget-message-sent": "{agentDisplayText} envoyé",
      "search-scripts": "Recherche de scripts",
      "search-transcripts": "Recherche",
      "static-transcript": "Transcription statique"
    },
    "submit": "Soumettre",
    "summary": {
      "updated-every-10-seconds": "Mis à jour toutes les 10 secondes"
    },
    "training": "Formation",
    "training-tab": "Onglet de formation",
    "transcript-agent-input": {
      "send": "Envoyer"
    },
    "transcript-history": {
      "no-transcript-history": "Pas d’historique des transcriptions",
      "select": "Sélection d’une transcription",
      "title": "Historique des transcriptions"
    },
    "transcript-window": {
      "abc-widget-message-sent": "{agentDisplayText} a été envoyé",
      "customer-closed-engagement": "Le client a fermé la fenêtre de tchat",
      "customer-is-typing": "Le client est en train d’écrire ...",
      "customer-lost-connection": "Le client a perdu la connexion",
      "customer-minimized-chat-window": "Le client a réduit le tchat",
      "customer-restored-chat-window": "Le client a restauré le tchat",
      "customer-stopped-typing": "Le client a arrêté d’écrire...",
      "default-abc-widget-message-sent": "Les données interactives ont été envoyées",
      "default-rich-widget-message-sent": "Le widget riche a été envoyé",
      "events": {
        "callEnded": "Appel terminé",
        "callInvitation": "L’invitation à un appel a été envoyée",
        "callRequestDeclined": "Demande d’appel refusée",
        "createRoomFailed": "Le salon de discussion n’a pas pu être créé",
        "createRoomSuccess": "Salon de discussion créé",
        "error": "Appareil non compatible",
        "newCallRequest": "Demande d’appel entrant",
        "newVoiceCallRequest": "Demande d’appel entrant",
        "roomAgentJoin": "L’agent a rejoint le salon de discussion",
        "roomAgentLeave": "L’agent a quitté le salon de discussion"
      },
      "interrupted": "Interrompu et non envoyé",
      "moved-to-persistent": "Le client a basculé sur une fenêtre de tchat permanente avec succès",
      "new-chat-information": "De nouvelles informations relatives au tchat sont disponibles. Appuyez sur les touches CTRL + MAJ + A pour présenter le contenu.",
      "no-new-chat-information": "Il n’y a pas de nouvelles informations relatives au tchat.",
      "not-sent": "Le message n’a pas pu être envoyé",
      "rich-widget-message-sent": "{agentDisplayText} a été envoyé",
      "scroll-to-bottom": "Défiler jusqu’en bas",
      "see-new-messages": "Voir nouveaux messages",
      "started-move-to-persistent": "Le client est transféré sur une fenêtre de tchat permanente",
      "time-delayed": "Envoi différé",
      "transcript-line": {
        "auto-close-close": "(La discussion prendra fin automatiquement dans {seconds} secondes si le client ne répond pas)",
        "auto-close-warn": "(Message d’avertissement - {seconds} secondes)",
        "command": "Commande",
        "rich-widget-response": "Réponse provenant du widget riche",
        "view-more": "Voir plus",
        "xform": "Transfert de données"
      }
    },
    "transfer-engagement": {
      "agent-group-placeholder": "Veuillez sélectionner un groupe d’agents",
      "agent-notes": "Notes de l’agent",
      "agent-placeholder": "Aucun agent sélectionné",
      "async-agent-group-info": "Aucun agent n’est disponible actuellement. Le client est placé dans la file d’attente jusqu’à ce qu’un agent soit disponible.",
      "available-slots": "Emplacements disponibles",
      "business-unit-placeholder": "Veuillez sélectionner une unité opérationnelle",
      "conference": "Conférence",
      "conference-queue-slots": "Emplacements de conférence en file d’attente",
      "field-reset": "les champs ont été réinitialisés",
      "no-agents-with-attributes-available": "Aucun agent correspondant aux critères sélectionnés n’est disponible actuellement",
      "no-matching-agents-found": "Aucun agent ne correspond aux critères sélectionnés",
      "optionally-select": "En option, vous pouvez sélectionner directement un agent",
      "please-select-a-bunit": "Veuillez sélectionner une unité opérationnelle dans la liste déroulante",
      "prioritize-request": "Prioriser la demande",
      "reset": "Réinitialiser",
      "select-a-bunit": "Sélectionnez une unité opérationnelle",
      "select-an-agent-group": "Sélectionnez un groupe d’agents",
      "select-one-or-more-agent-attributes": "Sélectionnez le ou les attributs que vous souhaitez que l’agent possède",
      "transfer": "Transfert",
      "transfer-queue-slots": "Emplacements de transfert en file d’attente"
    },
    "transfer-window": {
      "transfer-and-conference": "Transfert et conférence"
    },
    "twitter-private-reply-confirmation": {
      "message": "Êtes-vous sûr(e) de vouloir envoyer la réponse sous la forme d’un message privé ? Une fois que ce message sera envoyé, cet onglet de tchat sera fermé et un nouvel onglet de tchat sera créé pour ledit message privé Twitter une fois que le client aura répondu à votre message privé.",
      "title": "Répondre en privé - Confirmation"
    },
    "video-chat": {
      "accept-button": "Accepter",
      "available": "Disponible pour tchat vidéo",
      "close-engagement": "Fermer la discussion",
      "connected": "Connecté",
      "customer-disconnected": "Le client a quitté l’appel",
      "decline-button": "Refuser",
      "device-settings": "Paramètres de l’appareil",
      "end-call-button": "Terminer l’appel",
      "end-video-call": "L’appel vidéo est terminé",
      "failed-to-create-video-room": "La création du salon de discussion vidéo a échoué.",
      "incoming-call": "Appel vidéo entrant...",
      "incoming-call-in-progress": "Appel vidéo entrant en cours",
      "mute": "Couper le son",
      "retry": "Réessayer",
      "un-mute": "Rétablir le son"
    },
    "visitor-info-tab": {
      "browser-type": "Type de navigateur",
      "browser-version": "Version du navigateur",
      "business-rule": "Règle de gestion",
      "chat-id": "ID du tchat",
      "chat-type": "Type de tchat",
      "conversation-channel": "Canal de conversation",
      "conversation-id": "ID de la conversation",
      "customer-id": "ID du client",
      "customer-information": "Informations sur le client",
      "customer-name": "Nom du client",
      "device-type": "Type d’appareil",
      "locale": "Région",
      "operating-system-type": "Type de système d’exploitation",
      "page-marker": "Marque-page"
    },
    "visitor-information": "Informations du visiteur",
    "voice-chat": {
      "available": "Disponible pour tchat vocal",
      "incoming-call": "Appel vocal entrant...",
      "incoming-call-in-progress": "Appel vocal entrant en cours"
    },
    "web-tools": "Outils web",
    "x-tabs": {
      "access-frame": "ou cliquez pour accéder au cadre",
      "how-to-use": "utilisez les flèches gauche et droite pour passer d’un onglet à un autre",
      "left-arrow": "Flèche gauche",
      "press": "Appuyez",
      "right-arrow": "Flèche droite"
    }
  }], ["it", {
    "a11y": {
      "arrow-key-navigation": "utilizzare i tasti freccia su e giù per spostarsi su ciascun elemento. uilizzare i tasti freccia verso sinistra e verso destra per spostarsi sugli elementi cliccabili all’interno di un elemento",
      "link-navigation-instruction": "{content}. Utilizzare i tasti freccia verso sinistra e verso destra per navigare tra i collegamenti",
      "resize-horizontal": "manopola ridimensionabile, utilizzare i tasti freccia su-giù per ridimensionarla",
      "resize-vertical": "manopola ridimensionabile, utilizzare i tasti freccia sinistra-destra per ridimensionare",
      "scroll-message": "premere i tasti freccia su e giù per navigare",
      "transcript-history-navigation-instruction": "Cronologia delle trascrizioni. Utilizzare i tasti freccia su e giù per navigare tra gli elementi",
      "transcript-navigation-instruction": "Trascrizione dell’attività. Utilizzare i tasti freccia su e giù per navigare tra gli elementi",
      "widget-navigation-instruction": "{content}. Utilizzare i tasti freccia verso sinistra e verso destra per navigare"
    },
    "active-engagement": {
      "already-escalated": "Questa attività è già stata inoltrata",
      "app-chat": "App {chat}",
      "apple-business-chat": "Apple Business {chat}",
      "background-color": "Colore di sfondo",
      "bold": "Grassetto",
      "channel": "Canale",
      "close": "Chiudi",
      "close-chat": "Chiudi chat",
      "conference": "Riunione",
      "conversation": "Conversazione",
      "copy": "Copia",
      "copy-masked-transcript": "Copia trascrizione",
      "copy-transcript-notification": "Trascrizione copiata",
      "desktop-chat": "Desktop {chat}",
      "device": "Dispositivo",
      "eapi": "EAPI",
      "engagement": "Attività",
      "engagement-action-disabled-follow-up": "Disattivato fino all’inizio dell’attività",
      "escalate": "Inoltra",
      "escalate-chat": "Inoltra Chat",
      "escalated": "Inoltrata",
      "facebook-chat": "Messenger di Facebook {chat}",
      "file-upload": "Caricamento file",
      "follow-up": "Segui",
      "general-chat": "Generale {chat}",
      "googles-business-messages-chat": "Messaggi commerciali di Google {chat}",
      "hide-details": "Nascondi dettagli",
      "hide-editor": "Nascondi Editor",
      "hide-file-upload": "Nascondi richiesta di caricamento file",
      "instagram-chat": "Instagram {chat}",
      "italic": "Corsivo",
      "join-conference": "Partecipa",
      "join-conference-ada": "partecipare alla riunione con il cliente",
      "line-chat": "Line {chat}",
      "mask": "Maschera come informazione personale",
      "mobile-chat": "Mobile {chat}",
      "more-options": "Altre opzioni",
      "pending-send-message": "Attendere prego, invio del messaggio in corso",
      "resend": "Invia di nuovo",
      "resolve": "Risolvi",
      "resolve-chat": "Risolvi chat",
      "restricted-by-webtool": "Limitato da webtool",
      "search": "Ricerca...",
      "search-icon-title": "Ricerca",
      "search-input-title": "Ricerca",
      "send-a-message": "Mandare un messaggio",
      "send-message": "Invia messaggio",
      "show-details": "Mostra dettagli",
      "show-editor": "Mostra Editor",
      "show-file-upload": "Invia richiesta di caricamento file",
      "sms-chat": "SMS {chat}",
      "tablet-chat": "Tablet {chat}",
      "telegram-chat": "Telegram {chat}",
      "transfer": "Trasferisci",
      "transfer-and-conference": "Trasferimento e riunione",
      "twitter": {
        "private": "Invia come messaggio privato",
        "public": "Invia un Tweet pubblico"
      },
      "twitter-chat": "Twitter {chat}",
      "type-your-message": "digitare il proprio messaggio",
      "underline": "Sottolineatura",
      "viber-chat": "Viber {chat}",
      "waiting": "In attesa",
      "web-chat": "Web {chat}",
      "whatsapp-chat": "Whatsapp {chat}",
      "you-are-not-the-conference-owner": "Non sei gestore della riunione"
    },
    "agent-coach": {
      "context": {
        "reset": "Ripristina per seguire la chat dal vivo",
        "set": "Mostra raccomandazioni"
      },
      "error-fetching": "Si è verificato un errore nel recupero delle raccomandazioni",
      "fetch-recommendation": "Messaggio del cliente{customerMessage}. Premere invio per ottenere le raccomandazioni di risposta.",
      "filter-as-i-type": "Filtro durante la digitazione",
      "named-entities": {
        "address": "Indirizzo",
        "person-agent": "Nome Agente",
        "person-customer": "Nome Cliente"
      },
      "no-recommendations-found": "Nessuna raccomandazione trovata",
      "top-suggestions": "I suggerimenti migliori"
    },
    "agent-group": "Gruppo di Agenti",
    "agent-groups": {
      "confirm-button": "Conferma la selezione",
      "description": "Selezionare uno o più gruppi di agenti per i quali si desidera prendere in carico la chat nella sessione corrente",
      "list-header": "Gruppi di Agenti",
      "select-all": "Seleziona tutti",
      "title": "Selezione del Gruppo di Agenti"
    },
    "agent-settings": {
      "chat-details": "Dettagli della chat",
      "chat-tab-position": "Posizione della scheda della Chat",
      "close": "Chiudi",
      "collapse-all": "Chiudi tutti",
      "collapse-script-headings": "Chiudi tutte le intestazioni degli script per impostazione predefinita",
      "collapse-script-headings-disabled-description": "Tutte le intestazioni dei testi sono espanse per impostazione predefinita per le nuove attività",
      "collapse-script-headings-enabled-description": "Tutte le intestazioni degli script sono chiuse per impostazione predefinita per le nuove attività",
      "create-custom-layout": "Crea un layout personalizzato",
      "create-custom-layout-description": "È stato modificato il layout dell’attività in corso. È possibile creare un layout personalizzato per impostare sempre le nuove attività in questo stato.",
      "ctrl-enter-submit": "La scorciatoia “Ctrl+Invio” permette di inviare i messaggi (invece di utilizzare “Invio”)",
      "custom-layout-created": "È stato creato un layout personalizzato",
      "custom-layout-updated": "Il layout personalizzato è stato aggiornato",
      "double-click": "Doppio clic",
      "double-click-description": "con un singolo clic è possibile aggiungere uno script al campo di inserimento",
      "english": "English",
      "expand-all": "Espandi tutto",
      "font-size": "Dimensione carattere",
      "freeze-chat-tab-position": "Congela la posizione della scheda per la durata della chat",
      "french": "Français",
      "german": "Deutsch",
      "hide-details": "Nascondi dettagli",
      "hide-details-disabled-description": "I messaggi di sistema vengono mostrati per impostazione predefinita nella trascrizione della chat per le nuove attività",
      "hide-details-enabled-description": "I messaggi di sistema sono nascosti per impostazione predefinita nella trascrizione della chat per le nuove attività",
      "italian": "Italiano",
      "japanese": "日本語",
      "keyboard": "Tastiera",
      "language": "Lingua",
      "large": "Grande",
      "layout": "Modello di layout",
      "layouts": {
        "chat-center": "Chat centrale",
        "chat-left": "Chat a sinistra",
        "chat-left-two-columns": "Due colonne (Chat a sinistra)",
        "chat-right": "Chat a destra",
        "custom": "Personalizzato"
      },
      "login-as-available": "Accesso come disponibile",
      "login-as-busy": "Accesso come occupato",
      "login-state": "Stato di accesso",
      "medium": "Medio",
      "my-settings": "Le mie impostazioni",
      "notifications": "Notifiche",
      "play-sounds": "Riproduci suoni",
      "portuguese": "Português",
      "preview": "Anteprima",
      "russian": "Русский",
      "script-headings": "Intestazioni Script",
      "send-scripts": "Invia script",
      "single-click": "Singolo clic",
      "single-click-description": "shift+clic aggiunge lo script al campo di inserimento",
      "small": "Piccolo",
      "spanish": "Español",
      "status-available": "Disponibile",
      "update-custom-layout": "Aggiorna il layout personalizzato",
      "update-custom-layout-description": "È stato modificato il layout dell’attività in corso. È possibile aggiornare il layout personalizzato per impostare sempre le nuove attività in questo stato.",
      "visual-alerts": "Avvisi visivi"
    },
    "agent-status-toggle": {
      "available": "Disponibile",
      "busy": "Occupato",
      "busy-auto": "occupato-auto",
      "warning-set-fail": "Si è verificato un errore nell’aggiornamento dello stato. Si prega di riprovare. Se il problema persiste, contattare l’amministratore."
    },
    "agents": "Agenti",
    "alert": "Avviso",
    "alerts": {
      "agent-has-active-engagements": "È necessario uscire da tutte le chat attive prima di disconnettersi",
      "cobrowse-already-active": "Chiudere la sessione attiva di Cobrowse prima di inviare un nuovo invito"
    },
    "app-error-modal": {
      "error-n-of-total": "Errore {n} di {total}:",
      "error-occurred": "{count, plural, =1 {# si è verificato un errore.} other {# si sono verificati errori.}}",
      "hide-details": "Nascondi dettagli",
      "hide-errors": "Nascondi errori",
      "ok": "OK",
      "show-details": "Mostra dettagli",
      "title": "Errore"
    },
    "app-error-pill-title": "Errore",
    "async": {
      "cannot-resolve": "Non è possibile risolvere questa conversazione {conversationId}perché il cliente ha avviato una nuova chat.",
      "okay": "Ok",
      "title": "Avvertenza"
    },
    "auto-transfer": {
      "message": "È stata assegnata una nuova chat che non ha ricevuto una risposta iniziale nei tempi previsti.",
      "respond-now": "Rispondi ora",
      "title": "Trasferimento automatico"
    },
    "business-unit": "Unità Operativa",
    "cancel": "Annulla",
    "close-chat-modal": {
      "cancel": "Annulla",
      "chat": "Chiudi attività {engagementId}",
      "continue": "Continua",
      "conversation": "Chiudi conversazione {engagementId}",
      "resolve": "Risolvi la conversazione {engagementId}"
    },
    "co-browse": "Cobrowse",
    "cobrowse": {
      "color-picker": "Seleziona il colore di evidenziazione",
      "end": "Termina condivisione via Cobrowse",
      "error": "Si è appena verificato un errore con Cobrowse.",
      "hide-chat-window": "Nascondi la finestra della chat",
      "inactive": "Inattivo",
      "reload": "Ricarica pagina",
      "show-chat-window": "Mostra la finestra della chat",
      "unhighlight": "Rimuovi evidenziazione"
    },
    "collapsable-tile": {
      "collapse": "Comprimi",
      "compress": "Comprimi",
      "expand": "Espandi",
      "header-snap-back": "Chiudere per tornare al menu laterale",
      "reminders": "Promemoria"
    },
    "color": {
      "blue": "Blu",
      "cyan": "Ciano",
      "gray": "Grigio",
      "green": "Verde",
      "light-blue": "Azzurro",
      "light-green": "Verde chiaro",
      "magenta": "Magenta",
      "no-color": "Nessun colore",
      "orange": "Arancione",
      "peach": "Pesca",
      "pink": "Rosa",
      "purple": "Viola",
      "white": "Bianco",
      "yellow": "Giallo"
    },
    "conference": {
      "close-modal": {
        "no": "No",
        "title": "Chiudi riunione?",
        "warning": "L’utente è gestore della riunione. Se si abbandona la riunione, questa verrà interrotta. Si desidera abbandonarla?",
        "yes": "Sì"
      },
      "ownership-modal": {
        "accept": "Accetta",
        "refuse": "Rifiuta",
        "title": "Accettare la titolarità?"
      }
    },
    "custom-scripts": {
      "add-new-header-script": "Aggiungi nuova intestazione",
      "add-new-text-script": "Aggiungi nuovo script",
      "cancel": "Annulla",
      "confirm-changes": "Conferma le modifiche",
      "delete": "Cancella",
      "delete-header-script": "Cancella intestazione",
      "delete-text-script": "Cancella script",
      "do-not-save": "Non salvare",
      "edit-custom-scripts": "Modifica script personalizzati",
      "edit-header-script": "Modifica intestazione",
      "edit-text-script": "Modifica script",
      "incorrect-scripts": "Gli script non possono essere caricati a causa della presenza di uno o più caratteri non validi",
      "input-header-placeholder": "Inserire il titolo qui",
      "input-text-placeholder": "Inserire lo script qui",
      "invalid-chars-in-scripts": "Gli script contengono uno o più caratteri non validi:{n}",
      "my-scripts": "I miei script",
      "no-access": "L’utente non ha accesso agli script personalizzati",
      "save": "Salva",
      "save-and-close": "Salva e chiudi",
      "saved": "Script personalizzati salvati",
      "title": "Script personalizzati",
      "undo-delete-header-script": "Annulla cancellazione intestazione",
      "undo-delete-text-script": "Annulla cancellazione script",
      "unsaved-changes-prompt": "Le modifiche apportate agli script non sono state salvate. Si desidera salvarle?"
    },
    "device-settings": {
      "audio-device": "Dispositivo audio",
      "browser-not-supported": "Questa applicazione non supporta i dispositivi del browser in uso",
      "close": "Chiudi",
      "ff-warn-message": "I dispositivi di uscita audio non sono supportati dal browser in uso",
      "header": "Impostazioni del dispositivo",
      "mic-device": "Dispositivo microfono",
      "no-device": "selezione non disponibile",
      "permission-not-granted": "Autorizzazione del dispositivo non concessa",
      "video-device": "Dispositivo video",
      "video-error-message": "anteprima non disponibile",
      "video-not-available": "Il video non è disponibile"
    },
    "digital-voice-and-video": {
      "invite-to-video-call": "Invita il cliente a una videochiamata",
      "invite-to-voice-call": "Invita il cliente a una chiamata solo vocale",
      "title": "Voce e video digitali",
      "title-video": "Video digitale",
      "title-voice": "Voce digitale",
      "video-call": "Videochiamata",
      "voice-call": "Chiamata vocale"
    },
    "dispositions": "Disposizioni",
    "emojis": {
      "clapping-hands": "Mani che applaudono",
      "grinning-face": "Faccina sorridente",
      "happy-face": "Faccina felice",
      "icon-title": "Emoji",
      "panel-title": "Pannello Emoji",
      "raising-hands": "Mani alzate",
      "red-heart": "Cuore rosso",
      "sad-face": "Faccina triste",
      "thumbs-down": "Pollice verso",
      "thumbs-up": "Pollice in su",
      "waving-hand": "Mano che saluta"
    },
    "end-call-confirmation-modal": {
      "cancel": "Annulla",
      "content-video": "La videochiamata con il cliente sta per terminare.",
      "content-voice": "La telefonata con il cliente sta per terminare.",
      "end-call-button": "Fine chiamata",
      "title": "Termina chiamata?"
    },
    "engagement-tab": {
      "chat": "chat",
      "chat-duration": "durata della chat",
      "chat-has-new-messages": "{deviceType}chat {chatIndex}, timer di mancata risposta{unrespondedMessageTimer} secondi, {unrespondedMessages}nuovi messaggi.",
      "completed": "COMPLETATO",
      "countdown": "conto alla rovescia",
      "current-chat-has-new-messages": "Ci sono{unrespondedMessages} nuovi messaggi nella chat corrente.",
      "no-engagements": "Non ci sono attività in corso in questo momento",
      "unresponded-timer": "timer di mancata risposta"
    },
    "engagements-to-follow-up": "Attività da seguire",
    "escalate-modal": {
      "chat-escalation": "Inoltro chat"
    },
    "escalation-form": {
      "cancel": "Annulla",
      "description": "Descrizione",
      "submit": "Invia"
    },
    "filter": "Filtro",
    "follow-up": {
      "conversation-id": "ID conversazione",
      "notes": "Note",
      "required-field": "* è un campo obbligatorio",
      "time-since-last-engagement": "Tempo trascorso dall’ultima attività",
      "time-to-expire": "Tempo rimanente prima della scadenza",
      "updated": "L’attività di follow-up è stata aggiornata"
    },
    "follow-up-disabled": "Non ci sono chat di follow-up in coda",
    "follow-up-enabled": "Ci sono chat di follow-up pronte in coda",
    "follow-up-modal": {
      "cancel": "Annulla",
      "follow-up-agent": "Inserisci nella coda di follow-up per:",
      "notes": "Note",
      "submit": "Invia",
      "title": "Segui"
    },
    "header": {
      "active-chats": "Chat attive",
      "agent-desktop": "Agent Desktop",
      "chats-completed": "Chat completate",
      "chats-in-queue": "Chat in coda",
      "dismiss": "Interrompi",
      "ie-warning": "Internet Explorer 11 non è più supportato. Per un’esperienza d’uso ottimale, passare a un browser supportato (Google Chrome, Microsoft Edge o Mozilla Firefox).",
      "logged-in-at": "Collegato a",
      "menu": {
        "custom-scripts": "Script personalizzati",
        "logout": "Disconnessione",
        "my-settings": "Le mie impostazioni"
      },
      "navigation": "Navigazione",
      "nina-coach-completed": "Nina Coach Completato",
      "public-user-id": "Id utente pubblico",
      "scripts": "Script",
      "skip-main-region": "Vai alla regione principale",
      "skip-main-region-abbr": "Vai alla pagina principale",
      "summary": "Sintesi",
      "username": "Nome utente"
    },
    "illegal-word": "non può essere utilizzato nel messaggio al cliente. Deve essere rimosso prima dell’invio.",
    "interactive-data": {
      "default-label": "Dati interattivi"
    },
    "internal-chat": {
      "close": "Chiudi",
      "expand-internal-chat": "messaggi non letti. Premere control più shift più X per espandere la chat interna.",
      "how-to-close": "Per chiudere la chat interna, gli altri membri devono prima concludere l’attività.",
      "internal-chat": "Chat Interna",
      "minimize": "Riduci a icona",
      "unread-messages": "messaggi non letti"
    },
    "link": "Collegamento",
    "load-engagement": "Carica attività",
    "lockout": {
      "inactivity-message": "Blocco automatico per inattività"
    },
    "login-form": {
      "access-denied": "L’utente non ha accesso all’Agent Desktop.",
      "account-maybe-locked": "L’utente ha tentato di autenticarsi 5 volte senza successo. L’account dell’utente potrebbe essere bloccato, contattare l’amministratore",
      "agent-id": "ID Agente",
      "agent-logged-off": "L’utente è stato disconnesso",
      "ajax-authorization-failed": "Errore di autorizzazione Ajax - contattare l’amministratore di sistema.",
      "already-logged-in-modal-message": "Attualmente è presente una sessione attiva per l’agente{agentName}, si prega di chiudere e avviare una nuova sessione.",
      "already-logged-in-modal-title": "L’accesso è già stato effettuato",
      "application-error": "Si è verificato un errore dell’applicazione durante il tentativo di accesso",
      "application-error-generic": "Si è verificato un errore dell’applicazione. Contattare l’amministratore di sistema ed effettuare nuovamente l’accesso.",
      "bad-credentials": "Nome utente o password non validi",
      "changed-password": "La password è stata modificata con successo",
      "default-invalidation-error": "L’utente è stato disconnesso a causa di un errore di autenticazione imprevisto. Effettuare nuovamente l’accesso. Se il problema persiste, contattare l’amministratore del sistema.",
      "details": "Dettagli",
      "forgot-your-password": "Password dimenticata?",
      "hide-details": "Nascondi dettagli",
      "internet-restored": "La connessione a Internet è stata ripristinata, si prega di effettuare nuovamente l’accesso",
      "log-in": "Accedi",
      "logout-inactivity": "La sessione è stata interrotta per inattività",
      "missing-password": "Password mancante",
      "missing-username": "Nome utente mancante",
      "no-internet": "Non è stata rilevata alcuna connessione Internet, si prega di ricollegarsi e riprovare",
      "password": "Password",
      "server-timeout-message": "Si è verificato un errore di timeout del server. Si prega di aggiornare e riprovare. Grazie.",
      "server-timeout-title": "Timeout del server",
      "service-unavailable": "il componente non è disponibile, si prega di contattare l’amministratore di sistema",
      "show-details": "Mostra dettagli",
      "timestamp": "Timestamp",
      "unlock": "Sblocca",
      "url": "URL",
      "user-account-is-locked": "L’account è stato bloccato perché è stato raggiunto il numero massimo di tentativi di accesso",
      "username": "Nome utente"
    },
    "logout-button": {
      "logout": "Disconnessione"
    },
    "metrics": {
      "agent-metrics": "Metriche dell’Agente",
      "available": "Disponibile",
      "busy": "Occupato",
      "chat-slots": "Slot di chat",
      "chats-in-my-queue": "chat in attesa in “La mia coda”",
      "chats-in-queue": "Chat in coda",
      "free": "Libero",
      "max": "Massimo",
      "metrics-title": "Riepilogo disponibilità agenti",
      "my-queue": "(La mia coda: {n})",
      "total": "Totale"
    },
    "misc": {
      "prevent-refresh-message": "Si è sicuri di voler aggiornare la pagina?"
    },
    "modal": {
      "cancel": "Annulla",
      "close": "Chiudi",
      "okay": "Ok",
      "warn-title": "Avvertenza"
    },
    "my-settings": {
      "layout-preview": "anteprima layout",
      "preview": "ingrandisci immagine di anteprima"
    },
    "named-entities-form": {
      "submit-disabled-tooltip": "Uno o più valori segnaposto non sono stati impostati. Specificare un valore per tutti i segnaposto da inviare."
    },
    "next": "Avanti",
    "nina-coach": {
      "alert": "avviso di nina coach",
      "all": "Tutti",
      "all-checkbox": "casella di controllo “tutti” selezionata",
      "arrow-down-icon": "icona a forma di freccia verso il basso",
      "arrow-right-icon": "icona a forma di freccia a destra",
      "clear-search": "cancella ricerca",
      "cleared-intents-query": "Query di ricerca delle intenzioni annullata",
      "concepts": "Concept",
      "filter-by": "Filtra per",
      "filter-icon": "icona del filtro",
      "intents": "Intenzioni",
      "loading-intents": "caricamento intenzioni in corso",
      "name": "Nome",
      "nina": "Nina",
      "nina-suggested": "Nina ha suggerito",
      "no-available-concepts": "Non ci sono concept disponibili",
      "no-intents": "Non ci sono intenzioni",
      "no-opportunity": "Nessuna opportunità",
      "press-tab-key": "premere tabulazione per spostarsi sul filtro",
      "response-text": "Eventuale testo di risposta",
      "search-icon": "icona di ricerca",
      "search-placeholder": "Ricerca di intenzioni, concept, testi di risposta",
      "selected-intent": "Intenzione selezionata",
      "send": "Invia",
      "suggest-intent": "intenzione suggerita",
      "take-over": "Assumi il controllo",
      "title": "Nina Coach",
      "transfer": "Trasferisci",
      "type": "Tipo"
    },
    "notifications": {
      "lost-connection": "Connessione persa, tentativo di riconnessione",
      "lost-connection-no-internet": "La connessione a Internet è stata interrotta. Controllare la connessione a Internet, aggiornare la pagina e accedere nuovamente",
      "notify-new-engagement": "Nuova chat assegnata",
      "notify-new-message": "Nuovo messaggio del cliente"
    },
    "oh-no": "Oh no",
    "password-reset": {
      "confirm-password": "Conferma password",
      "current-password": "Password corrente",
      "message": "La password è scaduta, si prega di inserire una nuova password",
      "minimum-password-length": "La lunghezza minima della password deve essere di almeno dodici caratteri",
      "new-password": "Nuova password",
      "please-confirm-your-new-password": "Confermare la nuova password",
      "please-provide-a-new-password": "Si prega di fornire una nuova password",
      "please-provide-your-current-password": "Indicare la password corrente",
      "submit": "Invia",
      "username": "Nome utente",
      "username-is-a-required-field": "Il nome utente è un campo obbligatorio",
      "your-passwords-must-match": "La nuova password e la nuova password confermata sono diverse"
    },
    "performance-metrics": {
      "agentUtilization": "Utilizzo degli slot di chat",
      "assignedCount": "Richiesta assegnata",
      "availableUtilization": "Disponibilità degli slot di chat",
      "avgConversionProductQuantity": "Unità per conversione",
      "avgDispositionTime": "Tempo medio disponibile",
      "avgEngagementHandleTime": "Tempo di gestione della chat",
      "avgEngagementHandleTimeSLA": "% di SLA calcolata sul tempo di gestione della chat",
      "avgInitialAgentResponseTime": "Tempo medio di risposta iniziale",
      "avgOrderValue": "Valore medio dell’ordine ($)",
      "busyClickCount": "Tempi su occupato per accesso",
      "chatsPerLaborHour": "Chat per ora di lavoro",
      "conversionCount": "Conversazioni",
      "conversionProductQuantity": "Unità vendute",
      "cumulativeLaborUtilization": "Utilizzo complessivo della manodopera",
      "efficiency": "Efficienza",
      "initialResponseWithinSLA": "Risposta iniziale entro lo SLA (%)",
      "logged-in-time": "Metriche del tempo di connessione",
      "loginDuration": "Tempo di connessione",
      "percentAssisted": "% di assistenza",
      "percentConversion": "Conversione %",
      "percentEndedByCustomer": "% Terminato dal cliente",
      "salesPerLaborHour": "Vendite per ora di lavoro",
      "totalBusyTime": "Tempo totale su occupato",
      "utilization": "Utilizzo",
      "volume": "Volume"
    },
    "power-select": {
      "no-results-found": "Nessun risultato trovato",
      "type-to-filter": "tipo da filtrare"
    },
    "previous": "Precedente",
    "request-additional-chat": {
      "chat-slots-enabled": "Chat aggiuntive abilitate",
      "chat-slots-not-full": "Gli slot di chat dell’utente devono essere pieni per richiedere ulteriori chat",
      "default": "Sono disponibili ulteriori slot di chat",
      "details": "Per commutare lo stato, premere la barra spaziatrice",
      "extra-chat-slots-full": "È stato raggiunto il limite massimo per le chat aggiuntive",
      "not-available": "L’utente deve trovarsi nello stato di disponibilità",
      "request-more-chats": "Richiedi altre chat"
    },
    "retry": "Riprova",
    "rich-widget": {
      "default-label": "Rich Widget",
      "error-render": "Impossibile caricare l’anteprima del widget",
      "load": "Carica",
      "preview-title": "Anteprima widget",
      "preview-widget-navigation": "Widget: {widgetLabel}. Premere il tasto “invio” per aprire l’anteprima"
    },
    "scripts": "Script",
    "sdk-dispositions": {
      "add-notes": "Aggiungi note",
      "cancel": "Annulla",
      "choose-all": "Scegli tutte le opzioni applicabili.",
      "choose-one": "Scegli quella più adatta.",
      "close-engagement": "Chiudi attività",
      "load-fail": "Si è verificato un errore durante il caricamento delle disposizioni",
      "loading": "Caricamento in corso",
      "loading-dispositions": "Disposizioni per il recupero",
      "not-found": "\"{filterInput}\" non trovato",
      "pre-selected": {
        "title": "Dalla precedente attività",
        "tooltip": {
          "clear-all": "Deseleziona tutti i motivi di disposizione della precedente attività",
          "select-all": "Seleziona tutti i motivi di disposizione della precedente attività"
        }
      },
      "search-placeholder": "Ricerca Disposizioni",
      "select-disposition": "È necessario selezionare prima una disposizione.",
      "show-pagination": "Pagination",
      "submit": "Invia",
      "window-title": "Disposizioni"
    },
    "sdk-scripts": {
      "absent": "Non sono stati configurati script. Contattare l’amministratore.",
      "agent-coach": "Coach Agente",
      "commands-cannot-be-copied": "i comandi non possono essere copiati (è stato premuto il tasto shift)",
      "custom-scripts": "Personalizzato",
      "custom-scripts-here": "Gli script personalizzati verranno caricati qui",
      "empty-script-tree": "L’alberatura degli script è vuota.",
      "error": {
        "rich-widget-agent-input": "impossibile inviare i rich widget al campo di inserimento"
      },
      "error-fetching": "Si è verificato un errore durante il recupero degli script",
      "input-script": {
        "cancel": "Annulla",
        "submit": "Invia"
      },
      "limited-access": "Accesso limitato all’alberatura dello script.",
      "loading": "Attendere il caricamento degli script...",
      "script-cannot-be-modified": "Questo script non può essere modificato",
      "system-scripts": "Sistema",
      "training-keyboard-1": "Premere",
      "training-keyboard-2": "per accedere ai gruppi di script. Utilizzare i",
      "training-keyboard-3": "tasti freccia per spostarsi tra le righe dello script.",
      "unavailable": "Lo script non è disponibile"
    },
    "search": {
      "result": "risultado {searchResultsIndex} di {searchResultsCount}"
    },
    "settings": "Impostazioni",
    "site": "Sito",
    "slideout": {
      "expand-details": "espandi dettagli"
    },
    "sso": {
      "error-contact": "Contattare l’assistenza tecnica. Fare clic su “Ok” per essere reindirizzati alla Pagina di accesso",
      "error-message": "Qualcosa non ha funzionato con l’SSO.",
      "error-okay": "Ok",
      "logout-message": "L’utente è stato disconnesso dall’Agent Desktop",
      "relogin": "EFFETTUA NUOVAMENTE L’ACCESSO"
    },
    "static-transcript": {
      "abc-widget-message-sent": "{agentDisplayText}inviato",
      "chat-history": "Storico delle chat",
      "clear-filter": "azzera filtri",
      "current": "Attuale",
      "default-abc-widget-message-sent": "Dati interattivi inviati",
      "default-rich-widget-message-sent": "*** Rich Widget avviato ***",
      "initiated-widget": "*** Rich Widget avviato:{label} ***",
      "next-result": "risultato successivo",
      "previous-result": "risultato precedente",
      "rich-widget-message-sent": "{agentDisplayText}inviato",
      "search-scripts": "Script di ricerca",
      "search-transcripts": "ricerca trascrizioni",
      "static-transcript": "Trascrizione statica"
    },
    "submit": "Invia",
    "summary": {
      "updated-every-10-seconds": "Aggiornato ogni 10 secondi"
    },
    "training": "Formazione",
    "training-tab": "Scheda Formazione",
    "transcript-agent-input": {
      "send": "Invia"
    },
    "transcript-history": {
      "no-transcript-history": "Non ci sono cronologie delle trascrizioni disponibili",
      "select": "Selezione trascrizione",
      "title": "Cronologia delle trascrizioni"
    },
    "transcript-window": {
      "abc-widget-message-sent": "{agentDisplayText} è stato inviato",
      "customer-closed-engagement": "Finestra di chat chiusa dal cliente",
      "customer-is-typing": "Il cliente sta scrivendo...",
      "customer-lost-connection": "Il cliente ha perso la connessione",
      "customer-minimized-chat-window": "Il cliente ha ridotto a icona la chat",
      "customer-restored-chat-window": "Il cliente ha ripristinato la chat",
      "customer-stopped-typing": "Il cliente ha smesso di scrivere",
      "default-abc-widget-message-sent": "I dati interattivi sono stati inviati",
      "default-rich-widget-message-sent": "Rich Widget è stato inviato",
      "events": {
        "callEnded": "Chiamata terminata",
        "callInvitation": "L’invito alla chiamata è stato inviato",
        "callRequestDeclined": "Richiesta di chiamata rifiutata",
        "createRoomFailed": "Impossibile creare una stanza",
        "createRoomSuccess": "La stanza è stata creata",
        "error": "Dispositivo non supportato",
        "newCallRequest": "Richiesta di chiamata in entrata",
        "newVoiceCallRequest": "Richiesta di chiamata in entrata",
        "roomAgentJoin": "L’agente si è unito alla stanza",
        "roomAgentLeave": "L’agente ha lasciato la stanza"
      },
      "interrupted": "Interrotto, non inviato",
      "moved-to-persistent": "Il cliente è passato con successo a una chat permanente",
      "new-chat-information": "Sono disponibili nuovi dettagli sulla chat. Premere control più shift più A per comunicare il contenuto.",
      "no-new-chat-information": "Non ci sono nuovi dettagli sulla chat.",
      "not-sent": "Messaggio non inviato",
      "rich-widget-message-sent": "{agentDisplayText} è stato inviato",
      "scroll-to-bottom": "scorri fino in fondo",
      "see-new-messages": "visualizza i nuovi messaggi",
      "started-move-to-persistent": "Il cliente viene trasferito a una finestra di chat permanente",
      "time-delayed": "Ritardata",
      "transcript-line": {
        "auto-close-close": "(L’attività terminerà automaticamente tra {seconds}secondi in caso di mancata risposta da parte del cliente)",
        "auto-close-warn": "(Messaggio di avviso - {seconds} secondi)",
        "command": "Comando",
        "rich-widget-response": "Risposta del Rich Widget",
        "view-more": "Mostra altro",
        "xform": "Datapass"
      }
    },
    "transfer-engagement": {
      "agent-group-placeholder": "Selezionare un Gruppo di Agenti",
      "agent-notes": "Note dell’Agente",
      "agent-placeholder": "Non è stato selezionato alcun Agente",
      "async-agent-group-info": "Non ci sono agenti attualmente disponibili. Il cliente verrà messo in coda finché non sarà disponibile un agente",
      "available-slots": "Slot disponibili",
      "business-unit-placeholder": "Selezionare un’Unità Operativa",
      "conference": "Riunione",
      "conference-queue-slots": "Slot della coda di riunione",
      "field-reset": "i campi sono stati azzerati",
      "no-agents-with-attributes-available": "Nessun agente con attributi disponibili",
      "no-matching-agents-found": "Non sono stati trovati agenti corrispondenti",
      "optionally-select": "In alternativa, è possibile selezionare direttamente un agente",
      "please-select-a-bunit": "Selezionare un’Unità Operativa dall’elenco a discesa",
      "prioritize-request": "Assegna priorità alla richiesta",
      "reset": "Ripristina",
      "select-a-bunit": "Seleziona un’Unità Operativa",
      "select-an-agent-group": "Seleziona un Gruppo di agenti",
      "select-one-or-more-agent-attributes": "Seleziona uno o più attributi dell’agente desiderati",
      "transfer": "Trasferisci",
      "transfer-queue-slots": "Slot della coda di trasferimento"
    },
    "transfer-window": {
      "transfer-and-conference": "Trasferimento e riunione"
    },
    "twitter-private-reply-confirmation": {
      "message": "Si è sicuri di voler inviare la risposta come messaggio privato? Una volta inviato il messaggio, la scheda della chat verrà chiusa e ne verrà creata una nuova per il messaggio diretto di Twitter, previa risposta del cliente al messaggio privato.",
      "title": "Rispondi come Privato - Conferma"
    },
    "video-chat": {
      "accept-button": "Accetta",
      "available": "Disponibile per la chat video",
      "close-engagement": "Chiudi attività",
      "connected": "Collegato",
      "customer-disconnected": "Il cliente ha abbandonato la chiamata",
      "decline-button": "Rifiuta",
      "device-settings": "Impostazioni del dispositivo",
      "end-call-button": "Termina chiamata",
      "end-video-call": "La videochiamata è terminata",
      "failed-to-create-video-room": "Impossibile creare una stanza di videochiamata.",
      "incoming-call": "Videochiamata in arrivo...",
      "incoming-call-in-progress": "Videochiamata in corso",
      "mute": "Disattiva audio",
      "retry": "Riprova",
      "un-mute": "Riattiva l’audio"
    },
    "visitor-info-tab": {
      "browser-type": "Tipo di browser",
      "browser-version": "Versione del browser",
      "business-rule": "Regola di Business",
      "chat-id": "ID chat",
      "chat-type": "Tipo di chat",
      "conversation-channel": "Canale di conversazione",
      "conversation-id": "ID conversazione",
      "customer-id": "Cliente. ID",
      "customer-information": "Informazioni sul cliente",
      "customer-name": "Cliente. Nome",
      "device-type": "Tipo di dispositivo",
      "locale": "Locale",
      "operating-system-type": "Tipo di sistema operativo",
      "page-marker": "Segnapagina"
    },
    "visitor-information": "Informazioni per i visitatori",
    "voice-chat": {
      "available": "Disponibile per la chat vocale",
      "incoming-call": "Chiamata vocale in entrata...",
      "incoming-call-in-progress": "Chiamata vocale in entrata in corso"
    },
    "web-tools": "Strumenti web",
    "x-tabs": {
      "access-frame": "o fare clic per accedere al frame.",
      "how-to-use": "utilizzare i tasti freccia verso destra e verso sinistra per navigare tra le schede",
      "left-arrow": "Freccia verso sinistra",
      "press": "Premere",
      "right-arrow": "Freccia verso destra"
    }
  }], ["ja", {
    "a11y": {
      "arrow-key-navigation": "上下の矢印キーで移動してください。左右の矢印キーで各項目内のクリック可能箇所を移動できます。",
      "link-navigation-instruction": "{content}。ナビゲートするには左右矢印を使用します",
      "resize-horizontal": "resizable handle, use up down arrow keys to resize",
      "resize-vertical": "resizable handle, use left right arrow keys to resize",
      "scroll-message": "上下の矢印キーを押してナビゲートします",
      "transcript-history-navigation-instruction": "Transcript History. Use up, down arrow keys to navigate through items",
      "transcript-navigation-instruction": "チャット会話ログ。項目間を移動するには上下の矢印キーを使用します",
      "widget-navigation-instruction": "{content}. 左右の矢印キーを使用し移動してください。"
    },
    "active-engagement": {
      "already-escalated": "このチャットは既にエスカレーションされています",
      "app-chat": "アプリ{chat}",
      "apple-business-chat": "Apple Business {chat}",
      "background-color": "背景色",
      "bold": "太字",
      "channel": "チャネル",
      "close": "閉じる",
      "close-chat": "チャットを閉じる",
      "conference": "会議",
      "conversation": "会話",
      "copy": "コピー",
      "copy-masked-transcript": "マスキング済のログをコピー",
      "copy-transcript-notification": "会話ログをコピーしました",
      "desktop-chat": "デスクトップ{chat}",
      "device": "端末",
      "eapi": "EAPI",
      "engagement": "チャット",
      "engagement-action-disabled-follow-up": "チャットが開始されるまで無効になりました",
      "escalate": "エスカレート",
      "escalate-chat": "チャットをエスカレート",
      "escalated": "エスカレーションしたチャット",
      "facebook-chat": "Facebook Messenger {chat}",
      "file-upload": "ファイルのアップロード",
      "follow-up": "フォローアップ",
      "general-chat": "一般{chat}",
      "googles-business-messages-chat": "Google's Business Messages {chat}",
      "hide-details": "詳細を非表示",
      "hide-editor": "Hide Editor",
      "hide-file-upload": "ファイルのアップロードを閉じる",
      "instagram-chat": "Instagram{chat}",
      "italic": "斜体",
      "join-conference": "参加",
      "join-conference-ada": "お客様との会議に参加する",
      "line-chat": "Line{chat}",
      "mask": "個人情報としてマスキング",
      "mobile-chat": "モバイル{chat}",
      "more-options": "その他のオプション",
      "pending-send-message": "保留中。メッセージが送信されています",
      "resend": "再送信",
      "resolve": "解決",
      "resolve-chat": "チャットを解決",
      "restricted-by-webtool": "webtoolによる制限",
      "search": "捜索 . . .",
      "search-icon-title": "捜索",
      "search-input-title": "検索入力",
      "send-a-message": "メッセージを入力",
      "send-message": "メッセージを送信",
      "show-details": "詳細を表示",
      "show-editor": "Show Editor",
      "show-file-upload": "ファイルのアップロードを頼んで送る",
      "sms-chat": "SMS{chat}",
      "tablet-chat": "タブレット{chat}",
      "telegram-chat": "Telegram{chat}",
      "transfer": "転送",
      "transfer-and-conference": "転送と会議",
      "twitter": {
        "private": "プライベートメッセージとして送信",
        "public": "公開ツイートを送信"
      },
      "twitter-chat": "Twitter{chat}",
      "type-your-message": "メッセージを入力してください",
      "underline": "下線",
      "viber-chat": "Viber{chat}",
      "waiting": "待機中",
      "web-chat": "ウェブ{chat}",
      "whatsapp-chat": "WhatsApp{chat}",
      "you-are-not-the-conference-owner": "あなたは会議の主催者ではありません"
    },
    "agent-coach": {
      "context": {
        "reset": "Reset to follow live chat",
        "set": "Show recommendations"
      },
      "error-fetching": "推奨事項の取得にエラーが発生しました",
      "fetch-recommendation": "お客様のメッセージ {customerMessage}。Enterキーを押して応答の推奨事項を取得します。",
      "filter-as-i-type": "入力時にフィルタリングする",
      "named-entities": {
        "address": "住所",
        "person-agent": "エージェント名",
        "person-customer": "お客様名"
      },
      "no-recommendations-found": "推奨事項が見つかりませんでした",
      "top-suggestions": "最善の提案"
    },
    "agent-group": "エージェントグループ",
    "agent-groups": {
      "confirm-button": "選択を確認",
      "description": "このセッションでチャットを行うエージェントグループを1つ以上選択します",
      "list-header": "エージェントグループ",
      "select-all": "Select All",
      "title": "エージェントグループの選択"
    },
    "agent-settings": {
      "chat-details": "チャットの詳細",
      "chat-tab-position": "チャットタブの位置",
      "close": "閉じる",
      "collapse-all": "全て閉じる",
      "collapse-script-headings": "初期状態では全てのスクリプト見出しを閉じる",
      "collapse-script-headings-disabled-description": "新規チャット受付時のスクリプト見出しはデフォルトで全て折りたたまれています",
      "collapse-script-headings-enabled-description": "新規チャット受付時のスクリプト見出しはデフォルトで全て展開されています",
      "create-custom-layout": "カスタムレイアウトを作成",
      "create-custom-layout-description": "現在のチャットのレイアウトを変更しました。カスタムレイアウトを作成することにより、新規チャットを常にこの状態で開始させることができます。",
      "ctrl-enter-submit": "\"Ctrl+Enter\"を押してメッセージを送信 (\"Enter\"の代替キー)",
      "custom-layout-created": "カスタムレイアウトが作成されました",
      "custom-layout-updated": "カスタムレイアウトが更新されました",
      "double-click": "ダブルクリック",
      "double-click-description": "シングルクリックで入力フィールドにスクリプトが追加されます",
      "english": "English",
      "expand-all": "全て展開",
      "font-size": "フォントサイズ",
      "freeze-chat-tab-position": "チャット中はタブの位置を固定",
      "french": "Français",
      "german": "Deutsch",
      "hide-details": "詳細を非表示",
      "hide-details-disabled-description": "新規チャット受付時の会話ログではシステムメッセージがデフォルトで表示設定となっています",
      "hide-details-enabled-description": "新規チャット受付時の会話ログではシステムメッセージがデフォルトで非表示設定となっています",
      "italian": "Italiano",
      "japanese": "日本語",
      "keyboard": "キーボード",
      "language": "言語",
      "large": "大",
      "layout": "レイアウトのテンプレート",
      "layouts": {
        "chat-center": "中心でチャット",
        "chat-left": "左側でチャット",
        "chat-left-two-columns": "2列 (左側でチャット)",
        "chat-right": "右側でチャット",
        "custom": "カスタム"
      },
      "login-as-available": "対応可能でログイン",
      "login-as-busy": "ビジー状態でログイン",
      "login-state": "ログイン状態",
      "medium": "中",
      "my-settings": "設定",
      "notifications": "通知",
      "play-sounds": "サウンドを再生",
      "portuguese": "Português",
      "preview": "プレビュー",
      "russian": "Русский",
      "script-headings": "スクリプト見出し",
      "send-scripts": "スクリプトを送信",
      "single-click": "シングルクリック",
      "single-click-description": "[Shift]キーを押しながらクリックすると、入力フィールドにスクリプトが追加されます",
      "small": "小",
      "spanish": "Español",
      "status-available": "対応可能",
      "update-custom-layout": "カスタムレイアウトを更新",
      "update-custom-layout-description": "現在のチャットのレイアウトを変更しました。カスタムレイアウトを更新することにより、新規チャットを常にこの状態で開始させることができます。",
      "visual-alerts": "ビジュアルアラート"
    },
    "agent-status-toggle": {
      "available": "対応可能",
      "busy": "ビジー状態",
      "busy-auto": "自動ビジー状態",
      "warning-set-fail": "ステータス更新中にエラーが発生しました。もう一度やり直してください。問題が解決しない場合は管理者にお問い合わせください。"
    },
    "agents": "エージェント",
    "alert": "アラート",
    "alerts": {
      "agent-has-active-engagements": "ログアウトする前に、すべてのアクティブなチャットを終了する必要があります。",
      "cobrowse-already-active": "新規招待を送信する前に、アクティブなブラウズ共有 セッションを終了してください。"
    },
    "app-error-modal": {
      "error-n-of-total": "{total}　エラー{n}:",
      "error-occurred": "{count, plural, =1 {# エラーが発生しました。} other {# エラー(複数）が発生しました。}}",
      "hide-details": "詳細を非表示",
      "hide-errors": "エラーを非表示",
      "ok": "OK",
      "show-details": "詳細を表示",
      "title": "エラー"
    },
    "app-error-pill-title": "エラー",
    "async": {
      "cannot-resolve": "お客様が新しいチャットを開始したため、この会話{conversationId}を解決できません。",
      "okay": "Okay",
      "title": "警告"
    },
    "auto-transfer": {
      "message": "一定時間内に初回応答を受け取らなかった新規チャットが割り当てられました。",
      "respond-now": "今すぐ応答する",
      "title": "自動転送"
    },
    "business-unit": "ビジネスユニット",
    "cancel": "キャンセル",
    "close-chat-modal": {
      "cancel": "キャンセル",
      "chat": "チャットを終了 {engagementId}",
      "continue": "続行",
      "conversation": "会話を終了  {engagementId}",
      "resolve": "会話を解決 {engagementId}"
    },
    "co-browse": "ブラウザ共有",
    "cobrowse": {
      "color-picker": "ハイライトの色を選択します",
      "end": "ブラウザ共有を終了",
      "error": "ブラウザ共有でエラーが発生しました",
      "hide-chat-window": "チャットウィンドウを非表示",
      "inactive": "非アクティブ",
      "reload": "ページの再読み込み",
      "show-chat-window": "チャットウィンドウを表示",
      "unhighlight": "ハイライトを削除"
    },
    "collapsable-tile": {
      "collapse": "折りたたむ",
      "compress": "圧縮",
      "expand": "展開",
      "header-snap-back": "閉じてサイドメニューに戻る",
      "reminders": "リマインダー"
    },
    "color": {
      "blue": "青色",
      "cyan": "シアン",
      "gray": "グレー",
      "green": "緑色",
      "light-blue": "水色",
      "light-green": "薄緑色",
      "magenta": "マゼンタ",
      "no-color": "色なし",
      "orange": "オレンジ",
      "peach": "桃色",
      "pink": "ピンク",
      "purple": "紫色",
      "white": "白色",
      "yellow": "黄色"
    },
    "conference": {
      "close-modal": {
        "no": "いいえ",
        "title": "会議を閉じますか？",
        "warning": "あなたは会議の主催者です。会議を退席すると終了します。退席しますか？",
        "yes": "はい"
      },
      "ownership-modal": {
        "accept": "受け入れる",
        "refuse": "拒否する",
        "title": "主催者役を引き受けますか？"
      }
    },
    "custom-scripts": {
      "add-new-header-script": "新しい見出しを追加",
      "add-new-text-script": "新しいスクリプトを追加",
      "cancel": "キャンセル",
      "confirm-changes": "変更を確認",
      "delete": "削除",
      "delete-header-script": "見出しを削除",
      "delete-text-script": "スクリプトを削除",
      "do-not-save": "保存しない",
      "edit-custom-scripts": "カスタムスクリプトを編集",
      "edit-header-script": "見出しを編集",
      "edit-text-script": "スクリプトを編集",
      "incorrect-scripts": "無効な文字のため、スクリプトを読み込めませんでした。",
      "input-header-placeholder": "ここに見出しを入力してください",
      "input-text-placeholder": "ここにスクリプトを入力してください",
      "invalid-chars-in-scripts": "スクリプト内の無効な文字: {n}",
      "my-scripts": "カスタムスクリプト",
      "no-access": "カスタムスクリプトにはアクセスできません",
      "save": "保存",
      "save-and-close": "保存して閉じる",
      "saved": "保存されたカスタムスクリプト",
      "title": "カスタムスクリプト",
      "undo-delete-header-script": "見出しの削除を元に戻す",
      "undo-delete-text-script": "スクリプトの削除を元に戻す",
      "unsaved-changes-prompt": "スクリプトの変更が保存されていません。保存しますか？"
    },
    "device-settings": {
      "audio-device": "音声デバイス",
      "browser-not-supported": "このアプリケーションは現在のブラウザのデバイスをサポートしていません",
      "close": "閉じる",
      "ff-warn-message": "音声出力デバイスはブラウザでサポートされていません",
      "header": "デバイス設定",
      "mic-device": "マイクデバイス",
      "no-device": "選択項目が利用できません",
      "permission-not-granted": "デバイス権限が付与されていません",
      "video-device": "ビデオデバイス",
      "video-error-message": "プレビューが利用できません",
      "video-not-available": "ビデオが利用できません"
    },
    "digital-voice-and-video": {
      "invite-to-video-call": "お客様をビデオ通話に招待する",
      "invite-to-voice-call": "お客様を音声のみの通話に招待する",
      "title": "デジタル音声＆ビデオ",
      "title-video": "デジタルビデオ",
      "title-voice": "デジタル音声",
      "video-call": "ビデオ通話",
      "voice-call": "音声通話"
    },
    "dispositions": "ディスポジション",
    "emojis": {
      "clapping-hands": "拍手",
      "grinning-face": "にやりとした顔",
      "happy-face": "笑顔",
      "icon-title": "絵文字",
      "panel-title": "Emoji Panel",
      "raising-hands": "手を挙げる",
      "red-heart": "赤いハート",
      "sad-face": "悲しい顔",
      "thumbs-down": "よくないね",
      "thumbs-up": "いいね",
      "waving-hand": "手をふる"
    },
    "end-call-confirmation-modal": {
      "cancel": "キャンセル",
      "content-video": "お客様との通話を終了しようとしています",
      "content-voice": "お客様との通話を終了しようとしています。",
      "end-call-button": "通話を終了",
      "title": "通話を終了しますか？"
    },
    "engagement-tab": {
      "chat": "チャット",
      "chat-duration": "チャット持続時間",
      "chat-has-new-messages": "{deviceType} chat {chatIndex}, unresponded timer {unrespondedMessageTimer} seconds, {unrespondedMessages} new messages.",
      "completed": "完了",
      "countdown": "カウントダウン",
      "current-chat-has-new-messages": "Current chat has {unrespondedMessages} new messages.",
      "no-engagements": "現在対応中のチャットはありません",
      "unresponded-timer": "未応答時間のタイマー"
    },
    "engagements-to-follow-up": "フォローアップのためのチャット",
    "escalate-modal": {
      "chat-escalation": "チャットのエスカレーション"
    },
    "escalation-form": {
      "cancel": "キャンセル",
      "description": "説明",
      "submit": "送信"
    },
    "filter": "フィルター",
    "follow-up": {
      "conversation-id": "会話ID",
      "notes": "メモ",
      "required-field": "は必須フィールドです。",
      "time-since-last-engagement": "最後のチャットからの時間",
      "time-to-expire": "有効期限切れまでの時間",
      "updated": "フォローアップのチャットが更新されました"
    },
    "follow-up-disabled": "キューにフォローアップチャットがありません",
    "follow-up-enabled": "キューにフォローアップチャットがあります",
    "follow-up-modal": {
      "cancel": "キャンセル",
      "follow-up-agent": "次のフォローアップキューに入れる：",
      "notes": "メモ",
      "submit": "送信",
      "title": "フォローアップ"
    },
    "header": {
      "active-chats": "アクティブなチャット",
      "agent-desktop": "エージェントデスクトップ",
      "chats-completed": "完了済みのチャット",
      "chats-in-queue": "キュー内のチャット",
      "dismiss": "閉じる",
      "ie-warning": "Support for Internet Explorer 11 will be retired as of August 30th, 2021. For optimal user experience, please move to a supported browser (Google Chrome, Microsoft Edge or Mozilla Firefox).",
      "logged-in-at": "ログイン時刻",
      "menu": {
        "custom-scripts": "カスタムスクリプト",
        "logout": "ログアウト",
        "my-settings": "設定"
      },
      "navigation": "ナビゲーション",
      "nina-coach-completed": "完了済みのニーナコーチ",
      "public-user-id": "パブリックユーザID",
      "scripts": "スクリプト",
      "skip-main-region": "メイン領域にスキップ",
      "skip-main-region-abbr": "メインにスキップ",
      "summary": "概要",
      "username": "ユーザ名"
    },
    "illegal-word": "お客様へのメッセージでは使用できません。送信する前にこれを削除する必要があります。",
    "interactive-data": {
      "default-label": "対話型データ"
    },
    "internal-chat": {
      "close": "閉じる",
      "expand-internal-chat": "未読メッセージ。[Ctrl]、[Shift]と[X]を同時に押して、内部チャットを開きます。",
      "how-to-close": "内部チャットを終了するには、他のメンバーが先にお客様とのチャットを終了する必要があります。",
      "internal-chat": "内部チャット",
      "minimize": "最小化",
      "unread-messages": "未読メッセージ"
    },
    "link": "リンク",
    "load-engagement": "チャットを読み込む",
    "lockout": {
      "inactivity-message": "非アクティブ状態が継続したため自動ロックされました"
    },
    "login-form": {
      "access-denied": "ユーザはエージェントデスクトップにアクセスできません。",
      "account-maybe-locked": "認証に5回失敗しました。アカウントがロックされている可能性があります。管理者に確認してください。",
      "agent-id": "エージェントID",
      "agent-logged-off": "ログアウトしました",
      "ajax-authorization-failed": "Ajax認証エラー - システム管理者にお問い合わせください。",
      "already-logged-in-modal-message": "現在、エージェント{agentName}のアクティブなセッションがあります。閉じてから新しいセッションを開いてください。",
      "already-logged-in-modal-title": "すでにログインしています",
      "application-error": "ログイン中にアプリケーションエラーが発生しました",
      "application-error-generic": "アプリケーションエラーが発生しました、システム管理者に問い合わせて再ログインしてください。",
      "bad-credentials": "無効なユーザ名またはパスワード",
      "changed-password": "パスワードが変更されました",
      "default-invalidation-error": "予期しない認証エラーが発生したため、ログアウトしました。もう一度ログインしてください。この問題が解決しない場合は、システム管理者に問い合わせてください。",
      "details": "詳細",
      "forgot-your-password": "パスワードをお忘れですか？",
      "hide-details": "詳細を非表示",
      "internet-restored": "インターネット接続が復元されました。もう一度ログインしてください。",
      "log-in": "ログイン",
      "logout-inactivity": "非アクティブ状態が続いたためセッションがログアウトされました",
      "missing-password": "パスワードが未入力です",
      "missing-username": "ユーザ名が未入力です",
      "no-internet": "インターネット接続が検出されませんでした。再接続してもう一度お試しください。",
      "password": "パスワード",
      "server-timeout-message": "サーバーのタイムアウトエラーが発生しました。再読込みの上、やり直してください。申し訳ございません。",
      "server-timeout-title": "サーバーのタイムアウト",
      "service-unavailable": "コンポーネントが利用できません。システム管理者に連絡してください。",
      "show-details": "詳細を表示",
      "timestamp": "タイムスタンプ",
      "unlock": "ロックを解除",
      "url": "URL",
      "user-account-is-locked": "ログイン試行の最大数に達したため、アカウントがロックされました。",
      "username": "ユーザ名"
    },
    "logout-button": {
      "logout": "ログアウト"
    },
    "metrics": {
      "agent-metrics": "エージェント指標",
      "available": "対応可能",
      "busy": "ビジー状態",
      "chat-slots": "チャットスロット",
      "chats-in-my-queue": "chats waiting in \"My Queue\"",
      "chats-in-queue": "キュー内のチャット",
      "free": "空き状態",
      "max": "最大",
      "metrics-title": "エージェントの対応状況概要",
      "my-queue": "(自分のキュー: {n})",
      "total": "合計"
    },
    "misc": {
      "prevent-refresh-message": "ページを更新してもよろしいですか？"
    },
    "modal": {
      "cancel": "キャンセル",
      "close": "閉じる",
      "okay": "Okay",
      "warn-title": "警告"
    },
    "my-settings": {
      "layout-preview": "レイアウトプレビュー",
      "preview": "プレビュー画像を拡大"
    },
    "named-entities-form": {
      "submit-disabled-tooltip": "1つ以上の変数の値が設定されていません。送信する全ての変数の値を指定してください。"
    },
    "next": "次",
    "nina-coach": {
      "alert": "ニーナ・コーチのアラート",
      "all": "すべて",
      "all-checkbox": "すべてのチェックボックスがオン",
      "arrow-down-icon": "下矢印アイコン",
      "arrow-right-icon": "右矢印アイコン",
      "clear-search": "検索をクリア",
      "cleared-intents-query": "検索意図のクエリがクリアされました",
      "concepts": "概念",
      "filter-by": "フィルター条件：",
      "filter-icon": "フィルターアイコン",
      "intents": "インテント",
      "loading-intents": "インテントを読み込んでいます",
      "name": "名前",
      "nina": "ニーナ",
      "nina-suggested": "ニーナが提案されました",
      "no-available-concepts": "利用可能なコンセプトはありません",
      "no-intents": "インテントがありません",
      "no-opportunity": "機会がありません",
      "press-tab-key": "Tabを押してフィルターに移動",
      "response-text": "応答可能なテキスト",
      "search-icon": "検索アイコン",
      "search-placeholder": "検索意図、概念、応答テキスト",
      "selected-intent": "選択されたインテント",
      "send": "送信",
      "suggest-intent": "提案されたインテント",
      "take-over": "引き継ぐ",
      "title": "ニーナコーチ",
      "transfer": "転送",
      "type": "種類"
    },
    "notifications": {
      "lost-connection": "接続が切れました。再接続を試みています。",
      "lost-connection-no-internet": "インターネット接続が切れました。インターネット接続を確認し、ページを更新して再度ログインしてください",
      "notify-new-engagement": "新しいチャットが割り当てられました",
      "notify-new-message": "お客様からの新しいメッセージ"
    },
    "oh-no": "( ゜o゜!)",
    "password-reset": {
      "confirm-password": "パスワードを確認",
      "current-password": "現在のパスワード",
      "message": "パスワードの有効期限が切れています。新しいパスワードを入力してください。",
      "minimum-password-length": "パスワードは12文字以上の長さが必要です。",
      "new-password": "新しいパスワード",
      "please-confirm-your-new-password": "新しいパスワードを確認してください",
      "please-provide-a-new-password": "新しいパスワードを入力してください",
      "please-provide-your-current-password": "現在のパスワードを入力してください",
      "submit": "送信",
      "username": "ユーザ名",
      "username-is-a-required-field": "ユーザ名は必須フィールドです。",
      "your-passwords-must-match": "新しいパスワードと確認済みの新しいパスワードが異なります"
    },
    "performance-metrics": {
      "agentUtilization": "チャットスロット稼働率",
      "assignedCount": "割当リクエスト数",
      "availableUtilization": "チャット空きスロット率",
      "avgConversionProductQuantity": "コンバージョンあたりの販売数",
      "avgDispositionTime": "平均ディスポジション時間",
      "avgEngagementHandleTime": "チャットの平均対応時間",
      "avgEngagementHandleTimeSLA": "SLA以内で終了したチャットの割合[%]",
      "avgInitialAgentResponseTime": "初回の平均応答時間",
      "avgOrderValue": "平均注文額",
      "busyClickCount": "ログインあたりのビジー状態回数",
      "chatsPerLaborHour": "稼動時間あたりのチャット数",
      "conversionCount": "コンバージョン数",
      "conversionProductQuantity": "販売数",
      "cumulativeLaborUtilization": "累積稼動時間",
      "efficiency": "効率性",
      "initialResponseWithinSLA": "SLA以内で送信した初回応答の割合[%]",
      "logged-in-time": "ログイン時間指標",
      "loginDuration": "ログイン時間",
      "percentAssisted": "サポート基準を満たした割合[%]",
      "percentConversion": "コンバージョン率[%]",
      "percentEndedByCustomer": "お客様による切断の割合 [%]",
      "salesPerLaborHour": "稼動時間あたりの売上",
      "totalBusyTime": "ビジー状態の合計時間",
      "utilization": "稼働率",
      "volume": "量"
    },
    "power-select": {
      "no-results-found": "結果が見つかりません",
      "type-to-filter": "フィルタータイプ"
    },
    "previous": "前",
    "request-additional-chat": {
      "chat-slots-enabled": "Additional chats enabled",
      "chat-slots-not-full": "追加のチャットをリクエストするには、チャットスロットが埋まっている必要があります。",
      "default": "追加のチャット",
      "details": "To toggle the state please press space bar",
      "extra-chat-slots-full": "追加チャット数が上限に達しました",
      "not-available": "対応可能な状態になっていません",
      "request-more-chats": "追加チャットを要求"
    },
    "retry": "再試行",
    "rich-widget": {
      "default-label": "リッチウィジェット",
      "error-render": "ウィジェットプレビューを読み込めません",
      "load": "読み込む",
      "preview-title": "ウィジェットプレビュー",
      "preview-widget-navigation": " {widgetLabel}. [Enter]キーを押してプレビューを開く"
    },
    "scripts": "スクリプト",
    "sdk-dispositions": {
      "add-notes": "メモを追加",
      "cancel": "キャンセル",
      "choose-all": "該当するものを全て選択してください",
      "choose-one": "最も当てはまるものを選択してください",
      "close-engagement": "チャットを閉じる",
      "load-fail": "ディスポジションの読み込み中にエラーが発生しました",
      "loading": "読み込み中",
      "loading-dispositions": "ディスポジションを取得しています",
      "not-found": "\"{filterInput}\" が見つかりません",
      "pre-selected": {
        "title": "From previous engagement",
        "tooltip": {
          "clear-all": "Unselect all disposition reasons from previous engagement",
          "select-all": "Select all disposition reasons from previous engagement"
        }
      },
      "search-placeholder": "ディスポジションを検索",
      "select-disposition": "まずディスポジションを選択してください。",
      "show-pagination": "Pagination",
      "submit": "送信",
      "window-title": "ディスポジション"
    },
    "sdk-scripts": {
      "absent": "There are no scripts configured. Please contact administrator.",
      "agent-coach": "エージェントコーチ",
      "commands-cannot-be-copied": "コマンドはコピーできません（Shiftキーを長押ししていました）",
      "custom-scripts": "カスタムスクリプト",
      "custom-scripts-here": "カスタムスクリプトはここに表示されます",
      "empty-script-tree": "スクリプトツリーが空です。",
      "error": {
        "rich-widget-agent-input": "リッチウィジェットを入力フィールドに送信できません"
      },
      "error-fetching": "スクリプト取得中にエラーが発生しました",
      "input-script": {
        "cancel": "キャンセル",
        "submit": "送信"
      },
      "limited-access": "スクリプトツリーへのアクセスが制限されています。",
      "loading": "スクリプトが読み込まれるまでお待ちください...",
      "script-cannot-be-modified": "このスクリプトは変更できません",
      "system-scripts": "システムスクリプト",
      "training-keyboard-1": "押す",
      "training-keyboard-2": "スクリプトグループに移動します。矢印キーを",
      "training-keyboard-3": "押してスクリプトの行に移動します。",
      "unavailable": "スクリプトは利用できません"
    },
    "search": null,
    "settings": "設定",
    "site": "サイト",
    "slideout": {
      "expand-details": "詳細を展開する"
    },
    "sso": {
      "error-contact": "テクニカルサポートにお問い合わせください。ログインページにリダイレクトするには、下の[OK]をクリックしてください",
      "error-message": "SSOで問題が起きました。",
      "error-okay": "OK",
      "logout-message": "エージェントデスクトップからログアウトされました",
      "relogin": "再ログイン"
    },
    "static-transcript": {
      "abc-widget-message-sent": "{agentDisplayText}が送信されました",
      "chat-history": "チャット履歴",
      "clear-filter": "フィルタをクリア",
      "current": "現在",
      "default-abc-widget-message-sent": "対話型データが送信されました",
      "default-rich-widget-message-sent": "*** 開始したリッチウィジェット ***",
      "initiated-widget": "*** 開始したリッチウィジェット: {label} ***",
      "next-result": "次の結果",
      "previous-result": "前の結果",
      "rich-widget-message-sent": "{agentDisplayText}が送信されました",
      "search-scripts": "スクリプトを検索",
      "search-transcripts": "会話ログを検索",
      "static-transcript": "会話ログ"
    },
    "submit": "送信",
    "summary": {
      "updated-every-10-seconds": "10秒ごとに更新"
    },
    "training": "トレーニング",
    "training-tab": "トレーニングタブ",
    "transcript-agent-input": {
      "send": "送信"
    },
    "transcript-history": null,
    "transcript-window": {
      "abc-widget-message-sent": "{agentDisplayText}が送信されました",
      "customer-closed-engagement": "お客様がチャットウィンドウを閉じました",
      "customer-is-typing": "お客様が入力中です。",
      "customer-lost-connection": "お客様の接続が失われました",
      "customer-minimized-chat-window": "お客様がチャットを最小化しました",
      "customer-restored-chat-window": "お客様がチャットを復元しました",
      "customer-stopped-typing": "お客様が入力を停止しました。",
      "default-abc-widget-message-sent": "対話型データが送信されました",
      "default-rich-widget-message-sent": "リッチウィジェットが送信されました",
      "events": {
        "callEnded": "通話が終了しました",
        "callInvitation": "通話への招待が送信されました",
        "callRequestDeclined": "通話リクエストが拒否されました",
        "createRoomFailed": "ビデオ通話ルームの作成に失敗しました",
        "createRoomSuccess": "ビデオ通話ルームが作成されました",
        "error": "Device not supported",
        "newCallRequest": "受信リクエスト",
        "newVoiceCallRequest": "受信リクエスト",
        "roomAgentJoin": "Agent has joined the room",
        "roomAgentLeave": "Agent has left the room"
      },
      "interrupted": "中断され、送信されていません",
      "moved-to-persistent": "お客様が常駐チャットに移行しました",
      "new-chat-information": "新しいチャット情報があります。[Ctrl]、[Shift]と[A]を同時に押して、コンテンツをアナウンスします。",
      "no-new-chat-information": "新しいチャット情報はありません。",
      "not-sent": "メッセージを送信できませんでした",
      "rich-widget-message-sent": "{agentDisplayText}が送信されました",
      "scroll-to-bottom": "最下行までスクロール",
      "see-new-messages": "新しいメッセージを見る",
      "started-move-to-persistent": "お客様は常駐チャットウィンドウに転送されています",
      "time-delayed": "遅延",
      "transcript-line": {
        "auto-close-close": "(お客様が応答しない場合、チャットは{seconds}秒で自動終了します)",
        "auto-close-warn": "(警告メッセージ - {seconds}秒)",
        "command": "コマンド",
        "rich-widget-response": "Rich Widget Response",
        "view-more": "もっと見る",
        "xform": "データパス"
      }
    },
    "transfer-engagement": {
      "agent-group-placeholder": "エージェントグループを選択してください",
      "agent-notes": "エージェントによるメモ",
      "agent-placeholder": "エージェントが選択されていません",
      "async-agent-group-info": "現在対応できるエージェントはいません。エージェントが対応可能になるまで、お客様はキューに入れられます。",
      "available-slots": "使用可能なスロット",
      "business-unit-placeholder": "BUを選択してください",
      "conference": "会議",
      "conference-queue-slots": "会議のキュースロット",
      "field-reset": "フィールドがリセットされました",
      "no-agents-with-attributes-available": "条件を満たす対応可能なエージェントがいません",
      "no-matching-agents-found": "一致するエージェントが見つかりません",
      "optionally-select": "エージェントを直接選択することもできます",
      "please-select-a-bunit": "ドロップダウンリストからBUを選択してください",
      "prioritize-request": "リクエストを優先",
      "reset": "リセット",
      "select-a-bunit": "ビジネスユニット(BU)を選択",
      "select-an-agent-group": "エージェントグループを選択",
      "select-one-or-more-agent-attributes": "1つまたは1つ以上のエージェント属性を選択",
      "transfer": "転送",
      "transfer-queue-slots": "転送のキュースロット"
    },
    "transfer-window": {
      "transfer-and-conference": "転送と会議"
    },
    "twitter-private-reply-confirmation": {
      "message": "返信をプライベートメッセージとして送信してもよろしいですか？このメッセージが送信されると、このチャットタブは閉じられます。お客様がプライベートメッセージに返信すると、Twitterのダイレクトメッセージ用に新しいチャットタブが作成されます。",
      "title": "非公開として返信 - 確認"
    },
    "video-chat": {
      "accept-button": "許可",
      "available": "Available for video chat",
      "close-engagement": "チャットを閉じる",
      "connected": "接続されました",
      "customer-disconnected": "お客様が通話を終了しました",
      "decline-button": "拒否",
      "device-settings": "デバイス設定",
      "end-call-button": "通話を終了",
      "end-video-call": "ビデオ通話が終了しました",
      "failed-to-create-video-room": "ビデオ通話ルームの作成に失敗しました",
      "incoming-call": "ビデオ通話の着信...",
      "incoming-call-in-progress": "Incoming video call in progress",
      "mute": "ミュート",
      "retry": "再試行",
      "un-mute": "ミュート解除"
    },
    "visitor-info-tab": {
      "browser-type": "ブラウザの種類",
      "browser-version": "ブラウザ ヴァージョン",
      "business-rule": "ビジネスルール",
      "chat-id": "チャットID",
      "chat-type": "チャットの種類",
      "conversation-channel": "会話チャンネル",
      "conversation-id": "会話ID",
      "customer-id": "お客様ID",
      "customer-information": "お客様情報",
      "customer-name": "お客様名",
      "device-type": "デバイスの種類",
      "locale": "ロケール",
      "operating-system-type": "オペレーション システム タイプ",
      "page-marker": "ページマーカー"
    },
    "visitor-information": "訪問者情報",
    "voice-chat": {
      "available": "Available for voice chat",
      "incoming-call": "音声通話の着信...",
      "incoming-call-in-progress": "Incoming voice call in progress"
    },
    "web-tools": "ウェブツール",
    "x-tabs": {
      "access-frame": "クリックしてフレームにアクセスします。",
      "how-to-use": "左右の矢印キーを使用してタブ間を移動します",
      "left-arrow": "左矢印",
      "press": "押下",
      "right-arrow": "右矢印"
    }
  }], ["pt", {
    "a11y": null,
    "active-engagement": {
      "already-escalated": "Esse compromisso já foi priorizado",
      "app-chat": "{chat} por App",
      "apple-business-chat": "{chat} pelo Apple Business",
      "background-color": "Cor de fundo",
      "bold": "Negrito",
      "channel": "Channel",
      "close": "Encerrar",
      "close-chat": "Fechar o chat",
      "conference": "Conferência",
      "conversation": "Conversation",
      "copy": "Copiar",
      "copy-masked-transcript": "Copiar Transcrição Mascarada",
      "copy-transcript-notification": "Transcrição copiada",
      "desktop-chat": "{chat} com desktop",
      "device": "Device",
      "eapi": "EAPI",
      "engagement": "Engagement",
      "engagement-action-disabled-follow-up": "Desativado até que o compromisso tenha começado",
      "escalate": "Encaminhar",
      "escalate-chat": "Encaminhar o chat",
      "escalated": "Encaminhado",
      "facebook-chat": "{chat} pelo Facebook Messenger",
      "file-upload": "File Upload",
      "follow-up": "Acompanhar",
      "general-chat": "General {chat}",
      "googles-business-messages-chat": "{chat} pelo Google's Business Messages",
      "hide-details": "Ocultar detalhes",
      "hide-editor": "Hide Editor",
      "hide-file-upload": "Hide File Upload Request",
      "instagram-chat": "{chat} pelo Instagram",
      "italic": "Itálico",
      "join-conference": "Entrar",
      "join-conference-ada": "join conference with customer",
      "line-chat": "{chat} pelo Line",
      "mask": "Mascarar como Informação Pessoal",
      "mobile-chat": "{chat} com celular",
      "more-options": "More Options",
      "pending-send-message": "Pendente, a mensagem está sendo enviada",
      "resend": "Reenviar",
      "resolve": "Resolver",
      "resolve-chat": "Resolver chat",
      "restricted-by-webtool": "Restricted by webtool",
      "search": "Search...",
      "search-icon-title": "Search",
      "search-input-title": "search input",
      "send-a-message": "Send a message",
      "send-message": "Send Message",
      "show-details": "Show Details",
      "show-editor": "Show Editor",
      "show-file-upload": "Send File Upload Request",
      "sms-chat": "{chat} por SMS",
      "tablet-chat": "{chat} com tablet",
      "telegram-chat": "{chat} pelo Telegram",
      "transfer": "Transferência",
      "transfer-and-conference": "Transferir e conferenciar",
      "twitter": {
        "private": "Enviar como mensagem privada",
        "public": "Enviar Tweet público"
      },
      "twitter-chat": "{chat} pelo Twitter",
      "type-your-message": "type your message",
      "underline": "Sublinhado",
      "viber-chat": "{chat} pelo Viber",
      "waiting": "Waiting",
      "web-chat": "{chat} pela internet",
      "whatsapp-chat": "{chat} pelo WhatsApp",
      "you-are-not-the-conference-owner": "Você não é o proprietário da conferência"
    },
    "agent-coach": {
      "context": {
        "reset": "Reset to follow live chat",
        "set": "Show recommendations"
      },
      "error-fetching": "There was an error fetching recommendations",
      "fetch-recommendation": "Customer message {customerMessage}. Press enter to fetch response recommendations.",
      "filter-as-i-type": "Filter as I type",
      "named-entities": null,
      "no-recommendations-found": "No Recommendations Found",
      "top-suggestions": "Top Suggestions"
    },
    "agent-group": "Grupo de Agentes",
    "agent-groups": null,
    "agent-settings": {
      "chat-details": "Detalhes do chat",
      "chat-tab-position": "Posição da guia do chat",
      "close": "Fechar",
      "collapse-all": "Collapse All",
      "collapse-script-headings": "Recolher todos os cabeçalhos de script por padrão",
      "collapse-script-headings-disabled-description": "Script headings are all expanded by default for new engagements",
      "collapse-script-headings-enabled-description": "Script headings are all collapsed by default for new engagements",
      "create-custom-layout": "Create custom layout",
      "create-custom-layout-description": "You’ve  modified the layout of your current engagement. You can create a custom layout to always default new engagements to this state.",
      "ctrl-enter-submit": "Pressionar \"Ctrl+Enter\" para enviar mensagens (em vez de \"Enter\")",
      "custom-layout-created": "Custom layout has been created",
      "custom-layout-updated": "Custom layout has been updated",
      "double-click": "Clique duplo",
      "double-click-description": "um único clique adiciona um script ao campo de entrada",
      "english": "English",
      "expand-all": "Expand All",
      "font-size": "Tamanho da fonte",
      "freeze-chat-tab-position": "Fixar a posição da guia durante o chat",
      "french": "Français",
      "german": "Deutsch",
      "hide-details": "Ocultar detalhes",
      "hide-details-disabled-description": "System messages are shown by default in the chat transcript for new engagements",
      "hide-details-enabled-description": "System messages are hidden by default in the chat transcript for new engagements",
      "italian": "Italiano",
      "japanese": "日本語",
      "keyboard": "Teclado",
      "language": "Idioma",
      "large": "Grande",
      "layout": "Modelo de layout",
      "layouts": {
        "chat-center": "Chat no centro",
        "chat-left": "Chat à esquerda",
        "chat-left-two-columns": "Duas colunas (chat à esquerda)",
        "chat-right": "Chat à direita",
        "custom": "Personaliza"
      },
      "login-as-available": "Entrar como Disponível",
      "login-as-busy": "Entrar como Ocupado",
      "login-state": "Estado do acesso",
      "medium": "Médio",
      "my-settings": "Minhas configurações",
      "notifications": "Notificações",
      "play-sounds": "Reproduzir Sons",
      "portuguese": "Português",
      "preview": "Pré-visualização",
      "russian": "Русский",
      "script-headings": "Cabeçalhos de scripts",
      "send-scripts": "Enviar Scripts",
      "single-click": "Clique simples",
      "single-click-description": "shift-clique adiciona script ao campo de entrada",
      "small": "Pequeno",
      "spanish": "Español",
      "status-available": "Disponível",
      "update-custom-layout": "Update custom layout",
      "update-custom-layout-description": "You’ve modified the layout of your current engagement. You can update your custom layout to always default new engagements to this state.",
      "visual-alerts": "Alertas visuais"
    },
    "agent-status-toggle": {
      "available": "Disponível",
      "busy": "Ocupado",
      "busy-auto": "busy-auto",
      "warning-set-fail": "There was an error updating your status. Please try again. If the problem continues please contact your administrator."
    },
    "agents": "Agentes",
    "alert": "Alert",
    "alerts": {
      "agent-has-active-engagements": "Você deve sair de todos os chats ativos antes de sair",
      "cobrowse-already-active": "Por favor, termine a sessão de navegação cooperativa ativa antes de enviar um novo convite"
    },
    "app-error-modal": {
      "error-n-of-total": "Erro {n} do {total}:",
      "error-occurred": "{count, plural, =1 {# erro ocorreu.} other {# erros ocorreram.}}",
      "hide-details": "Ocultar detalhes",
      "hide-errors": "Ocultar erros",
      "ok": "OK",
      "show-details": "Exibir detalhes",
      "title": "Error"
    },
    "app-error-pill-title": "Erro",
    "async": {
      "cannot-resolve": "Não é possível resolver esta conversa {conversationId} porque o cliente iniciou um novo chat.",
      "okay": "Ok",
      "title": "Atenção"
    },
    "auto-transfer": {
      "message": "Foi atribuído um novo chat que não recebeu uma resposta inicial no tempo estipulado.",
      "respond-now": "Responder agora",
      "title": "Auto Transferência"
    },
    "business-unit": "Unidade de Negócios",
    "cancel": "Cancelar",
    "close-chat-modal": {
      "cancel": "Cancel",
      "chat": "{engagementId}",
      "continue": "Continue",
      "conversation": "{engagementId}",
      "resolve": "{engagementId}"
    },
    "co-browse": "Co-Browse",
    "cobrowse": {
      "color-picker": "Selecionar cor do realce",
      "end": "Finalizar navegação conjunta",
      "error": "An error with cobrowse just occurred.",
      "hide-chat-window": "Ocultar janela de bate-papo",
      "inactive": "Inativo",
      "reload": "Recarregar a página",
      "show-chat-window": "Exibir janela de bate-papo",
      "unhighlight": "Remover cor do realce"
    },
    "collapsable-tile": {
      "collapse": "Collapse",
      "compress": "Compress",
      "expand": "Expand",
      "header-snap-back": "Fechar para voltar ao menu lateral",
      "reminders": "Reminders"
    },
    "color": {
      "blue": "Azul",
      "cyan": "Ciano",
      "gray": "Cinza",
      "green": "Verde",
      "light-blue": "Azul suave",
      "light-green": "Verde suave",
      "magenta": "Magenta",
      "no-color": "Sem cor",
      "orange": "Laranja",
      "peach": "Pêssego",
      "pink": "Rosa",
      "purple": "Púrpura",
      "white": "Branco",
      "yellow": "Amarelo"
    },
    "conference": {
      "close-modal": {
        "no": "Não",
        "title": "Encerrar a Conferência?",
        "warning": "Você é o dono da conferência. Deixar a conferência irá terminá-la. Quer sair da conferência?",
        "yes": "Sim"
      },
      "ownership-modal": {
        "accept": "Aceitar",
        "refuse": "Recusar",
        "title": "Aceitar a propriedade?"
      }
    },
    "custom-scripts": {
      "add-new-header-script": "Add New Header",
      "add-new-text-script": "Adicionar Novo Script",
      "cancel": "Cancelar",
      "confirm-changes": "Confirmar mudanças",
      "delete": "Deletar",
      "delete-header-script": "Delete header",
      "delete-text-script": "Delete script",
      "do-not-save": "Não salvar",
      "edit-custom-scripts": "Edit custom scripts",
      "edit-header-script": "Edit header",
      "edit-text-script": "Edit script",
      "incorrect-scripts": "Scripts could not be loaded because of invalid character(s)",
      "input-header-placeholder": "Enter heading here",
      "input-text-placeholder": "Insira o script aqui",
      "invalid-chars-in-scripts": "Invalid character(s) in script(s): {n}",
      "my-scripts": "Meus Scripts",
      "no-access": "Você não tem acesso a scripts personalizados",
      "save": "Salvar",
      "save-and-close": "Salvar e fechar",
      "saved": "Saved custom scripts",
      "title": "Scripts customizadas",
      "undo-delete-header-script": "Undo delete header",
      "undo-delete-text-script": "Undo delete script",
      "unsaved-changes-prompt": "Você tem alterações não salvas em seus scripts. Você quer salvá-las?"
    },
    "device-settings": null,
    "digital-voice-and-video": null,
    "dispositions": "Provisões",
    "emojis": null,
    "end-call-confirmation-modal": null,
    "engagement-tab": {
      "chat": "chat",
      "chat-duration": "chat duration",
      "chat-has-new-messages": "{deviceType} chat {chatIndex}, unresponded timer {unrespondedMessageTimer} seconds, {unrespondedMessages} new messages.",
      "completed": "COMPLETADO",
      "countdown": "countdown",
      "current-chat-has-new-messages": "Current chat has {unrespondedMessages} new messages.",
      "no-engagements": "You have no engagements at this time",
      "unresponded-timer": "unresponded timer"
    },
    "engagements-to-follow-up": "Compromissos para acompanhamento",
    "escalate-modal": {
      "chat-escalation": "Encaminhamento do Chat"
    },
    "escalation-form": {
      "cancel": "Cancelar",
      "description": "Descrição",
      "submit": "Submeter"
    },
    "filter": "Filter",
    "follow-up": {
      "conversation-id": "ID da conversa",
      "notes": "Notas",
      "required-field": "* is a required field",
      "time-since-last-engagement": "Tempo desde o último compromisso",
      "time-to-expire": "Tempo para expirar",
      "updated": "Follow up engagement has been updated"
    },
    "follow-up-disabled": "Não há conversas de acompanhamento na sua fila",
    "follow-up-enabled": "There are follow up chats ready in your queue",
    "follow-up-modal": {
      "cancel": "Cancelar",
      "follow-up-agent": "Colocar em fila subsequente para:",
      "notes": "Notas",
      "submit": "Enviar",
      "title": "Acompanhar"
    },
    "header": {
      "active-chats": "Chats ativos",
      "agent-desktop": "Desktop de Agente",
      "chats-completed": "Chats completados",
      "chats-in-queue": "Chats na fila",
      "dismiss": "Dismiss",
      "ie-warning": "Support for Internet Explorer 11 will be retired as of August 30th, 2021. For optimal user experience, please move to a supported browser (Google Chrome, Microsoft Edge or Mozilla Firefox).",
      "logged-in-at": "Acessou em",
      "menu": {
        "custom-scripts": "Scripts Customizados",
        "logout": "Sair",
        "my-settings": "Minhas Configuraes"
      },
      "navigation": "Navigation",
      "nina-coach-completed": "Treinamento da Nina Completado",
      "public-user-id": "Id de Usuário do Público",
      "scripts": "Scripts",
      "skip-main-region": "Skip to Main Region",
      "skip-main-region-abbr": "Skip to Main",
      "summary": "Resumo",
      "username": "Nome de usuário"
    },
    "illegal-word": "não pode ser usado na sua mensagem ao cliente. Isso deve ser removido antes de enviar.",
    "interactive-data": null,
    "internal-chat": {
      "close": "Encerrar",
      "expand-internal-chat": "unread messages. Press control plus shift plus X to expand internal chat.",
      "how-to-close": "To close internal chat, other members must first leave the engagement.",
      "internal-chat": "Internal Chat",
      "minimize": "Minimize",
      "unread-messages": "unread messages"
    },
    "link": "Link",
    "load-engagement": "Compromisso de carga",
    "lockout": {
      "inactivity-message": "Bloqueio automático devido à inatividade"
    },
    "login-form": {
      "access-denied": "O usuário não tem acesso ao Desktop do Agente.",
      "account-maybe-locked": "Você tentou autenticar 5 vezes sem sucesso. A sua conta pode estar bloqueada; por favor, verifique com o seu administrador",
      "agent-id": "Agent ID",
      "agent-logged-off": "Você foi desconectado",
      "ajax-authorization-failed": "Erro de autorização Ajax - por favor contacte o seu administrador do sistema.",
      "already-logged-in-modal-message": "Existe atualmente uma sessão ativa para o agente {agentName}; por favor, feche-a para abrir uma nova sessão.",
      "already-logged-in-modal-title": "Já logado",
      "application-error": "Ocorreu um erro de aplicação ao tentar iniciar a sessão",
      "application-error-generic": "Ocorreu um erro de aplicação, por favor contate o seu administrador do sistema e acesse novamente.",
      "bad-credentials": "Nome de usuário ou senha inválidos",
      "changed-password": "Senha alterada com sucesso",
      "default-invalidation-error": "Você foi desconectado devido a um erro de autenticação inesperado. Por favor, faça login novamente. Se esse problema persistir, entre em contato com o administrador do sistema.",
      "details": "Detalhes",
      "forgot-your-password": "Esqueceu sua senha?",
      "hide-details": "Ocultar detalhes",
      "internet-restored": "A conexão com a Internet foi restaurada; por favor, faça o login novamente",
      "log-in": "Acessar",
      "logout-inactivity": "Sua sessão foi desconectada devido à inatividade",
      "missing-password": "Falta a senha",
      "missing-username": "Nome de usuário ausente",
      "no-internet": "Nenhuma conexão de internet detectada; por favor, reconecte e tente novamente",
      "password": "Senha",
      "server-timeout-message": "Ocorreu um erro de tempo limite de conexão do servidor. Por favor, atualize e tente novamente. Obrigado.",
      "server-timeout-title": "Timeout do Servidor",
      "service-unavailable": "componente não disponível; por favor, contate o administrador do sistema",
      "show-details": "Exibir detalhes",
      "timestamp": "Timestamp",
      "unlock": "Desbloquear",
      "url": "URL",
      "user-account-is-locked": "Sua conta foi bloqueada porque você atingiu o número máximo de tentativas de acesso",
      "username": "Nome do usuário"
    },
    "logout-button": {
      "logout": "Terminar sessão"
    },
    "metrics": {
      "agent-metrics": "Métricas do Agente",
      "available": "Disponível",
      "busy": "Ocupado",
      "chat-slots": "Vagas para chat",
      "chats-in-my-queue": "chats waiting in \"My Queue\"",
      "chats-in-queue": "Chats na fila",
      "free": "Livre",
      "max": "Máx",
      "metrics-title": "Resumo da disponibilidade do agente",
      "my-queue": "(Minha Fila: {n})",
      "total": "Total"
    },
    "misc": {
      "prevent-refresh-message": "Tem certeza de que deseja atualizar a página?"
    },
    "modal": {
      "cancel": "Cancelar",
      "close": "Encerrar",
      "okay": "Ok",
      "warn-title": "Warning"
    },
    "my-settings": null,
    "named-entities-form": null,
    "next": "Next",
    "nina-coach": {
      "alert": "nina coach alert",
      "all": "Todos",
      "all-checkbox": "all checkbox checked",
      "arrow-down-icon": "arrow down icon",
      "arrow-right-icon": "arrow right icon",
      "clear-search": "clear search",
      "cleared-intents-query": "Intents search query cleared",
      "concepts": "Conceitos",
      "filter-by": "Filtrar por",
      "filter-icon": "filter icon",
      "intents": "Intenções",
      "loading-intents": "loading intents",
      "name": "Nome",
      "nina": "Nina",
      "nina-suggested": "Nina sugeriu",
      "no-available-concepts": "Nenhum conceito disponível",
      "no-intents": "Não há intenções",
      "no-opportunity": "Sem oportunidade",
      "press-tab-key": "press tab to navigate to filter",
      "response-text": "Possível Texto de Resposta",
      "search-icon": "search icon",
      "search-placeholder": "Pesquisa de intenções, conceitos, textos de resposta",
      "selected-intent": "Finalidade selecionada",
      "send": "Enviar",
      "suggest-intent": "suggested intent",
      "take-over": "Assumir",
      "title": "Nina Coach",
      "transfer": "Transferir",
      "type": "Tipo"
    },
    "notifications": {
      "lost-connection": "Conexão perdida, tentando reconectar",
      "lost-connection-no-internet": "A sua conexão com a Internet foi perdida. Verifique a conexão com a Internet, atualize a página e volte a iniciar sessão",
      "notify-new-engagement": "New chat assigned",
      "notify-new-message": "New message from customer"
    },
    "oh-no": "Oh no",
    "password-reset": {
      "confirm-password": "Confirmar senha",
      "current-password": "Senha atual",
      "message": "A sua senha expirou, por favor digte uma nova senha",
      "minimum-password-length": "O comprimento mínimo da senha deve ser de pelo menos doze caracteres",
      "new-password": "Nova senha",
      "please-confirm-your-new-password": "Por favor, confirme a sua nova senha",
      "please-provide-a-new-password": "Por favor, forneça uma nova senha",
      "please-provide-your-current-password": "Por favor, forneça a sua senha atual",
      "submit": "Enviar",
      "username": "Nome do usuário",
      "username-is-a-required-field": "O nome de usuário é um campo obrigatório",
      "your-passwords-must-match": "Nova senha e nova senha confirmada são diferentes"
    },
    "performance-metrics": {
      "agentUtilization": "Utilização dos intervalos de chat",
      "assignedCount": "Solicitação atribuída",
      "availableUtilization": "Disponibilidade dos intervalos de chat",
      "avgConversionProductQuantity": "Unidades por aquisição",
      "avgDispositionTime": "Tempo médio disponibilizado",
      "avgEngagementHandleTime": "Tempo médio de Chat",
      "avgEngagementHandleTimeSLA": "% do tempo médio de atendimento do SLA (contrato de nível de serviço)",
      "avgInitialAgentResponseTime": "Tempo médio para a resposta inicial",
      "avgOrderValue": "Valor médio da solicitação ($)",
      "busyClickCount": "Tempos como cupado por sessão",
      "chatsPerLaborHour": "Chats por horário de expediente",
      "conversionCount": "Aceitações",
      "conversionProductQuantity": "Unidades vendidas",
      "cumulativeLaborUtilization": "Utilização acumulada de mão-de-obra",
      "efficiency": "Eficiência",
      "initialResponseWithinSLA": "Resposta inicial dentro do SLA (%)",
      "logged-in-time": "Métricas de tempo logadas",
      "loginDuration": "Hora de início da sessão",
      "percentAssisted": "% de assistência",
      "percentConversion": "% de aceitação",
      "percentEndedByCustomer": "% completados pelo cliente",
      "salesPerLaborHour": "Vendas por hora de trabalho",
      "totalBusyTime": "Tempo total como Ocupado",
      "utilization": "Utilização",
      "volume": "Volume"
    },
    "power-select": null,
    "previous": "Previous",
    "request-additional-chat": {
      "chat-slots-enabled": "Additional chats enabled",
      "chat-slots-not-full": "Seus slots de bate-papo devem estar cheios para solicitar bate-papos adicionais",
      "default": "Additional chat slots are available",
      "details": "To toggle the state please press space bar",
      "extra-chat-slots-full": "Você atingiu o limite para chats adicionais",
      "not-available": "Você deve estar no status de disponível",
      "request-more-chats": "Request more chats"
    },
    "retry": "Retry",
    "rich-widget": null,
    "scripts": "Scripts",
    "sdk-dispositions": {
      "add-notes": "Adicionar notas",
      "cancel": "Cancelar",
      "choose-all": "Choose all that apply.",
      "choose-one": "Choose the one which best applies.",
      "close-engagement": "Close Engagement",
      "load-fail": "An error occurred while loading dispositions",
      "loading": "Loading",
      "loading-dispositions": "Fetching dispositions",
      "not-found": "\"{filterInput}\" not found",
      "pre-selected": {
        "title": "From previous engagement",
        "tooltip": {
          "clear-all": "Unselect all disposition reasons from previous engagement",
          "select-all": "Select all disposition reasons from previous engagement"
        }
      },
      "search-placeholder": "Search Dispositions",
      "select-disposition": "You must select a disposition first.",
      "show-pagination": "Pagination",
      "submit": "Enviar",
      "window-title": "Condições"
    },
    "sdk-scripts": {
      "absent": "There are no scripts configured. Please contact administrator.",
      "agent-coach": "Agent Coach",
      "commands-cannot-be-copied": "os comandos não podem ser copiados (você tinha a tecla Shift pressionada)",
      "custom-scripts": "Meus Scripts",
      "custom-scripts-here": "Scripts personalizados irão para aqui",
      "empty-script-tree": "Script Tree is empty.",
      "error": null,
      "error-fetching": "There was an error while fetching scripts",
      "input-script": {
        "cancel": "Cancelar",
        "submit": "Enviar"
      },
      "limited-access": "Script Tree Access Limited.",
      "loading": "Por favor aguarde enquanto os scripts carregam...",
      "script-cannot-be-modified": "Este script não pode ser modificado",
      "system-scripts": "Scripts do Sistema",
      "training-keyboard-1": "Press",
      "training-keyboard-2": "to navigate to script groups. Use the",
      "training-keyboard-3": "arrow keys to navigate to script lines.",
      "unavailable": "O script está indisponível"
    },
    "search": null,
    "settings": "Settings",
    "site": "Site",
    "slideout": {
      "expand-details": "expand details"
    },
    "sso": {
      "error-contact": "Entre em contato com o suporte técnico. Clique em \"OK\" abaixo para redirecionar para a página de login",
      "error-message": "Algo deu errado com SSO.",
      "error-okay": "OK",
      "logout-message": "Você foi desconectado do Desktop do Agente",
      "relogin": "LOGAR NOVAMENTE"
    },
    "static-transcript": {
      "abc-widget-message-sent": "{agentDisplayText} sent",
      "chat-history": "Históro do chat",
      "clear-filter": "clear filter",
      "current": "Current",
      "default-abc-widget-message-sent": "Interactive Data sent",
      "default-rich-widget-message-sent": "*** Initiated Rich Widget ***",
      "initiated-widget": "*** Initiated Rich Widget: {label} ***",
      "next-result": "next result",
      "previous-result": "previous result",
      "rich-widget-message-sent": "{agentDisplayText} sent",
      "search-scripts": "Search Scripts",
      "search-transcripts": "search transcripts",
      "static-transcript": "Transcrição Estática"
    },
    "submit": "Submeter",
    "summary": {
      "updated-every-10-seconds": "atualizado a cada 10 segundos"
    },
    "training": "Treinamento",
    "training-tab": "Training Tab",
    "transcript-agent-input": {
      "send": "Enviar"
    },
    "transcript-history": null,
    "transcript-window": {
      "abc-widget-message-sent": "{agentDisplayText} has been sent",
      "customer-closed-engagement": "O cliente fechou a janela de chat",
      "customer-is-typing": "O cliente está digitando...",
      "customer-lost-connection": "O cliente perdeu a conexão",
      "customer-minimized-chat-window": "O cliente minimizou o chat",
      "customer-restored-chat-window": "O cliente restaurou o chat",
      "customer-stopped-typing": "O cliente parou de digitar",
      "default-abc-widget-message-sent": "Interactive Data has been sent",
      "default-rich-widget-message-sent": "Rich Widget has been sent",
      "events": null,
      "interrupted": "interrompida, não enviada",
      "moved-to-persistent": "Cliente movido com sucesso para um chat persistente",
      "new-chat-information": "There is new chat information. Press control plus shift plus A to announce content.",
      "no-new-chat-information": "There is no new chat information.",
      "not-sent": "Message failed to send",
      "rich-widget-message-sent": "{agentDisplayText} has been sent",
      "scroll-to-bottom": "Rolar para baixo",
      "see-new-messages": "Ver novas mensagens",
      "started-move-to-persistent": "O cliente está sendo transferido para uma janela persistente de chat",
      "time-delayed": "diferida",
      "transcript-line": {
        "auto-close-close": "(Engagement will auto-end in {seconds} seconds if customer doesn’t respond)",
        "auto-close-warn": "(Warning Message - {seconds} seconds)",
        "command": "Comando",
        "rich-widget-response": "Rich Widget Response",
        "view-more": "View More",
        "xform": "Datapasse"
      }
    },
    "transfer-engagement": {
      "agent-group-placeholder": "Por favor, selecione um grupo de agentes",
      "agent-notes": "Notas do Agente",
      "agent-placeholder": "Nenhum Agente Selecionado",
      "async-agent-group-info": "Não há agentes disponíveis no momento. O cliente será colocado na fila até que um agente esteja disponível.",
      "available-slots": "Available slots",
      "business-unit-placeholder": "Selecione uma unidade de negócios",
      "conference": "Conferência",
      "conference-queue-slots": "vagas da fila de conferências",
      "field-reset": "fields have been reset",
      "no-agents-with-attributes-available": "Nenhum agente com atributos disponível",
      "no-matching-agents-found": "Não foi encontrado nenhum agente coincidindo",
      "optionally-select": "Opcionalmente, você pode selecionar um agente diretamente",
      "please-select-a-bunit": "Por favor, selecione uma unidade BUnit na lista suspensa",
      "prioritize-request": "Priorizar a requisição",
      "reset": "Redefinir",
      "select-a-bunit": "Selecione um BUnit",
      "select-an-agent-group": "Selecione um Grupo de Agentes",
      "select-one-or-more-agent-attributes": "Selecione um ou mais atributos de agente desejados",
      "transfer": "Transferir",
      "transfer-queue-slots": "brechas da fila de transferência"
    },
    "transfer-window": {
      "transfer-and-conference": "Transferência and Conferênciancia"
    },
    "twitter-private-reply-confirmation": {
      "message": "Tem certeza de que deseja enviar a resposta como uma mensagem privada? Uma vez que esta mensagem for enviada, esta aba de chat será fechada e será criada uma nova para o Twitter Direct Message assim que o cliente responder à sua mensagem privada.",
      "title": "Responder como Privado - Confirmação"
    },
    "video-chat": null,
    "visitor-info-tab": {
      "browser-type": "Tipo de navegador",
      "browser-version": "Browser Version",
      "business-rule": "Regra da empresa",
      "chat-id": "ID do chat",
      "chat-type": "Tipo de chat",
      "conversation-channel": "Canal de conversação",
      "conversation-id": "Conversation ID",
      "customer-id": "ID do cliente",
      "customer-information": "Customer Information",
      "customer-name": "Nome do cliente",
      "device-type": "Tipo de dispositivo",
      "locale": "Local",
      "operating-system-type": "Operating System Type",
      "page-marker": "Marcador de páginas"
    },
    "visitor-information": "Informação do Visitante",
    "voice-chat": null,
    "web-tools": "Ferramentas da Web",
    "x-tabs": null
  }], ["ru", {
    "a11y": {
      "arrow-key-navigation": "используйте клавиши со стрелками вверх и вниз для перехода к каждому элементу. Используйте клавиши со стрелками влево и вправо для перехода к интерактивным элементам внутри элемента",
      "link-navigation-instruction": "{content}. Для перехода по ссылкам используйте клавиши со стрелками влево и вправо",
      "resize-horizontal": "resizable handle, use up down arrow keys to resize",
      "resize-vertical": "resizable handle, use left right arrow keys to resize",
      "scroll-message": "нажимайте клавиши со стрелками вверх и вниз для навигации",
      "transcript-history-navigation-instruction": "Transcript History. Use up, down arrow keys to navigate through items",
      "transcript-navigation-instruction": "Стенограмма взаимодействия. Используйте клавиши со стрелками вверх и вниз для навигации по элементам",
      "widget-navigation-instruction": "{content}. Для навигации используйте клавиши со стрелками влево и вправо"
    },
    "active-engagement": {
      "already-escalated": "Взаимодействие уже передано на более высокий уровень",
      "app-chat": "Приложение {chat}",
      "apple-business-chat": "Apple Business {chat}",
      "background-color": "Цвет фона",
      "bold": "Жирный",
      "channel": "канал",
      "close": "Закрыть",
      "close-chat": "Закрыть чат",
      "conference": "Конференция",
      "conversation": "Разговор",
      "copy": "Копировать",
      "copy-masked-transcript": "Копировать стенограмму",
      "copy-transcript-notification": "Стенограмма скопирована",
      "desktop-chat": "Компьютер {chat}",
      "device": "устройство",
      "eapi": "EAPI",
      "engagement": "Взаимодействие",
      "engagement-action-disabled-follow-up": "Отключено до начала взаимодействия",
      "escalate": "Передать на более высокий уровень",
      "escalate-chat": "Передать чат на более высокий уровень",
      "escalated": "передано на более высокий уровень",
      "facebook-chat": "Facebook Messenger {chat}",
      "file-upload": "File Upload",
      "follow-up": "Последующие действия",
      "general-chat": "Общий {chat}",
      "googles-business-messages-chat": "Googles Business Messages {chat}",
      "hide-details": "Скрыть подробности",
      "hide-editor": "Hide Editor",
      "hide-file-upload": "Hide File Upload Request",
      "instagram-chat": "Instagram {chat}",
      "italic": "Курсив",
      "join-conference": "Присоединиться",
      "join-conference-ada": "присоединиться к конференции с клиентом",
      "line-chat": "Line {chat}",
      "mask": "Скрыть как личную информацию",
      "mobile-chat": "Мобильный {chat}",
      "more-options": "Прочие параметры",
      "pending-send-message": "Ожидание, сообщение отправляется",
      "resend": "Отправить повторно",
      "resolve": "Решить",
      "resolve-chat": "Принять решение по чату",
      "restricted-by-webtool": "Ограничен веб-инструментом",
      "search": "Search...",
      "search-icon-title": "Search",
      "search-input-title": "search input",
      "send-a-message": "Send a message",
      "send-message": "Отправить сообщение",
      "show-details": "Показать подробности",
      "show-editor": "Show Editor",
      "show-file-upload": "Send File Upload Request",
      "sms-chat": "SMS {chat}",
      "tablet-chat": "Планшет {chat}",
      "telegram-chat": "Telegram {chat}",
      "transfer": "Перевод",
      "transfer-and-conference": "Передача и конференция",
      "twitter": {
        "private": "Отправить как приватное сообщение",
        "public": "Отправить публичный твит"
      },
      "twitter-chat": "Twitter {chat}",
      "type-your-message": "введите сообщение",
      "underline": "Подчеркивание",
      "viber-chat": "Viber {chat}",
      "waiting": "Ожидание",
      "web-chat": "Интернет {chat}",
      "whatsapp-chat": "WhatsApp {chat}",
      "you-are-not-the-conference-owner": "Вы не владелец конференции"
    },
    "agent-coach": {
      "context": {
        "reset": "Reset to follow live chat",
        "set": "Show recommendations"
      },
      "error-fetching": "Ошибка при получении рекомендаций",
      "fetch-recommendation": "Сообщение клиента {customerMessage}. Нажмите «Ввод», чтобы получить рекомендации по ответам.",
      "filter-as-i-type": "Фильтровать в процессе ввода",
      "named-entities": {
        "address": "Адрес",
        "person-agent": "Имя агента",
        "person-customer": "Имя клиента"
      },
      "no-recommendations-found": "Рекомендации не найдены",
      "top-suggestions": "Лучшие рекомендации"
    },
    "agent-group": "Группа агентов",
    "agent-groups": {
      "confirm-button": "Подтвердить выбор",
      "description": "Выберите одну или несколько групп агентов, которым следует участвовать в этом сеансе чата согласно вашему желанию.",
      "list-header": "Группы агентов",
      "select-all": "Выбрать все",
      "title": "Выбор группы агентов"
    },
    "agent-settings": {
      "chat-details": "Сведения о чате",
      "chat-tab-position": "Положение вкладки чата",
      "close": "Закрыть",
      "collapse-all": "Свернуть все",
      "collapse-script-headings": "По умолчанию сворачивать все заголовки сценария",
      "collapse-script-headings-disabled-description": "Заголовки сценария по умолчанию разворачиваются для новых взаимодействий",
      "collapse-script-headings-enabled-description": "Заголовки сценария по умолчанию сворачиваются для новых взаимодействий",
      "create-custom-layout": "Создать пользовательский макет",
      "create-custom-layout-description": "Вы изменили макет своего текущего взаимодействия. Вы можете создать пользовательский макет, чтобы новые взаимодействия всегда были в этом состоянии по умолчанию.",
      "ctrl-enter-submit": "Быстрая команда «Ctrl+Ввод» для отправки сообщений (вместо Ввод»)",
      "custom-layout-created": "Создан пользовательский макет",
      "custom-layout-updated": "Пользовательский макет обновлен",
      "double-click": "Двойной щелчок мышью",
      "double-click-description": "одиночный щелчок мышью добавляет сценарий в поле ввода",
      "english": "English",
      "expand-all": "Развернуть все",
      "font-size": "Размер шрифта",
      "freeze-chat-tab-position": "Закрепить позицию вкладки на время чата",
      "french": "Français",
      "german": "Deutsch",
      "hide-details": "Скрыть подробности",
      "hide-details-disabled-description": "Системные сообщения по умолчанию отображаются в стенограмме чата для новых взаимодействий",
      "hide-details-enabled-description": "Системные сообщения по умолчанию скрыты в стенограмме чата для новых взаимодействий",
      "italian": "Italiano",
      "japanese": "日本語",
      "keyboard": "Клавиатура",
      "language": "Язык",
      "large": "Большой",
      "layout": "Шаблон макета",
      "layouts": {
        "chat-center": "Чат в центре",
        "chat-left": "Чат слева",
        "chat-left-two-columns": "Две колонки (чат слева)",
        "chat-right": "Чат справа",
        "custom": "Пользовательский"
      },
      "login-as-available": "Войти как доступный(-ая)",
      "login-as-busy": "Войти как занятый(-ая)",
      "login-state": "Состояние входа в систему",
      "medium": "Средний",
      "my-settings": "Мои настройки",
      "notifications": "Уведомления",
      "play-sounds": "Воспроизвести звуки",
      "portuguese": "Português",
      "preview": "Предпросмотр",
      "russian": "Русский",
      "script-headings": "Заголовки сценария",
      "send-scripts": "Отправить сценарии",
      "single-click": "Один щелчок мышью",
      "single-click-description": "Shift+щелчок мышью добавляет сценарий в поле ввода",
      "small": "Маленький",
      "spanish": "Español",
      "status-available": "Доступно",
      "update-custom-layout": "Обновить пользовательский макет",
      "update-custom-layout-description": "Вы изменили макет своего текущего взаимодействия. Вы можете обновить свой пользовательский макет, чтобы новые взаимодействия всегда были в этом состоянии по умолчанию.",
      "visual-alerts": "Визуальные оповещения"
    },
    "agent-status-toggle": {
      "available": "Доступно",
      "busy": "Занят",
      "busy-auto": "занят-авто",
      "warning-set-fail": "Ошибка при обновлении вашего статуса. Повторите попытку. Если проблема сохранится, обратитесь к администратору."
    },
    "agents": "Агенты",
    "alert": "Оповещение",
    "alerts": {
      "agent-has-active-engagements": "Перед выходом из системы нужно выйти из всех активных чатов.",
      "cobrowse-already-active": "Завершите активный сеанс совместного просмотра перед отправкой нового приглашения"
    },
    "app-error-modal": {
      "error-n-of-total": "Ошибка {n} из {total}:",
      "error-occurred": "{count, plural, =1 {Возникла # ошибка.} other {Возникли # ошибки(-ок).}}",
      "hide-details": "Скрыть подробности",
      "hide-errors": "Скрыть ошибки",
      "ok": "OK",
      "show-details": "Показать подробности",
      "title": "Ошибка"
    },
    "app-error-pill-title": "Ошибка",
    "async": {
      "cannot-resolve": "Не удается достичь решения в этом разговоре {conversationId}, потому что клиент начал новый чат.",
      "okay": "ОК",
      "title": "Предупреждение"
    },
    "auto-transfer": {
      "message": "Поручен новый чат, в котором не дан первоначальный ответ в установленный срок.",
      "respond-now": "Ответить сейчас",
      "title": "Автоперевод"
    },
    "business-unit": "Структурное подразделение",
    "cancel": "Отмена",
    "close-chat-modal": {
      "cancel": "Отмена",
      "chat": "Закрыть взаимодействие {engagementId}",
      "continue": "Продолжить",
      "conversation": "Закрыть разговор {engagementId}",
      "resolve": "Принять решение по разговору {engagementId}"
    },
    "co-browse": "Совместный просмотр",
    "cobrowse": {
      "color-picker": "Выберите цвет выделения",
      "end": "Завершить совместный просмотр",
      "error": "Ошибка на сервере совместного просмотра.",
      "hide-chat-window": "Скрыть окно чата",
      "inactive": "Неактивный",
      "reload": "Перезагрузить страницу",
      "show-chat-window": "Показать окно чата",
      "unhighlight": "Удалить выделение"
    },
    "collapsable-tile": {
      "collapse": "свернуть",
      "compress": "сжать",
      "expand": "развернуть",
      "header-snap-back": "Закройте, чтобы вернуться в боковое меню",
      "reminders": "Напоминания"
    },
    "color": {
      "blue": "Синий",
      "cyan": "Голубой",
      "gray": "Серый",
      "green": "Зеленый",
      "light-blue": "Светло-синий",
      "light-green": "Светло-зеленый",
      "magenta": "Сиреневый",
      "no-color": "Нет цвета",
      "orange": "Оранжевый",
      "peach": "Персиковый",
      "pink": "Розовый",
      "purple": "Фиолетовый",
      "white": "Белый",
      "yellow": "Желтый"
    },
    "conference": {
      "close-modal": {
        "no": "Нет",
        "title": "Закрыть конференцию?",
        "warning": "Вы владелец конференции. Выход из конференции приведет к ее завершению. Хотите выйти из нее?",
        "yes": "Да"
      },
      "ownership-modal": {
        "accept": "Принять",
        "refuse": "Отказаться",
        "title": "Принять ответственность?"
      }
    },
    "custom-scripts": {
      "add-new-header-script": "Добавить новый заголовок",
      "add-new-text-script": "Добавить новый сценарий",
      "cancel": "Отмена",
      "confirm-changes": "Подтвердить изменения",
      "delete": "Удалить",
      "delete-header-script": "Удалить заголовок",
      "delete-text-script": "Удалить сценарий",
      "do-not-save": "Не сохранять",
      "edit-custom-scripts": "Изменить пользовательские сценарии",
      "edit-header-script": "Изменить заголовок",
      "edit-text-script": "Изменить сценарий",
      "incorrect-scripts": "Сценарии нельзя загрузить из-за недопустимого(-ых) символа(-ов)",
      "input-header-placeholder": "Введите заголовок здесь",
      "input-text-placeholder": "Введите сценарий здесь",
      "invalid-chars-in-scripts": "Недопустимый(-ые) символ(-ы) в сценарии (-ях): {n}",
      "my-scripts": "Мои сценарии",
      "no-access": "У вас нет доступа к пользовательским сценариям",
      "save": "Сохранить",
      "save-and-close": "Сохранить и закрыть",
      "saved": "Сохраненные пользовательские сценарии",
      "title": "Пользовательские сценарии",
      "undo-delete-header-script": "Отменить удаление заголовка",
      "undo-delete-text-script": "Отменить удаление сценария",
      "unsaved-changes-prompt": "Есть несохраненные изменения ваших сценариев. Хотите сохранить их?"
    },
    "device-settings": {
      "audio-device": "Аудиоустройство",
      "browser-not-supported": "Это приложение не поддерживает устройства в вашем текущем браузере.",
      "close": "Закрыть",
      "ff-warn-message": "Устройства аудиовыхода не поддерживаются вашим браузером",
      "header": "Настройки устройства",
      "mic-device": "Микрофонное устройство",
      "no-device": "вариант недоступен",
      "permission-not-granted": "Разрешение устройства не предоставлено",
      "video-device": "Видеоустройство",
      "video-error-message": "предпросмотр недоступен",
      "video-not-available": "Видео недоступно"
    },
    "digital-voice-and-video": {
      "invite-to-video-call": "Пригласить клиента в видеовызов",
      "invite-to-voice-call": "Пригласить клиента в голосовой вызов",
      "title": "Цифровая речь и видео",
      "title-video": "Цифровое видео",
      "title-voice": "Цифровая речь",
      "video-call": "Видеовызов",
      "voice-call": "Голосовой вызов"
    },
    "dispositions": "Решения по делу",
    "emojis": {
      "clapping-hands": "Хлопанье в ладоши",
      "grinning-face": "Улыбающееся лицо",
      "happy-face": "Счастливое лицо",
      "icon-title": "Эмодзи",
      "panel-title": "Emoji Panel",
      "raising-hands": "Поднятые руки",
      "red-heart": "Красное сердце",
      "sad-face": "Грустное лицо",
      "thumbs-down": "Большой палец вниз",
      "thumbs-up": "Большой палец вверх",
      "waving-hand": "Машущая рука"
    },
    "end-call-confirmation-modal": {
      "cancel": "Отмена",
      "content-video": "Вы собираетесь завершить видеовызов с клиентом.",
      "content-voice": "Вы собираетесь позвонить клиенту.",
      "end-call-button": "Завершить вызов",
      "title": "Завершить вызов?"
    },
    "engagement-tab": {
      "chat": "чат",
      "chat-duration": "продолжительность чата",
      "chat-has-new-messages": "Чат {chatIndex} на {deviceType}, на таймере отсутствия ответа {unrespondedMessageTimer} секунд(-ы), {unrespondedMessages} новых сообщения(-ий).",
      "completed": "ЗАВЕРШЕНО",
      "countdown": "обратный отсчет",
      "current-chat-has-new-messages": "В текущем чате есть новые сообщения: {unrespondedMessages}.",
      "no-engagements": "Сейчас у вас нет взаимодействий",
      "unresponded-timer": "таймер отсутствия ответа"
    },
    "engagements-to-follow-up": "Взаимодействия для последующего контроля",
    "escalate-modal": {
      "chat-escalation": "Передача чата на более высокий уровень"
    },
    "escalation-form": {
      "cancel": "Отмена",
      "description": "Описание",
      "submit": "Отправить"
    },
    "filter": "Фильтр",
    "follow-up": {
      "conversation-id": "Ид. разговора",
      "notes": "Примечания",
      "required-field": "* обозначает обязательное для заполнения поле",
      "time-since-last-engagement": "Время с последнего взаимодействия",
      "time-to-expire": "Время до истечения",
      "updated": "Последующее взаимодействие обновлено"
    },
    "follow-up-disabled": "В вашей очереди нет последующих чатов",
    "follow-up-enabled": "В вашей очереди есть последующие чаты",
    "follow-up-modal": {
      "cancel": "Отмена",
      "follow-up-agent": "Поставить в очередь для отслеживания последующих действий:",
      "notes": "Примечания",
      "submit": "Отправить",
      "title": "Последующие действия"
    },
    "header": {
      "active-chats": "Активные чаты",
      "agent-desktop": "Рабочий стол агента",
      "chats-completed": "Завершенные чаты",
      "chats-in-queue": "Чаты в очереди",
      "dismiss": "Отклонить",
      "ie-warning": "Поддержка Internet Explorer 11 прекращается 30 августа 2021 г. Для оптимального взаимодействия с пользователем воспользуйтесь поддерживаемым браузером (Google Chrome, Microsoft Edge или Mozilla Firefox).",
      "logged-in-at": "Вход в систему выполнен в",
      "menu": {
        "custom-scripts": "Пользовательские сценарии",
        "logout": "Выход",
        "my-settings": "Мои настройки"
      },
      "navigation": "Навигация",
      "nina-coach-completed": "Тренер Нина закончила",
      "public-user-id": "Публичный ид. пользователя",
      "scripts": "Сценарии",
      "skip-main-region": "Перейти сразу в главный регион",
      "skip-main-region-abbr": "Перейти в главное",
      "summary": "Резюме",
      "username": "Имя пользователя"
    },
    "illegal-word": "не может быть использовано в вашем сообщении клиенту. Его необходимо удалить перед отправкой.",
    "interactive-data": {
      "default-label": "Интерактивные данные"
    },
    "internal-chat": {
      "close": "Закрыть",
      "expand-internal-chat": "непрочитанные сообщения. Нажмите Ctrl+Shift+X, чтобы развернуть внутренний чат.",
      "how-to-close": "Чтобы закрыть внутренний чат, другие участники должны сначала выйти из взаимодействия.",
      "internal-chat": "Внутренний чат",
      "minimize": "Свернуть",
      "unread-messages": "непрочитанные сообщения"
    },
    "link": "Ссылка",
    "load-engagement": "Загрузить взаимодействие",
    "lockout": {
      "inactivity-message": "Автоматическая заблокировано из-за бездействия"
    },
    "login-form": {
      "access-denied": "У пользователя нет доступа к Рабочему столу агента.",
      "account-maybe-locked": "Вы пять раз безуспешно пытались пройти аутентификацию. Ваш аккаунт может быть заблокирован, обратитесь к администратору.",
      "agent-id": "Ид. агента",
      "agent-logged-off": "Вы вышли из системы",
      "ajax-authorization-failed": "Ошибка авторизации Ajax. Обратитесь к системному администратору.",
      "already-logged-in-modal-message": "Сейчас имеется активный сеанс агента {agentName}. Закройте его, чтобы открыть новый сеанс.",
      "already-logged-in-modal-title": "Вход в систему уже выполнен",
      "application-error": "Ошибка приложения при попытке входа в систему",
      "application-error-generic": "Ошибка приложения. Обратитесь к системному администратору и повторите попытку.",
      "bad-credentials": "Неверное имя пользователя или неверный пароль",
      "changed-password": "Пароль успешно изменен",
      "default-invalidation-error": "Вы вышли из системы из-за непредвиденной ошибки аутентификации. Войдите снова. Если проблема сохранится, обратитесь к системному администратору.",
      "details": "Подробности",
      "forgot-your-password": "Забыли пароль?",
      "hide-details": "Скрыть подробности",
      "internet-restored": "Подключение к Интернету восстановлено. Повторно войдите в систему",
      "log-in": "Войти",
      "logout-inactivity": "Ваш сеанс завершен из-за бездействия",
      "missing-password": "Пароль отсутствует",
      "missing-username": "Отсутствует имя пользователя",
      "no-internet": "Не подключения к Интернету. Переподключитесь и попробуйте еще раз",
      "password": "Пароль",
      "server-timeout-message": "Произошла ошибка тайм-аута сервера. Обновите и повторите попытку. Спасибо.",
      "server-timeout-title": "Тайм-аут сервера",
      "service-unavailable": "компонент недоступен, обратитесь к системному администратору",
      "show-details": "Показать подробности",
      "timestamp": "Отметка времени",
      "unlock": "Разблокировать",
      "url": "URL",
      "user-account-is-locked": "Ваш аккаунт заблокирован, поскольку вы достигли максимального количества попыток входа в систему",
      "username": "Имя пользователя"
    },
    "logout-button": {
      "logout": "Выход"
    },
    "metrics": {
      "agent-metrics": "Показатели агента",
      "available": "Доступно",
      "busy": "Занят",
      "chat-slots": "Слоты чата",
      "chats-in-my-queue": "chats waiting in \"My Queue\"",
      "chats-in-queue": "Чаты в очереди",
      "free": "Свободен",
      "max": "Макс.",
      "metrics-title": "Сводка доступности агентов",
      "my-queue": "(Моя очередь: {n})",
      "total": "Итого"
    },
    "misc": {
      "prevent-refresh-message": "Уверены, что хотите обновить страницу?"
    },
    "modal": {
      "cancel": "Отмена",
      "close": "Закрыть",
      "okay": "ОК",
      "warn-title": "Предупреждение"
    },
    "my-settings": {
      "layout-preview": "предпросмотр макета",
      "preview": "увеличить изображение предпросмотра"
    },
    "named-entities-form": {
      "submit-disabled-tooltip": "Одно или несколько значений-заполнителей не установлены. Укажите значения всех заполнителей для отправки."
    },
    "next": "Далее",
    "nina-coach": {
      "alert": "оповещение от тренера Нины",
      "all": "Все",
      "all-checkbox": "все флажки установлены",
      "arrow-down-icon": "значок «стрелка вниз»",
      "arrow-right-icon": "значок «стрелка вправо»",
      "clear-search": "очистить поиск",
      "cleared-intents-query": "Запрос поиска намерений очищен",
      "concepts": "Концепции",
      "filter-by": "Фильтровать по",
      "filter-icon": "значок «фильтр»",
      "intents": "Намерения",
      "loading-intents": "загрузка намерений",
      "name": "Имя",
      "nina": "Нина",
      "nina-suggested": "Нина предложила",
      "no-available-concepts": "Нет доступных концепций",
      "no-intents": "Нет намерении",
      "no-opportunity": "Нет возможности",
      "press-tab-key": "нажмите на вкладку для перехода к фильтру",
      "response-text": "Возможный текст ответа",
      "search-icon": "значок «поиск»",
      "search-placeholder": "Искать по намерениям, концепциям, текстам ответов",
      "selected-intent": "Выбранное намерение",
      "send": "Отправить",
      "suggest-intent": "предполагаемое намерение",
      "take-over": "Взять на себя",
      "title": "Тренер Нина",
      "transfer": "Перевод",
      "type": "Тип"
    },
    "notifications": {
      "lost-connection": "Отключено. Переподключение...",
      "lost-connection-no-internet": "Отключено от Интернета. Проверьте подключение к Интернету, обновите страницу и снова войдите в систему",
      "notify-new-engagement": "Поручен новый чат",
      "notify-new-message": "Новое сообщение от клиента"
    },
    "oh-no": "О, нет",
    "password-reset": {
      "confirm-password": "Подтвердите пароль",
      "current-password": "Текущий пароль",
      "message": "Срок действия вашего пароля истек, введите новый пароль",
      "minimum-password-length": "Минимальная длина пароля — не менее двенадцати символов",
      "new-password": "Новый пароль",
      "please-confirm-your-new-password": "Подтвердите новый пароль",
      "please-provide-a-new-password": "Укажите новый пароль",
      "please-provide-your-current-password": "Укажите свой текущий пароль",
      "submit": "Отправить",
      "username": "Имя пользователя",
      "username-is-a-required-field": "«Имя пользователя» — это обязательное для заполнения поле",
      "your-passwords-must-match": "Новый пароль и подтверждение нового пароля отличаются"
    },
    "performance-metrics": {
      "agentUtilization": "Использование слотов чата",
      "assignedCount": "Запрос закреплен",
      "availableUtilization": "Доступность слотов чата",
      "avgConversionProductQuantity": "Штук на конверсию",
      "avgDispositionTime": "Среднее время решения по делу",
      "avgEngagementHandleTime": "Время обработки чата",
      "avgEngagementHandleTimeSLA": "Время обработки чата в % согласно СУО",
      "avgInitialAgentResponseTime": "Средняя время начального ответа",
      "avgOrderValue": "Средняя сумма заказа ($)",
      "busyClickCount": "Сколько раз занят на сеанс в системе",
      "chatsPerLaborHour": "Чатов за рабочий час",
      "conversionCount": "Разговоры",
      "conversionProductQuantity": "Продано штук",
      "cumulativeLaborUtilization": "Совокупное использование рабочей силы",
      "efficiency": "Эффективность",
      "initialResponseWithinSLA": "Начальный ответ в рамках СУО (%)",
      "logged-in-time": "Показатели времени входа в систему",
      "loginDuration": "Время пребывания в системе",
      "percentAssisted": "Оказано содействие %",
      "percentConversion": "Конверсия %",
      "percentEndedByCustomer": "% завершен клиентом",
      "salesPerLaborHour": "Продажи за рабочий час",
      "totalBusyTime": "Общее время в статусе «занято»",
      "utilization": "Использование",
      "volume": "Объем"
    },
    "power-select": {
      "no-results-found": "Результаты не найдены",
      "type-to-filter": "введите текст для фильтрации"
    },
    "previous": "Previous",
    "request-additional-chat": {
      "chat-slots-enabled": "Дополнительные чаты включены",
      "chat-slots-not-full": "Для запроса дополнительных чатов ваши слоты чатов должны быть заполнены",
      "default": "Доступны дополнительные слоты чата",
      "details": "Для смены состояния нажмите пробел",
      "extra-chat-slots-full": "Вы достигли предела дополнительных чатов",
      "not-available": "У вас должен быть статус «Доступен(-на)»",
      "request-more-chats": "Запросить больше чатов"
    },
    "retry": "Повтор",
    "rich-widget": {
      "default-label": "Расширенный виджет",
      "error-render": "Не удается загрузить предпросмотр виджета",
      "load": "Загрузить",
      "preview-title": "Предпросмотр виджета",
      "preview-widget-navigation": "Виджет: {widgetLabel}. Нажмите клавишу «Ввод», чтобы открыть предпросмотр."
    },
    "scripts": "Сценарии",
    "sdk-dispositions": {
      "add-notes": "Добавить примечания",
      "cancel": "Отмена",
      "choose-all": "Выберите все, что подходит.",
      "choose-one": "Выберите наиболее подходящее.",
      "close-engagement": "Закрыть взаимодействие",
      "load-fail": "Ошибка при загрузке решений по делу",
      "loading": "Загрузка...",
      "loading-dispositions": "Получение решений",
      "not-found": "«{filterInput}» не найден",
      "pre-selected": {
        "title": "From previous engagement",
        "tooltip": {
          "clear-all": "Unselect all disposition reasons from previous engagement",
          "select-all": "Select all disposition reasons from previous engagement"
        }
      },
      "search-placeholder": "Поиск решений по делу",
      "select-disposition": "Сначала вам необходимо выбрать решение пол делу.",
      "show-pagination": "Pagination",
      "submit": "Отправить",
      "window-title": "Решения по делу"
    },
    "sdk-scripts": {
      "absent": "Сценарии не настроены. Обратитесь к администратору.",
      "agent-coach": "Тренер агентов",
      "commands-cannot-be-copied": "Невозможно скопировать команды (у вас нажата клавиша Shift)",
      "custom-scripts": "Пользовательский",
      "custom-scripts-here": "Пользовательские сценарии помещаются сюда",
      "empty-script-tree": "Дерево сценариев пусто.",
      "error": {
        "rich-widget-agent-input": "невозможно отправить расширенные виджеты в поле ввода"
      },
      "error-fetching": "Ошибка при получении сценариев ",
      "input-script": {
        "cancel": "Отмена",
        "submit": "Отправить"
      },
      "limited-access": "Доступ к дереву сценариев ограничен.",
      "loading": "Подождите, пока выполняется загрузка сценариев...",
      "script-cannot-be-modified": "Этот сценарий нельзя изменить",
      "system-scripts": "Система",
      "training-keyboard-1": "Нажмите",
      "training-keyboard-2": "для перехода к группам сценариев. Используйте",
      "training-keyboard-3": "клавиши со стрелками для перехода к строкам сценария.",
      "unavailable": "Сценарий недоступен"
    },
    "search": null,
    "settings": "Настройки",
    "site": "Сайт",
    "slideout": {
      "expand-details": "развернуть подробности"
    },
    "sso": {
      "error-contact": "Обратитесь в техническую поддержку. Нажмите ОК, для перехода на страницу входа в систему",
      "error-message": "Ошибка единого входа (SSO).",
      "error-okay": "ОК",
      "logout-message": "Вы вышли из Рабочего стола агента",
      "relogin": "ВОЙДИТЕ ПОВТОРНО"
    },
    "static-transcript": {
      "abc-widget-message-sent": "{agentDisplayText} отправлен",
      "chat-history": "История чата",
      "clear-filter": "очистить фильтр",
      "current": "Текущий",
      "default-abc-widget-message-sent": "Интерактивные данные отправлены",
      "default-rich-widget-message-sent": "*** Запущен расширенный виджет ***",
      "initiated-widget": "***Запущен расширенный виджет: {label} ***",
      "next-result": "следующий результат",
      "previous-result": "предыдущий результат",
      "rich-widget-message-sent": "{agentDisplayText} отправлен",
      "search-scripts": "Поиск сценариев",
      "search-transcripts": "поиск стенограмм",
      "static-transcript": "Статическая стенограмма"
    },
    "submit": "Отправить",
    "summary": {
      "updated-every-10-seconds": "Обновляется каждые 10 секунд"
    },
    "training": "Обучение",
    "training-tab": "Вкладка «Обучение»",
    "transcript-agent-input": {
      "send": "Отправить"
    },
    "transcript-history": null,
    "transcript-window": {
      "abc-widget-message-sent": "{agentDisplayText} отправлен",
      "customer-closed-engagement": "Клиент закрыл окно чата",
      "customer-is-typing": "Клиент набирает текст...",
      "customer-lost-connection": "У клиента пропало подключение",
      "customer-minimized-chat-window": "Клиент свернул чат",
      "customer-restored-chat-window": "Клиент восстановил чат",
      "customer-stopped-typing": "Клиент перестал набирать текст",
      "default-abc-widget-message-sent": "Интерактивные данные отправлены",
      "default-rich-widget-message-sent": "Расширенный виджет отправлен",
      "events": {
        "callEnded": "Вызов завершен",
        "callInvitation": "Приглашение к вызову отправлено",
        "callRequestDeclined": "Запрос вызова отклонен",
        "createRoomFailed": "Не удалось создать комнату",
        "createRoomSuccess": "Комната создана",
        "error": "Device not supported",
        "newCallRequest": "Входящий запрос вызова",
        "newVoiceCallRequest": "Входящий запрос вызова",
        "roomAgentJoin": "Agent has joined the room",
        "roomAgentLeave": "Agent has left the room"
      },
      "interrupted": "прерван, не отправлено",
      "moved-to-persistent": "Клиент успешно перешел в постоянный чат",
      "new-chat-information": "Появилась новая информация в чате. Нажмите Ctrl+Shift+A для объявления о контенте.",
      "no-new-chat-information": "Нет новой информации в чате.",
      "not-sent": "Не удалось отправить сообщение",
      "rich-widget-message-sent": "{agentDisplayText} отправлен",
      "scroll-to-bottom": "Прокрутите вниз",
      "see-new-messages": "Смотреть новые сообщения",
      "started-move-to-persistent": "Клиент переводится в постоянное окно чата",
      "time-delayed": "с задержкой во времени",
      "transcript-line": {
        "auto-close-close": "(Взаимодействие автоматически завершится через {seconds} секунд, если клиент не ответит)",
        "auto-close-warn": "(Предупреждающее сообщение - {seconds} секунд(-ы))",
        "command": "Команда",
        "rich-widget-response": "Ответ расширенного виджета",
        "view-more": "Подробнее",
        "xform": "Datapass"
      }
    },
    "transfer-engagement": {
      "agent-group-placeholder": "Выберите группу агентов",
      "agent-notes": "Примечания агента",
      "agent-placeholder": "Агент не выбран",
      "async-agent-group-info": "Сейчас нет доступных агентов. Клиент будет в очереди до тех пор, пока не освободится агент",
      "available-slots": "Доступные слоты",
      "business-unit-placeholder": "Выберите структурное подразделение",
      "conference": "Конференция",
      "conference-queue-slots": "Слоты очереди конференции",
      "field-reset": "поля сброшены",
      "no-agents-with-attributes-available": "Нет доступных агентов с такими атрибутами",
      "no-matching-agents-found": "Подходящие агенты не найдены",
      "optionally-select": "При желании вы можете напрямую выбрать агента",
      "please-select-a-bunit": "Выберите структ. подразделение из раскрывающегося списка",
      "prioritize-request": "Повысить приоритет запроса",
      "reset": "Сброс",
      "select-a-bunit": "Выберите структ. подразделение",
      "select-an-agent-group": "Выберите группу агентов",
      "select-one-or-more-agent-attributes": "Выберите один или несколько желаемых атрибутов агента",
      "transfer": "Перевод",
      "transfer-queue-slots": "Передать слоты очереди"
    },
    "transfer-window": {
      "transfer-and-conference": "Передача и конференция"
    },
    "twitter-private-reply-confirmation": {
      "message": "Вы действительно хотите отправить ответ как приватное сообщение? Сразу после отправки этого сообщения данная вкладка чата будет закрыта. После ответа на ваше приватное сообщение будет создана новая вкладка личного сообщения Twitter.",
      "title": "Ответить приватным сообщением — подтверждение"
    },
    "video-chat": {
      "accept-button": "Принять",
      "available": "Available for video chat",
      "close-engagement": "Завершить взаимодействие",
      "connected": "Подключено",
      "customer-disconnected": "Клиент завершил вызов",
      "decline-button": "Отклонить",
      "device-settings": "Настройки устройства",
      "end-call-button": "Завершить вызов",
      "end-video-call": "Видеовызов завершен",
      "failed-to-create-video-room": "Не удалось создать комнату видеочата.",
      "incoming-call": "Входящий видеовызов...",
      "incoming-call-in-progress": "Incoming video call in progress",
      "mute": "Отключить звук",
      "retry": "Повтор",
      "un-mute": "Включить звук"
    },
    "visitor-info-tab": {
      "browser-type": "Тип браузера",
      "browser-version": "Версия браузера",
      "business-rule": "Бизнес-правило",
      "chat-id": "Ид. чата",
      "chat-type": "Тип чата",
      "conversation-channel": "Канал разговора",
      "conversation-id": "Ид. разговора",
      "customer-id": "Ид. клиента",
      "customer-information": "Информация о клиенте",
      "customer-name": "Имя клиента",
      "device-type": "Тип устройства",
      "locale": "Локаль",
      "operating-system-type": "Тип операционной системы",
      "page-marker": "Маркер страницы"
    },
    "visitor-information": "Информация о посетителе",
    "voice-chat": {
      "available": "Available for voice chat",
      "incoming-call": "Входящий голосовой вызов...",
      "incoming-call-in-progress": "Incoming voice call in progress"
    },
    "web-tools": "Веб-инструменты",
    "x-tabs": {
      "access-frame": "или щелкните мышью, чтобы получить доступ к фрейму.",
      "how-to-use": "используйте клавиши со стрелками влево и вправо для навигации по вкладкам",
      "left-arrow": "Стрелка влево",
      "press": "Нажмите",
      "right-arrow": "Стрелка вправо"
    }
  }]];
});