define("ember-context-menu/templates/components/context-menu-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "S5FLiK9/",
    "block": "{\"symbols\":[\"subItem\"],\"statements\":[[6,\"span\"],[10,\"class\",\"context-menu__item__label\"],[8],[0,\"\\n\"],[4,\"if\",[[22,[\"item\",\"icon\"]]],null,{\"statements\":[[0,\"    \"],[1,[26,\"fa-icon\",[[22,[\"item\",\"icon\"]]],[[\"class\"],[\"context-menu__item__icon\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[1,[22,[\"item\",\"label\"]],false],[0,\" \"],[4,\"if\",[[22,[\"_amount\"]]],null,{\"statements\":[[0,\"(\"],[1,[20,\"_amount\"],false],[0,\")\"]],\"parameters\":[]},null],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[22,[\"item\",\"subActions\"]]],null,{\"statements\":[[4,\"unless\",[[22,[\"isDisabled\"]]],null,{\"statements\":[[0,\"    \"],[6,\"ul\"],[10,\"class\",\"context-menu--sub\"],[8],[0,\"\\n\"],[4,\"each\",[[22,[\"item\",\"subActions\"]]],null,{\"statements\":[[0,\"        \"],[1,[26,\"context-menu-item\",null,[[\"item\",\"amount\",\"itemIsDisabled\",\"clickAction\"],[[21,1,[]],[22,[\"amount\"]],[22,[\"itemIsDisabled\"]],[22,[\"clickAction\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-context-menu/templates/components/context-menu-item.hbs"
    }
  });
});