define("ember-context-menu/components/context-menu", ["exports", "ember-context-menu/templates/components/context-menu", "ember-invoke-action"], function (_exports, _contextMenu, _emberInvokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: '',
    layout: _contextMenu.default,
    contextMenu: Ember.inject.service('context-menu'),
    isActive: Ember.computed.reads('contextMenu.isActive'),
    renderLeft: Ember.computed.reads('contextMenu.renderLeft'),
    items: Ember.computed.reads('contextMenu.items'),
    _selection: Ember.computed.reads('contextMenu.selection'),
    details: Ember.computed.reads('contextMenu.details'),
    clickEvent: Ember.computed.reads('contextMenu.event'),
    selection: Ember.computed('_selection.[]', function () {
      return [].concat(Ember.get(this, '_selection'));
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.setWormholeTarget();
    },
    setWormholeTarget: function setWormholeTarget() {
      var id = 'wormhole-context-menu';
      var target = document.querySelectorAll("#".concat(id));
      if (target.length === 0) {
        document.body.insertAdjacentHTML('beforeend', "<div id=\"".concat(id, "\"></div>"));
      }
    },
    position: Ember.computed('contextMenu.position.{left,top}', function () {
      var _ref = Ember.get(this, 'contextMenu.position') || {},
        left = _ref.left,
        top = _ref.top;
      return Ember.String.htmlSafe("left: ".concat(left, "px; top: ").concat(top, "px;"));
    }),
    itemIsDisabled: Ember.computed('selection.[]', 'details', function () {
      var selection = Ember.get(this, 'selection') || [];
      var details = Ember.get(this, 'details');
      return function (item) {
        var disabled = Ember.get(item, 'disabled');
        if (!Ember.get(item, 'action') && !Ember.get(item, 'subActions')) {
          return true;
        }
        if (typeof disabled === 'function') {
          return disabled(selection, details);
        }
        return disabled;
      };
    }),
    clickAction: Ember.computed('selection.[]', 'details', function () {
      var selection = Ember.get(this, 'selection');
      var details = Ember.get(this, 'details');
      var event = Ember.get(this, 'clickEvent');
      return function (item) {
        (0, _emberInvokeAction.default)(item, 'action', selection, details, event);
      };
    })
  });
});