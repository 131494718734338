var RichMain = (function () {
    // eslint-disable no-unused-vars,no-prototype-builtins
    var configObj;
    var doc = window.document;
    var log;
    if (typeof window.log === 'undefined') {
        log = function (message, severity) {
            var level = severity || 'info';
            console[level](message);
        };
    } else {
        log = window.log;
    }

    var jsep = (function () { // eslint-disable-line no-unused-vars
        var COMPOUND = 'Compound';
        var IDENTIFIER = 'Identifier';
        var MEMBER_EXP = 'MemberExpression';
        var LITERAL = 'Literal';
        var THIS_EXP = 'ThisExpression';
        var CALL_EXP = 'CallExpression';
        var UNARY_EXP = 'UnaryExpression';
        var BINARY_EXP = 'BinaryExpression';
        var LOGICAL_EXP = 'LogicalExpression';
        var CONDITIONAL_EXP = 'ConditionalExpression';
        var ARRAY_EXP = 'ArrayExpression';

        var PERIOD_CODE = 46; // '.'
        var COMMA_CODE = 44; // ','
        var SQUOTE_CODE = 39; // single quote
        var DQUOTE_CODE = 34; // double quotes
        var OPAREN_CODE = 40; // (
        var CPAREN_CODE = 41; // )
        var OBRACK_CODE = 91; // [
        var CBRACK_CODE = 93; // ]
        var QUMARK_CODE = 63; // ?
        var SEMCOL_CODE = 59; // ;
        var COLON_CODE = 58; // :

        var throwError = function (message, index) {
            var error = new Error(message + ' at character ' + index);
            error.index = index;
            error.description = message;
            throw error;
        };

        // Operations
        // ----------

        // Set `t` to `true` to save space (when minified, not gzipped)
        var t = true;
        // Use a quickly-accessible map to store all of the unary operators
        // Values are set to `true` (it really doesn't matter)
        var unary_ops = {
            '-': t, '!': t, '~': t, '+': t,
        };
        // Also use a map for the binary operations but set their values to their
        // binary precedence for quick reference:
        // see [Order of operations](http://en.wikipedia.org/wiki/Order_of_operations#Programming_language)
        var binary_ops = {
            '||': 1,
            '&&': 2,
            '|': 3,
            '^': 4,
            '&': 5,
            '==': 6,
            '!=': 6,
            '===': 6,
            '!==': 6,
            '<': 7,
            '>': 7,
            '<=': 7,
            '>=': 7,
            '<<': 8,
            '>>': 8,
            '>>>': 8,
            '+': 9,
            '-': 9,
            '*': 10,
            '/': 10,
            '%': 10,
        };
        // Get return the longest key length of any object
        var getMaxKeyLen = function (obj) {
            var max_len = 0;
            var
                len;
            for (var key in obj) { // eslint-disable-line no-restricted-syntax
                if ((len = key.length) > max_len && obj.hasOwnProperty(key)) { // eslint-disable-line no-prototype-builtins,no-cond-assign
                    max_len = len;
                }
            }
            return max_len;
        };
        var max_unop_len = getMaxKeyLen(unary_ops);
        var max_binop_len = getMaxKeyLen(binary_ops);
        // Literals
        // ----------
        // Store the values to return for the various literals we may encounter
        var literals = {
            true: true,
            false: false,
            null: null,
        };
        // Except for `this`, which is special. This could be changed to something like `'self'` as well
        var this_str = 'this';
        // Returns the precedence of a binary operator or `0` if it isn't a binary operator
        var binaryPrecedence = function (op_val) {
            return binary_ops[op_val] || 0;
        };
        // Utility function (gets called from multiple places)
        // Also note that `a && b` and `a || b` are *logical* expressions, not binary expressions
        var createBinaryExpression = function (operator, left, right) {
            var type = (operator === '||' || operator === '&&') ? LOGICAL_EXP : BINARY_EXP;
            return {
                type: type,
                operator: operator,
                left: left,
                right: right,
            };
        };
        // `ch` is a character code in the next three functions
        var isDecimalDigit = function (ch) {
            return (ch >= 48 && ch <= 57); // 0...9
        };
        var isIdentifierStart = function (ch) {
            // `$` and `_`
            // A...Z
            // a...z
            // any non-ASCII that is not an operator
            return (ch === 36) || (ch === 35) || (ch === 95) || (ch >= 48 && ch <= 57) || (ch >= 65 && ch <= 90) || (ch >= 97 && ch <= 122) || (ch >= 128 && !binary_ops[String.fromCharCode(ch)]);
        };
        var isIdentifierPart = function (ch) {
            // `$` and `_`
            // A...Z
            // a...z
            // 0...9
            // any non-ASCII that is not an operator
            return (ch === 36) || (ch === 95) || (ch >= 65 && ch <= 90) || (ch >= 97 && ch <= 122) || (ch >= 48 && ch <= 57) || (ch >= 128 && !binary_ops[String.fromCharCode(ch)]);
        };

        // Parsing
        // -------
        // `expr` is a string with the passed in expression
        var jsep = function (expr) { // eslint-disable-line no-shadow
            // `index` stores the character number we are currently at while `length` is a constant
            // All of the gobbles below will modify `index` as we move along
            var index = 0;
            var charAtFunc = expr.charAt;
            var charCodeAtFunc = expr.charCodeAt;
            var exprI = function (i) {
                return charAtFunc.call(expr, i);
            };
            var exprICode = function (i) {
                return charCodeAtFunc.call(expr, i);
            };
            var length = expr.length;

            // Push `index` up to the next non-space character
            var gobbleSpaces = function () {
                var ch = exprICode(index);
                // space or tab
                while (ch === 32 || ch === 9 || ch === 10 || ch === 13) {
                    ch = exprICode(++index); // eslint-disable-line no-plusplus
                }
            };

            // The main parsing function. Much of this code is dedicated to ternary expressions
            var gobbleExpression = function () {
                var test = gobbleBinaryExpression(); // eslint-disable-line  no-use-before-define
                var consequent;
                var
                    alternate;
                gobbleSpaces();
                if (exprICode(index) === QUMARK_CODE) {
                    // Ternary expression: test ? consequent : alternate
                    index += 1;
                    consequent = gobbleExpression();
                    if (!consequent) {
                        throwError('Expected expression', index);
                    }
                    gobbleSpaces();
                    if (exprICode(index) === COLON_CODE) {
                        index += 1;
                        alternate = gobbleExpression();
                        if (!alternate) {
                            throwError('Expected expression', index);
                        }
                        return {
                            type: CONDITIONAL_EXP,
                            test: test,
                            consequent: consequent,
                            alternate: alternate,
                        };
                    }
                    throwError('Expected :', index);
                }
                return test;
            };

            // Search for the operation portion of the string (e.g. `+`, `===`)
            // Start by taking the longest possible binary operations (3 characters: `===`, `!==`, `>>>`)
            // and move down from 3 to 2 to 1 character until a matching binary operation is found
            // then, return that binary operation
            var gobbleBinaryOp = function () {
                gobbleSpaces();
                var to_check = expr.substr(index, max_binop_len);
                var
                    tc_len = to_check.length;
                while (tc_len > 0) {
                    // Don't accept a binary op when it is an identifier.
                    // Binary ops that start with a identifier-valid character must be followed
                    // by a non identifier-part valid character
                    if (binary_ops.hasOwnProperty(to_check) && ( // eslint-disable-line no-prototype-builtins
                        !isIdentifierStart(exprICode(index)) || (index + to_check.length < expr.length && !isIdentifierPart(exprICode(index + to_check.length)))
                    )) {
                        index += tc_len;
                        return to_check;
                    }
                    to_check = to_check.substr(0, --tc_len);
                }
                return false;
            };

            // This function is responsible for gobbling an individual expression,
            // e.g. `1`, `1+2`, `a+(b*2)-Math.sqrt(2)`
            var gobbleBinaryExpression = function () {
                var node;
                var biop;
                var prec;
                var stack;
                var biop_info;
                var left;
                var right;
                var i;
                var
                    cur_biop;

                // First, try to get the leftmost thing
                // Then, check to see if there's a binary operator operating on that leftmost thing
                left = gobbleToken();
                biop = gobbleBinaryOp();

                // If there wasn't a binary operator, just return the leftmost node
                if (!biop) {
                    return left;
                }

                // Otherwise, we need to start a stack to properly place the binary operations in their
                // precedence structure
                biop_info = { value: biop, prec: binaryPrecedence(biop) };

                right = gobbleToken();
                if (!right) {
                    throwError('Expected expression after ' + biop, index);
                }
                stack = [left, biop_info, right];

                // Properly deal with precedence using [recursive descent](http://www.engr.mun.ca/~theo/Misc/exp_parsing.htm)
                while ((biop = gobbleBinaryOp())) { // eslint-disable-line no-cond-assign
                    prec = binaryPrecedence(biop);

                    if (prec === 0) {
                        break;
                    }
                    biop_info = { value: biop, prec: prec };

                    cur_biop = biop;
                    // Reduce: make a binary expression from the three topmost entries.
                    while ((stack.length > 2) && (prec <= stack[stack.length - 2].prec)) {
                        right = stack.pop();
                        biop = stack.pop().value;
                        left = stack.pop();
                        node = createBinaryExpression(biop, left, right);
                        stack.push(node);
                    }

                    node = gobbleToken();
                    if (!node) {
                        throwError('Expected expression after ' + cur_biop, index);
                    }
                    stack.push(biop_info, node);
                }

                i = stack.length - 1;
                node = stack[i];
                while (i > 1) {
                    node = createBinaryExpression(stack[i - 1].value, stack[i - 2], node);
                    i -= 2;
                }
                return node;
            };

            // An individual part of a binary expression:
            // e.g. `foo.bar(baz)`, `1`, `"abc"`, `(a % 2)` (because it's in parenthesis)
            var gobbleToken = function () {
                var ch;
                var to_check;
                var
                    tc_len;

                gobbleSpaces();
                ch = exprICode(index);

                if (isDecimalDigit(ch) || ch === PERIOD_CODE) {
                    // Char code 46 is a dot `.` which can start off a numeric literal
                    return gobbleNumericLiteral();
                }
                if (ch === SQUOTE_CODE || ch === DQUOTE_CODE) {
                    // Single or double quotes
                    return gobbleStringLiteral();
                }
                if (ch === OBRACK_CODE) {
                    return gobbleArray();
                }
                to_check = expr.substr(index, max_unop_len);
                tc_len = to_check.length;
                while (tc_len > 0) {
                    // Don't accept an unary op when it is an identifier.
                    // Unary ops that start with a identifier-valid character must be followed
                    // by a non identifier-part valid character
                    if (unary_ops.hasOwnProperty(to_check) && ( // eslint-disable-line no-prototype-builtins
                        !isIdentifierStart(exprICode(index)) || (index + to_check.length < expr.length && !isIdentifierPart(exprICode(index + to_check.length)))
                    )) {
                        index += tc_len;
                        return {
                            type: UNARY_EXP,
                            operator: to_check,
                            argument: gobbleToken(),
                            prefix: true,
                        };
                    }
                    to_check = to_check.substr(0, --tc_len);
                }

                if (isIdentifierStart(ch) || ch === OPAREN_CODE) { // open parenthesis
                    // `foo`, `bar.baz`
                    return gobbleVariable();
                }

                return false;
            };
            // Parse simple numeric literals: `12`, `3.4`, `.5`. Do this by using a string to
            // keep track of everything in the numeric literal and then calling `parseFloat` on that string
            var gobbleNumericLiteral = function () {
                var number = '';
                var ch;
                var
                    chCode;
                while (isDecimalDigit(exprICode(index))) {
                    number += exprI(index++);
                }

                if (exprICode(index) === PERIOD_CODE) { // can start with a decimal marker
                    number += exprI(index++);

                    while (isDecimalDigit(exprICode(index))) {
                        number += exprI(index++);
                    }
                }

                ch = exprI(index);
                if (ch === 'e' || ch === 'E') { // exponent marker
                    number += exprI(index++);
                    ch = exprI(index);
                    if (ch === '+' || ch === '-') { // exponent sign
                        number += exprI(index++);
                    }
                    while (isDecimalDigit(exprICode(index))) { // exponent itself
                        number += exprI(index++);
                    }
                    if (!isDecimalDigit(exprICode(index - 1))) {
                        throwError('Expected exponent (' + number + exprI(index) + ')', index);
                    }
                }

                chCode = exprICode(index);
                // Check to make sure this isn't a variable name that start with a number (123abc)
                if (isIdentifierStart(chCode)) {
                    throwError('Variable names cannot start with a number (' + number + exprI(index) + ')', index);
                } else if (chCode === PERIOD_CODE) {
                    throwError('Unexpected period', index);
                }

                return {
                    type: LITERAL,
                    value: parseFloat(number),
                    raw: number,
                };
            };

            // Parses a string literal, staring with single or double quotes with basic support for escape codes
            // e.g. `"hello world"`, `'this is\nJSEP'`
            var gobbleStringLiteral = function () {
                var str = '';
                var quote = exprI(index++);
                var closed = false;
                var
                    ch;

                while (index < length) {
                    ch = exprI(index++);
                    if (ch === quote) {
                        closed = true;
                        break;
                    } else if (ch === '\\') {
                        // Check for all of the common escape codes
                        ch = exprI(index++);
                        switch (ch) {
                        case 'n':
                            str += '\n';
                            break;
                        case 'r':
                            str += '\r';
                            break;
                        case 't':
                            str += '\t';
                            break;
                        case 'b':
                            str += '\b';
                            break;
                        case 'f':
                            str += '\f';
                            break;
                        case 'v':
                            str += '\x0B';
                            break;
                        default:
                            str += ch;
                        }
                    } else {
                        str += ch;
                    }
                }

                if (!closed) {
                    throwError('Unclosed quote after "' + str + '"', index);
                }

                return {
                    type: LITERAL,
                    value: str,
                    raw: quote + str + quote,
                };
            };

            // Gobbles only identifiers
            // e.g.: `foo`, `_value`, `$x1`
            // Also, this function checks if that identifier is a literal:
            // (e.g. `true`, `false`, `null`) or `this`
            var gobbleIdentifier = function () {
                var ch = exprICode(index);
                var start = index;
                var
                    identifier;

                if (isIdentifierStart(ch)) {
                    index += 1;
                } else {
                    throwError('Unexpected ' + exprI(index), index);
                }

                while (index < length) {
                    ch = exprICode(index);
                    if (isIdentifierPart(ch)) {
                        index += 1;
                    } else {
                        break;
                    }
                }
                identifier = expr.slice(start, index);

                if (literals.hasOwnProperty(identifier)) { // eslint-disable-line no-prototype-builtins
                    return {
                        type: LITERAL,
                        value: literals[identifier],
                        raw: identifier,
                    };
                }
                if (identifier === this_str) {
                    return { type: THIS_EXP };
                }
                return {
                    type: IDENTIFIER,
                    name: identifier,
                };
            };

            // Gobbles a list of arguments within the context of a function call
            // or array literal. This function also assumes that the opening character
            // `(` or `[` has already been gobbled, and gobbles expressions and commas
            // until the terminator character `)` or `]` is encountered.
            // e.g. `foo(bar, baz)`, `my_func()`, or `[bar, baz]`
            var gobbleArguments = function (termination) {
                var ch_i;
                var args = [];
                var node;
                var
                    closed = false;
                var separator_count = 0;
                while (index < length) {
                    gobbleSpaces();
                    ch_i = exprICode(index);
                    if (ch_i === termination) { // done parsing
                        closed = true;
                        index += 1;
                        if (termination === CPAREN_CODE && separator_count && separator_count >= args.length) {
                            throwError('Unexpected token ' + String.fromCharCode(termination), index);
                        }
                        break;
                    } else if (ch_i === COMMA_CODE) { // between expressions
                        index += 1;
                        separator_count++;
                        if (separator_count !== args.length) { // missing argument
                            if (termination === CPAREN_CODE) {
                                throwError('Unexpected token ,', index);
                            } else if (termination === CBRACK_CODE) {
                                for (var arg = args.length; arg < separator_count; arg += 1) {
                                    args.push(null);
                                }
                            }
                        }
                    } else {
                        node = gobbleExpression();
                        if (!node || node.type === COMPOUND) {
                            throwError('Expected comma', index);
                        }
                        args.push(node);
                    }
                }
                if (!closed) {
                    throwError('Expected ' + String.fromCharCode(termination), index);
                }
                return args;
            };

            // Gobble a non-literal variable name. This variable name may include properties
            // e.g. `foo`, `bar.baz`, `foo['bar'].baz`
            // It also gobbles function calls:
            // e.g. `Math.acos(obj.angle)`
            var gobbleVariable = function () {
                var ch_i;
                var
                    node;
                ch_i = exprICode(index);

                if (ch_i === OPAREN_CODE) {
                    node = gobbleGroup();
                } else {
                    node = gobbleIdentifier();
                }
                gobbleSpaces();
                ch_i = exprICode(index);
                while (ch_i === PERIOD_CODE || ch_i === OBRACK_CODE || ch_i === OPAREN_CODE) {
                    index++;
                    if (ch_i === PERIOD_CODE) {
                        gobbleSpaces();
                        node = {
                            type: MEMBER_EXP,
                            computed: false,
                            object: node,
                            property: gobbleIdentifier(),
                        };
                    } else if (ch_i === OBRACK_CODE) {
                        node = {
                            type: MEMBER_EXP,
                            computed: true,
                            object: node,
                            property: gobbleExpression(),
                        };
                        gobbleSpaces();
                        ch_i = exprICode(index);
                        if (ch_i !== CBRACK_CODE) {
                            throwError('Unclosed [', index);
                        }
                        index++;
                    } else if (ch_i === OPAREN_CODE) {
                        // A function call is being made; gobble all the arguments
                        node = {
                            type: CALL_EXP,
                            arguments: gobbleArguments(CPAREN_CODE),
                            callee: node,
                        };
                    }
                    gobbleSpaces();
                    ch_i = exprICode(index);
                }
                return node;
            };

            // Responsible for parsing a group of things within parentheses `()`
            // This function assumes that it needs to gobble the opening parenthesis
            // and then tries to gobble everything within that parenthesis, assuming
            // that the next thing it should see is the close parenthesis. If not,
            // then the expression probably doesn't have a `)`
            var gobbleGroup = function () { // eslint-disable-line consistent-return
                index += 1;
                var node = gobbleExpression();
                gobbleSpaces();
                if (exprICode(index) === CPAREN_CODE) {
                    index += 1;
                    return node;
                }
                throwError('Unclosed (', index);
            };

            // Responsible for parsing Array literals `[1, 2, 3]`
            // This function assumes that it needs to gobble the opening bracket
            // and then tries to gobble the expressions as arguments.
            var gobbleArray = function () {
                index++;
                return {
                    type: ARRAY_EXP,
                    elements: gobbleArguments(CBRACK_CODE),
                };
            };

            var nodes = [];
            var ch_i;
            var
                node;

            while (index < length) {
                ch_i = exprICode(index);

                // Expressions can be separated by semicolons, commas, or just inferred without any
                // separators
                if (ch_i === SEMCOL_CODE || ch_i === COMMA_CODE) {
                    index += 1; // ignore separators
                } else {
                    // Try to gobble each expression individually
                    if ((node = gobbleExpression())) { // eslint-disable-line no-lonely-if,no-cond-assign
                        nodes.push(node);
                        // If we weren't able to find a binary expression and are out of room, then
                        // the expression passed in probably has too much
                    } else if (index < length) {
                        throwError('Unexpected "' + exprI(index) + '"', index);
                    }
                }
            }

            // If there's only one expression just try returning the expression
            if (nodes.length === 1) {
                return nodes[0];
            }
            return {
                type: COMPOUND,
                body: nodes,
            };
        };

        // To be filled in by the template
        jsep.version = '<%= version %>';
        jsep.toString = function () {
            return 'JavaScript Expression Parser (JSEP) v' + jsep.version;
        };

        /**
         * @method jsep.addUnaryOp
         * @param {string} op_name The name of the unary op to add
         * @return jsep
         */
        jsep.addUnaryOp = function (op_name) {
            max_unop_len = Math.max(op_name.length, max_unop_len);
            unary_ops[op_name] = t;
            return this;
        };

        /**
         * @method jsep.addBinaryOp
         * @param {string} op_name The name of the binary op to add
         * @param {number} precedence The precedence of the binary op (can be a float)
         * @return jsep
         */
        jsep.addBinaryOp = function (op_name, precedence) {
            max_binop_len = Math.max(op_name.length, max_binop_len);
            binary_ops[op_name] = precedence;
            return this;
        };

        /**
         * @method jsep.addLiteral
         * @param {string} literal_name The name of the literal to add
         * @param {*} literal_value The value of the literal
         * @return jsep
         */
        jsep.addLiteral = function (literal_name, literal_value) {
            literals[literal_name] = literal_value;
            return this;
        };

        /**
         * @method jsep.removeUnaryOp
         * @param {string} op_name The name of the unary op to remove
         * @return jsep
         */
        jsep.removeUnaryOp = function (op_name) {
            delete unary_ops[op_name];
            if (op_name.length === max_unop_len) {
                max_unop_len = getMaxKeyLen(unary_ops);
            }
            return this;
        };

        /**
         * @method jsep.removeAllUnaryOps
         * @return jsep
         */
        jsep.removeAllUnaryOps = function () {
            unary_ops = {};
            max_unop_len = 0;

            return this;
        };

        /**
         * @method jsep.removeBinaryOp
         * @param {string} op_name The name of the binary op to remove
         * @return jsep
         */
        jsep.removeBinaryOp = function (op_name) {
            delete binary_ops[op_name];
            if (op_name.length === max_binop_len) {
                max_binop_len = getMaxKeyLen(binary_ops);
            }
            return this;
        };

        /**
         * @method jsep.removeAllBinaryOps
         * @return jsep
         */
        jsep.removeAllBinaryOps = function () {
            binary_ops = {};
            max_binop_len = 0;

            return this;
        };

        /**
         * @method jsep.removeLiteral
         * @param {string} literal_name The name of the literal to remove
         * @return jsep
         */
        jsep.removeLiteral = function (literal_name) {
            delete literals[literal_name];
            return this;
        };

        /**
         * @method jsep.removeAllLiterals
         * @return jsep
         */
        jsep.removeAllLiterals = function () {
            literals = {};

            return this;
        };

        return jsep;
    }());

    /*
    function getcss3prop(cssprop) {
        var css3vendors = ['', '-moz-', '-webkit-', '-o-', '-ms-', '-khtml-'];
        var root = document.documentElement;

        function camelCase(str) {
            return str.replace(/\-([a-z])/gi, function (match, p1) {
                return p1.toUpperCase();
            });
        }

        for (var i = 0; i < css3vendors.length; i++) {
            var css3propcamel = camelCase(css3vendors[i] + cssprop);
            if (css3propcamel.substr(0, 2) == 'Ms') // if property starts with 'Ms'
            {
                css3propcamel = 'm' + css3propcamel.substr(1);
            } // Convert 'M' to lowercase
            if (css3propcamel in root.style) return css3propcamel;
        }
        return undefined;
    }

    var transformprop = getcss3prop('transform');
    */

    function isSupportedTouch() {
    	return "ontouchstart" in document.documentElement;
    }

    function $bind(o, m) {
        var f = function () {
            return f.method.apply(f.scope, arguments);
        };
        f.scope = o;
        f.method = m;
        return f;
    }

    /**
      * card deck now handles native swipe gesture in Touch devices.
    **/

	function SwipeListener(element, callback, options) {
		this.touchstartX = 0;
		this.touchstartY = 0;
		this.gestureZone = element;
		this.cb = callback;
		options = options || {};
		var pageWidth = window.innerWidth || document.body.clientWidth;
		this.threshold = options.threshold || Math.max(1,Math.floor(0.01 * (pageWidth)));
		this.diagonalLimit = options.diagonalLimit || Math.tan(45 * 1.5 / 180 * Math.PI); // eslint-disable-line no-mixed-operators
		this.touchStartHandler = this.handleTouchStart.bind(this);
		this.touchEndHandler = this.handleTouchEnd.bind(this);
		element.addEventListener('touchstart',this.touchStartHandler);
		element.addEventListener('touchend',this.touchEndHandler);
	}

	SwipeListener.prototype = {
		handleTouchStart: function (e) {
			this.touchstartX = e.changedTouches[0].screenX;
			this.touchstartY = e.changedTouches[0].screenY;
		},
		handleTouchEnd: function (e) {
			var touchendX = e.changedTouches[0].screenX;
			var touchendY = e.changedTouches[0].screenY;
			var deltaX = touchendX - this.touchstartX;
		    var deltaY = touchendY - this.touchstartY;
		    var yx = Math.abs(deltaY / deltaX);
		    if (Math.abs(deltaX) > this.threshold || Math.abs(deltaY) > this.threshold) {
		        if (yx <= this.diagonalLimit) {
		            if (deltaX < 0) {
		                this.cb("right", e);
		            } else {
		                this.cb("left", e);
		            }
		        }
		    }
		},
		destroy: function() {
			this.gestureZone.removeEventListener('touchstart',this.touchStartHandler);
			this.gestureZone.removeEventListener('touchend',this.touchEndHandler);
		}
	};
	/**
	  * @param el {HTMLElement} element which contains childs that needs to be visbile when user click next or prev
	  * @param wInst {Control} instance of control class
	**/
    function intializeScrollable(el, wInst) { // eslint-disable-line no-unused-vars
    	var start;

    	if (isSupportedTouch() && wInst.useSwipe) {
    		wInst.swipe = new SwipeListener(el, function(action, event) {
    			if (action == "right") {
    				wInst.diffx = wInst.getScrollRightSize();
		            mouseUp(event);
    			} else {
    				wInst.diffx = wInst.getScrollLeftSize();
		            mouseUp(event);
    			}
    		});

		}
        wInst.mouseDownHandler = $bind(wInst, function (e) {
            wInst.diffx = 0;
            wInst.diffy = 0;
            e.preventDefault();
            e.stopPropagation();
        });
        wInst.mouseUpPrevHandler = $bind(wInst, function (e) {
            wInst.diffx = wInst.getScrollLeftSize();
            mouseUp(e);
        });

        wInst.mouseUpNextHandler = $bind(wInst, function (e) {
            wInst.diffx = wInst.getScrollRightSize();

            mouseUp(e);
        });


        function mouseUp(e) {
             var start = el.scrollLeft,
		        change = wInst.diffx - start,
		        startTime = performance.now(),
		        val, now, elapsed, t;

		    function animateScroll() {
		        now = performance.now();
		        elapsed = (now - startTime)/1000;
		        t = (elapsed/0.7);

		        el.scrollLeft = start + change * easeInOutQuad(t);

		        if( t < 1 ) {
		            window.requestAnimationFrame(animateScroll);
		        }
		        else {
		            f();
		        }
		    }
		    window.requestAnimationFrame(animateScroll);
            e.preventDefault();
            e.stopPropagation();
        }

        function easeInOutQuad(t) { return t<0.5 ? 2*t*t : -1+(4-2*t)*t; }

        function f() {
            if ((el.scrollLeft + el.clientWidth) >= (el.scrollWidth - 1)) {
                wInst.next.classList.add('disabled');
                wInst.nextDisabled = true;
                start = 0;
            } else if (wInst.nextDisabled) {
                wInst.next.classList.remove('disabled');
                wInst.nextDisabled = false;
            }
            if (el.scrollLeft >= 0 && wInst.prevDisabled) {
                wInst.prevDisabled = false;
                wInst.prev.classList.remove('disabled');
            }
            if (el.scrollLeft <= 1) {
                wInst.prevDisabled = true;
                wInst.prev.classList.add('disabled');
                start = 0;
            }
        }

        wInst.prev = el.parentElement.querySelector('#prev');

        wInst.next = el.parentElement.querySelector('#next');
        wInst.refresh = function () {
            f();
        };
        f();

        wInst.prev.addEventListener('mousedown', wInst.mouseDownHandler);
        wInst.prev.addEventListener('mouseup', wInst.mouseUpPrevHandler);
        wInst.next.addEventListener('mousedown', wInst.mouseDownHandler);
        wInst.next.addEventListener('mouseup', wInst.mouseUpNextHandler);
    }

    /**
     * Finds closest element to the Html Element
     * with passed class selector(.nuan-row) param(sel)
     * @param el {HTMLElement} Html Element
     * @param sel {string} A class Selector ex. .nuan-row
     * @returns {HTMLElement|null|Element}
     */
    function elementClosest(el, sel) { // eslint-disable-line no-unused-vars
        if (typeof el.closest === 'function') {
            return el.closest(sel) || null;
        }

        while (el) {
            var selector = sel.substr(1);
            if (el.classList.contains(selector)) {
                return el;
            }
            el = el.parentElement;
        }
        return null;
    }

    function getWidgetRightScrollSize(cont, childEls) {
        var scrollLeft = cont.scrollLeft;
        var offsetWidth = cont.offsetWidth;
        var w = parseInt(window.getComputedStyle(childEls[0]).width, 10);
        for (var ci = childEls.length - 1; ci >= 0; ci--) {
            var oLeft = childEls[ci].offsetLeft;
            var visible = ((oLeft - scrollLeft) + w) <= offsetWidth;
            if (visible) {
               if (ci < childEls.length - 1) {
                    return childEls[ci + 1].offsetLeft;
               }
               break;
            }
        }
        return scrollLeft;
    }

    function getWidgetLeftScrollSize(cont, childEls) {
        var scrollLeft = cont.scrollLeft;
        var offsetWidth = cont.offsetWidth;
        var w = parseInt(window.getComputedStyle(childEls[0]).width);
        for (var ci = 0; ci < childEls.length; ci++) {
            var oLeft = childEls[ci].offsetLeft;
            var visible = oLeft >= scrollLeft;
            if (visible && ci > 0) {
                var offset = w - (scrollLeft - childEls[ci - 1].offsetLeft);
                return scrollLeft - (offsetWidth - offset);
            }
        }
        return scrollLeft;
    }

     if (!String.prototype.startsWith) {
          Object.defineProperty(String.prototype, 'startsWith', {
              value: function(search, rawPos) {
                  var pos = rawPos > 0 ? rawPos|0 : 0;
                  return this.substring(pos, pos + search.length) === search;
              }
          });
      }

    function Utils() {
}

Utils.isObject = function (object) {
    return Object(object) === object && !(object instanceof Array);
};

Utils.copy = function (source, target) {
    Object.getOwnPropertyNames(source)
        .forEach(function (propKey) {
            var desc = Object.getOwnPropertyDescriptor(source, propKey);
            Object.defineProperty(target, propKey, desc);
        });
    return target;
};

Utils.extend = function (sub_class, super_class) {
    sub_class.superProto = super_class.prototype;
    sub_class.prototype = Object.create(sub_class.superProto);
    sub_class.prototype.constructor = sub_class;
    sub_class.super = super_class;
};

Utils.executeParser = function (node, main) {
    switch (node.type) {
    case 'BinaryExpression':
    case 'LogicalExpression':
        return Utils.valueComparator(node.operator, Utils.executeParser(node.left, main), Utils.executeParser(node.right, main));
    case 'Literal':
        return node.value;
    case 'MemberExpression':
        return Utils.valueMapper(node, main);
    case 'CallExpression':
        return Utils.functionParser(node, main);
    case 'UnaryExpression':
        return !Utils.executeParser(node.argument, main);
        // no default
    }
    return null;
};

Utils.valueComparator = function (operator, l, r) {
    switch (operator) {
    case '==':
        return l === r;
    case '>':
        return l > r;
    case '>=':
        return l >= r;
    case '<':
        return l < r;
    case '<=':
        return l <= r;
    case '&&':
        return l && r;
    case '||':
        return l || r;
        // no default
    }
    return null;
};
Utils.functionParser = function (node, main) {
    if (node.callee.name === 'isEmpty') {
        return !Utils.executeParser(node.arguments['0'], main);
    }
    return null;
};

Utils.valueMapper = function (node, main) {
    var obj = node.object;
    var type = '';
    if (obj) {
        type = obj.type;
    }

    if (type == 'MemberExpression') {
        var ret = Utils.valueMapper(obj, main);
        if (ret instanceof Input || ret instanceof TextArea) {
            return ret.text;
        }
        if (ret instanceof ToggleButton) {
            return ret.active;
        }
        if (ret instanceof QuickReplyButton) {
            var propName = node.property.name;
            if (propName == 'selectedText') {
                return ret.selectedText;
            }
            if (propName == 'selectedValue') {
                return ret.selectedValue;
            }
            if (propName == 'snapShot') {
                return ret.getSnapShot();
            }
            return ret.selectedIndex;
        }
        if (ret instanceof Image) {
            if (node.property.name == 'selectedImage') {
                return ret.selectedImage;
            }
            return ret.selectedIndex;
        }
        if (ret instanceof Rating) {
            if (node.property.name == 'ratingText') {
                return ret.ratingText;
            }
            if (node.property.name == 'selectedText') {
                return ret.selectedText;
            }
            return ret.selectedRating;
        }
        if (ret instanceof Switch) {
            return ret.active;
        }
        if (ret instanceof FormattedLinkText) {
            return ret[node.property.name];
        }
        if (ret instanceof Slider) {
            if (node.property.name == 'selectedTextValue') {
                return ret.selectedTickValue;
            }
            return ret.selectedTick;
        }
        if (ret instanceof RadioButton || ret instanceof Select || ret instanceof ListSelectGroup) {
            if (node.property.name == 'selectedText') {
                return ret.selectedText;
            }
            return ret.selectedIndex;
        }
        if (ret instanceof CheckBox) {
            if (node.property.name == 'checkedItemsText') {
                return ret.selectedText;
            }
            if (node.property.name == 'item') {
                return ret;
            }
            var index = parseInt(node.property.name, 10);
            return ret.checked[index];
        }
        if (ret instanceof Calendar) {
            if (node.property.name == 'selectedDate') {
                return ret.selectedDate;
            }
            if (node.property.name == 'rangeStart') {
                return ret.rangeStartDate;
            }
            if (node.property.name == 'rangeEnd') {
                return ret.rangeEndDate;
            }
        } else if (ret instanceof ColumnSet) {
            return ret.getColumnForMapper(node.property.name);
        } else if (ret instanceof RowSet) {
            return ret.getRowForMapper(node.property.name);
        } else if (ret instanceof Card) {
            return ret.columnSet;
        } else if (ret instanceof Object && (ret.type == 'Column' || ret.type == 'Row')) {
            for (var k = 0; k < ret.items.length; k++) {
                if (node.property.name == ret.items[k].id) {
                    return ret.items[k];
                }
            }
        } else if (ret instanceof FileUpload) {
            return ret.files.length > 0 ? ret.files : null;
        } else {
            return ret;
        }
    } else if (type == 'Identifier') {
        var name = obj.name.slice(1);
        var ctrls = StoreFactory.getNodefromStore.call(main.sf, name).getControls();
        var
            ctl;
        for (var i = 0; i < ctrls.length; i++) {
            ctl = ctrls[i].getControl(node.property.name);
            if (ctl) {
                return ctl;
            }
        }
    } else if (node.type == 'Identifier') {
        return StoreFactory.getFromStore.call(main.sf, 'CS').getConstant(node.name);
    }
    return null;
};

Utils.parseValueMapperTemplate = function (text, main) {
    function replacer(match, p1) {
        if (p1 == 'nodeIndex') {
            return StoreFactory.getNodeIndex.call(main.sf, main.dv.currentVisibleNode.id);
        }
        return Utils.valueMapper(jsep('#' + p1), main);
    }

    if (text && text.indexOf('{#') != -1) {
        return text.replace(/{#(.*?)}/g, replacer);
    }
    return text;
};
/**
* When parsing the json to build the html, control values (ex:quick reply text) can be fetched from constant.
**/
Utils.parseValueFromConstant = function (key, main) {
    if (key && typeof key == "string" && key.startsWith('#')) {
        return StoreFactory.getFromStore.call(main.sf, 'CS').getConstant(key);
    }
    return key;
};
 // eslint-disable-line semi,quotes,no-trailing-spaces,no-unused-expressions
    function EventDispatcher() {
    this.clickHandlers = {};
    this.focusHandlers = {};
    this.textHandlers = {};
    this.selectHandlers = {};
}

EventDispatcher.prototype.registerEventHandler = function (control, type) {
    if (type === 'onClick') {
        this.clickHandlers[control.id] = control;
    } else if (type === 'onInput') {
        this.textHandlers[control.id] = control;
    } else if (type === 'onChange') {
        this.selectHandlers[control.id] = control;
    }
};

EventDispatcher.prototype.clearListeners = function () {
    this.clickHandlers = {};
    this.focusHandlers = {};
};

EventDispatcher.prototype.onClick = function (e) {
    var id = e.target.id || e.target.getAttribute('data-id');
    if (id) {
        if (id.indexOf('-') !== -1) {
            id = id.substr(0, id.indexOf('-'));
        }
        if (this.clickHandlers[id]) this.clickHandlers[id].onClickEvent(e);
    }
    e.stopPropagation();
};

EventDispatcher.prototype.onInput = function (e) {
    var id = e.target.id;
    if (id && this.textHandlers[id]) {
        this.textHandlers[id].onInputEvent(e);
    }
    e.stopPropagation();
};

EventDispatcher.prototype.onChange = function (e) {
    var id = e.target.id;
    if (id && this.selectHandlers[id]) {
        this.selectHandlers[id].onSelectEvent(e);
    }
    e.stopPropagation();
};

EventDispatcher.prototype.onTouch = function (e) {
    var id = e.target.id; // eslint-disable-line no-unused-vars

    e.stopPropagation();
};

EventDispatcher.prototype.addDispatcherToContainer = function (container) {
    container.addEventListener('click', this.onClick.bind(this));
    container.addEventListener('input', this.onInput.bind(this));
    container.addEventListener('change', this.onChange.bind(this));
};
 // eslint-disable-line semi,quotes,no-trailing-spaces,no-unused-expressions
    function Control(control, main) {
    if (Utils.isObject(control)) {
        Utils.copy(control, this);
        this.isEnabled = true;
        this.valid = true;
    } else {
        this.ctrls = [];
        var c;
        control.forEach(function (item) {
            c = Control.createControlInstance(item);
            c.main = main;
            this.ctrls.push(c);
        }, this);
    }
    this.isVisible = true;
}

Control.parseControl = function (control, main) {
    if (Utils.isObject(control)) {
        var obj = new Control(control.items, main);
        control.items = undefined;
        Utils.copy(control, obj);
        return obj;
    }
    return null;
};

Control.prototype.executeValidation = function () {
    if (this.ctrls) {
        for (var i = 0; i < this.ctrls.length; i++) {
            if (!this.ctrls[i].validateControl()) {
                return false;
            }
        }
        return true;
    }
    return this.validateControl();
};

Control.prototype.getOutputText = function () {
    return '';
};

Control.prototype.validateControl = function () {
    if (this.validation && this.isVisible) {
        var vals = Object.keys(this.validation);
        for (var i = 0; i < vals.length; i++) {
            if (!this.validate(vals[i], this.validation[vals[i]])) {
                this.markElementInvalid(vals[i]);
                return false;
            }
        }
    }
    var lbl = this.getLabel();
    if (lbl) {
        this.main.th.addToAnswers({
            id: this.id,
            val: encodeURIComponent(this.getOutputText() || ''),
            lbl: encodeURIComponent(lbl),
            type: this.getType(),
            ansID: this.getAnswerID(),
        });
    }
    return true;
};

Control.prototype.getLabel = function () {
    return this.label || this.hiddenLabel;
};

Control.prototype.getType = function () {
    return 0;
};

Control.prototype.getAnswerID = function () {
    return '';
};

Control.prototype.markElementInvalid = function (validatorName) {
    var el = this.main.dv.container.querySelector('#' + this.id);
    this.addInvalid(el);
    var feedbackMessage = this.getErrorTag(el, validatorName);
    if (feedbackMessage) {
        feedbackMessage.classList.add('message-visible');
        feedbackMessage.setAttribute("role","alert");
    }
    this.actualEl = el;
    this.valid = false;
};

Control.prototype.getErrorTag = function (el, name) {
    return el.parentNode.querySelector('[data-validation-message=' + name + ']');
};

Control.prototype.resetErrors = function () {
    var el = this.actualEl || this.main.dv.container.querySelector('#' + this.id);
    this.removeInvalid(el);

    var feedbackMessage = elementClosest(el.parentElement, ".nuan-row").querySelector('.message-visible');
    if (feedbackMessage) {
      feedbackMessage.classList.remove('message-visible');
      feedbackMessage.removeAttribute("role");
    }

    this.valid = true;
    return el;
};

Control.prototype.resetData = function () {
};

Control.prototype.resetRendering = function () {
};

Control.prototype.toggleInvalid = function () {

};

Control.prototype.addInvalid = function () {

};

Control.prototype.removeInvalid = function () {

};

Control.prototype.validate = function () {
    return true;
};

Control.prototype.onVisibilityChange = function () {
    var v = this.isVisible;
    this.isVisible = this.checkVisibleCondition();
    if (v !== this.isVisible) {
        var pelement = elementClosest(this.main.dv.container.querySelector('#' + this.id).parentElement, '.nuan-row');
        pelement.classList.toggle('displayoff');
        if (!this.isVisible) {
          this.resetData();
          if (this.event) {
            this.main.vh.invokeVisibilityEvent(this.event.name);
            this.main.edh.invokeEvent(this.event.name);
          }
          this.resetRendering();
        }
    }
};

Control.prototype.onEnableDisableChange = function () {
    var e = this.isEnabled;
    this.isEnabled = this.checkEnableCondition();
    if (e !== this.isEnabled) {
        var el = this.main.dv.container.querySelector('#' + this.id);
        if (el) el.disabled = !this.isEnabled;
    }
};

Control.prototype.registerHandlers = function () {
    if (this.visible) {
        this.main.vh.addToHandler(this);
    }

    if (this.enabled) {
        this.main.edh.addToHandler(this);
    }
};

Control.prototype.invokeHandlers = function () {
    var name = this.event.name;
    this.main.vh.invokeVisibilityEvent(name);
    this.main.edh.invokeEvent(name);
    this.main.th.handleTransition(name, true);
};

Control.prototype.invokeCancelHandlers = function () {
    var name = this.event.name;
    this.main.vh.invokeVisibilityEvent(name);
    this.main.edh.invokeEvent(name);
    this.main.th.handleTransition(name, false);
};

Control.prototype.invokeResetHandlers = function () {
    this.main.th.resetCurrrentNode();
};

Control.prototype.checkVisibleCondition = function () {
    if (this.visible && this.visible.guard) {
        if (!this.visible.parsed) {
            this.visible.parsed = jsep(this.visible.guard);
        }
        this.isVisible = Utils.executeParser(this.visible.parsed, this.main);
    }
    return this.isVisible;
};

Control.prototype.checkEnableCondition = function () {
    if (this.enabled && this.enabled.guard) {
        if (!this.enabled.parsed) {
            this.enabled.parsed = jsep(this.enabled.guard);
        }
        this.isEnabled = Utils.executeParser(this.enabled.parsed, this.main);
    }
    return this.isEnabled;
};

Control.prototype.disable = function () {
    if (this.ctrls) {
        for (var i = 0; i < this.ctrls.length; i++) {
            this.ctrls[i].disable();
        }
    } else {
        var el = this.main.dv.container.querySelector('#' + this.id);
        if (el) el.disabled = true;
        this.isEnabled = false;
    }
};

Control.prototype.enable = function () {
    if (this.ctrls) {
        for (var i = 0; i < this.ctrls.length; i++) {
            this.ctrls[i].enable();
        }
    } else if (this.isEnabled) {
        var el = this.main.dv.container.querySelector('#' + this.id);
        if (el) {
            el.disabled = false;
        }

        this.isEnabled = true;
    }
};

Control.prototype.getHTML = function (main) {
    if (this.ctrls) {
        var tags = [];
        var item;
        var len = this.ctrls.length;
        for (var i = 0; i < len; i++) {
            item = this.ctrls[i];
            item.first = (i == 0);
            item.last = (i == len - 1);
            tags.push(item.getHTML(main));
        }
        return tags.join('');
    }
    return '';
};

Control.prototype.getControl = function (id) {
    if (this.id === id) {
        return this;
    }
    if (this.ctrls) {
        for (var i = 0; i < this.ctrls.length; i++) {
            if (this.ctrls[i].id === id) {
                return this.ctrls[i];
            }
        }
    }

    return null;
};

Control.prototype.addValidationTags = function () {
    var vals = [];
    var val = this.validation;
    if (val) {
        Object.keys(val).forEach(function (key) {
            vals.push('<small data-validation-message=');
            vals.push(key);
            vals.push('>');
            vals.push(val[key].error);
            vals.push('</small>');
        }, this);
    }
    return vals.join('');
};

Control.prototype.isPlugin = function () {
    if (this.ctrls) {
        for (var i = 0; i < this.ctrls.length; i++) {
            if (this.ctrls[i].isPlugin()) {
                return true;
            }
        }
    }
    return false;
};

Control.prototype.renderPlugin = function () {
    if (this.ctrls) {
        for (var i = 0; i < this.ctrls.length; i++) {
            this.ctrls[i].renderPlugin();
        }
    }
};
Control.prototype.clear = function() {
};

Control.createControlInstance = function (control, main) {
    if (Utils.isObject(control)) {
        switch (control.type) {
        case 'Button':
        case 'ImageButton':
            return new Button(control);
        case 'ToggleButton':
            return new ToggleButton(control);
        case 'QuickReplyButton':
            return new QuickReplyButton(control);
        case 'ColorPicker':
            return new ColorPicker(control);
        case 'Input':
        case 'ShortInput':
            return new Input(control);
        case 'Heading':
            return new Heading(control);
        case 'Paragraph':
            return new Paragraph(control);
        case 'CheckBox':
            return new CheckBox(control);
        case 'RadioButton':
            return new RadioButton(control);
        case 'Select':
            return new Select(control);
        case 'Image':
            return new Image(control);
        case 'ListGroup':
            return new ListGroup(control);
        case 'ListSelectableGroup':
            return new ListSelectGroup(control);
        case 'Calendar':
            return new Calendar(control);
        case 'Card':
            return new Card(control);
        case 'CardDeck':
            return new CardDeck(control);
        case 'TextArea':
            return new TextArea(control);
        case 'Rating':
            return new Rating(control);
        case 'InputGroup':
            return new InputGroup(control);
        case 'ColumnSet':
            return new ColumnSet(control);
        case 'RowSet':
            return new RowSet(control);
        case 'Switch':
            return new Switch(control);
        case 'Box':
            return new Box(control);
        case 'TimePicker':
            return new TimePicker(control);
        case 'LinkPreview':
            return new LinkPreview(control);
        case 'FileUpload':
            return new FileUpload(control);
        case 'CoverImage':
            return new CoverImage(control);
        case 'ButtonGroup':
            return new TabButton(control);
        case 'VideoViewer':
            return new VideoViewer(control);
        case 'Slider':
            return new Slider(control);
        case 'GMap':
            return new GMap(control);
        case 'FormattedLinkText':
            return new FormattedLinkText(control);
        default:
            return Control.parseControl(control, main);
        }
    } else {
        return new Control(control, main);
    }
};

Control.getContStyleProps = function (control) {
    var con = control.context;
    var style = 'style="';
    if (typeof con === 'object') {
        if (con.itemBackground) {
            style += 'background:';
            style += Control.setStyleVal(con.itemBackground);
            style += 'padding:.5em .5em .5em .5em;';
        }

        if (con.itemBorder) {
            style += '';
            style += 'border-color:';
            style += Control.setStyleVal(con.itemBorder);
            style += 'border-radius:';
            style += (con.itemBorderRadius != undefined ? con.itemBorderRadius + 'px;' : '.3rem;');
            style += 'border-width:1px;';
            style += 'padding:.5em .5em .5em .5em;';
            style += Control.getBoxShadow(con);
        }

        if (typeof con.itemPadding !== 'undefined') {
            style += 'padding:';
            style += con.itemPadding;
            style += 'px;';
        }
        if (typeof con.itemPaddingLeft !== 'undefined') {
            style += 'padding-left:';
            style += con.itemPaddingLeft;
            style += 'px;';
        }
        if (typeof con.itemPaddingRight !== 'undefined') {
            style += 'padding-rght:';
            style += con.itemPaddingRight;
            style += 'px;';
        }
        if (con.itemMarginLeft >= 0) {
            style += 'margin-left:';
            style += con.itemMarginLeft;
            style += 'px;';
        }
        if (con.itemMarginRight >= 0) {
            style += 'margin-right:';
            style += con.itemMarginRight;
            style += 'px;';
        }

        control.textAlign = con.textAlign;
        control.textColor = con.text;
        control.textSize = con.textSize;
        control.textStyle = con.textStyle;
    }

    if (control.containerSize) {
        style += 'width:';
        style += control.containerSize;
        style += 'px;';
    }

    if (control.containerSpace) {
        style += 'margin-top:';
        style += control.containerSpace;
        style += 'px;';
    }

    if (control.containerSeperator || control.containerSeparator) {
        style += 'border-color:';
        style += Control.setStyleVal(control.containerSeperator || control.containerSeparator);
        if (control.containerSeperatorSpace || control.containerSeparator) {
            style += 'padding-top:';
            style += control.containerSeperatorSpace || control.containerSeparatorSpace;
            style += 'px;';
        }
    }

    style += '"';
    return style;
};

Control.getBoxShadow = function (con) {
    if (con.boxShadow) {
        var bh = 'box-shadow:';
        var shadow = [con.boxShadow.h + 'px', con.boxShadow.v + 'px', con.boxShadow.blur ? con.boxShadow.blur + 'px' : '',
            con.boxShadow.spread ? con.boxShadow.spread + 'px' : '', con.boxShadow.color];
        bh += Control.setStyleVal(shadow.join(' '));
        return bh;
    }
    return "";
};

Control.setStyleVal = function setVal(val) {
    var v = val;
    v += ';';
    return v;
};

Control.prototype.getStyle = function () {
    var style = 'style="';
    if (this.textAlign) {
        style += 'text-align:';
        style += Control.setStyleVal(this.textAlign);
    }
    if (this.textColor) {
        style += 'color:';
        style += Control.setStyleVal(this.textColor);
    }
    if (this.width) {
        style += 'width:';
        style += Control.setStyleVal(this.width + 'px');
    }
    if (this.height) {
        style += 'height:';
        style += Control.setStyleVal(this.height + 'px');
    }

    if (this.background || this.backgroundColor) {
        style += 'background:';
        style += Control.setStyleVal(this.background || this.backgroundColor);
    }

    if (this.border || this.borderColor) {
        style += 'border-color:';
        style += Control.setStyleVal(this.border || this.borderColor);

        if (this.borderWidth) {
            style += 'border-width:';
            style += this.borderWidth + 'px;';
        } else {
            style += 'border-width:1px;';
        }
        style += 'border-style:solid;';
        style += Control.getBoxShadow(this);
    }


    if (this.borderRadius) {
        style += 'border-radius:';
        style += this.borderRadius + 'px;';
    }
    if (this.cardBottom) {
        style += 'background-color:';
        style += Control.setStyleVal(this.cardBottom);
    }

    if (this.headerBackground) {
        style += 'background-color:';
        style += Control.setStyleVal(this.headerBackground);
    }

    if (this.textSize) {
        style += 'font-size:';
        style += Control.setStyleVal(this.textSize + 'px');
    }
    if (this.textStyle) {
        if (this.textStyle == 'bold') {
            style += 'font-weight:';
        } else {
            style += 'font-style:';
        }
        style += Control.setStyleVal(this.textStyle);
    }

    style += '"';
    return style;
};

Control.prototype.addPadding = function() {
    var s = "";
    if (this) {
        if (this.marginLeft) {
            s = "padding-left:" + this.marginLeft + "px;";
        }
        if (this.marginRight) {
            s += "padding-right:" + this.marginRight + "px;";
        }
    }
    return s;
};


Control.prototype.encodeLabel = function (label) {
    var qObj = this.main.dv.getPresentedQuestion();
    qObj.push({ id: this.id, lbl: encodeURIComponent(label) });
};

Control.prototype.forceFocus = function () {

};

Control.prototype.getAriaOutput = function () {
};

Control.getImageUrl = function(src) {
    var isSrcRelative = src && !src.startsWith("http");
    var isConfigHasImgUrl = configObj && configObj.skinPath;
    if (isConfigHasImgUrl && isSrcRelative) {
           return  configObj.skinPath + src;
    }
    return src;
};
 // eslint-disable-line semi,quotes,no-trailing-spaces,no-unused-expressions
    function Button(control) {
    Button.super.call(this, control);
}

Utils.extend(Button, Control);

Button.prototype.onClickEvent = function () {
    if (!this.isEnabled) return;
    this.clicked = true;
    if (this.actionType == 'cancel') {
        Button.superProto.invokeCancelHandlers.call(this);
    } else if (this.actionType == 'reset') {
        Button.superProto.invokeResetHandlers.call(this);
    } else if (this.event) {
        Button.superProto.invokeHandlers.call(this);
    }
};

Button.prototype.registerHandlers = function () {
    Button.superProto.registerHandlers.call(this);
    var e = this.event;
    if ((e && e.name) || this.actionType == 'reset' || this.type === 'ToggleButton' || this.type === 'QuickReplyButton' || this.type == 'ColorPicker' || this.type == 'TimePicker') {
        this.main.ev.registerEventHandler(this, 'onClick');
    }
};

Button.prototype.getHTML = function () {
    this.registerHandlers();
    this.checkEnableCondition();
    var t = Utils.parseValueFromConstant(this.text, this.main) || this.text;
    return this.getContent(this.id, t);
};

Button.prototype.getContent = function (id, text, selected, index) {
    var but = '<Button ';
    var context = this.context || 'primary';
    var tColor;
    var lColor;
    var oback;
    var oborder;
    but += 'id=';
    but += id;
    but += " role='button' class='nuanbtn ";

    var idStr = 'data-id =' + id;

    switch (this.style) {
    case 'outline':
        but += 'nuanbtn-outline-';
        break;
    case 'block':
        but += 'nuanbtn-block nuanbtn-';
        break;
    case 'outline-block':
        but += 'nuanbtn-block nuanbtn-outline-';
        break;
    default:
        but += 'nuanbtn-';
    }
    if (context instanceof Object) {
        if (context.color && context.color.startsWith('#')) {
            context.background = context.color;
        } else {
            but += context.color || 'primary';
        }
        tColor = context.textColor;
        lColor = context.titleColor;

        if (selected) {
            if (context.selectedColor) {
                oback = context.background;
                context.background = context.selectedColor;
            }
            tColor = context.selectedTextColor || tColor;
            lColor = context.selectedTitleColor || lColor;
            if (context.selectedBorderColor) {
                oborder = context.border;
                context.border = context.selectedBorderColor;
            }
        }

        switch (context.textAlign) {
        case 'left':
            but += ' nuan-text-left';
            break;
            // no default
        }
    } else {
        but += context;
    }

    switch (this.size) {
    case 'large':
        but += ' nuanbtn-lg ';
        break;
    default:
        but += ' nuanbtn-sm ';
    }
    but += this.id;
    if (this.first) {
        but += ' nuanbtn-first';
        if (this.align == 'left') {
            but += ' nuanbtn-first-left';
        }
    }
    if (this.last) {
        but += ' nuanbtn-last';
        if (this.align == 'right') {
            but += ' nuanbtn-last-right';
        }
    }

    but += "'";
    if (context instanceof Object) {
        // but.push(" style='background-color:",backgroundColor,";border-width:",0,"px;' ");
        but += this.getStyle.call(this.context);
        if (selected) {
            if (context.selectedBorderColor) context.border = oborder;
            if (context.selectedColor) context.background = oback;

            but += " aria-pressed='true'";
        }
    }
    if (this.ariaValues && index >= 0) {
        but += " aria-label='";
        but += this.ariaValues[index] || "";
        but += "'";
    }

    but += this.ariaHidden ? " aria-hidden='" + this.ariaHidden + "'": "";

    if (!this.isEnabled || this.main.isReloading) {
        but += ' disabled';
    }
    var t = [''];
    if (this.icon && Object.keys(this.icon).length > 0) {
        if (!this.icon.position || this.icon.position == 'left') {
            t.push(this.getImage(idStr));
        }
        t.push("<span class='nuan-inline-block button-item-center'>");
    } else {
        t.push("<span class='nuan-inline-block'>");
    }
    if (this.title) {
        t.push(setText(this.title, lColor, 'nuanbtn-label'));
        t.push('</br>');
    }
    t.push(setText(text, tColor, 'nuanbtn-text'));
    t.push('</span>');

    if (this.icon && this.icon.position && this.icon.position == 'right') {
        t.push(this.getImage(idStr));
    }

    but += ['>', t.join(''), '</Button>'].join("");

    function setText(textval, color, sClass) {
        var t1 = [];
        t1.push('<span ', idStr, " class='");
        t1.push(sClass);
        if (color) {
            t1.push("' style='");
            t1.push('color:');
            t1.push(color);
            t1.push(";");
        }
        t1.push("'>");

        t1.push(textval);
        t1.push('</span>');
        return t1.join('');
    }

    return but;
};

Button.prototype.getImage = function (idStr) {
    var t = [''];
    t.push('<span ', idStr, " class='button-icon ", this.icon.position && this.icon.position == 'right' ? 'mr-0 ml-2 ' : '');
    t.push(this.icon.name);
    if (this.icon.align == 'center') {
        t.push(' button-item-center ');
    }
    if (!this.title && !this.text) {
        t.push(' mr-0');
    }
    t.push("'></span>");
    return t.join('');
};

Button.prototype.isPlugin = function () {
    if (this.setFocus) {
        return true;
    }
    return false;
};

Button.prototype.renderPlugin = function () {
    if (this.setFocus) {
        var el = this.main.dv.container.querySelector('#' + this.id);
        if (!el) {
            return false;
        }
        el.focus();
    }
    return true;
};

Button.prototype.resetData = function() {
    this.clicked = false;
};
 // eslint-disable-line semi,quotes,no-trailing-spaces,no-unused-expressions
    function ToggleButton(control) {
    this.active = false;
    ToggleButton.super.call(this, control);
}
Utils.extend(ToggleButton, Button);
ToggleButton.prototype.onClickEvent = function (e) {
    if (e.target) {
        e.target.classList.toggle('active');
        this.active = !this.active;
    }
    if (this.event) {
        Button.superProto.invokeHandlers.call(this);
    }
};
 // eslint-disable-line semi,quotes,no-trailing-spaces,no-unused-expressions
    function QuickReplyButton(control) {
    this.selectedIndex = -1;
    this.selectedText = '';
    this.selectedValue = '';
    QuickReplyButton.super.call(this, control);
    this.values = typeof this.values !== 'undefined' && this.values instanceof Array ? this.values : [];
    this.defaultSelected = this.selectedIndex;
}

Utils.extend(QuickReplyButton, Button);

QuickReplyButton.prototype.onClickEvent = function (e) {
	if (!this.isEnabled) {
		return;
	}
	var id = e.target.id ? e.target.id : e.target.getAttribute('data-id');
    this.selectedIndex = parseInt(id.match(/\d+$/)[0], 10);
   	this.getSelectedText();
    Button.prototype.onClickEvent.call(this, e);

    if (!this.event || Object.keys(this.event).length == 0) {
        this.setSelectStyle(e);
    } else {
        this.selectedIndex = -1;
        this.selectedText = '';
    }
};

QuickReplyButton.prototype.setSelectStyle = function (e) {
    var con = this.context;
    if (this.selEl) {
        this.selEl.style.background = con.color.startsWith('#') ? con.color : null;
        this.selElText.style.color = con.textColor || null;
        this.selEl.style.borderColor = con.border || null;
        this.selEl.removeAttribute("aria-pressed");
    }
    this.selEl = e.currentTarget.querySelector(['#', this.id, '-', this.selectedIndex].join(''));
    this.selElText = this.selEl.querySelector('.nuanbtn-text');
    if (con instanceof Object && this.selEl && con.selectedColor) {
    	this.selEl.setAttribute("aria-pressed", true);
        this.selEl.style.background = con.selectedColor;
        if (con.selectedBorderColor) {
            this.selEl.style.borderColor = con.selectedBorderColor;
        }
        if (con.selectedTextColor && this.selElText) {
            this.selElText.style.color = con.selectedTextColor;
        }
    }
};

QuickReplyButton.prototype.getOutputText = function () {
    return this.selectedText;
};

QuickReplyButton.prototype.getAnswerID = function () {
    return this.text[this.selectedIndex] || '';
};

QuickReplyButton.prototype.getSelectedText = function () {
    this.selectedText = this.text[this.selectedIndex];
    this.selectedValue = this.values[this.selectedIndex] || this.text[this.selectedIndex];
};

QuickReplyButton.prototype.getHTML = function () {
    var items = (this.text instanceof Array) ? this.text : (this.colors instanceof Array) ? this.colors : this.getFromConstant(this.text || this.colors);

    // var els = ["<fieldset class='nuan-floating-cont' id="];
    var els = "<div role='group' class='quick-reply-container ";

    switch (this.align) {
    case 'left':
        els += "quick-reply-left'";
        break;
    case 'right':
        els += "quick-reply-right'";
        break;
    case 'justifyLeft':
        els += "justify-left'";
        break;
    case 'justifyRight':
        els += "justify-right'";
        break;
    case 'justifyCenter':
        els += "justify-center'";
        break;
    default: {
        els += "'";
    }
    }

    if (this.ariaLabel) {
        els += " aria-label='" + this.ariaLabel + "' ";
    }

    els += this.ariaHidden ? " aria-hidden='" + this.ariaHidden + "'>": ">";

    this.align = undefined;
    var sl = this.selectedIndex;
    if (this.selectedIndex >= 0) {
    	this.getSelectedText();
	}
    if (this.hiddenLabel) {
        this.encodeLabel(this.hiddenLabel);
    }

    // els.push(this.id);
    // els.push("> ");
    items.forEach(function (item, index) {
        this.icon = null;
        if (this.icons && index < this.icons.length) {
            this.icon = this.icons[index];
        }
        if (this.titles && index < this.titles.length) {
            this.title = this.titles[index];
        }
        els += this.getQuickContent(this.id + '-' + index, index, item, sl == index);
    }, this);
    els += '</div> ';
    // els.push("</fieldset>");
    this.registerHandlers();
    this.text = items;
    return els;
};

QuickReplyButton.prototype.getQuickContent = function (elId, index, item, selected) {
    var els = "<div class='quick-reply-item'> ";
    els += this.getContent(elId, item, selected, index);
    els += '</div> ';

    return els;
};

QuickReplyButton.prototype.isPlugin = function () {
    if (this.selectedIndex != -1 || this.setFocus) {
        return true;
    }
    return false;
};

QuickReplyButton.prototype.renderPlugin = function () {
    this.selEl = this.main.dv.container.querySelector(['#', this.id, '-', this.selectedIndex != -1 ? this.selectedIndex : 0].join(''));
    if (this.selEl) {
        this.selElText = this.selEl.querySelector('.nuanbtn-text');
        if (this.setFocus) {
        	this.selEl.focus();
        }
        return true;
    }
    return false;
};

QuickReplyButton.prototype.resetData = function () {
    this.selectedIndex = this.defaultSelected;
    if (this.selectedIndex >= 0) {
    	this.getSelectedText();
	}
};

QuickReplyButton.prototype.disable = function () {
    var x = this.main.dv.container.querySelectorAll('.' + this.id);
    var xl = x.length;
    this.isEnabled = false;
    for (var i = 0; i < xl; i += 1) {
        x[i].disabled = true;
    }
};

QuickReplyButton.prototype.enable = function () {
    var x = this.main.dv.container.querySelectorAll('.' + this.id);
    var xl = x.length;
    this.isEnabled = true;
    for (var i = 0; i < xl; i += 1) {
        x[i].disabled = false;
    }
};

QuickReplyButton.prototype.getSnapShot = function () {
    var items = this.text || this.colors;
    var si = this.selectedIndex;
    return {
        text: items[si],
        title: this.titles && this.titles[si],
        icon: this.icons && this.icons[si],
    };
};

QuickReplyButton.prototype.getFromConstant = function (key) {
    var result = Utils.parseValueFromConstant(key, this.main);
    if (Array.isArray(result)) {
        this.text = result;
        return result;
    }
    this.icons = [result.icon];
    this.titles = [result.title];
    this.text = [result.text];
    return this.text;
};

QuickReplyButton.prototype.getAriaOutput = function () {
    return  {
    	"text": this.text.join(". "),
    	"length":this.text.length,
    	"type": this.type
    };
};
 // eslint-disable-line semi,quotes,no-trailing-spaces,no-unused-expressions
    function ColorPicker(control) {
    ColorPicker.super.call(this, control);
}
Utils.extend(ColorPicker, QuickReplyButton);

ColorPicker.prototype.getQuickContent = function (elId, index, item, selected) {
    var els = ["<div class='quick-reply-item item' style='border-radius:", this.context.borderRadius, "px;'>"];
    this.context.color = item;
    els.push(this.getContent(elId, '', selected));
    els.push('</div> ');

    return els.join('');
};

ColorPicker.prototype.renderPlugin = function () {
    this.selEl = this.getSelectedElement();
    if (this.selEl) {
        if (this.context.selectedOutlineColor) {
            this.selEl.style.borderColor = this.context.selectedOutlineColor;
        }
        return true;
    }
    return false;
};
ColorPicker.prototype.getSelectedElement = function () {
    return this.main.dv.container.querySelector(['#', this.id, '-', this.selectedIndex].join('')).parentElement;
};

ColorPicker.prototype.setSelectStyle = function () {
    if (this.selEl) {
        this.selEl.style.borderColor = null;
    }
    this.selEl = this.getSelectedElement();
    if (this.selEl && this.context.selectedOutlineColor) {
        this.selEl.style.borderColor = this.context.selectedOutlineColor;
    }
};

/**
  Set the selected color and selected color value to the ColorPicker Object
 * @param {int} selected - pass the selected index
 **/
ColorPicker.prototype.getSelectedText = function (selected) {
    this.selectedText = this.colors[selected];
    this.selectedValue = this.values[selected] ? this.values[selected] : this.colors[selected];
};
 // eslint-disable-line semi,quotes,no-trailing-spaces,no-unused-expressions
    function Input(control) {
    this.text = '';
    Input.super.call(this, control);
}

Utils.extend(Input, Control);
Input.prototype.onInputEvent = function (e) {
    this.text = e.target.value;
    if (this.event) {
        Input.superProto.invokeHandlers.call(this);
    }

    if (!this.valid) {
        this.resetErrors();
    }
};
Input.prototype.getOutputText = function () {
    return this.text;
};

Input.prototype.registerHandlers = function () {
    Input.superProto.registerHandlers.call(this);
    this.checkEnableCondition();
    this.main.ev.registerEventHandler(this, 'onInput');
};

Input.prototype.getType = function () {
    return 1;
};

Input.prototype.getHTML = function () {
    this.registerHandlers();
    var inp = "<fieldset class='nuan-fieldset ";
    inp += (this.type == 'ShortInput' ? 'nuan-short-input' : 'w-100');
    inp += "' style='padding-left:2px;padding-right:2px;padding-top:";
    inp += (this.material ? '.4em' : '2px');
    inp += ";padding-bottom:2px;'";
    inp += this.ariaHidden ? " aria-hidden='" + this.ariaHidden + "'>": ">";

    if (this.actionType == 'date') {
        this.placeholder = this.dateFormat || 'mm/dd/yyyy';

        this.monthIndex = this.placeholder.toLowerCase().indexOf('mm') == 0 ? 0 : 1;
        this.dayIndex = this.placeholder.toLowerCase().indexOf('dd') == 0 ? 0 : 1;
    }

    if (this.label && !this.material) {
        inp += "<label class='nuan-label' for='";
        inp += this.id;
        inp += "' ";
        inp += this.getStyle();
        inp += '>';
        inp += this.label;
        inp += '</label>';
        this.encodeLabel(this.label);
    } else if (this.hiddenLabel) {
        this.encodeLabel(this.hiddenLabel);
    }

    inp += ("<div style='position:relative;" + this.addPadding.call(this.controlContext) + "'><input type='text' class='nuan-input nuan-form-control");
    inp += (this.material ? ' nuan-form-control-material' : '');
    inp += "'";
    inp += ' id=';
    inp += this.id;

    var helpId = (this.id + Date.now()) + 'hlp';
    if (this.helpText) {
        inp += "  aria-describedby='";
        inp += helpId;
        inp += "' ";
    } else if (this.ariaLabel) {
        inp += "  aria-label='";
        inp += this.ariaLabel;
        inp += "' ";
    }

    if (this.placeholder && !this.material) {
        inp += " placeholder='";
        inp += this.placeholder;
        inp += "'";
    }
    if (!this.isEnabled || this.main.isReloading) {
        inp += ' disabled';
    }

    if (this.text) {
        inp += " value='";
        inp += this.text;
        inp += "'";
    }
    if (this.material) {
        inp += ' required';
    }

    if(this.controlContext) {
        inp += this.getStyle.call(this.controlContext);
    }

    inp += "></input><span class='validation-error-icon'></span>";
    if (this.material) {
        inp += "<label class='nuan-label-material' for='";
        inp += this.id;
        inp += "' ";
        inp += this.getStyle();
        inp += '>';
        inp += this.label;
        inp += '</label>';
    }
    inp += '</div>';
    inp += this.addValidationTags();
    if (this.helpText) {
        inp += "<small class='form-text text-muted' id='";
        inp += helpId;
        inp += "'>";
        inp += this.helpText;
        inp += '</small>';
    }

    inp += '</fieldset>';

    return inp;
};

Input.prototype.validate = function (val, obj) {
    switch (val) {
    case 'required':
        return !!this.text;
    case 'minlength':
        return this.text.length >= obj.length;
    case 'maxlength':
        return this.text.length <= obj.length;
    case 'pattern':
        return new RegExp(obj.value).test(this.text);
        // no default
    }
    return null;
};

Input.prototype.toggleInvalid = function (el) {
    el.classList.toggle('invalid');
    // el.setAttribute("aria-invalid", true);
};

Input.prototype.addInvalid = function (el) {
    el.classList.add('invalid');
    // el.setAttribute("aria-invalid", true);
};
Input.prototype.forceFocus = function() {
    this.actualEl.focus();
    this.actualEl.scrollIntoView({block: 'nearest'});
};

Input.prototype.removeInvalid = function (el) {
    el.classList.remove('invalid');
    // el.setAttribute("aria-invalid", true);
};

Input.prototype.getErrorTag = function (el, name) {
    return el.parentNode.parentNode.querySelector('[data-validation-message=' + name + ']');
};

Input.prototype.resetData = function () {
    this.text = '';
};

Input.prototype.isPlugin = function () {
    if (this.actionType == 'date' || this.material || this.setFocus) {
        return true;
    }
    return false;
};

Input.prototype.renderPlugin = function () {
    var el = this.main.dv.container.querySelector('#' + this.id);
    if (!el) return false;

    if (this.actionType == 'date') {
        this.dateKeyUp = this.onDateTextKeyDown.bind(this);
        el.addEventListener('keyup', this.dateKeyUp);
        this.onDateTextKeyDown(el);

        this.dateChange = this.onDateChange.bind(this);
        el.addEventListener('change', this.dateChange);
    }
    if (this.material) {
        this.focusLnr = this.onTextFocus.bind(this);
        el.addEventListener('focus', this.focusLnr);
        this.blurLnr = this.onTextBlur.bind(this);
        el.addEventListener('blur', this.blurLnr);
    }

    if (this.setFocus) {
        el.focus();
    }

    return true;
};

Input.prototype.onTextFocus = function (evt) {
    var pl = this.placeholder;
    setTimeout(function () {
        evt.target.placeholder = pl;
    }, 250);
};

Input.prototype.onTextBlur = function (evt) {
    evt.target.placeholder = '';
};

Input.prototype.checkValue = function (str, max) {
    if (str.charAt(0) !== '0' || str == '00') {
        var num = parseInt(str, 10);
        if (isNaN(num) || num <= 0 || num > max) num = 1; // eslint-disable-line no-restricted-globals
        str = num > parseInt(max.toString().charAt(0), 10) && num.toString().length == 1 ? '0' + num : num.toString();
    }
    return str;
};

Input.prototype.onDateChange = function (evt) {
    this.onDateTextKeyDown(evt);
};

Input.prototype.onDateTextKeyDown = function (evt) {
    var target;
    if (evt.target) {
        target = evt.target;
    } else {
        target = evt;
    }
    target.type = 'text';
    var input = target.value;
    var key = evt.keyCode || evt.charCode;

    if (key == 8 || key == 46) { // here's where it checks if backspace or delete is being pressed
        return false;
    }
    if (/\D\/$/.test(input)) input = input.substr(0, input.length - 3);
    var original = input.split('/');
    var values = input.split('/').map(function (v) {
        return v.replace(/\D/g, '');
    });
    if (values[this.monthIndex]) values[this.monthIndex] = this.checkValue(values[this.monthIndex], 12);
    if (values[this.dayIndex]) values[this.dayIndex] = this.checkValue(values[this.dayIndex], 31);
    var output = values.map(function (v, i) {
        var val = v.length == 2 && i < 2 ? v + ' / ' : original[i].indexOf('/') != -1 ? v + ' / ' : v;

        return val;
    });
    target.value = output.join('').substr(0, 14);
    return false;
};

Input.prototype.clear = function () {
    var el = this.main.dv.container.querySelector('#' + this.id);
    if (this.dateKeyUp) {
        el.removeEventListener('input', this.dateKeyUp);
        el.removeEventListener('change', this.dateChange);
    }
    if (this.material) {
        el.removeEventListener('focus', this.focusLnr);
        el.removeEventListener('blur', this.blurLnr);
    }
};

Input.prototype.resetRendering = function () {
   var el = this.resetErrors();
   if (el) {
        el.value = "";
   }
};
 // eslint-disable-line semi,quotes,no-trailing-spaces,no-unused-expressions
    // add all the elements inside modal which you want to make focusable
var focusableElements
 = 'a, button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
function Select(control) {
    this.selectedIndex = -1;
    Select.super.call(this, control);

    if (!this.header && this.selectedIndex == -1) {
        this.selectedIndex = 0;
    }
    if (this.header && this.selectedIndex >= 0) {
        this.defaultSelected = this.selectedIndex + 1;
    } else {
      this.defaultSelected = this.selectedIndex;
    }
}
Utils.extend(Select, Control);

Select.prototype.onSelectEvent = function (e) {
    if (e.target) {
        this.selectedIndex = e.target.selectedIndex;
        if (this.header) {
            this.selectedIndex -= 1;
        }
        this.selectedText = e.target.value || this.items[this.selectedIndex];
        if (this.event) {
            Select.superProto.invokeHandlers.call(this);
        }

        if (!this.valid) {
            this.resetErrors();
        }
    }
};

Select.prototype.onClick = function (e) {
    var that = this;
    var modal = "<div class='select-modal' role='dialog' aria-modal='true'";
    if(this.label){
        modal += "aria-labelledby='"+ this.labelId;
    }
    modal += ">";
    modal += "<div class='modal-child'>";
    var lg = new ListSelectGroup({
        header: this.header,
        items: this.items,
        selectedIndex: this.selectedIndex,
        closeList: true,
        id: this.id + "modal",
        resultCallback: function () {
            var el = elementClosest(lg.el, ".select-modal");
            that.removeNegativeTabIndexOnClosingModal();
            el.parentElement.removeChild(el);
            if (lg.selectedIndex != -1) {
              that.selectedIndex = lg.selectedIndex;
              that.selectedText = (that.values && that.values.length > that.selectedIndex ? that.values[that.selectedIndex] : that.items[that.selectedIndex]);
              that.el.innerText = that.items[that.selectedIndex];
                if (that.event) {
                    Select.superProto.invokeHandlers.call(that);
                }
            }
            that.el.setAttribute("aria-expanded", "false");
            that.el.focus();
            lg.clear();
            if (!that.valid) {
                that.resetErrors();
            }
        }
    });
    lg.main = this.main;
    modal += lg.getHTML();
    modal += "</div>";
    this.main.dv.container.firstElementChild.insertAdjacentHTML("beforeend", modal);
    var rp = function () {
        if (!lg.renderPlugin()) {
          setTimeout(rp, 25);
        }
    };
    setTimeout(rp, 25);
    this.setFocusWithinModalWindow();
    this.el.setAttribute("aria-expanded", "true");
    e.preventDefault();
};

Select.prototype.getOutputText = function () {
    return this.selectedText;
};

Select.prototype.getAnswerID = function () {
    return this.items[this.selectedIndex] || '';
};

Select.prototype.registerHandlers = function () {
    Select.superProto.registerHandlers.call(this);
    this.main.ev.registerEventHandler(this, 'onChange');
};

Select.prototype.getHTML = function () {
    this.checkEnableCondition();
    var inp = "<fieldset class='nuan-fieldset w-100'";
    inp += this.ariaHidden ? " aria-hidden='" + this.ariaHidden + "'>": ">";

    var items = this.items || [];
    var values = this.values || items;
    var si = this.selectedIndex > items.length - 1 ? items.length - 1 : this.selectedIndex;
    this.selectedIndex = si;
    this.labelId = this.id ? this.id + "_label'" :'';
    if (this.label) {
        inp += "<label class='nuan-label' for='";
        inp += (this.id + "' ");
        inp += this.getStyle();
        if(this.id) {
            inp += "id='"+ this.labelId;
        }
        inp += '>';
        inp += this.label;
        inp += '</label>';
        this.encodeLabel(this.label);
    } else if (this.hiddenLabel) {
        this.encodeLabel(this.hiddenLabel);
    }
    if (this.modalView && isSupportedTouch()) {
        inp += ("<div role='button' tabindex='0' aria-haspopup='listbox' class='nuan-custom-select' "+ this.getControlStyles() + " id=" + this.id);
        if (this.ariaLabel || this.helpText) {
          inp += " aria-label='" + (this.ariaLabel || this.helpText) + "'";
        }
        inp += '> ';
        inp += (this.header ||  this.items[0]);
    } else {
        inp += ("<div class='nuan-custom-select' "+ this.getControlStyles() +">");
        inp += "<select class='nuan-select' id=";
        inp += this.id;
        if (!this.isEnabled || this.main.isReloading) {
            inp += ' disabled';
        }

        if (this.ariaLabel || this.helpText) {
            inp += " aria-label='" + (this.ariaLabel || this.helpText) + "'";
        }

        inp += '> ';

        if (this.header) {
            inp += "<option value=''>";
            inp += this.header;
            inp += '</option>';
        }
        if (si != -1) {
            this.selectedText = values.length > si ? values[si] : items[si];
        }

        items.forEach(function (item, index) {
            inp += '<option ';
            if (values.length > index) {
                inp += "value='";
                inp += values[index];
                inp += "'";
            }
            if (index === si) {
                inp += ' selected ';
            }
            inp += '>';
            inp += item;
            inp += '</option>';
        });
        inp += "</select>";
    }
    inp += "<span class='validation-error-icon'></span></div>";

    inp += this.addValidationTags();
    if (this.helpText) {
        inp += "<small class='form-text text-muted'>";
        inp += this.helpText;
        inp += '</small>';
    }
    inp += '</fieldset>';
    this.registerHandlers();
    return inp;
};

Select.prototype.isPlugin = function () {
    if (this.setFocus || this.modalView) {
        return true;
    }
    return false;
};

Select.prototype.renderPlugin = function () {
    var el = this.main.dv.container.querySelector('#' + this.id);
    if (!el) return false;

    if (this.setFocus) {
        el.focus();
    }

    if (this.modalView) {
        this.onClickHandler = $bind(this, this.onClick);
        this.el = el;
        if (isSupportedTouch()) {
          el.addEventListener("click", this.onClickHandler);
        }
    }

    return true;
};

Select.prototype.getControlStyles = function() {
  var s = "";
  if (this.controlContext) {
      s = this.getStyle.call(this.controlContext);
      s += this.addPadding.call(this.controlContext);
  }
  return s;
};

Select.prototype.validate = function () {
    if (this.header && this.selectedIndex == -1) {
        return false;
    }
    return true;
};

Select.prototype.addInvalid = function (el) {
    el.parentNode.classList.add('invalid');
    // el.setAttribute("aria-invalid", true);
};
Select.prototype.forceFocus = function() {
    this.actualEl.focus();
    this.actualEl.scrollIntoView({block: 'nearest'});
};

Select.prototype.removeInvalid = function (el) {
    el.parentNode.classList.remove('invalid');
    // el.setAttribute("aria-invalid", true);
};

Select.prototype.resetData = function () {
    this.selectedIndex = this.defaultSelected;
    this.selectedText = '';
};

Select.prototype.resetRendering = function () {
    var el = this.resetErrors();
    if (el) {
      el.selectedIndex = this.defaultSelected == -1 ? 0 : this.defaultSelected;
    }
};

Select.prototype.clear = function() {
    if (this.el) {
       this.el.addEventListener("click", this.onClickHandler);
    }
};

Select.prototype.getErrorTag = function (el, name) {
    return el.parentNode.parentNode.querySelector('[data-validation-message=' + name + ']');
};



Select.prototype.setFocusWithinModalWindow = function (element) {
    var modalElement = element || document.querySelector('.select-modal');
    // All the focusable  element of modal window
    var modalFocusableContent = modalElement.querySelectorAll(focusableElements);

    // All the focusable  element of documents
    var documentFocusableContent = document.querySelectorAll(focusableElements);

    // Setting negative tab index on element of documents
    for (var i = 0, tabIndexVal; i < documentFocusableContent.length; i++) {
        tabIndexVal = documentFocusableContent[i].getAttribute("tabindex") || 0;

        if (tabIndexVal != "-1") {
            documentFocusableContent[i].setAttribute("tabindex", "-1");

            /**
             * Creating custom data attribute  to track all the elements
             * where we applied negative tabindex values, on closing modal
             * we remove negative tabindex using this custome attribute
             */
            documentFocusableContent[i].setAttribute("data-custom-index", tabIndexVal);
        }
    }

    // Setting positive tab index on element of modal
    for (var j = 0; j < modalFocusableContent.length; j++) {
        if (modalFocusableContent[j].hasAttribute("tabindex")) {
            modalFocusableContent[j].setAttribute("tabindex", j);
        }
    }
};


Select.prototype.removeNegativeTabIndexOnClosingModal = function () {
        // All the elements of document with custom attribute "data-custom-index"
        var documentFocusableContent = document.querySelectorAll("[data-custom-index]");

        for (var i = 0, tabIndexVal; i < documentFocusableContent.length; i++) {
            tabIndexVal = documentFocusableContent[i].getAttribute("data-custom-index");
            documentFocusableContent[i].setAttribute("tabindex", tabIndexVal);
            documentFocusableContent[i].removeAttribute("data-custom-index");
        }
}; // eslint-disable-line semi,quotes,no-trailing-spaces,no-unused-expressions
    function DropDown(control) {
    DropDown.super.call(this, control);
}

Utils.extend(DropDown, Select);
 // eslint-disable-line semi,quotes,no-trailing-spaces,no-unused-expressions
    function CheckBox(control) {
    this.checked = [];
    CheckBox.super.call(this, control);
    this.defaultChecked = [].concat(this.checked || []);
}
Utils.extend(CheckBox, Control);
CheckBox.prototype.onClickEvent = function (e) {
    if (e.target) {
        var index = parseInt(e.target.id.substr(-1, 1), 10);
        this.checked[index] = e.target.checked;
        if (this.event) {
            CheckBox.superProto.invokeHandlers.call(this);
        }
        this.getSelectedText();
        if (!this.valid) {
            this.resetErrors();
        }
    }
};

CheckBox.prototype.getOutputText = function () {
    return this.selectedText;
};

CheckBox.prototype.getSelectedText = function() {
    var selText = '';
    for (var i = 0; i < this.values.length; i++) {
        if (this.checked[i]) {
            if (selText) {
                selText += ',';
            }
            selText += this.values[i];
        }
    }
    this.selectedText = selText;
};

CheckBox.prototype.getLabel = function () {
    var qs = '';
    for (var i = 0; i < this.checked.length - 1; i++) {
        if (this.checked[i]) {
            if (qs) {
                qs += ',';
            }
            qs += (this.label || this.hiddenLabel);
        }
    }
    return qs;
};

ListGroup.prototype.getAnswerID = function () {
    var ans;
    for (var i = 0; i < this.checked.length - 1; i++) {
        if (this.checked[i]) {
            if (ans) {
                ans += ',';
            }
            ans += this.items[i];
        }
    }
    return ans;
};

CheckBox.prototype.registerHandlers = function () {
    CheckBox.superProto.registerHandlers.call(this);
    this.main.ev.registerEventHandler(this, 'onClick');
};

CheckBox.prototype.getHTML = function () {
    this.checkEnableCondition();
    var inp = "<fieldset class='nuan-fieldset w-100' role='group' id=";
    inp += this.id;
    if (!this.isEnabled || this.main.isReloading) {
        inp += ' disabled';
    }
    if (this.ariaLabel || this.helpText) {
        inp += " aria-label='" + (this.ariaLabel || this.helpText) + "'";
    }

    inp += this.ariaHidden ? " aria-hidden='" + this.ariaHidden + "'": "";

    inp += '> ';
    var values = this.values || [];
    if (this.label) {
        inp += "<label class='nuan-label' ";
        inp += this.getStyle();
        inp += '>';
        inp += this.label;
        inp += '</label>';
        this.encodeLabel(this.label);
    } else if (this.hiddenLabel) {
        this.encodeLabel(this.hiddenLabel);
    }
    this.selectedText = '';
    var chItemId = "";
    this.items.forEach(function (item, index) {
        chItemId = this.id + '-' + Date.now() + index;
        inp += ("<label class='nuan-check-label' for='" + chItemId + "' style='" + this.addMargin() + "'><input type='checkbox' id='");
        inp += chItemId;
        inp += "' ";
        if (values.length > index) {
            inp += "value='";
            inp += values[index];
            inp += "'";
        }
        if (this.checked.length > index) {
            if (this.checked[index]) {
                inp += ' checked';
                if (this.selectedText) {
                    this.selectedText += ',';
                }
                this.selectedText += values.length > index ? values[index] : '';
            }
        }
        inp += '> ';
        inp += item;
        inp += "<span class='nuan-checkbox'></span></label>";
    }, this);

    inp += this.addValidationTags();
    if (this.helpText) {
        inp += "<small class='form-text text-muted'>";
        inp += this.helpText;
        inp += '</small>';
    }
    inp += '</fieldset>';
    this.registerHandlers();
    return inp;
};

CheckBox.prototype.isPlugin = function () {
    if (this.setFocus) {
        return true;
    }
    return false;
};

CheckBox.prototype.renderPlugin = function () {
    var el = this.main.dv.container.querySelector('#' + this.id);
    if (!el) {
        return false;
    }
    if (!this.els) {
        this.els = el.querySelectorAll("input[type='checkbox']");
    }
    this.els[0].focus();
    return true;
};

CheckBox.prototype.validate = function () {
    return this.checked.some(function (item) {
        return !!item;
    });
};

CheckBox.prototype.resetData = function () {
    this.checked = [].concat(this.defaultChecked || []);
    this.getSelectedText();
};

CheckBox.prototype.addInvalid = function (el) {
    var boxes = this.boxEls || el.querySelectorAll('.nuan-checkbox');
    for (var i = 0; i < boxes.length; i++) {
        boxes[i].classList.add('invalid');
    }

    // el.setAttribute("aria-invalid", true);
    this.boxEls = boxes;
};

CheckBox.prototype.forceFocus = function() {
    var el = this.actualEl.parentElement;
    el.firstElementChild.focus();
    el.scrollIntoView({block: 'nearest'});
};

CheckBox.prototype.removeInvalid = function (el) {
    var boxes = this.boxEls || el.querySelectorAll('.nuan-checkbox');
    for (var i = 0; i < boxes.length; i++) {
        boxes[i].classList.remove('invalid');
    }
    // el.setAttribute("aria-invalid", true);
    this.boxEls = boxes;
};

CheckBox.prototype.addMargin = function() {
    var s = "";

    if (this.controlContext) {
        if (this.controlContext.marginLeft) {
            s = "margin-left:" + this.controlContext.marginLeft + "px;";
        }
    }

    return s;
};

CheckBox.prototype.resetRendering = function () {
    var el = this.resetErrors();
    if (!this.els) {
        this.els = el.querySelectorAll("input[type='checkbox']");
    }
    for (var i = 0; i < this.els.length; i++) {
        if (this.defaultChecked[i]) {
            this.els[i].checked = true;
        } else {
            this.els[i].checked = false;
        }
    }
};
 // eslint-disable-line semi,quotes,no-trailing-spaces,no-unused-expressions
    function RadioButton(control) {
    this.selectedIndex = undefined;
    RadioButton.super.call(this, control);
    this.defaultSelected = this.selectedIndex;
}
Utils.extend(RadioButton, Control);
RadioButton.prototype.onClickEvent = function (e) {
    if (e.target) {
        this.selectedIndex = parseInt(e.target.id.substr(-1, 1), 10);
        this.selectedText = e.target.value || this.options[this.selectedIndex];
        if (this.event) {
            RadioButton.superProto.invokeHandlers.call(this);
        }

        if (!this.valid) {
            this.resetErrors();
        }
    }
};

RadioButton.prototype.getOutputText = function () {
    return this.selectedText;
};

RadioButton.prototype.registerHandlers = function () {
    RadioButton.superProto.registerHandlers.call(this);
    this.main.ev.registerEventHandler(this, 'onClick');
};

RadioButton.prototype.getAnswerID = function () {
    return (this.options && this.options[this.selectedIndex]) || '';
};

RadioButton.prototype.getHTML = function () {
    this.checkEnableCondition();
    var inp = "<fieldset class='nuan-fieldset w-100' role='group' id=";
    inp += this.id;
    if (!this.isEnabled || this.main.isReloading) {
        inp += ' disabled';
    }

    if (this.ariaLabel || this.helpText) {
        inp += " aria-label='" + (this.ariaLabel || this.helpText) + "'";
    }

    inp += this.ariaHidden ? " aria-hidden='" + this.ariaHidden + "'": "";
    inp += '> ';
    var items = this.options || [];
    var itemslen = items.length - 1;
    var values = this.values || [];
    var val = !!this.validation;
    var si = this.selectedIndex;
    if (si >= 0) {
        si = si > itemslen ? itemslen : si;
        this.selectedIndex = si;
        this.selectedText = values.length > si ? values[si] : items[si];
    }

    if (!this.name) {
        return;
    }
    if (this.label) {
        inp += "<label class='nuan-label' ";
        inp += this.getStyle();
        inp += '>';
        inp += this.label;
        inp += '</label>';
        this.encodeLabel(this.label);
    } else if (this.hiddenLabel) {
        this.encodeLabel(this.hiddenLabel);
    }

    var now = Date.now();
    var iId = "";
    if(this.layout && this.layout.startsWith('inline')) {
        var vbl = this.buttonLayout && this.buttonLayout == "vertical"? true : false;
        inp += ('<div class=' + (this.layout.indexOf("Grow") != -1 ? "nuan-flex" : "non-flex") + " style='" + this.addPadding.call(this.controlContext) + "'>");


        values.forEach (function (item, index) {
            iId = (this.id + "-" + now + index);
            inp += "<label for=" + iId + " class='nuan-radio-hori-cont'>";
            inp += this.getInputEl(index, values, val, iId);
            if (vbl) {
                inp += "<span class='text-above'>" + (items[index] || "") + "</span>";
            }
            inp += "<span class='nuan-radio'></span>";
            if (!vbl && items[index]) {
                inp += "<span>" + items[index] + "</span>";
            }
            inp += "</label>";
        }, this);
        inp += '</div>';

    } else {
        items.forEach(function (item, index) {
            iId = (this.id + "-" + now + index);
            inp += ("<label class='nuan-radio-label' for=" + iId + " style='" + this.addPadding.call(this.controlContext) + "'>");
            inp += this.getInputEl(index, values, val, iId);
            inp += "<span class='nuan-radio'></span>";
            inp += ("<span>" + (items[index] || "") + "</span>");
            inp += "</label>";
        }, this);
    }

    inp += this.addValidationTags();
    if (this.helpText) {
        inp += "<small class='form-text text-muted'>";
        inp += this.helpText;
        inp += '</small>';
    }
    inp += '</fieldset>';
    this.registerHandlers();
    return inp;
};

RadioButton.prototype.getInputEl = function(index, values, val, itemid) {
    var inp = "<input type='radio' class='nuan-radio' id='";
    inp += itemid;
    inp += "' name='";
    inp += this.name;
    inp += "' ";

    if (values.length > index) {
        inp += "value='";
        inp += values[index];
        inp += "'";
    }
    if (index === this.selectedIndex) {
        inp += ' checked ';
    }
    if (val) {
        inp += ' required';
    }
    if (this.ariaValues) {
        inp += " aria-label='";
        inp += this.ariaValues[index] || "";
        inp += "'";
    }
    inp += '>';
    return inp;
};

RadioButton.prototype.isPlugin = function () {
    if (this.setFocus) {
        return true;
    }
    return false;
};

RadioButton.prototype.renderPlugin = function () {
    this.queryAllButtons();
    if (!this.els || this.els.length == 0) {
        return false;
    }
    this.els[0].focus();

    return true;
};

RadioButton.prototype.validate = function () {
    if (this.selectedIndex == -1 || typeof this.selectedIndex == "undefined") {
        return false;
    }
    return true;
};

RadioButton.prototype.addInvalid = function (el) {
    this.radios = this.radios || el.querySelectorAll('span.nuan-radio');
    for (var i = 0; i < this.radios.length; i++) {
        this.radios[i].classList.add('invalid');
    }
    el.parentElement.firstElementChild.focus();
};

RadioButton.prototype.forceFocus = function() {
    var el = this.actualEl.parentElement;
    el.firstElementChild.focus();
    el.scrollIntoView({block: 'nearest'});
};

RadioButton.prototype.removeInvalid = function (el) {
    if (this.radios) {
        for (var i = 0; i < this.radios.length; i++) {
            this.radios[i].classList.remove('invalid');
        }
    }
};

RadioButton.prototype.resetData = function () {
    this.selectedIndex = this.defaultSelected;
    if (this.selectedIndex >= 0) {
        this.selectedText = this.values[this.selectedIndex] || this.options[this.selectedIndex];
    }
};

/**
 * Reset dependent UI and Errors when
 * master survey radio button state changed
 */
RadioButton.prototype.resetRendering = function () {
    this.queryAllButtons();
    this.resetCheckedState();
    this.resetErrors();
};

/**
 * Reset dependent radio buttons state
 */
RadioButton.prototype.resetCheckedState = function () {
    for (var i = 0; i < this.els.length; i++) {
        this.els[i].checked = this.defaultSelected === i;
    }
};

RadioButton.prototype.queryAllButtons = function () {
    if (!this.els) {
        this.els = this.main.dv.container.querySelectorAll("input[type='radio'][name='" + this.name +"']");
    }
};
 // eslint-disable-line semi,quotes,no-trailing-spaces,no-unused-expressions
    function Heading(control) {
    Heading.super.call(this, control);
}
Utils.extend(Heading, Control);
Heading.prototype.getHTML = function () {
    var l = this.level || '6';
    var h = '<h';
    h += l;
    h += "  class='nuan-heading";
    h += "'";
    h += this.getStyle();
    h += this.ariaHidden ? " aria-hidden='" + this.ariaHidden + "'": "";
    h += '>';
    this.text = Utils.parseValueMapperTemplate(this.text, this.main);
    h += this.text;
    h += '</h';
    h += l;
    h += '>';
    return h;
};

Heading.prototype.getAriaOutput = function () {
    return  {"text": this.text};
};
 // eslint-disable-line semi,quotes,no-trailing-spaces,no-unused-expressions
    function Paragraph(control) {
    Paragraph.super.call(this, control);
}
Utils.extend(Paragraph, Control);

Paragraph.prototype.registerHandlers = function () {
    Paragraph.superProto.registerHandlers.call(this);
};

Paragraph.prototype.getHTML = function () {
    var h = '<p ';
    if (this.id) {
        h += ("id=" + this.id);
    }
    h += " class='nuan-para' ";
    h += this.getStyle();
    h += this.ariaHidden ? " aria-hidden='" + this.ariaHidden + "'": "";
    h += '>';
    this.text = Utils.parseValueMapperTemplate(this.text, this.main);
    h += this.text;
    h += '</p>';
    if (this.hiddenLabel) {
        this.encodeLabel(this.hiddenLabel);
    }



    this.registerHandlers();
    return h;
};

Paragraph.prototype.getAriaOutput = function () {
    var ariaOut = {"text": this.text};
    if (this.accessibilityReader) {
        ariaOut.accessibilityReader = this.accessibilityReader;
    }
    return ariaOut;
};
 // eslint-disable-line semi,quotes,no-trailing-spaces,no-unused-expressions
    function Image(control) {
    Image.super.call(this, control);
}

Utils.extend(Image, Control);

Image.prototype.onClickEvent = function (e) {
    if (e.target && this.isEnabled) {
        this.selectedIndex = parseInt(e.target.getAttribute('data-index').substr(-1, 1), 10);
        this.selectedImage = this.src[this.selectedIndex];
        if (this.event) {
            Image.superProto.invokeHandlers.call(this);
        }
    }
};

Image.prototype.registerHandlers = function () {
    if (this.event) {
        this.main.ev.registerEventHandler(this, 'onClick');
    }
};

/**
 * @return {string} selectedImage - currently selected image src value
 **/
Image.prototype.getOutputText = function () {
    return this.selectedImage || "";
};

Image.prototype.getHTML = function () {
    this.registerHandlers();
    if (typeof this.src === 'string') {
        return this.getImage(this.src);
    }
    var imgs = (this.layout === 'horizontal' ? "<div class='nuan-img-cont-scroll'" : "<div class='nuan-img-cont-wrap'");
    imgs += this.ariaHidden ? " aria-hidden='" + this.ariaHidden + "'": "";
    imgs += ' id=';
    imgs +=  this.id;
    imgs +=  '>';
    var i = 0;
    this.src.forEach(function (img) {
        imgs += this.getImage(Utils.parseValueFromConstant(img, this.main), i);
        i += 1;
    }, this);
    imgs += '</div>';
    if (this.layout == 'horizontal') {
        imgs += "<nav class='carousel-nav'";
        imgs += this.ariaHidden ? " aria-hidden='" + this.ariaHidden + "'": "";
        imgs += "><button class='disabled prev' id='prev' role='button'><i class='arrow-left icon'></i><span class='sr-only'>Previous</span></button><button class='next' id='next' role='button'><i class='arrow-right icon'></i><span class='sr-only'>Next</span></button></nav>";
    }

    if (this.hiddenLabel && this.id) {
        this.encodeLabel(this.hiddenLabel);
    }
    return imgs;
};

Image.prototype.getImage = function (src, index) {
    var img = "<div class='img-thumbnail-cont mt-2'><img class='img-thumbnail nuan-image' src=";
    img += Control.getImageUrl(src);
    img += ' data-id=';
    img += this.id;
    if (this.event) {
    	img += " role='button'";
    }
    img += ' data-index=';
    img += index;
    if (this.size) {
        if (this.size.width) {
            img += ' width=';
            img += this.size.width;
        }
        img += ' height=';
        if (this.size.height) {
            img += this.size.height;
        } else {
            img += this.size.width / 1.33;
        }
        img += '></img></div>';
        return img;
    }
    return '';
};

Image.prototype.removeEventHandlers = function () {
};

Image.prototype.isPlugin = function () {
    return this.layout === 'horizontal';
};

Image.prototype.renderPlugin = function () {
    if (!this.main.isReloading && this.id) {
        this.el = this.main.dv.container.querySelector('#' + this.id);
        if (!this.el) {
        	return false;
        }
        intializeScrollable(this.el, this);
        this.imgsEls = this.el.children;
    }

    return true;
};

Image.prototype.getScrollRightSize = function () {
	return getWidgetRightScrollSize(this.el, this.imgsEls);
};

Image.prototype.getScrollLeftSize = function () {
	return getWidgetLeftScrollSize(this.el, this.imgsEls);
};

Image.prototype.getItemSize = function() {
	return this.stepSize || 14;
};

Image.prototype.disable = function() {
	this.isEnabled = false;
	this.clear();
};

Image.prototype.enable = function() {
	this.isEnabled = true;
	intializeScrollable(this.el, this);
};

Image.prototype.clear = function () {
    if (this.prev) {
        this.prev.removeEventListener('mousedown', this.mouseDownHandler);
        this.prev.removeEventListener('mouseup', this.mouseUpPrevHandler);
    }
    if (this.next) {
        this.next.removeEventListener('mousedown', this.mouseDownHandler);
        this.next.removeEventListener('mouseup', this.mouseUpNextHandler);
    }
    this.next = null;
    this.prev = null;
    this.mouseDownHandler = null;
    this.mouseUpPrevHandler = null;
    this.mouseUpNextHandler = null;
    this.nextDisabled = this.prevDisabled = false;
};
 // eslint-disable-line semi,quotes,no-trailing-spaces,no-unused-expressions
    function Calendar(control) {
    this.selectedDate = new Date().toDateString();
    this.rangeStartDate = null;
    this.rangeEndDate = null;
    Calendar.super.call(this, control);
    this.defaultDate = this.selectedDate;
    this.defaultStart = this.rangeStartDate;
    this.defaultEnd = this.rangeEndDate;
}

Utils.extend(Calendar, Control);
Calendar.prototype.onClickEvent = function () {
};
Calendar.prototype.isPlugin = function () {
    return true;
};

Calendar.prototype.getOutputText = function () {
    return this.selectedDate;
};

Calendar.prototype.getHTML = function () {
    var c = ["<div class='nuan-cal-cont mt-2'"];

    if(this.ariaHidden) {
        c.push(" aria-hidden='" + this.ariaHidden + "'");
    }

    c.push(">");

    if (this.label) {
        c.push("<label class='nuan-label' ");
        c.push(this.getStyle());
        c.push('>');
        c.push(this.label);
        c.push('</label>');
        this.encodeLabel(this.label);
    } else if (this.hiddenLabel) {
        this.encodeLabel(this.hiddenLabel);
    }

    if (this.dateInputs) {
        c.push("<div class='nuan-row container-positioning-center mb-2'");

        var obj = Control.createControlInstance(this.dateInputs, this.main);
        c.push(Control.getContStyleProps(obj));
        c.push('>');
        obj.main = this.main;
        c.push(obj.getHTML());
        c.push('</div>');
        this.dateInputs = obj;
    }
    c.push("<div class='nuan-calendar", this.main.isReloading ? " disabled' " : "' ", 'id=', this.id, '></div></div>');
    return c.join('');
};

Calendar.prototype.renderPlugin = function () {
    var that = this;
    var el = this.main.dv.container.querySelector('#' + this.id);
    if (!el) return false;
    if (this.dateInputs) this.dateInputs.renderPlugin();
    // var inputs = el.parentElement.querySelectorAll("input[type='text'");
    this.iCal = new ICalendar(el, function (date, rangedate) {
        if (!that.rangeSelect) {
            that.selectedDate = date;
        } else {
            that.rangeStartDate = date;
            that.rangeEndDate = rangedate;
        }
    }, this.selectedDate, this.startDate, this.endDate, this.allowedWeekends, this.rangeSelect, this.rangeStartDate, this.rangeEndDate);
    this.iCal.render();

    if (!this.main.isReloading) {
        this.iCal.eventHandler();
    }

    return true;
};

Calendar.prototype.clear = function () {
    this.iCal.clear();
    this.iCal.target = null;
};

Calendar.prototype.resetData = function () {
    this.selectedDate = this.defaultDate;
    this.rangeStartDate = this.defaultStart;
    this.rangeEndDate = this.defaultEnd;
};

Calendar.prototype.enable = function () {
    this.iCal.target.classList.remove('disabled');
    this.iCal.eventHandler();
};

Calendar.prototype.disable = function () {
    this.iCal.target.classList.add('disabled');
    this.iCal.clear();
};

Calendar.updateInput = function (el, dateVal) {
    if (el) {
        el.value = dateVal.toLocaleDateString(undefined, {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
        });

        var event;
        if (typeof(Event) === 'function') {
            event = new Event('change');
        } else {
            event = document.createEvent('Event');
            event.initEvent('change', true, true);
        }
        el.dispatchEvent(event);

        el.setAttribute('disabled', true);
    }
};

function ICalendar(target, cb, selectedDate, startDate, endDate, weekend, rangeselect, rangeStartDate, rangeEndDate) {
    this.target = target;
    this.cb = cb;
    this.version = 'v1.0-alpha';
    this.months = {
        0: 'January',
        1: 'February',
        2: 'March',
        3: 'April',
        4: 'May',
        5: 'June',
        6: 'July',
        7: 'August',
        8: 'September',
        9: 'October',
        10: 'November',
        11: 'December',
    };
    this.today = new Date();
    this.currentMonth = this.today.getMonth();
    this.currentYear = this.today.getFullYear();
    this.selectYear = this.currentYear;
    this.selectMonth = this.currentMonth;
    this.selectedDate = (selectedDate && new Date(selectedDate)) || this.today;

    this.startDate = startDate && new Date(startDate);
    this.endDate = endDate && new Date(endDate);
    if (this.endDate && ((this.endDate.getFullYear() == this.currentYear && this.endDate.getMonth() < this.currentMonth) || this.endDate.getFullYear() < this.currentYear)) {
        this.currentMonth = this.endDate.getMonth();
        this.currentYear = this.endDate.getFullYear();
    }
    this.hasEndReached = !!(endDate && this.currentMonth == this.endDate.getMonth() && this.currentYear == this.endDate.getFullYear());
    this.hasBeginReached = !!(startDate && this.currentMonth == this.startDate.getMonth() && this.currentYear == this.startDate.getFullYear());
    // this.calendarBody = document.querySelector('.cal-frame table.curr tbody');
    this.events = [];
    this.apiUrl = null;
    this.eventsJSON = [];
    this.weekend = weekend;
    this.isRangeSelector = rangeselect;

    if (this.isRangeSelector) {
        this.selectedDate = null;

        this.rangeStartDate = rangeStartDate && new Date(rangeStartDate);
        this.rangeEndDate = rangeEndDate && new Date(rangeEndDate);
        this.ranges = [this.rangeStartDate, this.rangeEndDate];
    }

    this.inputs = target.parentElement.querySelectorAll("input[type='text'");
    if (this.inputs.length > 0) {
        if (this.selectedDate) {
            Calendar.updateInput(this.inputs[0], this.selectedDate);
        } else {
            for (var i = 0; i < this.inputs.length; i++) {
                if (this.ranges[i]) {
                    Calendar.updateInput(this.inputs[i], this.ranges[i]);
                }
            }
        }
    }
}

ICalendar.prototype.render = function () {
    var structure = '<div class="cal"><div class="header"><button class="left button prev"><span class="" id="prev"> &lang; </span></button><span class="month-year" id="label"> June 2020 </span><button class="next right button';
    structure += (this.hasEndReached ? ' disabled' : "");
    structure += '"><span class="" id="next"> &rang; </span></button></div>';
    structure += '<table class="days"><td';
    structure += this.weekend ? ' class="weekday"' : '';
    structure += '>sun</td><td class="weekday">mon</td><td class="weekday">tue</td><td class="weekday">wed</td><td class="weekday">thu</td><td class="weekday">fri</td><td';
    structure += this.weekend ? ' class="weekday"' : '';
    structure += '>sat</td></table>';
    structure += '<div class="cal-frame"><table class="curr"><tbody id="calendar-body"></tbody></table></div>';
    structure += '</div>';

    this.target.innerHTML = structure;
    this.updateCalendar(this.currentMonth, this.currentYear);
};

ICalendar.prototype.updateCalendar = function (month, year) {
    var firstDay = (new Date(year, month)).getDay();
    var tbl = this.target.querySelector('#calendar-body'); // body of the calendar
    // clearing all previous cells
    tbl.innerHTML = '';

    // filing data about month and in the page via DOM.
    var monthAndYear = this.target.querySelector('#label');
    monthAndYear.innerHTML = this.months[month] + ' ' + year;

    var date = 1; var cell; var cellText; var div;

    for (var calendarRow = 0; calendarRow < 6; calendarRow++) {
    // creates a table row
        var row = document.createElement('tr');

        // creating individual cells, filing them up with data.
        for (var callendarCell = 0; callendarCell < 7; callendarCell++) {
            if (calendarRow === 0 && callendarCell < firstDay) {
                cell = document.createElement('td');
                cell.classList.add('nil');
                cellText = document.createTextNode('');
                div = document.createElement('div');
                div.setAttribute('class', 'day');
                div.appendChild(cellText);
                cell.appendChild(div);
                row.appendChild(cell);
            } else if (date > this.daysInMonth(month, year)) {
                break;
            } else {
                cell = document.createElement('td');
                cellText = document.createTextNode(date);
                div = document.createElement('div');
                div.setAttribute('class', 'day');
                var a = document.createElement('button');
                var actualMonth = this.currentMonth;
                var dateObj = new Date(this.currentYear, actualMonth, date);

                a.setAttribute('data-id', dateObj.toDateString());
                a.setAttribute('class', 'calendarLink');
                a.appendChild(cellText);
                div.appendChild(a);
                // color today's date
                if (date === this.today.getDate() && year === this.today.getFullYear() && month === this.today.getMonth()) {
                    // cell.classList.add("bg-info");
                    div.classList.add('today');
                }

                if (this.selectedDate && date === this.selectedDate.getDate() && year === this.selectedDate.getFullYear() && month === this.selectedDate.getMonth()) {
                    // cell.classList.add("bg-info");
                    a.classList.add('selected-link');
                }

                // Check if date is weekend and apply styles
                if (!this.weekend && this.isWeekend(this.currentYear, this.currentMonth, date)) {
                    a.classList.add('weekend');
                }

                if ((this.hasEndReached && date > this.endDate.getDate()) || (this.hasBeginReached && date < this.startDate.getDate())) {
                    a.classList.add('outofrange');
                }
                if (this.isRangeSelector) {
                    this.setRangeColor(dateObj, a, cell);
                }

                cell.appendChild(div);
                row.appendChild(cell);
                date++;
            }
        }
        tbl.appendChild(row); // appending each row into calendar body.
    }
};

ICalendar.prototype.setRangeColor = function (dateObj, dateLink) {
    if (dateObj > this.rangeStartDate && dateObj < this.rangeEndDate) {
        dateLink.classList.add('selected-range-link');
    // cell.classList.add("nuan-p-0");
    } else if (this.rangeStartDate && dateObj.getDate() === this.rangeStartDate.getDate() && dateObj.getMonth() === this.rangeStartDate.getMonth() && dateObj.getFullYear() === this.rangeStartDate.getFullYear()) {
        dateLink.classList.add('selected-link');
        dateLink.classList.add('rangestart');
    // cell.classList.add("rangestart");
    } else if (this.rangeEndDate && dateObj.getDate() === this.rangeEndDate.getDate() && dateObj.getMonth() === this.rangeEndDate.getMonth() && dateObj.getFullYear() === this.rangeEndDate.getFullYear()) {
        dateLink.classList.add('selected-link');
        dateLink.classList.add('rangeend');
    // cell.classList.add("rangeend");
    }
};

ICalendar.prototype.next = function () {
    if (!this.endDate || this.currentYear < this.endDate.getFullYear() || this.currentMonth < this.endDate.getMonth()) {
        if (this.hasBeginReached) {
            this.target.querySelector('button.prev').classList.remove('disabled');
        }
        this.hasBeginReached = false;
        this.currentYear = (this.currentMonth === 11) ? this.currentYear + 1 : this.currentYear;
        this.currentMonth = (this.currentMonth + 1) % 12;
        this.hasEndReached = !!(this.endDate && this.currentMonth == this.endDate.getMonth() && this.currentYear == this.endDate.getFullYear());
        this.updateCalendar(this.currentMonth, this.currentYear);
    }
    if (this.hasEndReached) {
        this.target.querySelector('button.next').classList.add('disabled');
    }
};

ICalendar.prototype.previous = function () {
    if (!this.startDate || this.currentYear > this.startDate.getFullYear() || this.currentMonth > this.startDate.getMonth()) {
        if (this.hasEndReached) {
            this.target.querySelector('button.next').classList.remove('disabled');
        }
        this.hasEndReached = false;
        this.currentYear = (this.currentMonth === 0) ? this.currentYear - 1 : this.currentYear;
        this.currentMonth = (this.currentMonth === 0) ? 11 : this.currentMonth - 1;
        this.hasBeginReached = !!(this.startDate && this.currentMonth == this.startDate.getMonth() && this.currentYear == this.startDate.getFullYear());
        this.updateCalendar(this.currentMonth, this.currentYear);
    }
    if (this.hasBeginReached) {
        this.target.querySelector('button.prev').classList.add('disabled');
    }
};

ICalendar.prototype.daysInMonth = function (iMonth, iYear) {
    return 32 - new Date(iYear, iMonth, 32).getDate();
};

ICalendar.prototype.isWeekend = function (year, month, date) {
    var dt = new Date(year, month, date);
    if (dt.getDay() == 6 || dt.getDay() == 0) {
        return true;
    }
    return false;
};
ICalendar.prototype.prevButton = function (event) {
    event.preventDefault();
    this.previous();
};
ICalendar.prototype.nextButton = function (event) {
    event.preventDefault();
    this.next();
};
ICalendar.prototype.dateClicked = function (event) {
    var cl = event.target.classList;
    if (cl.contains('calendarLink') && !cl.contains('outofrange') && (!cl.contains('weekend') || this.weekend)) {
    // remove previously selected element
        var calendarLinks = this.target.querySelector('#calendar-body').querySelectorAll('.selected-link');

        // remove previously selected element
        var selectedElement = event.target;
        var d = new Date(selectedElement.getAttribute('data-id'));
        var k; var i;

        if (this.isRangeSelector) {
            if (this.ranges.length == 2 || (this.ranges.length == 1 && d < this.ranges[0])) {
                var rangeLinks = this.target.querySelector('#calendar-body').querySelectorAll('.selected-range-link');
                this.ranges = [];
                var len = calendarLinks.length;
                for (k = 0; k < len; k++) {
                    calendarLinks[k].classList.remove('selected-link');
                }
                len = rangeLinks.length;
                for (k = 0; k < len; k++) {
                    rangeLinks[k].classList.remove('selected-range-link');
                }
                for (i = 0; i < this.inputs.length; i++) {
                    this.inputs[i].value = '';
                }
            }
            this.ranges.push(d);
            this.rangeStartDate = this.ranges[0];
            this.rangeEndDate = this.ranges[1];
            selectedElement.classList.add('selected-link');
            var dateLink;
            if (this.rangeStartDate && this.rangeEndDate) {
                var days = this.target.querySelector('#calendar-body').querySelectorAll('.day');
                for (k = 0; k < days.length; k++) {
                    dateLink = days[k].firstChild;
                    if (dateLink.hasAttribute && dateLink.hasAttribute('data-id')) {
                        this.setRangeColor(new Date(dateLink.getAttribute('data-id')), dateLink, days[k].parentElement);
                    }
                }
                for (i = 0; i < this.inputs.length; i++) {
                    Calendar.updateInput(this.inputs[i], this.ranges[i]);
                }

                this.cb(this.rangeStartDate.toDateString(), this.rangeEndDate.toDateString());
            } else if (this.rangeStartDate) {
                Calendar.updateInput(this.inputs[0], d);
            }
        } else {
            for (i = 0; i < calendarLinks.length; i++) {
                calendarLinks[i].classList.remove('selected-link');
            }
            selectedElement.classList.add('selected-link');
            this.selectedDate = d;
            Calendar.updateInput(this.inputs[0], d);
            this.cb(d.toDateString());
        }
        event.preventDefault();
    }
};

ICalendar.prototype.eventHandler = function () {
    this.prevh = $bind(this, this.prevButton);
    this.nexth = $bind(this, this.nextButton);
    var prevButton = this.target.querySelector('.prev');
    prevButton.addEventListener('click', this.prevh);

    var nextButton = this.target.querySelector('.next');
    nextButton.addEventListener('click', this.nexth);

    this.datec = $bind(this, this.dateClicked);

    this.target.addEventListener('click', this.datec);
};

ICalendar.prototype.clear = function () {
    var prevButton = this.target.querySelector('.prev');
    prevButton.removeEventListener('click', this.prevh);

    var nextButton = this.target.querySelector('.next');
    nextButton.removeEventListener('click', this.nexth);
    this.target.removeEventListener('click', this.datec);
};
 // eslint-disable-line semi,quotes,no-trailing-spaces,no-unused-expressions
    function ListGroup(control) {
    this.selectedIndex = -1;
    ListGroup.super.call(this, control);
    this.defaultSelected = this.selectedIndex;
}
Utils.extend(ListGroup, Control);

ListGroup.prototype.onClickEvent = function (e) {
    if (e.target && e.target.tagName.toLowerCase() === 'li' && !this.isReload && this.selectable) {
        this.selectedIndex = parseInt(e.target.id.match(/\d+$/)[0], 10);

        this.selectedText = this.values.length > this.selectedIndex ? this.values[this.selectedIndex] : this.items[this.selectedIndex];
        var els = e.target.parentNode.querySelector('.active');
        if (els) {
            els.classList.remove("active");
            els.setAttribute("aria-selected", "false");
        }
        e.target.classList.toggle("active");
        e.target.setAttribute("aria-selected", "true");
        if (this.event) {
            ListGroup.superProto.invokeHandlers.call(this);
        } else if (this.resultCallback) {
            this.resultCallback();
        }
    }
};

ListGroup.prototype.getOutputText = function () {
    return this.selectedText;
};

ListGroup.prototype.registerHandlers = function () {
    RadioButton.prototype.registerHandlers.call(this);
};

ListGroup.prototype.getAnswerID = function () {
    return this.items[this.selectedIndex] || '';
};

ListGroup.prototype.getHTML = function () {
    if (this.main.isReloading) {
        this.isReload = true;
    }



    var lg = "<div class='list-group'>";
    if (this.closeList) {
        lg += "<button class='close' aria-label='close'></button>";
    }
    lg += "<ul role='listbox' style='padding:0px;margin:0px;' class='";
    lg += this.isReload ? "disabled'" : "'";
    lg += this.ariaLabel ?  " aria-label='" + (this.ariaLabel) + "'" : "";
    lg += this.ariaHidden ? " aria-hidden='" + this.ariaHidden + "'": "";
    lg += " id=";
    lg += this.id;
    lg += ">";

    lg += this.getContent();
    lg += "</div>";
    return lg;
};

ListGroup.prototype.enable = function () {
    this.isReload = false;
    var el = this.main.dv.container.querySelector('#' + this.id);
    el.classList.remove('disabled');
};

ListGroup.prototype.disable = function () {
    this.isReload = true;
    var el = this.main.dv.container.querySelector('#' + this.id);
    el.classList.add('disabled');
};

ListGroup.prototype.getCardHTML = function () {
    if (this.main.isReloading) {
        this.isReload = true;
    }

    var lg = ["<ul role='listbox' tabindex='0'  class='list-group list-group-flush", this.isReload ? "disabled'" : "'", this.ariaLabel ?  " aria-label='" + (this.ariaLabel) + "'" : "", "id=", this.id, ">"];

    lg.push(this.getContent());

    return lg.join('');
};

ListGroup.prototype.getContent = function () {
    var lg = "";
    var items = this.items || this.getFromConstant(this.items) || [];
    var values = this.values = this.values || [];
    var si = this.selectedIndex = this.selectedIndex > items.length - 1 ? items.length - 1 : this.selectedIndex;
    this.selectedText = values.length > si ? values[si] : items[si];


    if (this.header) {
        lg += "<li class='nuan-list-group-item list-header disabled'>";
        lg += this.header;
        lg += '</li>';
        this.encodeLabel(this.header);
    } else if (this.label) {
        lg += "<label class='nuan-label' ";
        lg += this.getStyle();
        lg += '>';
        lg += this.label;
        lg += '</label>';
        this.encodeLabel(this.label);
    } else if (this.hiddenLabel) {
        this.encodeLabel(this.hiddenLabel);
    }



    if (si != -1) {
        this.selectedText = values.length > si ? values[si] : items[si];
    }
    var active;
    items.forEach(function (item, index) {
        lg += "<li class='nuan-list-group-item";
        active = index === si;
        if (active) {
            lg += ' active';
        }
        if (this.selectable) {
            lg += ' cursor-poiner';
        }
        lg += "' id=";
        lg += this.id;
        lg += '-';
        lg += index;
        if (active) {
            lg += " aria-selected='true'";
        } else {
            lg += " aria-selected='false'";
        }

        if (this.selectable) {
            lg += " role='option' tabindex='0'";
        }
        lg += '>';
        lg += item;
        lg += '</li>';
    }, this);

    lg += '</ul>';
    this.registerHandlers();
    return lg;
};

function ListSelectGroup(control) {
    this.selectable = true;
    ListSelectGroup.super.call(this, control);
}
Utils.extend(ListSelectGroup, ListGroup);
ListSelectGroup.prototype.resetData = function () {
    this.selectedIndex = this.defaultSelected;
    if (this.selectedIndex >= 0) {
        this.selectedText = this.values[this.selectedIndex] || this.items[this.selectedIndex];
    }
};

ListSelectGroup.prototype.isPlugin = function () {
    if (this.setFocus || this.closeList) {
        return true;
    }
    return false;
};

ListSelectGroup.prototype.renderPlugin = function () {
    this.el = this.main.dv.container.querySelector('#' + this.id);
    if (!this.el) {
        return false;
    }
    var children = this.el.children;
    var firstSelChild = this.header ? 1 :0;
    if (this.closeList) {
        this.closeHandler = $bind(this, this.onClose);
        this.closeEl = this.el.previousSibling;
        this.closeEl.addEventListener("click", this.closeHandler);
    }
    children[firstSelChild].focus();

    return true;
};

ListSelectGroup.prototype.clear = function () {
    if (this.closeEl) {
        this.closeEl.removeEventListener("click", this.closeHandler);
    }
};

ListSelectGroup.prototype.onClose = function () {
    if (this.resultCallback) {
        this.resultCallback();
    }
};

ListGroup.prototype.getFromConstant = function (key) {
    var result = Utils.parseValueFromConstant(key, this.main);
    if (result) {
        if (Array.isArray(result)) {
            return result;
        }
        return [result];
    }
    return null;
};

 // eslint-disable-line semi,quotes,no-trailing-spaces,no-unused-expressions
    function Card(control) {
    Card.super.call(this, control);
}

Utils.extend(Card, Control);

Card.prototype.onClickEvent = function (e) {
    if (e.target && this.isEnabled) {
        if (this.event) {
        	this.deck.toggleSelection(this);
            Card.superProto.invokeHandlers.call(this);
        }
    }
};

Card.prototype.registerHandlers = function () {
    // if(this.event) {
    // this.main.ev.registerEventHandler(this, "onClick");
    // }
};

Card.prototype.getHTML = function (index) {
    this.registerHandlers();
    var that = this;
    that.id = that.id || index;

    if (that.size) {
        this.width = that.size;
    }
    if (that.context) {
        this.background = that.context.color;
        this.border = that.context.cardBorder;
        this.borderRadius = that.context.cardBorderRadius;
        this.boxShadow = that.context.cardShadow;
    }

    var card = "<div class='card nuan-card mt-2 nuan-card-width' id='";
    card += that.id;
    card += "'";
    card += this.ariaHidden ? " aria-hidden='" + this.ariaHidden + "'": "";
    card += this.getStyle();
    if (this.event) {
    	card += " role='button'";
    }
    card += '>';
    this.background = undefined;
    this.border = undefined;
    this.width = undefined;

    var body = "";
    if (that.cardTitle || that.cardSubTitle || that.cardText) {
        body += "<div class='card-body";/* ,that.cardButtons?" card-body-padding":"" */
        body += "'>";
        if (that.cardTitle) {
            body += "<h5 class='card-title' ";
            if (that.context) {
                body += this.getTextStyle(that.context.title, that.context.titleSize, that.context.titleStyle);
            }
            body += '>';
            body += that.cardTitle;
           body += '</h5>';
        }
        if (that.cardSubTitle) {
            body += "<h6 class='card-subtitle mb-2 text-muted' ";
            if (that.context) {
                body += this.getTextStyle(that.context.subTitle, that.context.subTitleSize, that.context.subTitleStyle);
            }
            body += '>';
            body += that.cardSubTitle;
            body += '</h6>';
        }

        if (that.cardText) {
            body += "<p class='card-text' ";
            if (that.context) {
                body += this.getTextStyle(that.context.text, that.context.textSize, that.context.textStyle);
            }
            body += '>';
            body += that.cardText;
            body += '</p>';
        }
        body += '</div>';
    }
    this.textColor = undefined;

    if (that.listGroup) {
        that.list_group = new ListGroup(that.listGroup);
        that.list_group.main = that.main;
        body += that.list_group.getCardHTML();
    }

    if (that.columnSet) {
        var c = new ColumnSet(that.columnSet);
        c.parentWidget = 'card';
        c.main = that.main;
        body += c.getHTML();
        that.columnSet = c;
    }

    if (that.context && that.context.bottomSeparator) {
        that.cardBottom = that.context.bottomSeparator;
        body += "<hr class='body-separator'";
        body += this.getStyle();
        body += '/>';
    }

    if (that.cardButtons) {
        body += "<div class='card-bottom' ";
        // that.cardBottom = that.context && that.context.bottomborder;
        // body.push(this.getStyle());

        body += '>';
        that.cardButtons.forEach(function (button, index) {
            button = new Button(button);
            button.main = that.main;
            body += button.getHTML();
            that.cardButtons[index] = button;
        });
        body += '</div>';
        that.cardBottom = undefined;
    }
    that.cardBottom = undefined;

    if (that.header) {
        card += "<h5 class='card-header' ";
        if (that.context) {
            that.headerBackground = that.context.headerBackground;
            that.textColor = that.context.header;
        }
        card += this.getStyle();
        card += '>';
        card += that.header;
        card += '</h5>';
        card += body;
        that.headerBackground = undefined;
        that.textColor = undefined;
    } else if (that.cardImage) {
        that.cardImage.borderRadius = this.borderRadius;
        if (that.cardImage.position === 'top') {
            if (that.cardImage.width || that.cardImage.height) {
                if (that.cardImage.spaceAround) {
                    card += "<div class='card-custom-img-top'>";
                }

                card += "<div class='card-img-top' ";
                card += this.getImageStyle.call(that.cardImage);
                card += '></div>';
                if (that.cardImage.spaceAround) {
                    card += '</div>';
                }
            } else {
                card += "<img src='";
                card += that.cardImage.src;
                card += "' class='card-img-top'";
                if (that.size) {
                    card += "style='height:";
                    card += that.size / 1.33;
                    card += ";'";
                }
                card += ' ></img>';
            }
            card += body;
        } else if (that.cardImage.position == 'left') {
            card += "<div class='nuan-row-card no-gutters'><div class='card-left-section ";
            if (that.cardImage.width && that.cardImage.height) {
                if (that.cardImage.spaceAround) {
                    card += 'card-custom-img-left';
                }
                card += "'><div class='card-img-left card-custom-left-img' ";
                card += this.getImageStyle.call(that.cardImage);
                card += '></div>';
            } else {
                // card.push("'> <img src='",that.cardImage.src,"' class='card-img-left'></img>");
                card += "'>";
            }
            card += '</div>';
            card += "<div class='col-fx-10 card-section' style='position:relative;";
            card +=  "'> ";
            card += body;
            card += '</div></div>';
        } else {
            card += "<img src='";
            card += that.cardImage.src;
            card += "' class='card-img'";
            if (this.cardImage.width) {
                card += ' width=';
                card += this.cardImage.width;
            }
            if (this.cardImage.height) {
                card += ' height=';
                card += this.cardImage.height;
            }
            card += ' ></img>';
            card += "<div class='card-img-overlay'>";
            card += body;
            card += '</div>';
        }
    } else {
        card += body;
    }
    card += '</div>';

    if (this.hiddenLabel && this.id) {
        this.encodeLabel(this.hiddenLabel);
    }

    return card;
};

Card.prototype.enable = function () {
    this.cardButtons.forEach(function (b) {
        b.enable();
    });
    if (this.columnSet) {
        this.columnSet.enable();
    }
    this.isEnabled = true;
};

Card.prototype.getTextStyle = function (color, size, style) {
    if (color || size || style) {
        this.textColor = color;
        this.textSize = size;
        this.textStyle = style;
        return this.getStyle();
    }
    return null;
};

Card.prototype.disable = function () {
    if (this.cardButtons) {
        this.cardButtons.forEach(function (b) {
            b.disable();
        });
    }

    if (this.columnSet) {
        this.columnSet.disable();
    }
    this.isEnabled = false;
};

Card.prototype.executeValidation = function () {
    if (this.columnSet) {
        return this.columnSet.executeValidation();
    }
    return true;
};


Card.prototype.getImageStyle = function () {
    var style = "style='";
    if (this.src) {
        style += 'background-image:';
        style += Control.setStyleVal('url(' + Control.getImageUrl(this.src) + ')');
        style += 'background-size:';
        if (this.width && this.height) {
            style += Control.setStyleVal(this.width + 'px ' + this.height + 'px');
        } else {
            style += 'contain;';
        }
        style += 'background-repeat:';
        style += Control.setStyleVal('no-repeat');
        style += 'background-position:';
        if (this.align) {
            style += Control.setStyleVal(this.align);
        } else {
            style += Control.setStyleVal('center');
        }

        if (this.position == 'top' && this.setImageBorder) {
            style += 'border-top-right-radius:';
            style += Control.setStyleVal(this.borderRadius + 'px');
            style += 'border-top-left-radius:';
            style += Control.setStyleVal(this.borderRadius + 'px');
        }

        if (this.position == 'left' && this.setImageBorder) {
            style += 'border-bottom-left-radius:';
            style += Control.setStyleVal(this.borderRadius + 'px');
            style += 'border-top-left-radius:';
            style += Control.setStyleVal(this.borderRadius + 'px');
        }

        if (this.height) {
            style += 'height:';
            style += Control.setStyleVal(this.height + 'px');
        }
    }
    style += "'";
    return style;
};

Card.prototype.isPlugin = function (deckInstance) {
    if (this.event && this.id) {
    	this.deck = deckInstance;
        return true;
    }
    if (this.columnSet) {
    	return true;
    }
    return false;
};

Card.prototype.renderPlugin = function () {
	if (!this.id || this.main.isReloading) {
		return true;
	}
    this.el = this.main.dv.container.querySelector('#' + this.id);
    if (!this.el) return false;

    if (this.deck) {
    	this.cardclick = $bind(this, this.onClickEvent);
    	this.el.addEventListener('click', this.cardclick);
    	this.offLeft = this.el.offsetLeft;
    	this.width = this.el.offsetWidth;
	}

	if (this.columnSet) {
        this.columnSet.renderPlugin();
    }

    return true;
};

Card.prototype.clear = function () {
    // var el = this.main.dv.container.querySelector("#"+this.id);
    if (this.el) {
        this.el.removeEventListener('click', this.cardclick);
    }
};

Card.prototype.getCard = function () {
    this.el = this.el || this.main.dv.container.querySelector('#' + this.id);
    return this.el;
};


Card.prototype.getOffsetLeft = function () {
	this.offLeft = this.offLeft || this.getCard().offsetLeft;
    return this.offLeft;
};

Card.prototype.getWidth = function () {
	this.width = this.width || this.getCard().offsetWidth;
    return this.width;
};

Card.prototype.toggleSelection = function (selected) {
	var sb = this.context.cardSelectedBorder;
	if (sb) {
		this.el.style.borderColor = selected ? sb : this.context.cardBorder;
	}
};

Card.prototype.getAriaOutput = function () {
	var ariaOut = null;
	if (this.columnSet) {
		ariaOut = this.columnSet.getAriaOutput();
	}
    return ariaOut;
};
 // eslint-disable-line semi,quotes,no-trailing-spaces,no-unused-expressions
    function CardDeck(control) {
    CardDeck.super.call(this, control);
}

Utils.extend(CardDeck, Control);

CardDeck.prototype.getHTML = function () {
    var deck = "<div class='nuan-row-card nuan-card-deck mt-2 flex-nowrap' id=";
    deck += this.id;
    deck += this.ariaHidden ? " aria-hidden='" + this.ariaHidden + "'": "";
    deck += '>';
    if (this.context) {
        this.background = this.context.color;
        this.border = this.context.cardBorder;
    }
    if (this.hiddenLabel && this.id) {
        this.encodeLabel(this.hiddenLabel);
    }

    this.cards.forEach(function (card, index) {
        card = new Card(card);
        card.main = this.main;
        deck += "<div class='deck-size' >";
        deck += card.getHTML(this.id + index);
        deck += '</div>';
        this.cards[index] = card;
    }, this);
    if (this.navigationStyle == "dot") {
        deck += '</div>';
        deck += "<nav class='carousel-nav fixed-nav'";
        deck += this.ariaHidden ? " aria-hidden='" + this.ariaHidden + "'": "";
        deck += "><button class='disabled prev' id='prev' role='button'><i class='arrow-left icon'></i><span class='sr-only'>Previous</span></button><ul class='nav-dot-cont'>";
        deck += "</ul><button class='next' id='next' role='button'><i class='arrow-right icon'></i><span class='sr-only'>Next</span></button></nav>";
    } else {
        deck += "</div><nav class='carousel-nav'><button class='disabled prev' id='prev' role='button'><i class='arrow-left icon'></i><span class='sr-only'>Previous</span></button><button class='next' id='next' role='button'><i class='arrow-right icon'></i><span class='sr-only'>Next</span></button></nav>";
    }
    return deck;
};

CardDeck.prototype.isPlugin = function () {
    return true;
};

/**
 * return selected card Id or if the card has button then find if button is clicked then return it
 * @return {string}
 **/
CardDeck.prototype.getOutputText = function () {
    if (this.selectedCardID) {
        return this.selectedCardID;
    } else {
        for (var i = 0; i < this.cards.length; i++) {
            if (this.cards[i].cardButtons) {
                for (var k = 0; k < this.cards[i].cardButtons.length; k++) {
                    if (this.cards[i].cardButtons[k].clicked) {
                        return  this.cards[i].id;
                    }
                }
            }
        }
    }
    return "";

};

CardDeck.prototype.renderPlugin = function () {
    if (this.id) {
        this.el = this.main.dv.container.querySelector('#' + this.id);
        if (!this.el) {
            return false;
        }
        intializeScrollable(this.el, this);
        if (!this.main.isReloading) {
            this.cards.forEach(function (card) {
                if (card.isPlugin(this)) {
                    card.renderPlugin();
                }
            }.bind(this));
        }
        var ow = this.el.offsetWidth;
        this.cardsPerPage = Math.floor((ow + 30) / (this.cards[0].getWidth() + 30));

        if (this.navigationStyle == 'dot') {
            this.navDots = [];
            var con =  this.el.parentElement.querySelector(".nav-dot-cont");
            for (var i = 0; i < this.cards.length/this.cardsPerPage; i++) {
                var li = document.createElement("li");
                li.classList.add("nav-dots");
                if (i == 0) {
                    li.classList.add("highlight");
                }
                this.navDots.push(li);
                con.appendChild(li);
            }
        }

        if (this.useSwipe) {
            this.el.style.overflow = "hidden";
        }
    }
    return true;
};

CardDeck.prototype.enable = function () {
    this.cards.forEach(function (card) {
        card.enable();
    });
    intializeScrollable(this.el, this);
};


CardDeck.prototype.disable = function () {
    this.cards.forEach(function (card) {
        card.disable();
    });
};

CardDeck.prototype.getScrollRightSize = function () {
	var sl = this.el.scrollLeft;
	var ow = this.el.offsetWidth;
	for (var ci = this.cards.length - 1; ci >= 0; ci--) {
        var oLeft = this.cards[ci].getOffsetLeft() - 16;
        var visible = ((oLeft - sl) + this.cards[ci].getWidth()) <= ow;
        if (visible) {
           if (ci < this.cards.length - 1) {

                // Cards to be scrolled into view.
                var startIdx = (ci + 1);
                for(var i = startIdx; i < (startIdx + this.cardsPerPage) && (i < this.cards.length); i++){
                    this.cardScrolledIntoView(this.cards[i]);
                }

           		if (this.navDots) {
           			var page = (ci + 1) /this.cardsPerPage;
           			this.navDots[page - 1].classList.remove("highlight");
           			this.navDots[page].classList.add("highlight");
           		}
           		return this.cards[ci + 1].getOffsetLeft() - 15;
           }
           break;
        }
    }
    return sl;
};

CardDeck.prototype.getScrollLeftSize = function () {
	var sl = this.el.scrollLeft;
	var ow = this.el.offsetWidth;
	for (var ci = 0; ci < this.cards.length; ci++) {
        var oLeft = this.cards[ci].getOffsetLeft();
        var visible = oLeft >= sl;
        if (visible && ci > 0) {

            // Cards to be scrolled into view.
            var startIdx = ci - this.cardsPerPage;
            if (startIdx < 0) {
                startIdx = 0;
            }
            for(var i = startIdx; i < (startIdx + this.cardsPerPage) && (i < this.cards.length); i++){
                this.cardScrolledIntoView(this.cards[i]);
            }

        	if (this.navDots) {
    			var page = Math.ceil(ci / this.cardsPerPage);
       			this.navDots[page].classList.remove("highlight");
       			this.navDots[page - 1].classList.add("highlight");
       		}
    		var offset = this.cards[ci - 1].getWidth() - (sl - this.cards[ci - 1].getOffsetLeft());
       		return sl - (ow - offset) + 1;
        }
    }
    return sl;
};

/**
 * Actions to be done once a card in a CardDeck is being scrolled
 * into view.
 * @param {Card} card - Card object that is being displayed
 */
CardDeck.prototype.cardScrolledIntoView = function (card) {

    // if a viewRenderedCb is registered send out aria outputs
    // for the scrolled card
    if (card && card.main && card.main.viewRenderedCb){
        var ariaOut = card.getAriaOutput();
        if (ariaOut) {
            card.main.viewRenderedCb([ariaOut]);
        }
    }
};

CardDeck.prototype.toggleSelection = function(selCard) {
     this.selectedCardID = selCard.id;
	 this.cards.forEach(function (card) {
	 	 if (selCard.id == card.id) {
	 	 	card.toggleSelection(true);
	 	 }	else {
	 	 	card.toggleSelection(false);
	 	 }
	 });
};

CardDeck.prototype.clear = function () {
    if (this.prev) {
        this.prev.removeEventListener('mousedown', this.mouseDownHandler);
        this.prev.removeEventListener('mouseup', this.mouseUpPrevHandler);
    }
    if (this.next) {
        this.next.removeEventListener('mousedown', this.mouseDownHandler);
        this.next.removeEventListener('mouseup', this.mouseUpNextHandler);
    }
    if (this.swipe) {
    	this.swipe.destroy();
    }
    this.next = null;
    this.prev = null;
    this.mouseDownHandler = null;
    this.mouseUpPrevHandler = null;
    this.mouseUpNextHandler = null;
    this.nextDisabled = this.prevDisabled = false;
    this.cards.forEach(function (card) {
        if (card.isPlugin()) {
            card.clear();
        }
    });
};

CardDeck.prototype.getAriaOutput = function () {
    var cardAriaOut = this.cards[0].getAriaOutput();
    return  {
    	"text": (cardAriaOut ? cardAriaOut.text : ""),
    	"length": this.cards.length,
    	"type": this.type
    };
};
 // eslint-disable-line semi,quotes,no-trailing-spaces,no-unused-expressions
    function TextArea(control) {
    TextArea.super.call(this, control);
}
Utils.extend(TextArea, Input);

TextArea.prototype.getHTML = function () {
    this.registerHandlers();
    var inp = "<fieldset class='nuan-fieldset w-100' style='padding-left:2px;padding-right:2px;padding-top:";
    inp += (this.material ? '.4em' : '2px');
    inp += ";padding-bottom:2px;'";

    if (this.ariaLabel || this.helpText) {
        inp += " aria-label='" + (this.ariaLabel || this.helpText) + "'";
    }

    inp += this.ariaHidden ? " aria-hidden='" + this.ariaHidden + "'": "";
    inp += ">";

    if (this.label && !this.material) {
        inp += "<label class='nuan-label' ";
        inp += this.getStyle();
        inp += '>';
        inp += this.label;
        inp += '</label>';
        this.encodeLabel(this.label);
    } else if (this.hiddenLabel) {
        this.encodeLabel(this.hiddenLabel);
    }

    inp += "<div style='position:relative;" + this.addPadding.call(this.controlContext) + "'><textarea class='nuan-textarea nuan-form-control";
    inp += (this.material ? ' nuan-form-control-material' : '');
    inp +=  "'";
    inp += ' id=';
    inp += this.id;

    if (this.placeholder && !this.material) {
        inp += " placeholder='";
        inp += this.placeholder;
        inp += "'";
    }
    if (!this.isEnabled || this.main.isReloading) {
        inp += ' disabled';
    }
    if (this.material) {
        inp += ' required';
    }

    if (this.rows) {
        inp += ' rows=';
        inp += this.rows;
    }
    if(this.controlContext) {
        inp += (" " + this.getStyle.call(this.controlContext));
    }

    inp += '>';
    if (this.text) {
        inp += this.text;
    }

    inp += "</textarea><span class='validation-error-icon'></span>";
    if (this.material) {
        inp += "<label class='nuan-label-material' ";
        inp += this.getStyle();
        inp += '>';
        inp += this.label;
        inp += '</label>';
    }
   	inp += '</div>';
    inp += this.addValidationTags();
    if (this.helpText) {
        inp += "<small class='form-text text-muted'>";
        inp += this.helpText;
        inp += '</small>';
    }

    inp += '</fieldset>';

    return inp;
};
 // eslint-disable-line semi,quotes,no-trailing-spaces,no-unused-expressions
    function Rating(control) {
    this.selectedRating = -1;
    Rating.super.call(this, control);

}
Utils.extend(Rating, Control);

Rating.prototype.getOutputText = function () {
    return this.ratingText;
};

Rating.prototype.getHTML = function () {
    this.selectedRating = this.defaultSelected = parseInt(Utils.parseValueFromConstant(this.selectedRating, this.main));
    var rat = "<div class='nuan-rating' id='";
    rat += this.id + "'";
    rat += this.ariaHidden ? " aria-hidden='" + this.ariaHidden + "'>": ">";
    rat +=  "<fieldset role='group' ";
    if (this.ariaLabel) {
        inp += " aria-label='" + (this.ariaLabel ) + "'";
    }
    rat += ">";

    if (this.label) {
        rat += "<label class='nuan-label' ";
        rat += this.getStyle();
        rat += '>';
        rat += this.label;
        rat += '</label>';
        this.encodeLabel(this.label);
    } else if (this.hiddenLabel) {
        this.encodeLabel(this.hiddenLabel);
    }
    rat += "<div class='nuan-text-center'>";
    for (var i = 0; i < this.ratingScale.length; i += 1) {
        rat += "<button class='";
        rat += this.ratingScale[i];
        rat += (i < (this.selectedRating + 1) ? ' selected' : '');
        rat += "' data-indicator='";
        rat += (i + 1);
        rat += "'";
        rat +=  this.ratingScale[i];
        rat += (i == this.selectedRating ? ' aria-pressed=true' : '');
        if (this.ariaValues) {
            rat += " aria-label='";
            rat += this.ariaValues[i] || "";
            rat += "'";
        }
        rat += (this.main.isReloading ? ' disabled' : '', '></button>');
    }

    rat += '</div>';

    rat += this.addValidationTags();
    rat += '</fieldset></div>';

    return rat;
};

Rating.prototype.isPlugin = function () {
    return true;
};

Rating.prototype.renderPlugin = function () {
    var el = this.main.dv.container.querySelector('#' + this.id);
    if (!el) return false;
    this.el = el;
    this.registerEvents();
    if (this.values) {
        this.ratingText = this.selectedRating < this.values.length ? this.values[this.selectedRating] : '';
    }
    if (this.text) {
       this.selectedText = this.selectedRating < this.text.length ? this.text[this.selectedRating] : '';
    }

    return true;
};
Rating.prototype.registerEvents = function () {
    this.hl = this.highlight.bind(this);
    this.rs = this.resetSelected.bind(this);
    this.ra = this.ratingAction.bind(this);
    this.el.addEventListener('mouseover', this.hl, false);
    this.el.addEventListener('focus', this.hl, true);
    this.el.addEventListener('mouseleave', this.rs, true);
    this.el.addEventListener('blur', this.rs, true);

    this.el.addEventListener('click', this.ra, false);
};

Rating.prototype.disable = function () {
    this.clear();
    var stars = this.el.querySelectorAll('button');
    stars.forEach(function (star) {
        star.setAttribute('disabled', true);
    });
};

Rating.prototype.enable = function () {
    this.registerEvents();
    var stars = this.el.querySelectorAll('button');
    stars.forEach(function (star) {
        star.removeAttribute('disabled');
    });
};

Rating.prototype.validate = function (val) {
    if (val == 'required' && this.selectedRating == -1) {
        return false;
    }
    return true;
};

Rating.prototype.addInvalid = function (el) {
    el.classList.add('invalid');
    // el.setAttribute("aria-invalid", true);
};

Rating.prototype.forceFocus = function() {
    var el = this.actualEl.firstElementChild;
    el.focus();
    el.scrollIntoView({block: 'nearest'});
};

Rating.prototype.removeInvalid = function (el) {
    el.classList.remove('invalid');
    // el.setAttribute("aria-invalid", true);
};

Rating.prototype.ratingAction = function (event) {
    event.preventDefault();
    var selected = event.target;
    if (!selected) return;
    var selectedIndex = parseInt(selected.getAttribute('data-indicator'), 10);
    var stars = Array.from(this.el.querySelectorAll('button'));
    stars.forEach(function (star, index) {
        if (index < selectedIndex) {
            star.classList.add('selected');
        } else {
            star.classList.remove('selected');
        }
    });

    var previousRating = this.el.querySelector('button[aria-pressed="true"]');
    if (previousRating) {
        previousRating.removeAttribute('aria-pressed');
    }
    selected.setAttribute('aria-pressed', true);

    this.selectedRating = selectedIndex - 1;
    if (this.values) {
        this.ratingText = this.selectedRating < this.values.length ? this.values[this.selectedRating] : '';
    }
    if (this.text) {
       this.selectedText = this.selectedRating < this.text.length ? this.text[this.selectedRating] : '';
    }
    this.resetErrors();

    if (this.event) {
        Rating.superProto.invokeHandlers.call(this);
    }
};

Rating.prototype.highlight = function (event) {
    var form = event.target.closest('.nuan-rating');
    var indicator = event.target.closest('button');
    if (!indicator || !form) return;
    var selectedIndex = parseInt(event.target.getAttribute('data-indicator'), 10);
    var stars = Array.from(form.querySelectorAll('button'));
    stars.forEach(function (star, index) {
        if (index < selectedIndex) {
            star.classList.add('selected');
        } else {
            star.classList.remove('selected');
        }
    });
};

Rating.prototype.resetSelected = function (event) {
    if (!event.target.closest) return;

    var form = event.target.closest('.nuan-rating');
    if (!form) return;
    var stars = Array.from(form.querySelectorAll('button'));

    var selected = form.querySelector('button[aria-pressed="true"]');
    var selectedIndex = selected ? parseInt(selected.getAttribute('data-indicator'), 10) : 0;

    stars.forEach(function (star, index) {
        if (index < selectedIndex) {
            star.classList.add('selected');
        } else {
            star.classList.remove('selected');
        }
    });
};

Rating.prototype.clear = function () {
    if (this.el) {
        this.el.removeEventListener('mouseover', this.hl, false);
        this.el.removeEventListener('focus', this.hl, true);
        this.el.removeEventListener('mouseleave', this.rs, true);
        this.el.removeEventListener('blur', this.rs, true);
        this.el.removeEventListener('click', this.ra, false);
    }
};

Rating.prototype.resetData = function () {
    this.selectedRating = this.defaultSelected;
};
 // eslint-disable-line semi,quotes,no-trailing-spaces,no-unused-expressions
    function InputGroup(control) {
    InputGroup.super.call(this, control);
}
Utils.extend(InputGroup, Input);

InputGroup.prototype.getHTML = function () {
    this.registerHandlers();
    var inp = "<fieldset class='nuan-fieldset ";
    inp += (this.type == 'ShortInput' ? 'col-md-6' : 'w-100');
    inp += "' style='padding-left:2px;padding-right:2px;padding-top:2px;padding-bottom:2px;'";

    if (this.ariaLabel) {
        inp += " aria-label='" + this.ariaLabel + "'";
    }

    inp += this.ariaHidden ? " aria-hidden='" + this.ariaHidden + "'": "";
    inp += ">";
    if (this.label) {
        inp += "<label class='nuan-label' ";
        inp += this.getStyle();
        inp += '>';
        inp += this.label;
        inp += '</label>';
        this.encodeLabel(this.label);
    } else if (this.hiddenLabel) {
        this.encodeLabel(this.hiddenLabel);
    }
    inp += "<div class='nuan-input-group'>";
    if (this.inputGroupText) {
        inp += "<div class='nuan-input-group-prepend'><span class='nuan-input-group-text'>";
        inp += this.inputGroupText;
        inp += '</span></div>';
    }
    inp += "<input type='text' class='nuan-input nuan-form-control'";
    inp += ' id=';
    inp += this.id;

    if (this.placeholder) {
        inp += " placeholder='";
        inp += this.placeholder;
        inp += "'";
    }
    if (!this.isEnabled || this.main.isReloading) {
        inp += ' disabled';
    }

    if (this.text) {
        inp += ' value=';
        inp += this.text;
    }

    inp += '></input>';
    inp += "<span class='validation-error-icon'></span></div>";
    inp += this.addValidationTags();
    if (this.helpText) {
        inp += "<small class='form-text text-muted'>";
        inp += this.helpText;
        inp += '</small>';
    }

    inp += '</fieldset>';
    return inp;
};
 // eslint-disable-line semi,quotes,no-trailing-spaces,no-unused-expressions
    function ColumnSet(control) {
    ColumnSet.super.call(this, control);
}

Utils.extend(ColumnSet, Control);

ColumnSet.prototype.registerHandlers = function () {
    ColumnSet.superProto.registerHandlers.call(this);
};

ColumnSet.prototype.getHTML = function () {
    var cl = "<div ";
    cl += (this.id ? "id=" + this.id : "");
    cl += " class='nuan-row flex-container w-100";
    if (Array.isArray(this.spaceAround)) {
        cl += "' style='padding:";
        cl += this.getSpaceAroundVals(this.spaceAround);
        cl += ";'";
    }
    cl += "'>";

    if (this.columns) {
        this.columns.forEach(function (column) {
            if (column.type == 'Column') {
                cl += this.getColumn(column);
            }
        }, this);
    }

    cl += '</div>';
    this.registerHandlers();
    return cl;
};

ColumnSet.prototype.executeValidation = function () {
    return this.columns.every(function (column) {
        if (column.items) {
            return column.items.every(function (item) {
                return item.executeValidation();
            });
        } else {
            return true;
        }
    });
};

ColumnSet.prototype.enable = function () {
    for (var i = 0; i < this.columns.length; i++) {
        if (this.columns[i].items) {
            for (var k = 0; k < this.columns[i].items.length; k++) {
                this.columns[i].items[k].enable();
            }
        }
    }
};

ColumnSet.prototype.disable = function () {
    for (var i = 0; i < this.columns.length; i++) {
        if (this.columns[i].items) {
            for (var k = 0; k < this.columns[i].items.length; k++) {
                this.columns[i].items[k].disable();
            }
        }
    }
};

ColumnSet.prototype.getColumn = function (col) {
    var cl = "<div class='";
    cl += (col.width ? 'col-md-' + col.width : 'col-md-12');
    if (Array.isArray(col.spaceAround)) {
        cl += "' style='padding:";
        cl += this.getSpaceAroundVals(col.spaceAround);
        cl += ";'";
    }
    cl += "'>";
    if (col.items) {
        var i = 0;
        col.items.forEach(function (item) {
            cl += "<div class='nuan-row flex-container nuan-column-item";
            if (col.align == 'middle') {
                cl += ' align-middle';
            } else if (col.align == 'bottom') {
                cl += ' align-bottom';
            }

            var obj = Control.createControlInstance(item, this.main);
            if (obj.spaceAround) {
                cl += ' mt-0';
            }
            col.items[i] = obj;

            // cl.push(that.getContainerAlignment(obj));
            // cl.push(that.showSeperator(obj));
            cl += "'";
            cl += Control.getContStyleProps(obj);
            cl += '>';

            obj.main = this.main;
            cl += obj.getHTML();
            cl += '</div>';
            i += 1;
        }, this);
    }

    cl += '</div>';
    return cl;
};

ColumnSet.prototype.isPlugin = function () {
    return true;
};

ColumnSet.prototype.renderPlugin = function () {
    for (var i = 0; i < this.columns.length; i++) {
        if (this.columns[i].items) {
            for (var k = 0; k < this.columns[i].items.length; k++) {
                if (this.columns[i].items[k].isPlugin()) {
                    this.columns[i].items[k].renderPlugin();
                }
            }
        }
    }
    return true;
};

ColumnSet.prototype.getSpaceAroundVals = function (spaces) {
    var s = [];
    spaces.forEach(function (v) {
        s.push(v + 'px');
    });

    return s.join(' ');
};

ColumnSet.prototype.getColumnForMapper = function (id) {
    for (var i = 0; i < this.columns.length; i++) {
        if (id == this.columns[i].id) {
            return this.columns[i];
        }
    }
    return null;
};

ColumnSet.prototype.resetData = function () {
    for (var i = 0; i < this.columns.length; i++) {
        if (this.columns[i].items) {
            for (var k = 0; k < this.columns[i].items.length; k++) {
                this.columns[i].items[k].resetData();
            }
        }
    }
};

ColumnSet.prototype.resetRendering = function () {
   for (var i = 0; i < this.columns.length; i++) {
        if (this.columns[i].items) {
            for (var k = 0; k < this.columns[i].items.length; k++) {
                this.columns[i].items[k].resetRendering();
            }
        }
    }
};

ColumnSet.prototype.clear = function () {
    for (var i = 0; i < this.columns.length; i++) {
        if (this.columns[i].items) {
            for (var k = 0; k < this.columns[i].items.length; k++) {
                if (this.columns[i].items[k].isPlugin()) {
                    this.columns[i].items[k].clear();
                }
            }
        }
    }
};

ColumnSet.prototype.getAriaOutput = function () {
    var text = "",val;
    for (var i = 0; i < this.columns.length; i++) {
        if (this.columns[i].items) {
            for (var k = 0; k < this.columns[i].items.length; k++) {
                val = this.columns[i].items[k].getAriaOutput();
                if (val) {
                    if (text) {
                        text += " ";
                    }
                    text += val.text;
                }
            }
        }
    }
    return {"text":text};
}; // eslint-disable-line semi,quotes,no-trailing-spaces,no-unused-expressions
    function RowSet(control) {
    RowSet.super.call(this, control);
}
Utils.extend(RowSet, Control);

RowSet.prototype.getHTML = function () {
    var cl = ["<div class='nuan-row w-100"];
    if (Array.isArray(this.spaceAround)) {
        cl.push("' style='padding:");
        cl.push(this.getSpaceAroundVals(this.spaceAround));
        cl.push(";'");
    }
    cl.push("'>");

    if (this.rows) {
        this.rows.forEach(function (row) {
            if (row.type == 'Row') {
                cl.push(this.getRow(row));
            }
        }, this);
    }

    cl.push('</div>');
    return cl.join('');
};

RowSet.prototype.executeValidation = function () {
    return this.rows.every(function (row) {
        return row.items.every(function (item) {
            return item.executeValidation();
        });
    });
};

RowSet.prototype.enable = function () {
    return this.rows.every(function (row) {
        return row.items.every(function (item) {
            item.enable();
            return true;
        });
    });
};

RowSet.prototype.disable = function () {
    return this.rows.every(function (row) {
        return row.items.every(function (item) {
            item.disable();
            return true;
        });
    });
};

RowSet.prototype.getRow = function (row) {
    var cl = ["<div class='w-100'"];
    if (Array.isArray(row.spaceAround)) {
        cl.push(" style='padding:");
        cl.push(this.getSpaceAroundVals(row.spaceAround));
        cl.push(";'");
    }
    cl.push('>');
    if (row.items) {
        var i = 0;
        row.items.forEach(function (item) {
            cl.push("<div class='nuan-row nuan-row-item");
            var obj = Control.createControlInstance(item, this.main);
            row.items[i] = obj;
            if (row.spaceAround && i == 0) {
                cl.push(' mt-0');
            }
            // cl.push(that.getContainerAlignment(obj));
            // cl.push(that.showSeperator(obj));
            cl.push("'");
            cl.push(Control.getContStyleProps(obj));
            cl.push('>');

            obj.main = this.main;
            cl.push(obj.getHTML());
            cl.push('</div>');
            i += 1;
        }, this);
    }

    cl.push('</div>');
    return cl.join('');
};

RowSet.prototype.getSpaceAroundVals = function (spaces) {
    var s = [];
    spaces.forEach(function (v) {
        s.push(v + 'px');
    });

    return s.join(' ');
};

RowSet.prototype.getRowForMapper = function (id) {
    for (var i = 0; i < this.rows.length; i += 1) {
        if (id == this.rows[i].id) {
            return this.rows[i];
        }
    }
    return null;
};

RowSet.prototype.resetData = function () {
    return this.rows.every(function (row) {
        return row.items.every(function (item) {
            item.resetData();
            return true;
        });
    });
};
 // eslint-disable-line semi,quotes,no-trailing-spaces,no-unused-expressions
    function Switch(control) {
    Switch.super.call(this, control);
    this.defaultStatus = this.active;
}
Utils.extend(Switch, Control);

Switch.prototype.onClickEvent = function (e) {
    if (e.target) {
        this.state = e.target.checked ? 'on' : 'off';
        if (this.event) {
            Switch.superProto.invokeHandlers.call(this);
        }
    }
};

Switch.prototype.getOutputText = function () {
    return this.state;
};

Switch.prototype.registerHandlers = function () {
    Switch.superProto.registerHandlers.call(this);
    this.main.ev.registerEventHandler(this, 'onClick');
};

Switch.prototype.getHTML = function () {
    var st = ['<div'];

    if(this.ariaHidden) {
        st.push(" aria-hidden='" + this.ariaHidden + "'");
    }

    st.push(">");

    st.push("<label><input type='checkbox' class='nuan-switch'");
    if (this.active) {
        st.push(' checked');
        this.state = 'on';
    } else {
        this.state = 'off';
    }
    st.push(' id=');
    st.push(this.id);

    if (this.main.isReloading) {
        st.push(' disabled');
    }

    st.push(' ></><div><div></div></div></label>');

    st.push('</div>');
    if (this.hiddenLabel) {
        this.encodeLabel(this.hiddenLabel);
    }

    this.registerHandlers();
    return st.join('');
};

Switch.prototype.resetData = function () {
    this.active = this.defaultStatus;
};

 // eslint-disable-line semi,quotes,no-trailing-spaces,no-unused-expressions
    function Box(control) {
    Box.super.call(this, control);
}

Utils.extend(Box, Control);
Box.prototype.onClickEvent = function () {
    if (this.event) {
        Box.superProto.invokeHandlers.call(this);
    }
};

Box.prototype.registerHandlers = function () {
    if (this.event) {
        Box.superProto.registerHandlers.call(this);
        this.main.ev.registerEventHandler(this, 'onClick');
    }
};

Box.prototype.getHTML = function () {
    this.registerHandlers();
    var t = "<div class='w-100 nuan-flex nuan-box-container' style='justify-content:";
    t += (this.boxAlign == 'left' ? 'flex-start' : (this.boxAlign == 'right' ? 'flex-end' : 'center'));
    t += ";'><div";
    t += (this.id ? ' id=' + this.id : '');
    if (!this.width || !this.height) return;
    if (this.width == 'fill') {
        t += " class='w-100' style='";
    } else {
        t += " style='position:relative;width:";
        t += this.width;
        t += 'px;';
    }

    t += 'height:';
    t += this.height;
    t += 'px;';

    if (this.context) {
        var st = this.getStyle.call(this.context);
        t += st.substring(7, st.length - 1);
        /* if(this.context.backgroundColor){
                t.push("background-color:");
                t.push(Control.setStyleVal(this.context.backgroundColor));
            }

            if(this.context.borderColor){
                t.push("border-width:1px;");
                t.push("border-style:solid;");
                t.push("border-color:");
                t.push(Control.setStyleVal(this.context.borderColor));
            }
            if(this.context.borderWidth){
                t.push("border-width:");
                t.push(Control.setStyleVal(this.context.borderWidth+"px"));
            }

            if(this.context.borderRadius){
                t.push("border-radius:");
                t.push(Control.setStyleVal(this.context.borderRadius+"px"));
            }

            if(this.context.boxPadding) {
                t.push("padding:");
                t.push(Control.setStyleVal(this.context.boxPadding+"px"));
            } */
    }
    t += "'";
    t += this.ariaHidden ? " aria-hidden='" + this.ariaHidden + "'>": ">";

    if (this.item) {
        t += "<div class='box-item";
        t += (this.itemAlign == 'middle' ? ' middle' : (this.itemAlign == 'end' ? ' end' : ''));
        t += "' style='padding:";
        t += (this.context && this.context.boxPadding ? this.context.boxPadding : '5');
        t += "px;'>";
        var c = Control.createControlInstance(this.item, this.main);
        c.main = this.main;
        t += c.getHTML();
        t += '</div>';
        this.item = c;
    }
    t += '</div></div>';

    return t; // eslint-disable-line consistent-return
};

Box.prototype.executeValidation = function () {
    if (this.item) {
        return this.item.executeValidation();
    }
    return true;
};

Box.prototype.enable = function () {
    if (this.item) {
        this.item.enable();
    }
};

Box.prototype.disable = function () {
    if (this.item) {
        this.item.disable();
    }
};

Box.prototype.resetData = function () {
    if (this.item) {
        this.item.resetData();
    }
};
 // eslint-disable-line semi,quotes,no-trailing-spaces,no-unused-expressions
    function TimePicker(control) {
    TimePicker.super.call(this, control);
}
Utils.extend(TimePicker, QuickReplyButton);

TimePicker.prototype.getHTML = function () {
    var els = "<div class='timepicker-container'";
    els += this.ariaHidden ? " aria-hidden='" + this.ariaHidden + "'>": ">";

    if (this.times) {
        this.text = this.times;
    } else {
        this.text = [];
        var cDate = new Date();
        var startTime = this.getTime(cDate, this.startHour, this.startMinute);
        var endTime = this.getTime(cDate, this.endHour, this.endMinute);

        for (var i = startTime; i < endTime; i += (this.step * 60000)) {
            var d = new Date(i);
            var h = d.getHours();
            var m = d.getMinutes();
            this.text.push((this.format == '12' ? (h >= 12 ? (h - 12 == 0 ? '00' : h - 12) : h) : h) + ':' + (m < 10 ? '0' : '') + m + (this.format == '12' ? (h >= 12 ? 'PM' : 'AM') : ''));
        }
    }
    els += TimePicker.superProto.getHTML.call(this);
    els += '</div>';
    return els;
};

TimePicker.prototype.getTime = function (dt, hour, minutes) {
    return new Date(dt.getTime() + (minutes * 60000) + (hour * (60 * 60000))).getTime();
};
 // eslint-disable-line semi,quotes,no-trailing-spaces,no-unused-expressions
    function LinkPreview(control) {
    LinkPreview.super.call(this, control);
}

Utils.extend(LinkPreview, Control);

LinkPreview.prototype.onClickEvent = function (e) {
    if (this.event) {
        LinkPreview.superProto.invokeHandlers.call(this);
    }

    if (this.launchMode && this.metaUrl) {
        switch (this.launchMode) {
        case 'parent':
            window.open(this.metaUrl, '_parent');
            break;
        case 'tab':
            window.open(this.metaUrl, '_blank');
            break;
            // no default
        }
    }
    e.preventDefault();
};

LinkPreview.prototype.registerHandlers = function () {
    LinkPreview.superProto.registerHandlers.call(this);
    this.main.ev.registerEventHandler(this, 'onClick');
};

LinkPreview.prototype.getHTML = function () {
    this.registerHandlers();
    var lp = "<div class='w-100 nuan-link-preview nuan-row flex-container ";
    lp += (this.size == 'small' ? 'small_prev' : '');
    lp += "' data-id=";
    lp += this.id;
    lp += '>';
    lp += "<div class='col-md-4'>";
    // if(this.size == "small"){
    // lp.push("<img src=", this.metaImgSrc," height='60px' width='100%'/>");

    // } else {
    // lp.push("<img src=", this.metaImgSrc," height='120px' width='100%'/>");
    // }
    lp += "<div class='meta_img_cont'";
    lp += this.getImageStyle();
    lp += '>';
    lp += '</div>';
    lp += '</div>';
    lp += "<div class='col-md-8'>";
    if (this.metaTitle) {
        this.textColor = this.context ? this.context.titleColor : this.textColor;
        // this.level = "4";
        // this.text = this.metaTitle;
        // this.textAlign = "left";
        lp += "<a href='";
        lp += this.metaUrl;
        lp += "' id=";
        lp += this.id;
        if (this.textColor) {
            lp += " style='color:";
            lp += this.textColor;
            lp += ";'";
        }
        lp += '>';
        lp += this.metaTitle;
        lp += '</a>';
    }

    if (this.metaDescription) {
        this.textColor = this.context ? this.context.descriptionColor : this.textColor;
        this.text = this.metaDescription;
        this.textAlign = 'left';
        lp += new Paragraph(this).getHTML();
    }

    lp += '</div>';

    lp += '</div>';
    if (this.hiddenLabel && this.id) {
        this.encodeLabel(this.hiddenLabel);
    }
    return lp;
};
LinkPreview.prototype.getImageStyle = function () {
    var style = ["style='"];
    if (this.metaImgSrc) {
        style.push('background-image:');
        style.push(Control.setStyleVal('url(' + this.metaImgSrc + ')'));
        style.push('background-size:');
        if (this.width && this.height) {
            style.push(Control.setStyleVal(this.width + 'px ' + this.height + 'px'));
        } else {
            style.push('contain;');
        }
        style.push('background-repeat:');
        style.push(Control.setStyleVal('no-repeat'));
        style.push('background-position:');
        style.push(Control.setStyleVal('center'));
    }
    style.push("'");
    return style.join('');
};

LinkPreview.prototype.isPlugin = function () {
    return true;
};

LinkPreview.prototype.renderPlugin = function () {
    if (!this.id) return true;
    var el = this.main.dv.container.querySelector('#' + this.id);

    if (el) {
        this.linkClick = $bind(this, this.onClickEvent);
        el.addEventListener('click', this.linkClick);
        return true;
    }
    return false;
};

LinkPreview.prototype.clear = function () {
    var el = this.main.dv.container.querySelector('#' + this.id);

    if (el) {
        el.removeEventListener('click', this.linkClick);
    }
};
 // eslint-disable-line semi,quotes,no-trailing-spaces,no-unused-expressions
    function FileUpload(control) {
    FileUpload.super.call(this, control);
    this.files = [];
}

Utils.extend(FileUpload, Control);

FileUpload.prototype.getHTML = function () {
    var fup = ["<div class='nuan-file-upload mt-0'", this.limit == 1 ? "style=' justify-content:center;'" : '', ' id=', this.id];

    if(this.ariaHidden) {
        fup.push(" aria-hidden='" + this.ariaHidden + "'");
    }

    fup.push(">");

    fup.push("<div class='nuan-upload-cont upload-button'");
    if (this.context) {
        if (this.context.fileBackground) {
            fup.push(" style='background-color:", this.context.fileBackground, ";'");
        }
    }
    fup.push("><i class='nuan-upload-plus nuan-fi-center'></i><input type='file' accept='.jpg,.jpeg,.gif,.png,.doc,.xls,.docx,.xlsx,.pdf,.txt'>");
    fup.push('</div>');
    fup.push('</div>');
    if (this.limit > 2) {
        fup.push("<nav class='carousel-nav'");
        if(this.ariaHidden) {
            fup.push(" aria-hidden='" + this.ariaHidden + "'");
        }
        fup.push("><button class='disabled prev' id='prev'><i class='arrow-left icon'></i></button><button class='next' id='next'><i class='arrow-right icon'></i></button></nav>");
    }

    return fup.join('');
};

FileUpload.prototype.getFileContent = function (fileType, filename) {
    var f = ["<div class='nuan-upload-cont'"];

    if (this.context) {
        if (this.context.fileBackground) {
            f.push(" style='background-color:", this.context.fileBackground, ";'");
        }
    }

    f.push("><i class='nuan-fi-close'", ' data-id=', this.files.length - 1, '></i>');
    if ((fileType == 'png' || fileType == 'jpeg' || fileType == 'jpg') && this.imagePreview) {
        f.push("<div class='image_preview'", ' data-id=', this.files.length - 1, '></div>');
    } else {
        this.imagePreview = false;
        f.push(this.getTypePreview(filename,fileType));

    }

    f.push('</div>');
    return f.join('');
};

FileUpload.prototype.getTypePreview = function(filename, fileType) {
    var str = "<div class='nuan-fi nuan-fi-center nuan-fi-";
    str += fileType;
    str += "'><div class='fi-content'>";
    str += fileType;
    str += "</div></div><div class='nuan-fi-name'>";
    str += filename;
    str += "</div>";
   return str;
};

FileUpload.prototype.isPlugin = function () {
    return true;
};

FileUpload.prototype.renderPlugin = function () {
    if (!this.id) {
        return true;
    }

    this.cont = this.main.dv.container.querySelector('#' + this.id);

    if (this.cont) {
        this.fileInput = this.main.dv.container.querySelector('.nuan-file-upload input[type=file]');
        this.uploadButtonCont = this.main.dv.container.querySelector('.upload-button');
        this.uploadFIle = $bind(this, this.onChange);
        this.fileInput.addEventListener('change', this.uploadFIle);
        this.fileInput.focus();
        this.containerClick = $bind(this, this.onContClick);
        this.cont.addEventListener('click', this.containerClick);

        if (this.limit > 2) {
            intializeScrollable(this.cont, this);
        }
        return true;
    }
    return false;
};

FileUpload.prototype.onChange = function (e) {
    var file = e.target.files[0];
    if (file) {
        this.files.push(file);
        var ft = file.type.split('/').pop();
        this.uploadButtonCont.insertAdjacentHTML('beforebegin', this.getFileContent(ft, file.name));
        this.setUploadButtonVisibility();
        if (this.event) {
            FileUpload.superProto.invokeHandlers.call(this);
        }
        if ((ft == 'png' || ft == 'jpeg' || ft == 'jpg') && this.imagePreview) {
            this.readUrl();
        }
    }
};
FileUpload.prototype.readUrl = function () {
    var that = this;
    setTimeout(function () {
        if (that.files.length > 0) {
            var file = that.files[that.files.length - 1];
            var reader = new FileReader();
            var els = that.main.dv.container.querySelectorAll('.image_preview');
            reader.onload = function (e) {
                if (els && els.length > 0) {
                    var el = els[els.length - 1];
                    // el.setAttribute("src",e.target.result);
                    el.style.backgroundImage = "url('" + e.target.result + "')";
                } else {
                    that.readUrl();
                }
            };
            reader.onerror = function() {
                if (els && els.length > 0) {
                    var el = els[els.length - 1];
                    var fileContentContainer = el.parentElement;
                    fileContentContainer.removeChild(el);
                    fileContentContainer.insertAdjacentHTML('afterend', this.getTypePreview(file.type.split('/').pop(), file.name));
                }
            };

            reader.readAsDataURL(file);
        }
    }, 1000);
};

FileUpload.prototype.onContClick = function (e) {
    var id = e.target.getAttribute('data-id');
    if (id && e.target.classList.contains('nuan-fi-close')) {
        e.target.parentElement.parentNode.removeChild(e.target.parentElement);
        id = parseInt(id, 10);
        this.files.splice(id, 1);
        this.setUploadButtonVisibility();
        this.fileInput.value = "";
    }
};
FileUpload.prototype.setUploadButtonVisibility = function () {
    if (this.files.length == this.limit) {
        this.uploadButtonCont.style.display = 'none';
    } else {
        this.uploadButtonCont.style.display = 'block';
    }
    this.uploadButtonCont.parentElement.scrollLeft = this.uploadButtonCont.parentElement.scrollWidth;
};

FileUpload.prototype.getScrollRightSize = function() {
     return getWidgetRightScrollSize(this.cont,  this.cont.querySelectorAll('.nuan-upload-cont'));
};

FileUpload.prototype.getScrollLeftSize = function() {
    return getWidgetLeftScrollSize(this.cont,  this.cont.querySelectorAll('.nuan-upload-cont'));
};

FileUpload.prototype.clear = function () {
    // var el = this.main.dv.container.querySelector(".nuan-file-upload input[type=file]");

    if (this.fileInput) {
        this.fileInput.removeEventListener('click', this.uploadFIle);
        this.cont.removeEventListener('click', this.containerClick);
    }
    this.uploadButtonCont = null;
    this.cont = null;
    this.fileInput = null;

    if (this.prev) {
        this.prev.removeEventListener('mousedown', this.mouseDownHandler);
        this.prev.removeEventListener('mouseup', this.mouseUpPrevHandler);
    }
    if (this.next) {
        this.next.removeEventListener('mousedown', this.mouseDownHandler);
        this.next.removeEventListener('mouseup', this.mouseUpNextHandler);
    }
    this.next = null;
    this.prev = null;
    this.mouseDownHandler = null;
    this.mouseUpPrevHandler = null;
    this.mouseUpNextHandler = null;
    this.nextDisabled = this.prevDisabled = false;
};
 // eslint-disable-line semi,quotes,no-trailing-spaces,no-unused-expressions
    function CoverImage(control) {
    CoverImage.super.call(this, control);
}

Utils.extend(CoverImage, Control);

CoverImage.prototype.getHTML = function () {
    var ci = ["<div class='nuan-img-cont-wrap nuan-cover-image mr-0 mt-0' id=", this.id];

    if(this.ariaHidden) {
        ci.push(" aria-hidden='" + this.ariaHidden + "'");
    }

    ci.push(" style='");
    if (this.context) {
        if (this.context.align) {
            switch (this.context.align) {
            case 'left':
                ci.push('margin-right:auto !important;');
                break;
            case 'right':
                ci.push('margin-left:auto;');
                break;
                // no default
            }
        }
        if (this.context.imageBorderRadius) {
            ci.push('border-radius:');
            ci.push(this.context.imageBorderRadius);
            ci.push('px;');
        }
        if (this.context.imageBorderColor) {
            ci.push('border-color:');
            ci.push(this.context.imageBorderColor);
            ci.push(';');
        }

        if (this.context.imageBorderWidth) {
            ci.push('border-width:');
            ci.push(this.context.imageBorderWidth);
            ci.push('px;');
        }
    }

    if (this.width) {
        ci.push('width:', this.width, 'px;');
    }

    if (this.height) {
        ci.push('height:', this.height, 'px;');
    }

    if (this.url) {
        var u = Utils.parseValueFromConstant(this.url, this.main) || this.url;
        ci.push('background-image:URL(', u, ');');
    }

    if (this.hiddenLabel && this.id) {
        this.encodeLabel(this.hiddenLabel);
    }

    ci.push("'>");

    ci.push("<div class='nuan-modal'> <span class='nuan-close nuan-cursor'>&times;</span><div class='nuan-modal-content'><img src='", this.url, "' style='width:100%'></div></div>");

    ci.push('</div>');

    return ci.join('');
};

CoverImage.prototype.isPlugin = function () {
    return true;
};
CoverImage.prototype.onImageClick = function () {
    var el = this.img.querySelector('#' + this.id + ' .nuan-modal');
    if (el) {
        el.style.display = 'block';
    }
};
CoverImage.prototype.onCloseClick = function (e) {
    var el = this.img.querySelector('#' + this.id + ' .nuan-modal');
    if (el) {
        el.style.display = 'none';
    }
    e.stopPropagation();
};
CoverImage.prototype.renderPlugin = function () {
    var that = this;
    if (!this.id) return true;

    that.img = that.main.dv.container.querySelector('#' + that.id);
    if (that.img) {
        that.imageClick = $bind(this, this.onImageClick);
        that.img.addEventListener('click', this.imageClick);
        that.closeClick = $bind(this, this.onCloseClick);
        that.img.querySelector('.nuan-close').addEventListener('click', that.closeClick);
        return true;
    }

    return false;
};

CoverImage.prototype.clear = function () {
    var that = this;
    that.img.removeEventListener('click', this.imageClick);
    that.img.querySelector('.nuan-close').removeEventListener('click', that.closeClick);
};
 // eslint-disable-line semi,quotes,no-trailing-spaces,no-unused-expressions
    function TabButton(control) {
    TabButton.super.call(this, control);
}

Utils.extend(TabButton, Control);

TabButton.prototype.getHTML = function () {
    var tb = ["<div class='nuan-button-group", this.topSeparator ? ' top-seperator' : '', this.layout == 'vertical' ? ' vertical-group' : '', "'"]; var that = this;
    if(this.ariaHidden) {
        tb.push(" aria-hidden='" + this.ariaHidden + "'");
    }
    tb.push(">");
    this.items.forEach(function (button) {
        var t = ["<div class='nuan-tab-button", that.horizontalSeparator ? ' hor-seperator' : (that.verticalSeparator ? ' ver-seperator' : ''), "'>"];
        var but = new Button(button);
        but.main = that.main;
        t.push(but.getHTML());
        t.push('</div>');

        tb.push(t.join(''));
    });
    tb.push('</div>');

    return tb.join('');
};

TabButton.prototype.disable = function () {
    this.items.forEach(function (b) {
        b.disable();
    });
};

TabButton.prototype.enable = function () {
    this.items.forEach(function (b) {
        b.enable();
    });
};
 // eslint-disable-line semi,quotes,no-trailing-spaces,no-unused-expressions
    function VideoViewer(control) {
    VideoViewer.super.call(this, control);
}

Utils.extend(VideoViewer, Control);

VideoViewer.prototype.getHTML = function () {
    var vel = ['<video id=', this.id, " class='nuan-video-viewer'"];
    if (this.posterImageUrl) {
        vel.push(' poster=', this.posterImageUrl);
    }

    if(this.ariaHidden) {
        vel.push(" aria-hidden='" + this.ariaHidden + "'");
    }

    vel.push(" style='width:", this.width ? this.width : '100%', ";'");

    vel.push(' controls', this.autoPlay ? ' autoplay' : '', '>');
    vel.push('<source src=', this.sourceUrl, ' type=', this.sourceContentType, '> </source>');
    vel.push('</video>');

    return vel.join('');
};
 // eslint-disable-line semi,quotes,no-trailing-spaces,no-unused-expressions
    function Slider(control) {
    this.selectedTick = 0;
    Slider.super.call(this, control);
    this.defaultTick = this.selectedTick;
}

Utils.extend(Slider, Control);

Slider.prototype.getOutputText = function () {
    return this.selectedTickValue;
};

Slider.prototype.getHTML = function () {
    var step = this.step = this.step || 10;
    var min = this.minValue = this.minValue || 0;
    var max = this.maxValue = this.maxValue || 100;
    this.selectedTick = this.selectedTick || min;
    this.setTickValue();
    var sl = [];

    if (this.label) {
        sl.push("<label class='nuan-label' ");
        sl.push(this.getStyle());
        if(this.ariaHidden) {
            sl.push(" aria-hidden='" + this.ariaHidden + "'");
        }
        sl.push('>');
        sl.push(this.label);
        sl.push('</label>');
        this.encodeLabel(this.label);
    } else if (this.hiddenLabel) {
        this.encodeLabel(this.hiddenLabel);
    }

    sl.push("<div class='nuan-tick-slider'", this.id ? 'id=' + this.id : '');

    if(this.ariaHidden) {
        sl.push(" aria-hidden='" + this.ariaHidden + "'");
    }

    sl.push(">");

    sl.push("<div class='nuan-tick-slider-background'");

    if (this.context) {
        var c = this.context;
        sl.push(" style='");
        if (c.backgroundColor) {
            sl.push('background-color:');
            sl.push(Control.setStyleVal(c.backgroundColor));
        }
        if (c.backgroundColorFrom && c.backgroundColorTo) {
            sl.push('background-image:');
            sl.push('linear-gradient(to right,', c.backgroundColorFrom, ',', c.backgroundColorTo, ');');
        }
        sl.push("'");
    }
    sl.push('></div>');

    sl.push("<div class='nuan-tick-slider-progress' style='");
    sl.push('width:');
    sl.push(Control.setStyleVal(this.getSliderPercent() * 100 + '%'));
    if (this.context.progressColor) {
        sl.push('background-color:');
        sl.push(Control.setStyleVal(this.context.progressColor));
    }
    sl.push("'></div>");

    sl.push("<div class='nuan-tick-slider-tick-container'>");

    var spacing = step;
    var sliderRange = max - min;
    var tickCount = sliderRange / spacing + 1; // +1 to account for 0

    for (var ii = 0; ii < tickCount; ii += 1) {
        sl.push("<span class='nuan-tick-slider-tick'>");
        sl.push('</span>');
    }
    sl.push('</div>');

    sl.push("<input class='tick-slider-input' type='range'");

    sl.push(' min=', min);
    sl.push(' max=', max);
    sl.push(' step=', step);
    sl.push(' value=', this.selectedTick);
    sl.push(' data-tick-step=', step);
    /* sl.push(" data-tick-id='sizeTicks'");
      sl.push(" data-value-id='sizeValue'");
      sl.push(" data-progress-id='sizeProgress'");
      sl.push(" data-handle-size='18'");
      sl.push(" data-min-label-id='sizeLabelMin'");
      sl.push(" data-max-label-id='sizeLabelMax'"); */

    sl.push(' >');
    sl.push('</input>');
    sl.push('</div>');
    sl.push("<div class='nuan-tick-slider-value-container'");

    if(this.ariaHidden) {
        sl.push(" aria-hidden='" + this.ariaHidden + "'");
    }

    sl.push(">");

    if (this.tickValues && !this.showSliderValue) {
        this.tickValues.forEach(function (val) {
            sl.push("<div class='tick-slider-label'>");
            sl.push(val);
            sl.push('</div>');
        });
    }
    if (this.showSliderValue) {
        sl.push("<div class='nuan-tick-slider-label'>");
        sl.push(min);
        sl.push('</div>');
        sl.push("<div class='nuan-tick-slider-label'>");
        sl.push(max);
        sl.push('</div>');
        sl.push("<div class='nuan-tick-slider-value'></div>");
    }

    sl.push('</div>');
    return sl.join('');
};

Slider.prototype.isPlugin = function () {
    return true;
};

Slider.prototype.setTickValue = function () {
    if (this.tickValues) {
        if (this.selectedTick == this.minValue) {
            this.selectedTickValue = this.tickValues[0];
        } else {
            this.selectedTickValue = this.tickValues[this.selectedTick / this.step];
        }
    }
};

Slider.prototype.renderPlugin = function () {
    if (!this.id || this.main.isReloading) {
        return true;
    }
    var sliderContainer = this.main.dv.container.querySelector('#' + this.id);
    this.slider = sliderContainer.querySelector('input[type="range"]');
    this.sliderProgress = sliderContainer.querySelector('.nuan-tick-slider-progress');
    this.sliderVal = sliderContainer.parentElement.querySelector('.nuan-tick-slider-value');
    if (this.slider) {
        this.sliderInput = $bind(this, this.onSliderInput);
        this.slider.addEventListener('input', this.sliderInput);
        return true;
    }
    this.updateValue(this.slider);
    this.updateValuePosition(this.slider);
    return false;
};

Slider.prototype.onSliderInput = function (event) {
    var that = this;
    this.selectedTick = event.target.value;
    this.setTickValue();
    this.updateProgress(event.target);
    this.updateValue(this.slider);
    this.updateValuePosition(this.slider);
    if (this.timerId != -1) {
        clearTimeout(this.timerId);
    }
    this.timerId = setTimeout(function () {
        if (that.event) {
            Slider.superProto.invokeHandlers.call(this);
        }
    }, 2000);
};

Slider.prototype.getSliderPercent = function () {
    var range = this.maxValue - this.minValue;
    var absValue = this.selectedTick - this.minValue;

    return absValue / range;
};

Slider.prototype.updateProgress = function (slider) {
    var percent = this.getSliderPercent(slider);
    this.sliderProgress.style.width = percent * 100 + '%';
};

Slider.prototype.updateValue = function (slider) {
    if (this.sliderVal) {
      this.sliderVal.innerHTML = '<div>' + slider.value + '</div>';
    }
};

Slider.prototype.updateValuePosition = function (slider) {
   if (this.sliderVal) {
    var percent = this.getSliderPercent();

    var sliderWidth = slider.getBoundingClientRect().width;
    var valueWidth = this.sliderVal.getBoundingClientRect().width;

    var left = percent * (sliderWidth - 18) + 18 / 2 - valueWidth / 2;

    left = Math.min(left, sliderWidth - valueWidth);
    left = slider.value === slider.min ? 0 : left;

    this.sliderVal.style.left = left + 2 + 'px';
  }
};

Slider.prototype.enable = function () {
   this.isEnabled = true;
   this.renderPlugin();
};

Slider.prototype.disable = function () {
  this.isEnabled = false;
  this.clear();
};


Slider.prototype.clear = function () {
    if (this.slider) {
        this.slider.removeEventListener('input', this.sliderInput);
    }
    this.sliderProgress = null;
};
Slider.prototype.resetData = function () {
    this.selectedTick = this.defaultTick;
};
 // eslint-disable-line semi,quotes,no-trailing-spaces,no-unused-expressions
    function GMap(control) {
    GMap.super.call(this, control);
}

Utils.extend(GMap, Control);

GMap.prototype.getHTML = function () {
    var ci = ["<div class='nuan-map-widget mr-0 mt-0' id=", this.id];
    ci.push(" style='");
    if (this.context) {
        if (this.context.align) {
            switch (this.context.align) {
            case 'left':
                ci.push('margin-right:auto !important;');
                break;
            case 'right':
                ci.push('margin-left:auto;');
                break;
                // no default
            }
        }
        if (this.context.mapBorderRadius) {
            ci.push('border-radius:');
            ci.push(this.context.mapBorderRadius);
            ci.push('px;');
        }
        if (this.context.mapBorderColor) {
            ci.push('border-color:');
            ci.push(this.context.mapBorderColor);
            ci.push(';');
        }
    }

    ci.push('width:', this.width || '240', 'px;');

    ci.push('height:', this.height || '240', 'px;');

    ci.push("'");

    if(this.ariaHidden) {
        ci.push(" aria-hidden='" + this.ariaHidden + "'");
    }

    ci.push(">");

    ci.push('</div>');

    return ci.join('');
};

GMap.prototype.isPlugin = function () {
    return true;
};

GMap.prototype.renderPlugin = function () {
    if (!this.id) return true;
    this.mapCanvas = this.main.dv.container.querySelector('#' + this.id);
    if (this.mapCanvas) {
        if ((typeof google !== 'undefined' && google.maps) || window.initMapWidget) {
            if (typeof google === 'undefined') {
                return false;
            }
            this.initMap();
        } else {
            window.initMapWidget = this.initMap.bind(this);
            this.addMapScript(this.key || 'AIzaSyDmODzVAnfVQJAQz8BG9Low_F_8mFTHWNw');
        }
        return true;
    }
    return false;
};
GMap.prototype.initMap = function () {
    var that = this;
    this.map = new google.maps.Map(this.mapCanvas, {});
    var bounds = new google.maps.LatLngBounds();

    function renderMarker() {
        if (that.locations && that.locations.length > 0) {
            that.locations.forEach(function (marker) {
                var position = new google.maps.LatLng(marker.lat, marker.lng);
                bounds.extend(position);
                that.displayMap(position, marker.address, marker.name);
            });

            if (that.locations.length > 1) {
                that.map.fitBounds(bounds);
            } else {
                var center = new google.maps.LatLng(that.locations[0].lat, that.locations[0].lng);
                that.map.setCenter(center);
                that.map.setZoom(17);
            }
        }
    }

    if (this.addresses) {
        var geocoder = new google.maps.Geocoder();
        this.locations = [];
        this.addresses.forEach(function (address, index) {
            geocoder.geocode({ address: address }, function (results, status) {
                if (status == 'OK') {
                    that.displayMap(results[0].geometry.location.lat(), results[0].geometry.location.lng());

                    that.locations.push({
                        lat: results[0].geometry.location.lat(),
                        lng: results[0].geometry.location.lng(),
                        address: address,
                    });
                }

                if (index == that.addresses.length - 1) {
                    renderMarker();
                }
            });
        });
    } else {
        renderMarker();
    }
};
GMap.prototype.displayMap = function (latLngObj, address) {
    var marker = new google.maps.Marker({
        position: latLngObj,
        map: this.map,
        title: address,
    });

    marker.setMap(this.map);
    var infoWindow = new google.maps.InfoWindow({
        content: address,
    });

    google.maps.event.addListener(marker, 'click', (function (markerp, map) {
        return function () {
            infoWindow.open(map, markerp);
        };
    }(marker, this.map)));
};

GMap.prototype.addMapScript = function (key) {
    var s = document.createElement('script');
    s.setAttribute('src', 'https://maps.googleapis.com/maps/api/js?key=' + key + '&callback=initMapWidget');
    document.body.appendChild(s);
};
 // eslint-disable-line semi,quotes,no-trailing-spaces,no-unused-expressions
    function FormattedLinkText(control) {
    FormattedLinkText.super.call(this, control);
}

Utils.extend(FormattedLinkText, Paragraph);
FormattedLinkText.prototype.getHTML = function () {
    var h = '<p';
    h += " class='nuan-para nuan-link' ";
    h += this.getStyle();
    h += this.ariaHidden ? " aria-hidden='" + this.ariaHidden + "'": "";
    h += '>';
    h += this.text.replace(/{link:(.*?),(.*?),(.*?)}/g, this.replacer.bind(this));
    h += '</p>';
    if (this.hiddenLabel) {
        this.encodeLabel(this.hiddenLabel);
    }
    return h;
};

FormattedLinkText.prototype.onClickEvent = function (e) {
    for (var i = 0; i < e.target.attributes.length; i++) {
        var attrib = e.target.attributes[i];
        if (attrib.specified && attrib.name.startsWith('vtz-')) {
            this[attrib.name.split("-")[1]] = attrib.value;
        }
    }

    if (this.event) {
        FormattedLinkText.superProto.invokeHandlers.call(this);
    }
    this.selectedLinkText = e.target.text;
    e.preventDefault();
};

FormattedLinkText.prototype.getOutputText = function () {
    return this.selectedLinkText;
};

FormattedLinkText.prototype.replacer = function (match, p1, p2, p3) {
    var link = "<a class='formatted-link' href='";
    link += p1;
    link += "'";

    if (this.context) {
        if (this.context.linkColor) {
            this.textColor = this.context.linkColor;
        }
        if (this.context.linkStyle) {
            this.textStyle = this.context.linkStyle;
        }
        if (this.context.linkSize) {
            this.textSize = this.context.linkSize;
        }
    }
    link += this.getStyle();
    if (p3) {
        p3 = p3.split(',');
        p3.forEach(function (attr) {
            link += ' ';
            link += attr;
        });
    }
    link += '>';
    link += p2;
    this.linkText = p2;
    link += '</a>';

    return link;
};

FormattedLinkText.prototype.isPlugin = function () {
    return true;
};

FormattedLinkText.prototype.renderPlugin = function () {
    var that = this;
    if (!this.id || this.main.isReloading) {
        return true;
    }
    var els = this.main.dv.container.querySelectorAll('a.formatted-link');

    if (els && els.length > 0) {
        this.linkClick = $bind(this, this.onClickEvent);
        Array.prototype.forEach.call(els, function(el) {
            el.addEventListener('click', that.linkClick);
        });
        return true;
    }
    return false;
};

FormattedLinkText.prototype.resetData = function() {
   this.selectedLinkText = "";
   Object.keys(this).forEach(function(key) {
        if (key.startsWith('vtz')) {
            this[key] = "";
        }
   });
};

FormattedLinkText.prototype.clear = function () {
    var els = this.main.dv.container.querySelectorAll('a');

    if (els && els.length > 0) {
        els.forEach(function (el) {
            el.removeEventListener('click', this.linkClick);
        });
    }
};
 // eslint-disable-line semi,quotes,no-trailing-spaces,no-unused-expressions
    function StoreFactory() {
    this.nodeStore = [];
    this.transitionStore = [];
    this.constantStore = null;
}

StoreFactory.getFromStore = function (type) {
    if (type === 'NS') {
        return this.nodeStore;
    } if (type === 'TS') {
        return this.transitionStore;
    } if (type === 'CS') {
        return this.constantStore;
    }
    return null;
};

StoreFactory.setupFactory = function (spec, initialView, main) {
    var nodes = spec.nodes;
    var iv = initialView || spec.initialState;
    if (nodes) {
        nodes.forEach(function (node) {
            var n = new NodeStore(node, main);
            this.nodeStore.push(n);
            if (node.id === iv) {
                this.initialNode = n;
            }
        }, this);
    }

    if (spec.transitions) {
        spec.transitions.forEach(function (transition) {
            this.transitionStore.push(new TransitionStore(transition));
        }, this);
    }

    if (spec.constants) {
        this.constantStore = new ConstantStore(spec.constants);
    }
};

StoreFactory.getInitialNode = function () {
    return this.initialNode ? this.initialNode : this.nodeStore[0];
};

StoreFactory.getNodefromStore = function (viewName) {
    if (this.nodeStore) {
        var node;
        this.nodeStore.forEach(function (n) {
            if (n.id == viewName) {
                node = n;
            }
        });
        return node;
    }
    return null;
};

StoreFactory.getNodeIndex = function (nodeID) {
    var index = 1;
    if (this.nodeStore) {
        this.nodeStore.some(function (n) {
            if (n.id == nodeID) {
                return true;
            }
            index += 1;
            return false;
        });
    }
    return index;
};

StoreFactory.getTransitionFromStore = function (eventName, nodename) {
    var t;
    if (this.transitionStore) {
        for (var i = 0; i < this.transitionStore.length; i++) {
            t = this.transitionStore[i].getFromStoreByEvent(eventName, nodename);
            if (t) {
                return t;
            }
        }
    }
    return t;
};

StoreFactory.getNodeSize = function () {
    return this.nodeStore.length;
};

StoreFactory.setupFactory.clear = function () {
    this.nodeStore = [];
};
 // eslint-disable-line semi,quotes,no-trailing-spaces,no-unused-expressions
    function NodeStore(node, main) {
    this.id = node.id;
    var that = this; var
        conInst;
    this.controls = [];
    this.context = node.context;

    Object.keys(node.controls || {}).forEach(function (key) {
        conInst = Control.createControlInstance(node.controls[key], main);
        if (conInst) {
            that.controls.push(conInst);
            conInst.main = main;
        }
    });
}

NodeStore.prototype.getControls = function () {
    return this.controls;
};
 // eslint-disable-line semi,quotes,no-trailing-spaces,no-unused-expressions
    function VisibilityHandler() {
    this.controls = {};
}
VisibilityHandler.prototype.addToHandler = function (control) {
    var arr = this.controls[control.visible.trigger];
    if (!arr) {
        this.controls[control.visible.trigger] = [control];
    } else {
        arr.push(control);
    }
};

VisibilityHandler.prototype.invokeVisibilityEvent = function (eventname) {
    var arr = this.controls[eventname];
    if (arr) {
        arr.forEach(function (item) {
            item.onVisibilityChange();
        });
    }
};

VisibilityHandler.prototype.clear = function () {
    this.controls = {};
};
 // eslint-disable-line semi,quotes,no-trailing-spaces,no-unused-expressions
    function EnableDisableHandler() {
    this.controls = {};
}
EnableDisableHandler.prototype.addToHandler = function (control) {
    var arr = this.controls[control.enabled.trigger];
    if (!arr) {
        this.controls[control.enabled.trigger] = [control];
    } else {
        arr.push(control);
    }
};

EnableDisableHandler.prototype.invokeEvent = function (eventname) {
    var arr = this.controls[eventname];
    if (arr) {
        arr.forEach(function (item) {
            item.onEnableDisableChange();
        });
    }
};

EnableDisableHandler.prototype.clear = function () {
    this.controls = {};
}; // eslint-disable-line semi,quotes,no-trailing-spaces,no-unused-expressions
    var RENDERING_STATE = {
    STARTING: 'STARTING',
    STARTED: 'STARTED',
    PRESENTED: 'PRESENTED',

};

function DynamicView(richInstance) {
    this.questions = [];
    this.richInstance = richInstance;
    this.ariaOutput = [];
}

DynamicView.prototype.createPaginationContainer = function (parent) {
    var container = document.createElement('div');
    container.classList.add('nuan-node-bar-container');
    parent.appendChild(container);
    return container;
};
DynamicView.prototype.displayPagination = function (container, obj, sf) {
    this.isPaginationDisplayed = true;
    var fragment = document.createDocumentFragment();
    var firstChild = document.createElement('div');
    fragment.appendChild(firstChild);

    var w = container.clientWidth;
    var cs = getComputedStyle(container);
    var pl = parseFloat(cs.paddingLeft);
    var pr = parseFloat(cs.paddingRight);
    w = w - pl - pr;
    var p = ["<div class='nuan-node-bar'  style='"];
    this.pageHW = parseInt(w / StoreFactory.getNodeSize.call(sf), 10);
    if (obj.background) {
        p.push('background-color:', obj.background, ';');
    }
    p.push('width:', this.pageHW, "px;'");
    p.push('></div>');
    fragment.firstChild.innerHTML = p.join('');
    container.appendChild(fragment);
    this.sf = sf;
    this.pContainer = container;
    this.pageSpec = obj;
};

DynamicView.prototype.updatePagination = function (index) {
    var el = this.pContainer.querySelector('.nuan-node-bar');
    if (el) {
        if (!this.pageSpec.style || this.pageSpec.style == 'step') {
            el.style.left = (el.clientWidth * (index - 1)) + 'px';
        } else {
            el.style.width = (this.pageHW * index) + 'px';
        }
    }
};

DynamicView.prototype.initRendering = function (node, container, type, isBuilder, lock) {
    this.currentVisibleNode = node;
    this.container = this.container || container;
    this.state = RENDERING_STATE.STARTING;
    this.continerHTML = [];
    this.type = this.type || type;
    this.shouldLock = typeof lock == "undefined" ? true : lock;
    this.isBuilder = this.isBuilder || isBuilder;
    this.continerHTML.push("<div tabindex='-1' class='nuan-widget-container nuan-mlr-1 nuan-p-1 ");

    this.continerHTML.push(this.type ? this.type : 'form');
    if (this.isBuilder) {
        this.continerHTML.push(' builder');
    }
    this.continerHTML.push("'");

    console.debug('node: ', node);
    this.continerHTML.push(this.widgetContainerStyle(node.context), '>');
    this.questions = [];
    this.ariaOutput = [];
    this.renderVisibleNode();

    if (this.isPaginationDisplayed) {
        this.updatePagination(StoreFactory.getNodeIndex.call(this.sf, node.id));
    }

    if (this.richInstance.viewRenderedCb) {
    	this.richInstance.viewRenderedCb(this.ariaOutput);
    }
};

DynamicView.prototype.initRenderingPartial = function (container, control, main, isBuilder) {
    this.container = container;
    this.continerHTML = [];
    this.continerHTML.push("<div class='nuan-widget-container nuan-mlr-1 nuan-p-1");
    this.isBuilder = isBuilder;
    if (this.isBuilder) {
        this.continerHTML.push(' builder');
    }
    this.continerHTML.push("'>");
    var that = this;
    this.isBuilder = isBuilder;
    if (control.items) {
        control.items.forEach(function (controlItem) {
            var cont = Control.createControlInstance(controlItem, main);
            cont.main = main;
            that.renderControl(cont);
        });
    } else if (control.type) {
        var cont = Control.createControlInstance(control, main);
        cont.main = main;
        that.renderControl(cont);
    }
    this.continerHTML.push('</div>');
    this.insertFragment();
};

DynamicView.prototype.widgetContainerStyle = function (styleObj) {
    if (!styleObj) return '';

    var s = [" style='"];

    if (styleObj.widgetContainerBorderRadius) {
        s.push('border-radius:', styleObj.widgetContainerBorderRadius, 'px;');
    }

    if (styleObj.widgetContainerBorderColor) {
        s.push('border-color:', styleObj.widgetContainerBorderColor, ';');
        s.push('border-width:1px;');
        s.push('border-style:solid;');
    }

    if (styleObj.widgetContainerBackgroundColor) {
        s.push('background-color:', styleObj.widgetContainerBackgroundColor, ';');
    }

    if (typeof styleObj.widgetContainerPadding !== 'undefined') {
        s.push('padding:', styleObj.widgetContainerPadding, 'px!important;');
    }

    if (styleObj.widgetContainerShadow) {
        styleObj.boxShadow = styleObj.widgetContainerShadow;
        Control.getBoxShadow(styleObj, s);
    }

    if (styleObj.widgetMarginLeft >= 0) {
        s.push('margin-left:', styleObj.widgetMarginLeft, 'px;');
    }

    if (styleObj.widgetMarginRight >= 0) {
        s.push('margin-right:', styleObj.widgetMarginRight, 'px;');
    }
    s.push("'");

    return s.join('');
};

DynamicView.prototype.renderVisibleNode = function () {
    var that = this, ariaVal;
    this.state = RENDERING_STATE.STARTED;

    // this.transformScrollingCtrls = [];
    this.plugins = [];
    this.carousels = [];
    this.currentVisibleNode.getControls().forEach(function (control) {
        that.renderControl(control);
        ariaVal = control.getAriaOutput();
        if (ariaVal) {
        	that.ariaOutput.push(ariaVal);
        }
    });

    this.continerHTML.push('</div>');
    if (this.isBuilder) {
        // this.continerHTML.push(this.addDropZone());
        this.continerHTML.push(this.getToolTipForBuilder());
    }

    this.state = RENDERING_STATE.PRESENTED;
    // this.container.innerHTML = this.continerHTML.join("");
    this.insertFragment();
};

DynamicView.prototype.insertFragment = function () {
    var fragment = document.createDocumentFragment();
    var firstChild = document.createElement('div');
    firstChild.style.height = '100%';
    if (this.isBuilder) {
        firstChild.classList.add('builder');
    }
    firstChild.classList.add('nuan-widget-outer-cont');

    fragment.appendChild(firstChild);
    fragment.firstChild.innerHTML = this.continerHTML.join('');
    this.container.appendChild(fragment);
    // this.registerEventListener();
    if (!this.richInstance.isReloading) {
    	this.renderPlugins(0);
    } else if(this.carousels.length > 0) {
        var tempPlugin = this.plugins;
        this.plugin = this.carousels;
        this.renderPlugins(0);
        this.plugins = tempPlugin;
    }
};

DynamicView.prototype.renderControl = function (control) {
    var that = this;
    that.continerHTML.push('<div class="nuan-row');
    if (!control.checkVisibleCondition()) {
        that.continerHTML.push(' displayoff');
    }
    if (control.type) {
    	that.continerHTML.push(" " + control.type.toLowerCase());
	}
    that.continerHTML.push(that.getContainerAlignment(control));
    that.continerHTML.push(that.showSeperator(control));
    that.continerHTML.push('" ');
    that.continerHTML.push(Control.getContStyleProps(control));

    if (control.isPlugin()) {
        that.plugins.push(control);
        if (control.type == "CardDeck") {
            that.carousels.push(control);
        }

    }
    that.continerHTML.push('>');
    that.continerHTML.push(control.getHTML());
    if (that.isBuilder) {
        that.continerHTML.push(that.getToolTipForBuilder());
    }
    that.continerHTML.push('</div>');
};

DynamicView.prototype.addDropZone = function () {
    this.continerHTML.push('<div class="nuan-row dropzone');
    this.continerHTML.push('">');
    this.continerHTML.push('<p> Drop zone </p>');
    this.continerHTML.push('</div>');
};

DynamicView.prototype.getToolTipForBuilder = function () {
    return "<div class='richio-tooltip'><div class='btn-group mr-2' role='group'><i class='far fa-edit'></i><i class='far fa-trash-alt ml-1'></i></div></div>";
};

DynamicView.prototype.getContainerAlignment = function (control) {
    switch (control.containerAlign) {
    case 'alignParentBottom':
        return ' container-positioning-bottom';
    case 'alignParentLeft':
        break;
    case 'alignParentRight':
        return ' container-positioning-right';
    case 'alignParentCenter':
        return ' container-positioning-center';
        // no default
    }

    return '';
};

DynamicView.prototype.showSeperator = function (control) {
    if (control.containerSeperator || control.containerSeparator) {
        return ' container-seperator';
    }
    return '';
};

DynamicView.prototype.renderPlugins = function (index) {
    var that = this;
    var i = index;
    if (that.plugins) {
        setTimeout(function () {
            for (; i < that.plugins.length; i++) {
                if (!that.plugins[i].renderPlugin()) {
                    setTimeout(that.renderPlugins.bind(that, i), 100);
                }
            }
        }, 200);
    }
};

DynamicView.prototype.clear = function (offload) {
    this.currentVisibleNode = null;
    this.state = RENDERING_STATE.STARTING;
    if (this.plugins) {
        for (var i = 0; i < this.plugins.length; i++) {
            this.plugins[i].clear();
        }
        this.plugins = [];
    }

    if (this.carousels) {
        this.carousels = [];
    }

    if (this.container && offload) {
        this.container.innerHTML = '';
    } else if (this.container) {
        var inCont = this.container.querySelector('.nuan-widget-outer-cont');
        inCont.parentElement.removeChild(inCont);
    }
};

DynamicView.prototype.getPresentedQuestion = function () {
    return this.questions;
};

DynamicView.prototype.setNodesValues = function (sf, obj) {
    if (obj) {
        var ctrls = StoreFactory.getNodefromStore.call(main.sf, obj.name.slice(1)).getControls(); // eslint-disable-line no-unused-vars
    }
};
DynamicView.prototype.getNodeID = function () {
    return this.currentVisibleNode && this.currentVisibleNode.id;
};

DynamicView.prototype.getNodeName = function () {
    return this.currentVisibleNode && this.currentVisibleNode.name;
};


 // eslint-disable-line semi,quotes,no-trailing-spaces,no-unused-expressions
    function TransitionHandler(main) {
    this.main = main;
    this.answers = [];
    this.anserCache = {};
}

TransitionHandler.prototype.handleTransition = function (eventName, validate) {
    var main = this.main;
    var cvn = main.dv.currentVisibleNode;

    var transition = StoreFactory.getTransitionFromStore.call(main.sf, eventName, cvn.id);
    if (transition != null) {
        if (validate && !this.validate(cvn)) {
            return;
        }
        if (typeof transition.to === 'string') {
            this.transitionView(transition.to);
        } else {
            var guard = transition.to.guard;
            if (guard) {
                if (Utils.isObject(guard)) {
                    if (this.checkGuardCondition(guard.condition)) {
                        if (typeof guard.onTrue === 'string') {
                            this.transitionView(guard.onTrue);
                        } else {
                            this.sendCustomAction(guard.onTrue);
                        }
                    } else if(guard.onFalse) {
                        this.transitionView(guard.onFalse);
                    } else {
                        this.answers = [];
                    }
                } else {
                    var guItem;
                    var i;
                    for (i = 0; i < guard.length; i++) {
                        guItem = guard[i];
                        if (this.checkGuardCondition(guItem.condition)) {
                            if (typeof guItem.onTrue === 'string') {
                                this.transitionView(guItem.onTrue);
                            } else {
                                this.sendCustomAction(guard.onTrue);
                            }
                            break;
                        }
                    }

                    if (i === guard.length) {
                        if(guard.onFalse) {
                            this.transitionView(guard.onFalse);
                        } else {
                            this.answers = [];
                        }
                    }
                }
            } else {
                this.sendCustomAction(transition.to);
            }
        }
    }
};

TransitionHandler.prototype.transitionView = function (view) {
    if (view) {
        var dv = this.main.dv;
        if (dv.isBuilder) {
            if (this.main.builderViewChangeStartCb) this.main.builderViewChangeStartCb();
        }
        var oQues = dv.getPresentedQuestion();
        var oAns = this.answers;
        var oNodeId = dv.getNodeID();
        var oNodeName = dv.getNodeName();
        this.answers = [];
        clear(this.main);
        dv.initRendering(StoreFactory.getNodefromStore.call(this.main.sf, view));
        if (dv.isBuilder) {
            setTimeout(function () {
                if (this.main.builderViewChangeCb) this.main.builderViewChangeCb();
            }.bind(this), 1000);
        } else if (this.main.viewChangeCb) {
            this.main.viewChangeCb(oNodeId, oNodeName, oQues, oAns, dv.getNodeID(), dv.getNodeName(), dv.getPresentedQuestion());
        }
    }
};

TransitionHandler.prototype.resetCurrrentNode = function () {
    var node = this.main.dv.currentVisibleNode;
    var ctrls = node.getControls();
    for (var i = 0; i < ctrls.length; i++) {
        ctrls[i].resetData();
    }
    if (this.main.dv.isBuilder) {
        if (this.main.builderViewChangeStartCb) this.main.builderViewChangeStartCb();
    }
    clear(this.main);
    this.answers = [];
    this.main.dv.initRendering(node);

    if (this.main.dv.isBuilder) {
        setTimeout(function () {
            if (this.main.builderViewChangeCb) this.main.builderViewChangeCb();
        }.bind(this), 1000);
    }
};

TransitionHandler.prototype.validate = function (node) {
    var ctrls = node.getControls(), retVal = [];
    for (var i = 0; i < ctrls.length; i++) {
        if(!ctrls[i].executeValidation()) {
        	retVal.push(ctrls[i]);
        }

    }
    if (retVal.length > 0) {
    	retVal[0].forceFocus();
    	return false;
    }
    return true;
};

TransitionHandler.prototype.checkGuardCondition = function (condition) {
    return Utils.executeParser(jsep(condition), this.main);
};

TransitionHandler.prototype.sendCustomAction = function (obj) {
    if (obj.engage) {
        var outComeText = 'Customer navigated to chat window';
        if (obj.engage.checkAgent && obj.engage.data && this.main.CIAPIinst) {
            var d = obj.engage.data;
            var agent = obj.engage.checkAgent;
            var that = this;
            if (d.businessUnitID) {
                this.main.CIAPIinst.checkAgentAvailability(d.businessUnitID, d.agentGroupId, d.agentAttrs, d.queueThreshold, function (data) {
                    if (Object(data) == data) {
                        var viewName;
                        if (!data.inHOP) {
                            viewName = agent.isNotHop;
                            outComeText = 'Not in hours of operation';
                        } else if (data.status == 'offline') {
                            viewName = agent.offline;
                            outComeText = 'Agents are offline';
                        } else if (data.status == 'buzy') {
                            viewName = agent.buzy;
                            outComeText = 'Agents are buzy';
                        } else if (data.status == 'online') {
                            that.sendEngageAction(obj.engage);
                        }
                        that.fireAutomatonEndEvent(outComeText);
                        if (viewName) {
                            that.transitionView(viewName);
                        }
                    }
                });
            }
        } else {
            this.sendEngageAction(obj.engage);
            this.fireAutomatonEndEvent(outComeText);
        }
    } else if (obj.survey) {
        this.parseResult(obj);
        var outCome = 'Customer completed post chat survey.';
        if (this.main.cb) {
            this.main.cb(obj);
        }
        this.main.disable();
        this.fireAutomatonEndEvent(outCome);
    } else if (Array.isArray(obj)) {
        for (var i = 0; i < obj.length; i++) {
            if (typeof obj[i] === 'string') {
                this.transitionView(obj[i]);
            } else {
                this.sendCustomAction(obj[i]);
            }
        }
    } else if (obj) {
    	var cObj = JSON.parse(JSON.stringify(obj));
        this.parseResult(cObj);
        if (this.main.dv.shouldLock) {
        	this.main.disable();
        } else {
        	this.anserCache = {};
        }

        if (this.main.cb) {
            this.main.cb(cObj);
        }
        this.fireAutomatonEndEvent("Customer selection made");
    }
};

TransitionHandler.prototype.sendEngageAction = function (obj) {
    if (obj.datapass) {
        this.parseResult(obj);
    }

    this.main.disable();
    if (this.main.cb) {
        this.main.cb(obj);
    }
};

TransitionHandler.prototype.fireAutomatonEndEvent = function (outComeText) {
    if (this.main.viewEndedCb) {
        var dv = this.main.dv;
        this.main.viewEndedCb(dv.getNodeID(), dv.getNodeName(), true, outComeText, dv.getPresentedQuestion(), this.answers);
        this.main.viewChangeCb = null;
        this.main.viewEndedCb = null;
    }
};

TransitionHandler.prototype.parseResult = function (obj) {
    for (var o in obj) { // eslint-disable-line no-restricted-syntax,guard-for-in
        var sm = obj[o];
        if (sm instanceof Object) {
            this.parseValues(sm);
        }
    }
};

TransitionHandler.prototype.parseValues = function (sm) {
    var oItem;
    var val;
    var keys = Object.keys(sm);
    for (var item = 0; item < keys.length; item++) {
        oItem = sm[keys[item]];
        if (typeof oItem === 'object') {
            if (keys[item].startsWith('constants')) {
                oItem.forEach(function (cItem) {
                    this.parseValues(cItem);
                }.bind(this));
            }
        } else if (oItem.startsWith('#EmptyAnswer')) {
            // eslint-disable-line no-empty
        } else if (oItem.indexOf('#') == 0 && oItem.charAt(1) != "#") {
            if (this.anserCache[oItem]) {
                val = this.anserCache[oItem];
            } else {
                val = Utils.valueMapper(jsep(oItem), this.main);
                this.anserCache[oItem] = val;
            }
            sm[keys[item]] = val;
        } else if (oItem.indexOf('{#') != -1) {
        	sm[keys[item]] = Utils.parseValueMapperTemplate(oItem, this.main);
        }
    }
};

TransitionHandler.prototype.addToAnswers = function (obj) {
    this.answers.push(obj);
};
 // eslint-disable-line semi,quotes,no-trailing-spaces,no-unused-expressions
    function ConstantStore(constants) {
    if (Array.isArray(constants)) {
        constants.forEach(function (constant) {
            this[constant.name] = constant.value;
        }, this);
    }
}

ConstantStore.prototype.getConstant = function (key) {
    key = key.slice(1);
    return this[key] ? this[key] : key;
};
 // eslint-disable-line semi,quotes,no-trailing-spaces,no-unused-expressions
    function TransitionStore(transition) {
    if (Utils.isObject(transition)) {
        Utils.copy(transition, this);
    }
}

TransitionStore.prototype.getFromStoreByEvent = function (eventName, nodename) {
    if (this.trigger === eventName && this.from === nodename) {
        return this;
    }
    return null;
};
 // eslint-disable-line semi,quotes,no-trailing-spaces,no-unused-expressions

    function clear(richMain, offload) {
        richMain.dv.clear(offload);
        richMain.vh.clear();
        richMain.edh.clear();
        richMain.ev.clearListeners();
        // StoreFactory.clear.call(richMain.sf);
    }

    function RichMain() { // eslint-disable-line no-shadow
        this.sf = new StoreFactory();
        this.dv = new DynamicView(this);
        this.ev = new EventDispatcher();
        this.vh = new VisibilityHandler();
        this.edh = new EnableDisableHandler();
        this.th = new TransitionHandler(this);
    }

    /**
     * Method that is exposed for CI to initiate a rich widget rendering.
     * Calling this method allows reporting events to fire the passed callbacks
     * @param {object} params
     **/
    RichMain.prototype.renderRichContentinCI = function (params) {
        this.viewStartedCb = params.viewStartedCb;
        this.viewChangeCb = params.viewChangeCb;
        this.viewEndedCb = params.viewEndedCb;
        this.renderRichContent(params.spec, params.container, params.initalView, params.isReload, false, params.viewRenderedCb);
    };

    RichMain.prototype.renderRichContent = function (spec, container, initalView, isReload, isBuilder, viewRenderedCb) {
        if (!Utils.isObject(spec)) {
            log('spec param should be a json object');
        }
        if (!container) {
            log('Container must be provided to render rich html');
        }
        var pCont;
        if (spec.pagination) {
            pCont = this.dv.createPaginationContainer(container);
        }
        this.isReloading = isReload || false;
        if (viewRenderedCb) {
            this.viewRenderedCb = viewRenderedCb;
        }
        StoreFactory.setupFactory.call(this.sf, spec, initalView, this);
        if (!isReload) {
        	this.ev.addDispatcherToContainer(container);
        }
        console.debug('this.sf: ', this.sf);
        console.debug('getInitialNode: ', StoreFactory.getInitialNode.call(this.sf));
        this.dv.initRendering(StoreFactory.getInitialNode.call(this.sf), container, spec.widgetType, isBuilder, spec.lockView);

        if (this.viewStartedCb) {
            this.viewStartedCb(this.dv.getNodeID(), this.dv.getNodeName(), this.dv.getPresentedQuestion());
        }
        if (spec.pagination) {
            this.dv.displayPagination(pCont, spec.pagination, this.sf, container);
        }


        if (isSupportedTouch()) {
        	container.classList.add('can-touch');
    	}


        return this;
    };
    RichMain.prototype.renderRichContentWithPagination = function (spec, container, paginationContainer, initalView, isReload) {
        var page = spec.pagination;
        var
            cont;
        spec.pagination = null;
        cont = this.renderRichContent(spec, container, initalView, isReload);
        if (page) {
            this.dv.displayPagination(paginationContainer, page, this.sf);
        }
        return cont;
    };

    RichMain.prototype.renderRichContentForBuilder = function (spec, container, initalView, isReload, viewChangeStartCb, viewChangecb) {
        this.builderViewChangeCb = viewChangecb;
        this.builderViewChangeStartCb = viewChangeStartCb;
        return this.renderRichContent(spec, container, initalView, isReload, true);
    };

    RichMain.prototype.renderSurveyContent = function (params) {
        this.viewStartedCb = params.viewStartedCb;
        this.viewChangeCb = params.viewChangeCb;
        this.viewEndedCb = params.viewEndedCb;
        this.viewRenderedCb = params.viewRenderedCb;
        var page = params.spec.pagination;
        var cont;
        if (params.paginationContainer) {
            params.spec.pagination = null;
        }
        cont = this.renderRichContent(params.spec, params.container, params.initalView || '', params.isReload || false, false);
        if (page && params.paginationContainer) {
            this.dv.displayPagination(params.paginationContainer, page, this.sf);
        }
        this.CIAPIinst = params.CIAPI;
        return cont;
    };

    RichMain.prototype.fireCloseSurveyEvent = function () {
        if (this.viewEndedCb) {
            this.viewEndedCb(this.dv.getNodeID(), this.dv.getNodeName(), false, 'Customer closed chat window', this.dv.getPresentedQuestion(), this.th.answers, StoreFactory.getNodeIndex.call(this.sf, this.dv.getNodeID()) > 1);
        }
    };

    RichMain.prototype.renderPartialContent = function (spec, container, isBuilder) {
        this.dv.initRenderingPartial(container, spec, this, isBuilder);
    };

    RichMain.prototype.disable = function () {
        this.dv.currentVisibleNode.getControls().forEach(function (con) {
            con.disable();
        });
    };
    RichMain.prototype.enable = function () {
        this.dv.currentVisibleNode.getControls().forEach(function (con) {
            con.enable();
        });
    };

    RichMain.prototype.registerCBListener = function (cb) {
        this.cb = cb;
    };

    RichMain.prototype.setControlValues = function () {

    };

    RichMain.prototype.clearAll = function () {
        clear(this, true);
    };

    return {
        setup: function (config) {
            if (!Utils.isObject(config)) {
                log('allowed type for config param is object');
            }
            configObj = {
              /**
                * Base path where media is located.
                * If none is set Rich Media engine presumes relative paths.
                */
                skinPath: config.skinPath ? config.skinPath : ""
            };

            if (config.isDivChat) {
                doc = window.parent.document; // eslint-disable-line no-unused-vars
            }
        },
        get instance() {
            return new RichMain();
        },

    };
}());

window.RichMain = RichMain;
