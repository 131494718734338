define("ember-context-menu/templates/components/context-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "hI5kEQbo",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[4,\"if\",[[22,[\"isActive\"]]],null,{\"statements\":[[4,\"ember-wormhole\",null,[[\"to\"],[\"wormhole-context-menu\"]],{\"statements\":[[0,\"    \"],[6,\"div\"],[10,\"class\",\"context-menu-container\"],[11,\"style\",[20,\"position\"],null],[8],[0,\"\\n      \"],[6,\"ul\"],[11,\"class\",[27,[\"context-menu \",[26,\"if\",[[22,[\"renderLeft\"]],\"context-menu--left\"],null]]]],[8],[0,\"\\n\"],[4,\"each\",[[22,[\"items\"]]],null,{\"statements\":[[0,\"          \"],[1,[26,\"context-menu-item\",null,[[\"item\",\"amount\",\"itemIsDisabled\",\"clickAction\"],[[21,1,[]],[22,[\"selection\",\"length\"]],[22,[\"itemIsDisabled\"]],[22,[\"clickAction\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-context-menu/templates/components/context-menu.hbs"
    }
  });
});